import React from 'react'
import { Redirect } from "react-router-dom"
import withStyles from '@material-ui/core/styles/withStyles'
import Assignment from '@material-ui/icons/Assignment'
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import Button from '../../../components/CustomButtons/Button.jsx'
import TextField from '@material-ui/core/TextField'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import CardActions from '@material-ui/core/CardActions'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Card from '../../../components/Card/Card.jsx'
import CardBody from '../../../components/Card/CardBody.jsx'
import CardIcon from '../../../components/Card/CardIcon.jsx'
import CardHeader from '../../../components/Card/CardHeader.jsx'
import token from '../../../utils/token'
import uuid from 'uuid'
import extendedTablesStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import * as constants from './constants'
import backend from '../../../utils/backend'
import crypto from 'crypto'
import { BackendError } from './../../../utils/errors'
import { showSuccessMessage, showErrorMessage } from '../../../components/Alerts/Alerts'
import Select from '@material-ui/core/Select'

class AccountPage extends React.Component {
  _isMounted = false
  state = {
    tenantName: '',
    tenantId: '',
    apiKey: '',
    apiKeyHash: '',
    apiKeyShown: '',
    tier: '',
    paymentContactEmail: null,
    expiresAt: '',
    openPaymentModal: false,
    openSubscriptionDataModal: false,
    paymentCustomerId: '',
    paymentSubscriptionId: '',
    error: {}
  }
  isExpired = false

  componentDidMount = async () => {
    this._isMounted = true
    let user
    try {
      user = token.loadUserFromToken()
      if (user) {
        this._isMounted && this.setState({ tenantName: user.company })
        try {
          const result = await backend.get(constants.TENANTS, user.company, true)
          if (result.code && result.code > 399) throw new BackendError(result)
          this._isMounted && this.setState({
            tenantId: result.id,
            tier: result.tier,
            expiresAt: result.expiresAt,
            paymentContactEmail: result.paymentContactEmail,
            paymentMethod: result.stripePaymentMethod,
            paymentCustomerId: result.stripeCustomerId,
            paymentSubscriptionId: result.stripeSubscriptionId
          })
          result.apiKeyHash && this.maskAPIKey()
        } catch (e) {
          console.error('ERROR: ', e.message)
          showErrorMessage(e.message)
        }
      } else {
        this._isMounted && this.setState({ noUser: true })
      }
    } catch (err) {
      showErrorMessage(err.message)
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  generateAPIKey = () => {
    const apiKey = uuid.v4()
    const apiKeyShown = apiKey
    const apiKeyHash = this.getAPIKeyHash(apiKey)
    this._isMounted && this.setState({ apiKey, apiKeyShown, apiKeyHash })
  }

  getAPIKeyHash = apiKey => {
    if (apiKey) {
      return crypto
        .createHash('sha256')
        .update(apiKey)
        .digest('hex')
    }
  }

  maskAPIKey = () => {
    this._isMounted && this.setState({ apiKeyShown: constants.MASK })
  }

  handleSave = async () => {
    const tenant = {
      id: this.state.tenantId,
      name: this.state.tenantName,
      apiKeyHash: this.state.apiKeyHash
    }
    try {
      const result = await backend.update(constants.TENANTS, tenant)
      if (result.code && result.code > 399) { throw new BackendError(result) }
      await showSuccessMessage('Your item has been saved!')
      this.props.history.goBack()
    } catch (e) {
      console.error('Error while Saving: ' + e.message)
      showErrorMessage('Error while Saving: ' + e.message)
    }
  }

  createExpiryCountdown (expiresAt) {
    const leftMilliSec = -(new Date() - new Date(expiresAt))
    const days = Math.floor(leftMilliSec / 1000 / 60 / 60 / 24)
    const hours = new Date(leftMilliSec).getHours()

    const expiresInString = `${days} days and ${hours} hours`
    if (days <= 0){
      this.isExpired = true
    }

    return expiresInString
  }

  closePaymentModal = () => this.setState({ openPaymentModal: false })

  closeSubscriptionDataModal = () => this.setState({ openSubscriptionDataModal: false })

  loadUserEmail = () => {
    const user = token.loadUserFromToken()
    if (!user) return ''
    return user.email || ''
  }

  showSubscriptionDetails = () => {
    this.setState({ openSubscriptionDataModal: true })
  }

  changeFieldValue = (fieldName, value) => {
    if (this.state[fieldName] === value) {
      return
    }
    const newState = { error: this.state.error }
    newState.error[fieldName] = false
    newState[fieldName] = value
    this.setState(newState)
  }

  
  contactSupport = ()=> {
    window.open("https://nowtilus.atlassian.net/servicedesk/customer/portal/2/group/12/create/25", "_blank", "noopener")
  }

  render () {
    if (this.state.noUser) {
      return <Redirect
        to="/login"
      />
    }

    const { classes, history } = this.props
    const {
      apiKey,
      apiKeyShown,
      tenantName,
      tier,
      expiresAt,
      openPaymentModal,
      openSubscriptionDataModal,
      paymentContactEmail,
      paymentMethod,
      paymentCustomerId,
      paymentSubscriptionId,
      paymentContactName,
      paymentAdressLine1,
      paymentAdressLine2,
      paymentCity,
      paymentCountry,
      paymentPostalCode,
      paymentPhone,
      paymentTaxType,
      paymentTaxId
    } = this.state

    const { TAX_TYPES, COUNTRIES } = constants
    const colors = ['trial', 'basic', 'professional', 'enterprise'].map(availTier => {
      return tier === availTier ? '#fe1f4a' : '#e2e2e2'
    })

    const defaultEmail = this.loadUserEmail()
    const email = this.state.paymentContactEmail === null ? defaultEmail : this.state.paymentContactEmail

    const tierOptionsInnerBlockHeight = 450
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>API Settings</h4>
            </CardHeader>
            <CardBody>
              <TextField
                id="apiKey"
                label="API key"
                type="search"
                className={classes.textField}
                margin="dense"
                fullWidth
                value={apiKeyShown}
                disabled={true}
              />
              <Button
                style={{ margin: '20px 10px' }}
                variant="contained"
                color="warning"
                onClick={this.generateAPIKey}
              >
                Generate
              </Button>
              <GridContainer>
              </GridContainer>
              <Button
                style={{ margin: '20px 10px' }}
                variant="contained"
                color="white"
                onClick={() => {
                  history.goBack()
                }}
              >
                Back
                </Button>
              <Button
                style={{ margin: '20px 10px' }}
                variant="contained"
                color="success"
                onClick={this.handleSave}
                disabled={!apiKey}
              >
                Save
              </Button>{' '}
            </CardBody>
          </Card>
        </GridItem>
        {tenantName && tier ? (
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Subscription Settings</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={3}>
                    <Card variant="outlined" style={{
                      height: '700px',
                      borderColor: colors[0],
                      borderWidth: "4px",
                      borderStyle: "solid"
                    }}>
                      <CardContent style={{ backgroundColor: colors[0] }}>
                        <h4>TRIAL</h4>
                        <p><span style={{ fontSize: '34px', fontWeight: 900 }}>0</span> EUR CPM</p>
                      </CardContent>
                      <CardContent style={{ textAlign: 'center', height: tierOptionsInnerBlockHeight }}>
                        <h6>PURPOSE</h6>
                        <h5>Test it out</h5>
                        <Divider />
                        <Divider />
                        <h6>HOSTING</h6>
                        <h5>Shared cluster</h5>
                        <Divider />
                        <h6>PERIOD</h6>
                        <h5>30 day Free-period</h5>
                        <Divider />
                        <h6>MONTHLY COMITTED AD EVENTS</h6>
                        <h5>Just test, we'll serve!</h5>
                        <Divider />
                        <h6>SCALE SET</h6>
                        <h5>up to 500</h5>
                        <Divider />
                        <h6>MEDIA SERVICES AD CREATIVES</h6>
                        <h5>Limited Package included</h5>
                        <Divider />
                        {
                          tier === 'trial' &&
                          !this.isExpired ? 
                          <div>
                            <h6>Expires in</h6>
                            <h5 style={{ color: "red" }}>{this.createExpiryCountdown(expiresAt)}</h5>
                          </div> :
                          <div>
                            <h6>Your 30-day free trial has been expired! Please upgrade your plan to avoid losing access, or get in touch with us via "contact@nowtilus.tv".</h6>
                          </div>
                        }
                      </CardContent>
                    </Card>
                  </GridItem>
                  <GridItem xs={3}>
                    <Card variant="outlined" style={{
                      height: '700px',
                      borderColor: colors[1],
                      borderWidth: "4px",
                      borderStyle: "solid"
                    }}>
                      <CardContent style={{ backgroundColor: colors[1] }}>
                        <h4>BASIC</h4>
                        <p><span style={{ fontSize: '36px', fontWeight: 900 }}>0,89</span> EUR CPM</p>
                      </CardContent>
                      <CardContent style={{ textAlign: 'center', height: tierOptionsInnerBlockHeight }}>
                        <h6>PURPOSE</h6>
                        <h5>Market entry</h5>
                        <Divider />
                        <Divider />
                        <h6>HOSTING</h6>
                        <h5>Shared cluster</h5>
                        <Divider />
                        <h6>PERIOD</h6>
                        <h5>Monthly recurring</h5>
                        <Divider />
                        <h6>MONTHLY COMITTED AD EVENTS</h6>
                        <h5>100.000</h5>
                        <Divider />
                        <h6>SCALE SET</h6>
                        <h5>up to 1.000</h5>
                        <Divider />
                        <h6>MEDIA SERVICES AD CREATIVES</h6>
                        <h5><a href="https://files.serverside.ai/pricing/serverside_ai_pricing.pdf" target="_blank" rel="noopener noreferrer">see pricing PDF</a></h5>
                      </CardContent>
                      {
                        tier === 'trial' &&
                        <CardActions style={{ justifyContent: 'center', margin: '25px' }}>
                          <Button style={{ backgroundColor: '#fe1f4a' }} onClick={() => this.contactSupport()}>Contact Support</Button>
                        </CardActions>
                      }
                      {
                        tier === 'basic' &&
                        <CardActions style={{ justifyContent: 'center', margin: '25px' }}>
                          <Button style={{ backgroundColor: '#fe1f4a' }} onClick={this.showSubscriptionDetails}>Subscription details</Button>
                        </CardActions>
                      }
                    </Card>
                  </GridItem>
                  <GridItem xs={3}>
                    <Card variant="outlined" style={{
                      height: '700px',
                      borderColor: colors[2],
                      borderWidth: "4px",
                      borderStyle: "solid"
                    }}>
                      <CardContent style={{ backgroundColor: colors[2] }}>
                        <h4>PROFESSIONAL</h4>
                        <p><span style={{ fontSize: '34px', fontWeight: 900 }}>0,69</span> EUR CPM</p>
                      </CardContent>
                      <CardContent style={{ textAlign: 'center', height: tierOptionsInnerBlockHeight }}>
                        <h6>PURPOSE</h6>
                        <h5>Stable and growing business</h5>
                        <Divider />
                        <Divider />
                        <h6>HOSTING</h6>
                        <h5>Managed dedicated cluster</h5>
                        <Divider />
                        <h6>PERIOD</h6>
                        <h5>Yearly recurring</h5>
                        <Divider />
                        <h6>MONTHLY COMITTED AD EVENTS</h6>
                        <h5>5.000.000</h5>
                        <Divider />
                        <h6>SCALE SET</h6>
                        <h5>up to 100.000</h5>
                        <Divider />
                        <h6>MEDIA SERVICES AD CREATIVES</h6>
                        <h5><a href="https://files.serverside.ai/pricing/serverside_ai_pricing.pdf" target="_blank" rel="noopener noreferrer">see pricing PDF</a></h5>
                      </CardContent>
                      <CardActions style={{ justifyContent: 'center', margin: '25px' }}>
                        <Button style={{ backgroundColor: '#fe1f4a' }} onClick={() => this.contactSupport()}>Contact Support</Button>
                      </CardActions>
                    </Card>
                  </GridItem>
                  <GridItem xs={3}>
                    <Card variant="outlined" style={{
                      height: '700px',
                      borderColor: colors[3],
                      borderWidth: "4px",
                      borderStyle: "solid"
                    }}>
                      <CardContent style={{ backgroundColor: colors[2] }}>
                        <h4>ENTERPRISE</h4>
                        <p><span style={{ fontSize: '34px', fontWeight: 900 }}>0,49</span> EUR CPM</p>
                      </CardContent>
                      <CardContent style={{ textAlign: 'center', height: tierOptionsInnerBlockHeight }}>
                        <h6>PURPOSE</h6>
                        <h5>Enterprise Solution</h5>
                        <Divider />
                        <Divider />
                        <h6>HOSTING</h6>
                        <h5>Dedicated - BYO Azure Subscription</h5>
                        <Divider />
                        <h6>PERIOD</h6>
                        <h5>2-Yearly recurring</h5>
                        <Divider />
                        <h6>MONTHLY COMITTED AD EVENTS</h6>
                        <h5>10.000.000</h5>
                        <Divider />
                        <h6>SCALE SET</h6>
                        <h5>Scale set by customer</h5>
                        <Divider />
                        <h6>MEDIA SERVICES AD CREATIVES</h6>
                        <h5><a href="https://files.serverside.ai/pricing/serverside_ai_pricing.pdf" target="_blank" rel="noopener noreferrer">see pricing PDF</a></h5>
                      </CardContent>
                      <CardActions style={{ justifyContent: 'center', margin: '25px' }}>
                        <Button style={{ backgroundColor: '#fe1f4a' }} onClick={() => this.contactSupport()}>Contact Support</Button>
                      </CardActions>
                    </Card>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Dialog
              open={openPaymentModal}
              onClose={this.closePaymentModal}
              aria-labelledby="form-dialog-title"
              style={{ zIndex: "1010" }}
            >
              <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  The Basic-Tier subscription has a minumum fee of 99€ per month.
                  The first invoice is executed with the subscription and charged on a monthly basis.
                  Additional Costs are based on the usage of the system and will be added to the monthly invoice.
                </DialogContentText>
                <TextField
                  error={this.state.error.paymentContactEmail}
                  margin="dense"
                  id="contactEmail"
                  label="Contact email address for billing"
                  type="text"
                  value={email || ''}
                  onChange={(event) => this.changeFieldValue('paymentContactEmail', event.target.value)}
                  fullWidth
                  style={{ marginTop: '20px' }}
                />
                <TextField
                  error={this.state.error.paymentContactName}
                  margin="dense"
                  id="paymentContactName"
                  label="Contact person full name for billing"
                  type="text"
                  value={paymentContactName || ''}
                  onChange={(event) => this.changeFieldValue('paymentContactName', event.target.value)}
                  fullWidth
                  style={{ marginTop: '20px' }}
                />
                <TextField
                  error={this.state.error.paymentAdressLine1}
                  margin="dense"
                  id="paymentAdressLine1"
                  label="Company Name"
                  type="text"
                  value={paymentAdressLine1 || ''}
                  onChange={(event) => this.changeFieldValue('paymentAdressLine1', event.target.value)}
                  fullWidth
                  style={{ marginTop: '20px' }}
                />
                <TextField
                  error={this.state.error.paymentAdressLine2}
                  margin="dense"
                  id="paymentAdressLine2"
                  label="Address"
                  type="text"
                  value={paymentAdressLine2 || ''}
                  onChange={(event) => this.changeFieldValue('paymentAdressLine2', event.target.value)}
                  fullWidth
                  style={{ marginTop: '20px' }}
                />
                <TextField
                  error={this.state.error.paymentCity}
                  margin="dense"
                  id="paymentCity"
                  label="City, district, suburb, town, or village"
                  type="text"
                  value={paymentCity || ''}
                  onChange={(event) => this.changeFieldValue('paymentCity', event.target.value)}
                  fullWidth
                  style={{ marginTop: '20px' }}
                />
                {/* <CountrySelect
                  id="paymentCountry"
                  value={paymentCountry}
                  onChange={(event) => this.setState({paymentCountry: event.target.value})}
                /> */}
                <TextField
                  error={this.state.error.paymentPostalCode}
                  margin="dense"
                  id="paymentPostalCode"
                  label="ZIP or postal code"
                  type="text"
                  value={paymentPostalCode || ''}
                  onChange={(event) => this.changeFieldValue('paymentPostalCode', event.target.value)}
                  fullWidth
                  style={{ marginTop: '20px' }}
                />
                <FormControl className={classes.formControl} fullWidth error={this.state.error.paymentCountry}>
                  <Select
                    margin="dense"
                    label="Country"
                    value={paymentCountry || ''}
                    onChange={(event) => this.changeFieldValue('paymentCountry', event.target.value)}
                    style={{ marginTop: '20px' }}
                  >
                    {COUNTRIES.map(entry =>
                      <MenuItem key={entry.code} value={entry.code}>{entry.code} - {entry.label}</MenuItem>
                    )}
                  </Select>
                  <FormHelperText>Country</FormHelperText>
                </FormControl>
                <TextField
                  error={this.state.error.paymentPhone}
                  margin="dense"
                  id="paymentPhone"
                  label="Contact person phone number"
                  type="tel"
                  value={paymentPhone || ''}
                  onChange={(event) => this.changeFieldValue('paymentPhone', event.target.value)}
                  fullWidth
                  style={{ marginTop: '20px' }}
                />
                <FormControl className={classes.formControl} fullWidth error={this.state.error.paymentTaxType}>
                  <Select
                    margin="dense"
                    label="Tax Type"
                    id="paymentTaxType"
                    value={paymentTaxType || ''}
                    onChange={(event) => this.changeFieldValue('paymentTaxType', event.target.value)}
                    style={{ marginTop: '20px' }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {TAX_TYPES.map(entry =>
                      <MenuItem key={entry.type} value={entry.type}>{entry.title}</MenuItem>
                    )}
                  </Select>
                  <FormHelperText>Tax Type (<a href="/#" target="_blank" rel="noopener noreferrer">Supported Tax Ids)</a></FormHelperText>
                </FormControl>


                <TextField
                  error={this.state.error.paymentTaxId}
                  margin="dense"
                  id="paymentTaxId"
                  label="Tax ID"
                  type="text"
                  value={paymentTaxId || ''}
                  onChange={(event) => this.changeFieldValue('paymentTaxId', event.target.value)}
                  fullWidth
                  style={{ marginTop: '20px' }}
                />
                {/* 
                    paymentTaxId: tenant.paymentTaxId,
                    paymentTaxType: tenant.paymentTaxType 
                */}
              </DialogContent>
            </Dialog>
            <Dialog
              open={openSubscriptionDataModal}
              onClose={this.closeSubscriptionDataModal}
              aria-labelledby="form-dialog-title"
              style={{ zIndex: "1009" }}
            >
              <DialogTitle id="form-dialog-title">Subscription Details</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please contact support to change your subscription related data.
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="tenantName"
                  label="Tenant name"
                  type="text"
                  value={tenantName}
                  fullWidth
                  disabled={true}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="paymentContactEmail"
                  label="Contact email address for billing"
                  type="email"
                  value={paymentContactEmail}
                  fullWidth
                  disabled={true}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="tier"
                  label="Subscription Tier"
                  type="text"
                  value={tier}
                  fullWidth
                  disabled={true}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="paymentCustomerId"
                  label="Payment Customer ID"
                  type="text"
                  value={paymentCustomerId}
                  fullWidth
                  disabled={true}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="paymentSubscriptionId"
                  label="Payment Subscription ID"
                  type="text"
                  value={paymentSubscriptionId}
                  fullWidth
                  disabled={true}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="paymentMethod"
                  label="Payment Payment Method"
                  type="text"
                  value={paymentMethod}
                  fullWidth
                  disabled={true}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeSubscriptionDataModal} color="primary">
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        ) : (
            <h4>...loading</h4>
          )}
      </GridContainer>
    )
  }
}

export default withStyles(extendedTablesStyle)(AccountPage)
