import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Assignment from '@material-ui/icons/Assignment'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import Card from '../../components/Card/Card.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import CardIcon from '../../components/Card/CardIcon.jsx'
import CardHeader from '../../components/Card/CardHeader.jsx'
import Button from '../../components/CustomButtons/Button.jsx'
import TextField from '@material-ui/core/TextField'
import extendedTablesStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import backend from '../../utils/backend'
import { showErrorMessage } from '../../components/Alerts/Alerts'
import { BackendError } from '../../utils/errors'
import PlaylistWidget from "./PlaylistWidget"
import AddIcon from '@material-ui/icons/Add';
import { IconButton } from '@material-ui/core';
import nameGenerate from 'project-name-generator'

class Details extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      playlist: {
        name: nameGenerate({words: 2, alliterative: true}).spaced + " Playlist",
        type: 'vod',
        startDate: new Date().toISOString().slice(0,16)
      }
    }
  }

  componentDidMount = async () => {
    const playlistSchema = await backend.schema('playlists')
    this.setState({
      playlistSchema,
      availTypes: playlistSchema.properties.type.enum,
      availStatus: playlistSchema.properties.status.enum,
    })
    if (this.props.id) {
      try {
        const playlist = await backend.get('playlists', this.props.id)
        const schema = playlistSchema
        this.setState({
          schema,
          playlist
        })
      } catch (e) {
        return showErrorMessage('Error while Loading. ' + e.message)
      }
    }
  }

  onSave = async () => {
    const upsert = this.state.playlist.id ? backend.update : backend.create
    try {
      const result = await upsert('playlists', this.state.playlist)
      if (result.code && result.code > 399) throw new BackendError(result)
    } catch (e) {      
      console.error(e)
      showErrorMessage(e)
    }
  }

  onChange = (event) => {
    const playlist = this.state.playlist
    console.log(event.target.name, event.target.value)
    playlist[event.target.name] = event.target.value
    this.setState({playlist})
  }
  
  addContent = () => {
    const playlist = this.state.playlist
    playlist.contents = playlist.contents || []
    playlist.contents.push({
      sourceType: "url",
      spliceType: "seconds",
      cuePoints: []
    })
    this.setState({playlist})
  }

  buildUpdateConent = (contentId) => {
    return (event) => {
      const playlist = this.state.playlist
      console.log(event.target)
      playlist.contents[contentId][event.target.name] = event.target.value
      this.setState({playlist})
    }
  }

  deleteContent = (i) => {
    const playlist = this.state.playlist
    playlist.contents.splice(i,1)
    this.setState({playlist})
  }

  addCuePoint = (i) => {
    let playlist = this.state.playlist
    playlist.contents[i].cuePoints.push({
      spliceTime: 0,
      maxAds: 1,
      maxDuration: 30
    })
    this.setState({playlist})
  }

  buildUpdateCuePoint = (contentId, cuePointId) => {
    return (event) => {
      let playlist = this.state.playlist
      console.log(event.target)
      playlist.contents[contentId].cuePoints[cuePointId][event.target.name] = event.target.value
      this.setState({playlist})
    }
  }

  deleteCuePoint = (contentIndex, cuePointIndex) => {
    let playlist = this.state.playlist
    playlist.contents[contentIndex].cuePoints.splice(cuePointIndex,1)
    this.setState({playlist})
  }

  render () {
    const { classes } = this.props

    const backButton = <Button onClick={() => this.props.toggleDisplay()}>Back</Button>

    let header
    let content

    const playlist = this.state.playlist
    console.log(playlist)
    playlist.contents = playlist.contents || []

    if (this.state.status === 404) {
      content = <CardBody><h4>Playlist not found</h4>{backButton}</CardBody>
    } else {
      header = <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <Assignment />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>Playlist Details</h4>
      </CardHeader>
      content = (<CardBody>
        <GridContainer>
          <GridItem xs={6}>
            <TextField
              id="name"
              name="name"
              label="Name"
              value={playlist.name || ''}
              onChange={this.onChange}
              margin="normal"
              fullWidth
            />
          </GridItem>
          <GridItem xs={6}>
            {/* <TextField
              id="channelId"
              name="channelId"
              label="Channel"
              value={playlist.channelId || ''}
              onChange={this.onChange}
              margin="normal"
              fullWidth
            /> */}
          </GridItem>
          <GridItem xs={4}>
            <FormControl fullWidth>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Select
                name="status"
                value={playlist.status || 'disabled'}
                onChange={this.onChange}
                fullWidth
                inputProps={{
                  name: 'status',
                  id: 'status'
                }}
              >
                {this.state.availStatus ? (
                  this.state.availStatus.map(status => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key={'placeholder'} value={''}>
                    loading...
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={4}>
            <FormControl fullWidth>
              <InputLabel htmlFor="type">Type</InputLabel>
              <Select
                name="type"
                value={playlist.type || 'vod'}
                onChange={this.onChange}
                fullWidth
                inputProps={{
                  name: 'type',
                  id: 'type'
                }}
              >
                {this.state.availTypes ? (
                  this.state.availTypes.map(type => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))
                ) : (
                    <MenuItem key={'placeholder'} value={''}>
                      loading...
                </MenuItem>
                  )}
              </Select>
            </FormControl>
          </GridItem>
          {
            playlist.type === 'vod2live' &&
            <GridItem xs={4}>
              <form className={classes.container} noValidate>
                <TextField
                  id="startDate"
                  name="startDate"
                  label="Start Date (UTC)"
                  type="datetime-local"
                  value={playlist.startDate}
                  onChange={this.onChange}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </form>
            </GridItem>
          }
        </GridContainer>
        <h3>Playlist Items</h3>
        <PlaylistWidget
          buildUpdateCuePoint={this.buildUpdateCuePoint}
          buildUpdateConent={this.buildUpdateConent}
          updateContent={this.updateContent}
          deleteContent={this.deleteContent}
          addCuePoint={this.addCuePoint}
          deleteCuePoint={this.deleteCuePoint}
          playlist={playlist}
        />
        <GridContainer
          justify="space-between" 
          dir="rtl"
          style={{marginTop: "10px"}}
        >
          <GridItem>
            <IconButton 
              onClick={this.addContent}
              aria-label="add"
            >
              <AddIcon />
            </IconButton>
          </GridItem>
        </GridContainer>
        {backButton}
        <Button onClick={this.onSave}>Save</Button>
      </CardBody>)
    }

    return (
      <GridItem xs={12}>
        <Card>
          {header}
          {content}
        </Card>
      </GridItem>
    )
  }
}

export default withStyles(extendedTablesStyle)(Details)