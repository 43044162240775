import React, { Component } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'
import GridItem from '../../components/Grid/GridItem.jsx'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import Button from '../../components/CustomButtons/Button.jsx'
import Input from '@material-ui/core/Input'

class Subscriptions extends Component {

  addSubscription() {
    const subscriptions = this.props.subscriptions || []
    subscriptions.push({
      topic: "workflows.jobs.failed",
      channel: 'email',
      listenTo: 'own',
    })
    this.setState({
      subscriptions: subscriptions
    })
  }

  removeSubscription(position) {
    this.setState({
      subscriptions: this.props.subscriptions.splice(position, 1)
    })
  }

  renderSubscriptionDetails(subscription, i, handleChange) {
    const isValidWebhookUrl = subscription.webhookUrl ? subscription.webhookUrl.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm) : false
    if (subscription.channel === 'slack') return (
      <GridItem>
        <TextField
          name={"app_metadata.subscriptions." + i + ".webhookUrl"}
          label="Webhook Url"
          helperText={!isValidWebhookUrl ? 'Enter a valid url' : ''}
          error={!isValidWebhookUrl}
          type="text"
          margin="dense"
          onChange={handleChange}
          fullWidth
          value={subscription.webhookUrl || ''}
        />
      </GridItem>
    )
    else if (subscription.channel === 'webhook') {
      return (
        <GridItem>
          <TextField
            name={"app_metadata.subscriptions." + i + ".webhookUrl"}
            label="Webhook Url"
            helperText={!isValidWebhookUrl ? 'Enter a valid url' : ''}
            error={!isValidWebhookUrl}
            type="text"
            margin="dense"
            onChange={handleChange}
            fullWidth
            value={subscription.webhookUrl || ''}
          />
          <FormControl
            style={{marginTop:'10px'}}
          >
            <InputLabel shrink htmlFor={"subscriptions." + i}>
              Method
            </InputLabel>
            <Select
              value={subscription.webhookMethod}
              onChange={handleChange}
              input={<Input name={"app_metadata.subscriptions." + i + ".webhookMethod"}
              id={"subscriptions." + i + ".webhookMethod"} />}
              name={"app_metadata.subscriptions." + i + ".webhookMethod"}
            >
              <MenuItem value={'get'}>GET</MenuItem>
              <MenuItem value={'post'}>POST</MenuItem>
              <MenuItem value={'put'}>PUT</MenuItem>
              <MenuItem value={'delete'}>DELETE</MenuItem>
            </Select>
          </FormControl>
        </GridItem>
      )
    }
  }

  render() {
    const { classes, handleChange } = this.props
    const { subscriptions } = this.props

    return(
      <GridContainer>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={6}>
              <h5>Subscriptions</h5>
            </GridItem>
            <GridItem xs={6}>
              <Button onClick={() => {this.addSubscription()}} color="success">Add</Button>
            </GridItem>
          </GridContainer>
          <GridContainer>
            {subscriptions.map((subscription, i) => {
              return (
                <Grid item xs={12} key={i} style={{marginTop:"30px"}}>
                  <GridItem xs={12}>
                    <GridContainer>
                      <GridItem xs={6}>
                        <h5>Subscription {i+1}</h5>
                      </GridItem>
                      <GridItem xs={6}>
                        <IconButton
                          color="secondary"
                          onClick={this.removeSubscription.bind(this, i)}
                        >
                          <Close fontSize="small" />
                        </IconButton>
                      </GridItem>
                    </GridContainer>
                    <FormControl
                      className={classes.formControl}
                      style={{marginTop:'10px'}}
                    >
                      <InputLabel shrink htmlFor={"subscriptions." + i}>
                        Topic
                      </InputLabel>
                      <Select
                        value={subscription.topic}
                        onChange={handleChange}
                        input={<Input name={"app_metadata.subscriptions." + i + ".topic"} id={"subscriptions." + i} />}
                        name={"app_metadata.subscriptions." + i + ".topic"}
                        className={classes.selectEmpty}
                      >
                        <MenuItem value={'workflow.jobs.create'}>Job created</MenuItem>
                        <MenuItem value={'workflow.jobs.failed'}>Job failed</MenuItem>
                        <MenuItem value={'workflow.jobs.done'}>Job is done</MenuItem>
                        <MenuItem value={'asset.channels.synchronisation'}>Channel synchronisation</MenuItem>
                        <MenuItem value={'asset.stream-monitor.source'}>Source stream failed</MenuItem>
                        <MenuItem value={'asset.stream-monitor.output'}>Output stream failed</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12}>
                    <FormControl
                      className={classes.formControl}
                      style={{marginTop:'10px'}}
                    >
                      <InputLabel 
                        shrink 
                        htmlFor={"subscriptions." + i + ".listenTo"}
                      >
                        Listen To
                      </InputLabel>
                      <Select
                        value={subscription.listenTo}
                        onChange={handleChange}
                        input={<Input name={"app_metadata.subscriptions." + i + ".listenTo"} id={"subscriptions." + i + ".listenTo"} />}
                        name={"app_metadata.subscriptions." + i + ".listenTo"}
                        className={classes.selectEmpty}
                      >
                        <MenuItem value={'own'}>Events of own items</MenuItem>
                        <MenuItem value={'all'}>Events of all items</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12}>
                    <FormControl
                      className={classes.formControl}
                      style={{marginTop:'10px'}}
                    >
                      <InputLabel shrink htmlFor={"subscriptions." + i}>
                        Channel
                      </InputLabel>
                      <Select
                        value={subscription.channel}
                        onChange={handleChange}
                        margin="dense"
                        input={<Input name={"app_metadata.subscriptions." + i + ".channel"} id={"subscriptions." + i} />}
                        name={"app_metadata.subscriptions." + i + ".channel"}
                        className={classes.selectEmpty}
                      >
                        <MenuItem value={'email'}>Email</MenuItem>
                        <MenuItem value={'slack'}>Slack</MenuItem>
                        <MenuItem value={'webhook'}>Webhook</MenuItem>
                      </Select>
                    </FormControl>
                    {this.renderSubscriptionDetails(subscription, i, handleChange)}
                  </GridItem>
                </Grid>)
              }
            )}
          </GridContainer>
        </GridItem>
      </GridContainer>
    )
  }
}

export default Subscriptions
