import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Tasks from "../../../components/Notifications/Notifications";
import CustomTabs from "../../../components/CustomTabs/CustomTabs.jsx";
import Person from "@material-ui/icons/Person";
import Work from "@material-ui/icons/Work";
import Archive from "@material-ui/icons/Archive";

import extendedTablesStyle from "../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";

class NotificationsPage extends React.Component {
  render() {
    return (
      <GridItem xs={12}>
        <CustomTabs
          title="Notifications:"
          headerColor="primary"
          tabs={[
            {
              tabName: "Personal",
              tabIcon: Person,
              tabContent: (
                <Tasks
                  status='new'
                  userOnly='true'
                />
              )
            },
            {
              tabName: "My Company",
              tabIcon: Work,
              tabContent: (
                <Tasks
                  status='new'
                  userOnly='false'
                />
              )
            },
            {
              tabName: "Archived",
              tabIcon: Archive,
              tabContent: (
                <Tasks
                  status='archived'
                  userOnly='false'
                />
              )
            }
          ]}
        />

      </GridItem>
    )
  }
}

export default withStyles(extendedTablesStyle)(NotificationsPage);
