import { IconButton, Snackbar, SnackbarContent } from "@material-ui/core"
import { isFunction } from "lodash"
import React from "react"

import './Toaster.css'

export class ToasterController {
    _registeredState = {}

    constructor() {
        this._isMounted = false
    }

    register(name, cb) {
        this._registeredState[name] = cb
    }

    showMessage(type, message) {
        if(isFunction(this._registeredState['show']))
            this._registeredState['show'].call(null, type, message)
    }
}

export default class Toaster extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            type: 'success',
            message: null
        }
        this.controller = props.controller || new ToasterController()
    }

    componentDidMount() {
        this.controller._isMounted = true
        this.controller.register('show', (type, message) => this.setState({
            open: true,
            type,
            message
        }))
    }

    componentWillUnmount() {
        this.controller._isMounted = false
    }

    handleClose() {
        this.setState({
            open: false
        })
    }

    render() {
        return <div className="nemo2-toaster">
            <div className={`toaster-item toaster-${this.state.type}`}>
                <Snackbar open={this.state.open} anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }} autoHideDuration={3000} onClose={this.handleClose.bind(this)}>
                    <SnackbarContent color="success" message={
                        <div>
                            { this.state.type === 'success' && <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="SuccessOutlinedIcon"><path d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z"></path></svg> }
                            { this.state.type === 'error' && <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ErrorOutlineIcon"><path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path></svg> }
                            {this.state.message}
                        </div>
                    } action={
                        <React.Fragment>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                onClick={this.handleClose.bind(this)}
                                style={{
                                    fontSize: '10pt'
                                }}
                            >
                                &#10006;
                            </IconButton>
                        </React.Fragment>
                    } />
                </Snackbar>
            </div>
        </div>
    }

}