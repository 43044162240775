import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import AddIcon from '@material-ui/icons/Add';
import { Table, TableHead, TableRow, TableCell, TableBody, TextField, InputAdornment, FormControl, OutlinedInput, IconButton, RadioGroup, Radio, FormControlLabel, InputLabel, Select, MenuItem } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
});

class PlaylistWidget extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: null,
      useUrl: false
    };
  }

  expand = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  toUpperFirstLetter(word) {
    return word[0].toUpperCase() + word.slice(1)
  }

  calculateStartTime(playlist, untilIndex) {
    const secondsToAdd = playlist.contents.reduce((agg, content, i) => {
      if (i < untilIndex) agg += Number(content.duration)
      return agg
    }, 0)
    if(playlist.type === 'vod2live') {
      const date = new Date(playlist.startDate+"Z")
      date.setSeconds(date.getSeconds() + secondsToAdd);
      return date.toISOString()
    } else {
      const date = new Date(null)
      date.setSeconds(date.getSeconds() + secondsToAdd);
      return date.toISOString()
    }
  }

  secondsToTimeDisplay(seconds) {
    const date = new Date(null)
    date.setSeconds(seconds)
    return date.toISOString().substr(11, 8)
  }

  render() {
    const { classes, playlist, addCuePoint, deleteCuePoint, deleteContent, buildUpdateConent, buildUpdateCuePoint } = this.props;
    const { expanded } = this.state;

    playlist.contents = playlist.contents  || []

    return (
      <div className={classes.root}>
        {playlist.contents.map((content, i) => {
          content.cuePoints = content.cuePoints || []
          content.duration = content.duration || 600
          const specificUpdateContent = buildUpdateConent(i)

          let contentStartDate, contentStartTime
          if (playlist.type === 'vod2live') {
            if(i === 0) {
              contentStartDate = playlist.startDate.slice(0,10)
              contentStartTime = playlist.startDate.slice(11,16)
            } else {
              contentStartDate = this.calculateStartTime(playlist, i).slice(0,10)
              contentStartTime = this.calculateStartTime(playlist, i).slice(11,16)
            }
          } else {
            contentStartDate = null
            if(i === 0) {
              contentStartTime = "00:00:00"
            } else {
              contentStartTime = this.calculateStartTime(playlist, i).slice(11,19)
            }
          }

          return <ExpansionPanel key={i} expanded={expanded === i} onChange={this.expand(i)}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>{this.toUpperFirstLetter(content.sourceType)}</Typography>
              {contentStartDate &&
                <Typography className={classes.secondaryHeading}>{contentStartDate}</Typography>
              }
              <Typography className={classes.secondaryHeading} style={{paddingLeft: "20px"}}>{contentStartTime}</Typography>
              <Typography className={classes.secondaryHeading} style={{paddingLeft: "20px"}}>{this.secondsToTimeDisplay(content.duration)}</Typography>
              <Typography className={classes.secondaryHeading} style={{paddingLeft: "20%"}}>
                <DeleteOutlinedIcon fontSize="small" onClick={deleteContent}/>
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridContainer justify="space-between">
                <GridItem xs={12}>
                  <GridContainer alignItems="center">
                    <GridItem xs={2}>Source</GridItem>
                    <GridItem xs={5}>
                      <FormControl fullWidth component="fieldset">
                        <RadioGroup row aria-label="sourceType" name="sourceType" value={content.sourceType} onChange={specificUpdateContent}>
                          {/* <FormControlLabel labelPlacement="end" value="title" control={<Radio />} label="Title" /> */}
                          <FormControlLabel labelPlacement="end" value="url" control={<Radio />} label="URL" />
                          <FormControlLabel labelPlacement="end" value="placeholder" control={<Radio />} label="Placeholder" />
                        </RadioGroup>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={5}>
                      {content.sourceType === 'url' &&
                        <TextField fullWidth label="URL" name="url" onChange={specificUpdateContent} value={content.url}>URL</TextField>
                      }
                      {/* {content.sourceType === 'title' &&
                        <TextField fullWidth label="Title Id" name="titleId" onChange={specificUpdateContent} value={content.titleId}>TitleId</TextField>
                      } */}
                      {content.sourceType === 'placeholder' &&
                        <p>A placeholder can be filled using the <Typography variant2="caption" color="primary">content</Typography> querystring like <Typography variant2="caption" color="primary">/playlists/:id?content=(url)</Typography></p>
                      }
                    </GridItem>
                  </GridContainer>
                  <GridContainer alignItems="center">
                    <GridItem xs={2}>Splice Type</GridItem>
                    <GridItem xs={4}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="spliceType">Slice Type</InputLabel>
                        <Select
                          name="spliceType"
                          value={content.spliceType}
                          onChange={specificUpdateContent}
                          fullWidth
                          inputProps={{
                            name: 'spliceType',
                            id: 'spliceType'
                          }}
                        >
                          {['seconds', 'percent'].map(spliceType => (
                            <MenuItem key={spliceType} value={spliceType}>
                              {spliceType}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer xs={12} alignItems="center">
                    <GridItem xs={2}>Timings</GridItem>
                    <GridItem xs={5}>
                      <TextField fullWidth label="Duration in seconds" name="duration" onChange={specificUpdateContent} value={content.duration}/>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem>
                  <h3>Cue Points</h3>
                </GridItem>
                <GridItem xs={12}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Splice Time</TableCell>
                        <TableCell>Max Duration (sec.)</TableCell>
                        <TableCell>Max Ads</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {content.cuePoints.map((cuePoint, cuePointId) => {
                        const specificUpdateCuePoint = buildUpdateCuePoint(i, cuePointId)
                        return (
                          <TableRow key={cuePointId}>
                          <TableCell component="th" scope="row">{cuePointId + 1}</TableCell>
                          <TableCell scope="row">
                            <FormControl
                              className={classes.textField}
                              style={{paddingTop: '2px', marginTop: "0px", paddingBottom: '2px'}}
                              size="small"
                              variant="outlined"
                            >
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                type="number"
                                name="spliceTime"
                                value={cuePoint.spliceTime}
                                onChange={specificUpdateCuePoint}
                                endAdornment={<InputAdornment position="end">{content.spliceType === 'percent' ? '%' : 'sec.' }</InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  'aria-label': 'weight',
                                }}
                                size="small"
                                labelWidth={0}
                              />
                            </FormControl>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <TextField type="number" variant="outlined" name="maxDuration" onChange={specificUpdateCuePoint} value={cuePoint.maxDuration}></TextField>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <TextField type="number" variant="outlined" name="maxAds" onChange={specificUpdateCuePoint} value={cuePoint.maxAds}></TextField>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <IconButton onClick={deleteCuePoint.bind(this, i, cuePointId)}>
                              <DeleteOutlinedIcon/>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        )}
                      )}
                    </TableBody>
                  </Table>
                  <GridContainer
                    justify="space-between" 
                    dir="rtl"
                    style={{marginTop: "10px"}}
                  >
                    <GridItem>
                      <IconButton
                        onClick={addCuePoint.bind(this,i)}
                        aria-label="add"
                      >
                        <AddIcon />
                      </IconButton>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        })}
      </div>
    );
  }
}

PlaylistWidget.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PlaylistWidget);


