import React, { Component } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'

class ResourceEnumDropdown extends Component {
  render () {
    const { classes, handleChange, enumArray, required, data, name } = this.props
    let key = this.props.theKey

    return (
      <FormControl className={classes.formControl} required={required}>
        <InputLabel shrink htmlFor="role-label-placeholder">
          {key}
        </InputLabel>
        <Select
          value={data[key]  || ''}
          onChange={handleChange}
          input={<Input id={key} />}
          name={name || key}
          className={classes.selectEmpty}
        >
          {enumArray.map((item, index) =>
            <MenuItem key={index} value={item}>{item}</MenuItem>
          )}
        </Select>
      </FormControl>
    )
  }

}

export default ResourceEnumDropdown