import React from "react"

import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import headerLinksStyle from '../../assets/jss/material-dashboard-pro-react/components/headerLinksStyle'
// import DataConnector from '../../utils/DataConnector';
import backend from '../../utils/backend'
import { showErrorMessage } from '../Alerts/Alerts'
import token from './../../utils/token'

class HeaderJobs extends React.Component {
  mounted = false
  state = {
    display: false,
    jobCount: 0,
    timer: null,
    hasError: false
  }

  async componentDidMount () {
    this.mounted = true

    await this.updateJobCount()

    this.state.timer = setInterval(async () => {
      await this.updateJobCount()
    }, 10000)

    try {
      const user = token.loadUserFromToken()
      if (['Admin', 'SuperAdmin', 'Editor'].includes(user.role)) {
        this.mounted && this.setState({
          display: true
        })
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  componentWillUnmount () {
    this.mounted = false
    clearInterval(this.state.timer)
  }

  async updateJobCount () {
    try {
      const status = 'running'
      const jobs = await this.loadJobs({ status })
      if (this.mounted)
        this.setState({
          jobCount: jobs.totalCount
        })
    } catch (err) {
      console.log(err)
    }
  }

  async loadJobs ({ status }) {
    try {
      // const d = new Date();
      // d.setDate(d.getDate()-1);
      return backend.query('jobs', {
        status,
        // createdAt: {
        //   $gt: Number(d) - ((1000 * 60) - 30) // Reduce 30 minutes
        // },
        // limit: 10
      })
    } catch (e) {
      console.error(e)
      showErrorMessage(e.message)
    }
  }

  render () {
    return (this.state.display && <div style={{
      display: 'inline-block',
      width: '50%'
    }}>
      <div style={{
        display: 'inline-block',
        backgroundImage: this.state.jobCount === 0 ?
          `url(${require('../../assets/img/rounded-arrow-disabled.svg')})` :
          `url(${require('../../assets/img/rounded-arrow.svg')})`,
        width: 35,
        height: 35,
        backgroundSize: '100%',
        fontWeight: 'bold',
        paddingTop: 7
      }}>
        <center style={{
          color: this.state.jobCount === 0 ?
            '#ccc' :
            'black'
        }}>{this.state.jobCount}</center>
      </div>
      <button onClick={() => {
        this.props.history.push(`/app/jobs#status=running&time=${Number(new Date()) - ((1000 * 60) - 30)}`)
      }} style={{
        background: 'transparent',
        border: 0,
        marginLeft: 10,
        fontWeight: 'bold',
        color: this.state.jobCount === 0 ?
          '#ccc' :
          'black'
      }}>Jobs Running</button>
    </div>)
  }

}

export default withRouter(withStyles(headerLinksStyle)(HeaderJobs))
