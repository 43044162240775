import React from 'react'
import crypto from 'crypto-js'

import Button from '../../components/CustomButtons/Button.jsx'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'

class HMACSecretOption extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            showPlainSecretModal: false,
            showEncryptedModal: false,
            secret: '',
            encryptedKey: '',
            isSecretEncrypted: false,
            value: props.value || null,
            error: null
        }
    }

    AESEncrypt(secret, key) {
        const encrypted = crypto.AES.encrypt(secret, key).toString()
        return encrypted
    }

    /** To decrype the encrypted secret */
    /** -- START -- */
    // AESDecrypt(encryptedSecret, key) {
    //     const decrypt = crypto.AES.decrypt(encryptedSecret, key).toString(crypto.enc.Utf8)
    //     return decrypt
    // }
    /** -- END -- */

    showModalPlainSecret() {
        this.setState({
            showPlainSecretModal: true,
            isSecretEncrypted: false,
            secret: ''
        })
    }

    closeModalPlainSecret() {
        this.setState({
            showPlainSecretModal: false
        })
    }

    showModalEncryptedSecret() {
        this.setState({
            showEncryptedModal: true,
            isSecretEncrypted: true,
            secret: '',
            encryptedKey: ''
        })
    }

    closeModalEncryptedSecret() {
        this.setState({
            showEncryptedModal: false
        })
    }

    savePlainSecret() {
        const onSave = this.props.onSave || ((value) => null)
        onSave({
            secret: this.state.secret,
            isSecretEncrypted: this.state.isSecretEncrypted
        })
        this.setState({
            showPlainSecretModal: false,
            value: this.state.secret
        })
    }

    saveEncryptedSecret() {
        if((this.state.secret.length % 2) === 1) {
            this.setState({
                error: 'Secret code is not an even character length.'
            })
            return
        }
        try {
            const onSave = this.props.onSave || ((value) => null)
            const result = this.AESEncrypt(this.state.secret, this.state.encryptedKey)
            onSave({
                secret: result,
                isSecretEncrypted: this.state.isSecretEncrypted
            })
            this.setState({
                showEncryptedModal: false,
                value: result
            })
        } catch(err) {
            console.log(err)
        }
    }

    render() {
        return (this.state.value ? <div style={{
            width: '120px'
        }}>
            <GridContainer>
                    <GridItem xs={10} sm={10} md={10} lg={10}>
                        <TextField
                            type="password"
                            readOnly={true}
                            disabled={true}
                            value={this.state.value}
                        />
                    </GridItem>
                    <button onClick={() => this.setState({
                        value: null
                    })} style={{
                        margin: 0,
                        padding: 0,
                        border: 0,
                        fontWeight: 'bold',
                        backgroundColor: 'transparent',
                        fontSize: '20px',
                        cursor: 'pointer'
                    }}>&#215;</button>
            </GridContainer>
        </div> : <div style={{
            width: '228px'
        }}>
            <Button onClick={this.showModalPlainSecret.bind(this)}>Plain</Button>
            <Button onClick={this.showModalEncryptedSecret.bind(this)}>Encrypted</Button>
            <Dialog
              open={this.state.showPlainSecretModal}
              onClose={this.closeModalPlainSecret.bind(this)}
              aria-labelledby="form-dialog-title"
              style={{ zIndex: "1010" }}
            >
                <DialogTitle id="form-dialog-title">Set Plain HMAC Secret</DialogTitle>
                <DialogContent>
                    <TextField
                        placeholder="Secret"
                        type="text"
                        margin="dense"
                        fullWidth
                        onChange={e =>
                            this.setState({
                                secret: e.target.value
                            })
                        }
                        value={this.state.secret}
                    />
                </DialogContent>
                
                <DialogActions>
                    <Button onClick={this.savePlainSecret.bind(this)} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.showEncryptedModal}
              onClose={this.closeModalEncryptedSecret.bind(this)}
              aria-labelledby="form-dialog-title"
              style={{ zIndex: "1010" }}
            >
                <DialogTitle id="form-dialog-title">Set Encrypted HMAC Secret</DialogTitle>
                <DialogContent>
                    The ecryption key needs to be set on the server. Please contact the support to get your key.
                    <TextField
                        placeholder="Encryption-Key"
                        type="text"
                        margin="dense"
                        fullWidth
                        onChange={e => 
                            this.setState({
                                encryptedKey: e.target.value
                            })
                        }
                        value={this.state.encryptedKey}
                    />
                    <TextField
                        error={!!this.state.error}
                        placeholder="Secret"
                        type="text"
                        margin="dense"
                        fullWidth
                        onChange={e => 
                            this.setState({
                                secret: e.target.value
                            })
                        }
                        value={this.state.secret}
                        helperText={this.state.error}
                    />
                </DialogContent>
                
                <DialogActions>
                    <Button onClick={this.saveEncryptedSecret.bind(this)} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>)
    }

}

export default HMACSecretOption