import React, { Component } from "react"
import PropTypes from "prop-types"

import {
  Card,
  AppBar,
  Tabs,
  Tab,
  Typography,
  withStyles
} from "@material-ui/core"

import GridItem from "../../components/Grid/GridItem.jsx"
import AssetMetadata from './AssetMetadata.jsx'
import Medias from './Medias.jsx'
import Jobs from './Jobs.jsx'
import { withRouter } from 'react-router-dom'

const styles = {
  card: {
    minWidth: 275,
    minHeight: 500
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  root: {
    flexGrow: 1
  }
}

function TabContainer (props) {
  return (
    <Typography component="div" style={{ padding: typeof props.padding !== 'undefined' ? props.padding : 8 * 3 }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

class AssetDetailsPage extends Component {
  state = {
    value: 0
  }

  handleChange = (event, value) => {
    switch (value) {
      case 0:
        this.props.history.push('/app/assets/' + this.props.id)
        break
      case 1:
        this.props.history.push('/app/assets/' + this.props.id + '/medias')
        break
      case 2:
        this.props.history.push('/app/assets/' + this.props.id + '/jobs')
        break
      case 3:
        this.props.history.push('/app/assets/')
        break
      default:
        break
    }
  }

  getTabValue = () => {
    if (this.props.match.path.indexOf('/app/assets/:assetId/medias') >= 0) {
      return 1
    }
    if (this.props.match.path.indexOf('/app/assets/:assetId/jobs') >= 0) {
      return 2
    }
    if (this.props.match.path.indexOf('/app/assets') >= 0) {
      return 0
    }
  }

  setAssetStatus = status => this.setState({ status })

  render () {
    const { classes, id, toggleDisplay } = this.props
    const value = this.getTabValue()
    return (
      <GridItem xs={12}>
        <Card>
          <div className={classes.root}>
            <AppBar position="static">
              {this.state.status !== 404 ?
                <Tabs value={value} onChange={this.handleChange} variant="fullWidth" style={{ backgroundColor: '#6d8dbe' }}>
                  <Tab label="Metadata" />
                  <Tab label="Medias" />
                  <Tab label="Jobs" />
                </Tabs> : <Tab label="" />
              }
            </AppBar>
            {value === 0 && (
              <TabContainer>
                <AssetMetadata
                  id={id}
                  setAssetStatus={this.setAssetStatus}
                />
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer padding={0}>
                <Medias search={id} />
              </TabContainer>
            )}
            {value === 2 && (
              <TabContainer padding={0}>
                <Jobs search={id} />
              </TabContainer>
            )}
            {value === 3 && toggleDisplay()}
          </div>
        </Card>
      </GridItem>
    )
  }
}

AssetDetailsPage.propTypes = {}

export default withRouter(withStyles(styles)(AssetDetailsPage))
