import backend from './backend'
import JSONStateHandler from './JSONStateHandler.js'
import { BackendError } from './errors';

class DataConnector {
  constructor(resource, control, onError, onSuccess) {
    this.resource = resource
    this.control = control
    this.onError = onError ? onError : () => { }
    this.onSuccess = onSuccess ? onSuccess : () => { }
    this.schema = null
  }

  setState = (state) => {
    this.control.setState(state)
  }

  getData = () => {
    return this.control.state.data
  }

  getSchema = async () => {
    if (!this.schema) {
      this.schema = await backend.schema(this.resource.model)
    }
    return this.schema
  }

  loadItem = async (id) => {
    if(!id) return;
    try {
      let result = await backend.get(this.resource.model, id)

      if (this.resource.handleObjectsAsJSON) {
        // we intend that the transform function changes only the properties in which it is interested
        const schema = await this.getSchema()
        result = { ...result, ...JSONStateHandler.transformJSONPropertiesFromSchema(result, schema) }
      }

      this.setState({
        isLoaded: true,
        data: result
      })
    } catch (error) {
      // * TODO: proper error handling
      const newStatePart = {
        isLoaded: true,
        error
      }
      if (error.response && error.response.status) {
        newStatePart.status = error.response.status
      }
      this.setState(newStatePart)
    }
  }
  /**
   * @param callback - should be a classic  (err, result) handler
   * If not passed, and handleObjectsAsJSON is set, will transform
   * objects / arrays back to strings for display and editing
   *
   * @memberof DataConnector
   */
  handleSave = async ({ event, validate, callback }) => {
    let proceed = true
    if (!validate && this.resource.handleObjectsAsJSON) {
      validate = () => { return JSONStateHandler.validateJSONFieldsFromSchema(this.getData(), this.schema) }
    }

    if (validate && typeof validate === 'function') {
      proceed = validate()
    }

    if (proceed) {
      // call base save
      const result = await this.handleSaveInternal(event, callback || this.onSavedHandler)
      if (result && result.id) {
        this.setState({ data: { ...this.getData(), id: result.id } })
      }
    }
  }

  handleSaveInternal = async (event, callback) => {
    try {
      const data = this.getData()
      let upsert = data.id ? backend.update : backend.create
      const result = await upsert(this.resource.model, data)

      if (result.code && result.code > 399) throw new BackendError(result)

      // this.onSuccess is defaulted to an empty function call if not passed in
      // the constructor

      this.onSuccess(`Your ${this.resource.caption.singular} has been saved!`)

      if (callback) {
        callback(null, result) // remember, for update will just be "1"
      }
      return result
    } catch (err) {
      if (callback) {
        callback(err)
      } else {
        this.onError(`Error while saving ${this.resource.caption.singular}. ${err.message}`)
      }
    }
  }

  onSavedHandler = async (err, result) => {
    // result is just "1" (number of records updated) for updates, recall ...
    if (err) {
      //recall this.onError is an empty function if not passed in in dataParams in the constructor
      this.onError(`Error while saving ${this.resource.caption.singular}. ${err.message}`)
    } else if (this.resource.handleObjectsAsJSON) {
      // object properties to JSON for display
      // saves reloading from db, although that would be more consistent 
      const data = this.getData()
      let transformed = JSONStateHandler.transformJSONPropertiesFromSchema(data, this.schema)
      // recall that transformObjectProperties only returns the ones from the list
      // this.objectFields
      this.setState({ data: { ...data, ...transformed } })
    }
  }

  handleChange = (event, transform) => {
    // TODO: on change validation?

    // use any transform provided
    let value = (transform && typeof transform === 'function') ? transform(event.target.value) : event.target.value

    // some controls only pass "name" and "value" in the event.target
    this.setState({ data: { ...this.getData(), [event.target.name]: value } })
  }
}

export default DataConnector