import React, { useRef, useEffect } from 'react'
import * as d3 from 'd3'
import moment from 'moment'
import Tooltip from './Tooltip'

const formatDate = (d, hours = true) => {
  const m = new moment(d)
  if (hours) {
    return m.format('D/M HH:mm')
  }
  return m.format('D/M/YY')
}
const formatValue = d3.format('.0%')
const margin = { top: 47, right: 5, bottom: 23, left: 5 }
const curve = d3.curveLinear

const legendText = (data, hours = false) => data && data.length ? `${formatDate(data[0].date, hours)} - ${formatDate(data[data.length - 1].date, hours)}` : ``

const tooltipRectParams = { x: 5.0, y: -47, width: 180, height: 64 }
const tooltipCircleParams = { cx: 0, cy: 0, r: 3 }
const tooltipKeyParams = { x: 20, y: -20 }
const tooltipValueParams = { x: 100, y: -20 }

const tooltipKey2Params = { x: 20, y: 0 }
const tooltipValue2Params = { x: 155, y: 0 }

const showTooltip = (width, x, y, hours) => function (d) {
  if (!d) {
    return
  }
  let _x = x(d.date), _y = y(d.value *100), _cx = 0, _cy = 0
  if (_y < -this.tooltipRectParams.y) {
    _y = -this.tooltipRectParams.y
    _cy = y(d.value) - _y
  }
  if (_x > width - this.tooltipRectParams.width - this.tooltipRectParams.x - margin.right) {
    _x = x(d.date) - this.tooltipRectParams.width - 2 * this.tooltipRectParams.x
    _cx = x(d.date) - _x
  }
  this.node.setAttribute('transform', `translate(${_x},${_y})`)
  this._circle.setAttribute('transform', `translate(${_cx},${_cy})`)
  this._key.textContent = formatDate(d.date, hours)
  this._value.textContent = formatValue(d.value, hours)
  this._key2.textContent = 'Completed Ad Views'
  this._value2.textContent = `${d.count}`
}

const isHours = data => {
  for (let i = 1; i < data.length; ++i) {
    if (data[i].date - data[i - 1].date < 86400000) {
      return true
    }
  }
  return false
}

const CampaignArea = ({ data = [], legend, width, height }) => {
  const hours = isHours(data)

  const d3Container = useRef(null)
  useEffect(() => {
    if (height && width && data && d3Container.current) {
      const x = d3.scaleUtc()
        .domain(d3.extent(data, d => d.date))
        .range([margin.left, width - margin.right])
      const y = d3.scaleLinear()
        .domain([0, d3.max(data, d => d.value * 100)])
        .range([height - margin.bottom, margin.top])

      const line = d3.line()
        .defined(d => !isNaN(d.value))
        .x(d => x(d.date))
        .y(d => y(d.value * 100))
      const area = d3.area()
        .curve(curve)
        .x(d => x(d.date))
        .y0(y(0))
        .y1(d => y(d.value * 100))
      const bisectDate = d3.bisector(d => d.date).left
      const bisect = (data, date) => {
        const i = bisectDate(data, date, 1)
        const a = data[i - 1], b = data[i]
        if (!b) return a
        if (!a) return b
        return date - a.date > b.date - date ? b : a
      }
      const xAxisLine = g => g
        .attr(`transform`, `translate(0,${height - margin.bottom})`)
        .style(`font-size`, `12px`)
        .style(`color`, `#979ba0`)
        .call(d3.axisBottom(x).tickSize(0).tickFormat(() => ''))

      const xTicksMax = Math.floor(width / 100)
      const xTicks = Math.max(3, Math.min(data.length, xTicksMax))

      const xAxisLabels = g => g
        .attr(`transform`, `translate(0,${height - margin.bottom + 7})`)
        .style(`font-size`, `12px`)
        .style(`color`, `#979ba0`)
        .attr('stroke-width', 0)
        .call(d3.axisBottom(x).ticks(xTicks).tickSize(0).tickFormat(d => formatDate(d, hours)))
      const yAxis = g => g
        .attr('transform', `translate(${margin.left},0)`)
        .style(`font-size`, `12px`)
        .style(`color`, `#979ba0`)
        .attr('stroke-width', 0)
        .call(d3.axisRight(y).ticks(2).tickSize(0).tickFormat((y, i) => i === 0 ? '' : y))

      const tooltip = new Tooltip(
        {
          d3,
          tooltipRectParams,
          tooltipCircleParams,
          tooltipKeyParams,
          tooltipValueParams,
          tooltipKey2Params,
          tooltipValue2Params,
          _show: showTooltip(width, x, y, hours)
        })

      const svg = d3.select(d3Container.current)
      svg.selectAll('*').remove()
      svg.attr('viewBox', [0, 0, width, height])
        .attr('font-family', 'Roboto, Helvetica, Arial, sans-serif')
        .style('font-size', '12px')

      // legend:
      if (data.length && legend.toUpperCase() !== 'NONE') {
        svg.append('circle').attr('cx', margin.left + 3).attr('cy', 20).attr('r', 2.5).style('fill', '#ff00aa')
        const legendT = legend || legendText(data, hours)
        svg.append('text').attr('x', margin.left + 10).attr('y', 22).text(legendT).attr('alignment-baseline', 'middle').attr(`fill`, `#979ba0`)
      }

      // gridlines:
      let ticks = y.ticks(3)
      if (ticks.length === 3) {
        ticks = [ticks[0], (ticks[0] + ticks[1]) / 2, ticks[1], (ticks[1] + ticks[2]) / 2, ticks[2]]
      }
      const gridLines = svg.selectAll('line.horizontalGrid').data(ticks).enter().append('line')
      gridLines.attr('x1', margin.left)
      gridLines.attr('y1', y)
      gridLines.attr('fill', 'none')
      gridLines.attr('stroke', '#f2f4f7')
      gridLines.attr('stroke-width', '1px')

      const redArea = svg.append('path')
        .datum(data)
        .attr('fill', '#ffb2d140')
        .attr('d', area)

      // red line:
      svg.append('path')
        .datum(data)
        .attr('stroke', '#ff0066')
        .attr('fill', 'none')
        .attr('stroke-width', 1.5)
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round')
        .attr('d', line)

      const circles = svg.selectAll('myCircles')
        .data(data)
        .enter()
        .append('circle')
        .attr('fill', '#ff0066')
        .attr('stroke', 'none')
        .attr('cx', d => x(d.date))
        .attr('cy', d => y(d.value * 100))
        .attr('r', 2)
        .attr('display', 'none')

      svg.append('g')
        .call(xAxisLine)
      svg.append('g')
        .call(xAxisLabels)

      svg.append('g')
        .call(yAxis)

      svg.append(() => tooltip.node)

      svg.on('mousemove', () => {
        tooltip.show(bisect(data, x.invert(d3.event.offsetX)))
        circles.attr('display', 'block')
        redArea.attr('fill', '#ffb2d120')
      })
      svg.on('mouseleave', () => {
        tooltip.hide()
        circles.attr('display', 'none')
        redArea.attr('fill', '#ffb2d140')
      })
    }
  }, [data, height, width, hours, legend])

  return <svg width={width} height={height} ref={d3Container} />
}

export default CampaignArea
