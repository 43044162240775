import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Assignment from '@material-ui/icons/Assignment'
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import GridItem from '../../../components/Grid/GridItem.jsx'
import Button from '../../../components/CustomButtons/Button.jsx'
import TextField from '@material-ui/core/TextField'
import Card from '../../../components/Card/Card.jsx'
import CardBody from '../../../components/Card/CardBody.jsx'
import CardIcon from '../../../components/Card/CardIcon.jsx'
import CardHeader from '../../../components/Card/CardHeader.jsx'
import extendedTablesStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import token from '../../../utils/token'
import backend from '../../../utils/backend'
import { showSuccessMessage, showErrorMessage } from '../../../components/Alerts/Alerts'
import Subscriptions from '../../../components/Subscriptions/Subscriptions.jsx'
import dot from 'dot-object'
import { BackendError } from '../../../utils/errors'
import Spinner from 'react-spinner-material'

class ProfilePage extends React.Component {
  state = {
    user: null,
    fetching: false
  }

  componentDidMount = async () => {
    try {
      const userId = token.loadUserFromToken().id
      const user = await backend.get('users', userId)
      this.setState({ user })
    } catch (err) {
      console.error('Cannot login. ' + err.message)
      showErrorMessage('Cannot login. ' + err.message)
    }
  }

  sendPasswordReset = async () => {
    try {
      const resBody = await backend.sendPasswordReset(this.state.user.email)
      if (resBody.error)
        throw new Error('Error sending password reset: ' + resBody.error)
      await showSuccessMessage('Password reset mail sent. Please check your email!')
    } catch (e) {
      console.error(e.message)
      showErrorMessage(e.message)
    }
  }

  handleChange = e => {
    this.setState({ 
      user: dot.str(e.target.name, e.target.value, this.state.user)
    })
  }

  save = async e => {
    try {
      const user = this.state.user
      user.id = user.user_id
      this.setState({fetching: true})
      const result = await backend.update(['Admin', 'SuperAdmin'].indexOf(user.role) >= 0 ? 'users' : 'user-subscriptions', user)
      if (result.code && result.code > 399) throw new BackendError(result)
      await showSuccessMessage('Your profile has been saved!')
    } catch (e) {
      showErrorMessage('Error while Saving: ' + e.message)
    }
    this.setState({fetching: false})
  }

  render() {
    const { classes } = this.props
    const { user } = this.state

    return (
      <GridContainer>
        {user ? (
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Profile</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} md={6}>
                    <h5>User Details</h5>
                    <TextField
                      id="email"
                      label="Email"
                      type="search"
                      className={classes.textField}
                      margin="dense"
                      fullWidth
                      value={user.email}
                      disabled={true}
                    />
                    <TextField
                      id="tenant"
                      label="Company"
                      type="search"
                      className={classes.textField}
                      margin="dense"
                      fullWidth
                      value={user.user_metadata.company}
                      disabled={true}
                    />
                    <TextField
                      id="role"
                      label="Role"
                      type="search"
                      className={classes.textField}
                      margin="dense"
                      fullWidth
                      value={user.app_metadata.role}
                      disabled={true}
                    />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                  <Subscriptions
                    subscriptions={user.app_metadata.subscriptions || []}
                    classes={classes}
                    handleChange={this.handleChange}
                  ></Subscriptions>

                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} md={6}>
                    <Button
                      style={{ margin: '20px 10px' }}
                      variant="contained"
                      color="success"
                      onClick={this.save}
                    >
                      <Spinner
                        size={12}
                        spinnerColor={'#FFF'}
                        spinnerWidth={2}
                        visible={this.state.fetching}
                      />
                      {this.state.fetching ? 'Saving ...' : 'Save'}
                    </Button>

                    <Button
                      style={{ margin: '20px 10px' }}
                      variant="contained"
                      color="warning"
                      onClick={this.sendPasswordReset}
                    >
                      Reset password
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        ) : (
          <h4>...loading</h4>
        )}
      </GridContainer>
    )
  }
}

export default withStyles(extendedTablesStyle)(ProfilePage)
