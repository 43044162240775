import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import extendedTablesStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import { withRouter } from 'react-router-dom'
import backend from '../../utils/backend'
import { showErrorMessage } from '../../components/Alerts/Alerts'

class FlowName extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      name: '---'
    }
  }

  async componentDidMount () {
    this._isMounted = true
    const flow = await this.loadFlow()
    this._isMounted && this.setState({
      name: flow.name.replaceAll(/-/g, ' ')
    })
  }
  componentWillUnmount () {
    this._isMounted = false
  }
  async loadFlow () {
    try {
      return backend.get('flows', this.props.id)
    } catch (e) {
      console.error(e)
      showErrorMessage(e.message)
    }
  }

  render () {
    return (
      <div style={{
        textTransform: 'capitalize'
      }}>
        {this.state.name}
      </div>
    )
  }

}

export default withRouter(withStyles(extendedTablesStyle)(FlowName))