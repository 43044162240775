/*!

 =========================================================
 * Material Dashboard PRO React - v1.4.0 based on Material Dashboard PRO - v1.2.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const drawerWidth = 320

const drawerMiniWidth = 105

const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
}

const containerFluid = {
  paddingRight: "46px",
  paddingLeft: "46px",
  marginRight: "auto",
  marginLeft: "auto",
  "&:before,&:after": {
    display: "table",
    content: '" "'
  },
  "&:after": {
    clear: "both"
  }
}

const container = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
  "@media (min-width: 768px)": {
    width: "750px"
  },
  "@media (min-width: 992px)": {
    width: "970px"
  },
  "@media (min-width: 1200px)": {
    width: "1170px"
  },
  "&:before,&:after": {
    display: "table",
    content: '" "'
  },
  "&:after": {
    clear: "both"
  }
}

const boxShadow = {
  boxShadow: "none"
  //"0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
}

const card = {
  display: "inline-block",
  position: "relative",
  width: "100%",
  margin: "25px 0",
  boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
  borderRadius: "6px",
  color: "rgba(0, 0, 0, 0.87)",
  background: "#fff"
}

const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: "300",
  lineHeight: "1.5em"
}

const primaryColor = "#9c27b0"
const warningColor = "#ff9800"
const dangerColor = "#f44336"
const successColor = "#4caf50"
const infoColor = "#00acc1"
const roseColor = "#e91e63"
const grayColor = "#999999"


//ideenamufaktur colors:


const idmPrimaryDarkColor = '#cc0052'
const idmPrimaryColor = '#ff0066'
const idmPrimaryLightColor = '#ff66a3'
const idmPrimaryRedColor = '#ff2724'
const idmPrimaryIconRedColor = '#ff0066'

const idmGradientFromColor = '#ff2823'
const idmGradientToColor = '#ff0066'

const idmSecondaryDarkColor = '#2a384d'
const idmSecondaryColor = '#465d80'
const idmSecondaryLightColor = '#6282b3'

const idmDarkGreyColor = '#363a40'
const idmGreyColor = '#525861'
const idmMediumGrey01Color = '#818994'
const idmMediumGrey02Color = '#B7BDC7'
const idmLightGrey01Color = '#dadfe6'
const idmLightGrey02Color = '#f2f4f7'
const idmLightGrey03Color = '#cdd1d6'
const idmUltraLightGreyColor = '#fafbfc'

const idmChart01Color = '#ff0066'
const idmChart02Color = '#78005a'
const idmChart03Color = '#28b4a0'
const idmChart04Color = '#1482c8'
const idmChart05Color = '#1e5096'

const idmWhiteColor = '#ffffff'
const idmBlackColor = '#000000'
const idmFontColor = '#1e1e1e'

const primaryBoxShadow = {
  boxShadow: "none"
  //"0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)"
}
const infoBoxShadow = {
  boxShadow: "none"
  //"0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)"
}
const successBoxShadow = {
  boxShadow: "none"
  //"0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)"
}
const warningBoxShadow = {
  boxShadow: "none"
  //"0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)"
}
const dangerBoxShadow = {
  boxShadow: "none"
  //"0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)"
}
const roseBoxShadow = {
  boxShadow: "none"
  //"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)"
}

// old card headers
const orangeCardHeader = {
  background: "linear-gradient(60deg, #ffa726, #fb8c00)",
  ...warningBoxShadow
}
const greenCardHeader = {
  background: "linear-gradient(60deg, #66bb6a, #43a047)",
  ...successBoxShadow
}
const redCardHeader = {
  background: "linear-gradient(60deg, #ef5350, #e53935)",
  ...dangerBoxShadow
}
const blueCardHeader = {
  background: "linear-gradient(60deg, #26c6da, #00acc1)",
  ...infoBoxShadow
}
const purpleCardHeader = {
  background: "linear-gradient(60deg, #ab47bc, #8e24aa)",
  ...primaryBoxShadow
}
// new card headers
const warningCardHeader = {
  background: "linear-gradient(60deg, #ffa726, #fb8c00)",
  ...warningBoxShadow
}
const successCardHeader = {
  background: "linear-gradient(60deg, #66bb6a, #43a047)",
  ...successBoxShadow
}
const dangerCardHeader = {
  background: "linear-gradient(60deg, #ef5350, #e53935)",
  ...dangerBoxShadow
}
const infoCardHeader = {
  background: "linear-gradient(60deg, #26c6da, #00acc1)",
  ...infoBoxShadow
}
const primaryCardHeader = {
  background: "linear-gradient(60deg, #ab47bc, #8e24aa)",
  ...primaryBoxShadow
}
const roseCardHeader = {
  background: "linear-gradient(60deg, #ec407a, #d81b60)",
  ...roseBoxShadow
}

const cardActions = {
  margin: "0 20px 10px",
  paddingTop: "10px",
  borderTop: "1px solid #eeeeee",
  height: "auto",
  ...defaultFont
}

const cardHeader = {
  margin: "-20px 15px 0",
  borderRadius: "3px",
  padding: "15px"
}

const defaultBoxShadow = {
  border: "0",
  borderRadius: "3px",
  boxShadow: "none",
  //"0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  padding: "10px 0",
  transition: "all 150ms ease 0s"
}

const tooltip = {
  padding: "10px 15px",
  minWidth: "130px",
  color: "#FFFFFF",
  lineHeight: "1.7em",
  background: "rgba(85,85,85,0.9)",
  border: "none",
  borderRadius: "3px",
  opacity: "1!important",
  boxShadow:
    "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
  maxWidth: "200px",
  textAlign: "center",
  fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  textShadow: "none",
  textTransform: "none",
  letterSpacing: "normal",
  wordBreak: "normal",
  wordSpacing: "normal",
  wordWrap: "normal",
  whiteSpace: "normal",
  lineBreak: "auto"
}

const title = {
  color: "#3C4858",
  textDecoration: "none",
  fontWeight: "300",
  marginTop: "30px",
  marginBottom: "25px",
  minHeight: "32px",
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  "& small": {
    color: "#777",
    fontSize: "65%",
    fontWeight: "400",
    lineHeight: "1"
  }
}

const cardTitle = {
  ...title,
  marginTop: "0",
  marginBottom: "3px",
  minHeight: "auto",
  "& a": {
    ...title,
    marginTop: ".625rem",
    marginBottom: "0.75rem",
    minHeight: "auto"
  }
}

const cardSubtitle = {
  marginTop: "-.375rem"
}

const cardLink = {
  "& + $cardLink": {
    marginLeft: "1.25rem"
  }
}

/**
 * disabling margins and paddings, mostly for material-ui components with their weird classes
 */
const noMP = {
  margin: '0px !important',
  padding: '0px !important'
}

/**
 * enclosing element to center vertically
 */
const cYbnd = {
  position: 'relative'
}

/**
 * element that will be centered vertically
 */
const cY = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)'
}

export {
  //utility stuff
  noMP,
  cYbnd,
  cY,

  //variables
  drawerWidth,
  drawerMiniWidth,
  transition,
  container,
  containerFluid,
  boxShadow,
  card,
  defaultFont,
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  roseBoxShadow,
  // old card header colors
  orangeCardHeader,
  greenCardHeader,
  redCardHeader,
  blueCardHeader,
  purpleCardHeader,
  roseCardHeader,
  // new card header colors
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  cardActions,
  cardHeader,
  defaultBoxShadow,
  tooltip,
  title,
  cardTitle,
  cardSubtitle,
  cardLink,

  idmPrimaryDarkColor,
  idmPrimaryColor,
  idmPrimaryLightColor,
  idmPrimaryRedColor,
  idmPrimaryIconRedColor,

  idmGradientFromColor,
  idmGradientToColor,

  idmSecondaryDarkColor,
  idmSecondaryColor,
  idmSecondaryLightColor,

  idmDarkGreyColor,
  idmGreyColor,
  idmMediumGrey01Color,
  idmMediumGrey02Color,
  idmLightGrey01Color,
  idmLightGrey02Color,
  idmLightGrey03Color,
  idmUltraLightGreyColor,

  idmChart01Color,
  idmChart02Color,
  idmChart03Color,
  idmChart04Color,
  idmChart05Color,

  idmWhiteColor,
  idmBlackColor,
  idmFontColor
}
