import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import cx from 'classnames'
import uuid from 'uuid/v4'
import moment from 'moment'
import GridItem from '../../components/Grid/GridItem.jsx'
import Paper from '@material-ui/core/Paper'
import Grow from '@material-ui/core/Grow'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import dashboardStyle from '../../assets/jss/material-dashboard-pro-react/views/dashboardStyle'
import { noMP, cYbnd, cY, transition } from '../../assets/jss/material-dashboard-pro-react'
import XLSX from 'xlsx'
import MultiLine from "./MultiLine.jsx"
import Map from "./Map.jsx"
import SSAIMainKPI from "./SSAIMainKPI.jsx"
import AreaAndLine from "./AreaAndLine.jsx"
import CampaignArea from "./CampaignArea.jsx"
import HorizontalBars from "./HorizontalBars.jsx"
import SimpleTable from "../../components/SimpleTable"
import { ReactComponent as HelpIcon } from '../../assets/icons/serverside-icon-help.svg'
import { ReactComponent as FavGreyIcon } from '../../assets/icons/serverside-icon-favourite-grey.svg'
import { ReactComponent as FavRedIcon } from '../../assets/icons/serverside-icon-favourite-red.svg'
import { ReactComponent as ExportIcon } from '../../assets/icons/serverside-icon-export-diagram.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/serverside-icon-close-black.svg'
import IconButton from '@material-ui/core/IconButton'
import { componentWithContext } from '../../state/DispatchContext'
import {
  getReport,
  getReportInstance,
  toggleDashboardItem,
  getTenant,
  getPeriod,
  getInterval,
  getViewOption,
  getCampaignName,
  setAppMetadata,
  refreshReport
} from '../../state/helpers'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  input: {
    borderRadius: 1,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 14,
    width: 'auto',
    height: 14,
    paddingLeft: 5,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 2,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  }
}))(InputBase)


const styles = () => ({
  ...dashboardStyle,
  noMP, cYbnd, cY, transition,
  paper: {
    ...noMP
  },
  reportHeader: {
    margin: 0
  },
  reportPaper: {
    margin: 15,
    paddingTop: 31,
    paddingBottom: 37,
    paddingLeft: 40,
    paddingRight: 40
  },
  reportPeriodSwitch: {
    float: 'right',
    width: 180,
    height: 30,
    cursor: 'pointer'
  },
  reportPeriodSwitchButton: {
    float: 'right',
    width: 60,
    height: 30,

    '&:hover': {
      backgroundColor: '#f2f4f7'
    },

  },
  reportPeriodSwitchButtonActive: {
    backgroundColor: '#f2f4f7'
  },
  reportPeriodSwitchButtonInner: {
    letterSpacing: 0,
    display: 'table',
    margin: '0 auto',
    width: '100%',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 500,
    color: '#757981'
  },

  reportNameBlock: {
    fontSize: 20,
    fontWeight: 400,
    color: '#465d80'
  },

  helpPaper: {
    backgroundColor: '#f2f4f7',
    fontSize: 12,
    maxWidth: 400
  },
  helpTexts: {
    paddingTop: 25,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 5
  },

  activeInterval: {
    backgroundColor: '#f2f4f7'
  }
})

const iconWidth = 25

const iconMarginTop = 3
const textMarginTop = 5
const buttonsMarginTop = 0

const possibleIntervals = [60 * 60 * 1000, 24 * 60 * 60 * 1000, 7 * 24 * 60 * 60 * 1000]
const reportPeriodSwitchTextsFull = ['HOURS', 'DAYS', 'WEEKS']
const reportPeriodSwitchTextsShort = ['H', 'D', 'W']

class Report extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      paperId: uuid(),
      reportNameBlockId: uuid(),
      widths: [],
      helpOpen: false
    }
    this.renderReportBody = this.renderReportBody.bind(this)
    this.getWidths = this.getWidths.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }
  componentDidMount () {
    this.setState({ widths: this.getWidths() })
    const { reportName, systemId } = this.props

    this.props.context.actions.ui.addResizer(systemId + reportName, () => this.setState({ widths: this.getWidths() }))
  }
  componentWillUnmount () {
    const { reportName, systemId } = this.props
    this.props.context.actions.ui.removeResizer(systemId + reportName)
  }
  getWidths () {
    if (document.getElementById(this.state.paperId) && document.getElementById(this.state.reportNameBlockId)) {
      return [document.getElementById(this.state.paperId).getBoundingClientRect().width,
      document.getElementById(this.state.reportNameBlockId).getBoundingClientRect().width]
    }
    return [undefined, undefined]
  }
  getReportData = (instanceIndex = 0) => {
    const { context, systemId, reportName } = this.props
    const instance = getReportInstance(context, systemId, reportName, instanceIndex)
    return instance ? instance.reportData : undefined
  }
  downloadXlsx = () => {
    const { context, systemId, reportName, useDashboard, disableTimingSelect } = this.props
    const report = getReport(context, systemId, reportName)
    const new_workbook = XLSX.utils.book_new()
    const interval = getInterval(context, systemId, reportName, useDashboard)
    const intervalText = disableTimingSelect ? '_' : '_' + reportPeriodSwitchTextsFull[possibleIntervals.indexOf(interval)] + '_'    
    for (let instanceIndex = 0; instanceIndex < report.numOfInstances; ++instanceIndex) {
      const reportData = this.getReportData(instanceIndex)
      let sheets = [{}]
      if (reportData.excelData && reportData.excelData.sheets) {
        sheets = reportData.excelData.sheets
      }
      sheets.forEach(sheet => {
        
        if (!reportData) {
          return
        }
        const grid = []
        let r = 0
        grid[r++] = ['Date/Time generated', (new moment()).format('D/M/YYYY HH:mm')]
        grid[r++] = ['Tenant Name', getTenant(this.props.context)]
        const [m1, m2] = getPeriod(this.props.context, instanceIndex)
        grid[r++] = ['Date range', `${m1.format('D/M/YYYY HH:mm')} - ${m2.format('D/M/YYYY HH:mm')}`]
        grid[r++] = ['Report type', reportData.reportName]
        grid[r++] = ['', '']

        if (sheet.data) {
          for (let _r = 0; _r < sheet.data.length; ++_r) {
            grid[r + _r] = []
            for (let _c = 0; _c < sheet.data[_r].length; ++_c) {
              grid[r + _r][_c] = sheet.data[_r][_c]
            }
          }
        } else {
          const tableHeader = ['Data label:']

          reportData.series.forEach(entry => {
            tableHeader.push(entry.name)
          })

          grid[r++] = tableHeader


          reportData.labels.forEach((label, i) => {
            grid[i + r] = []
            grid[i + r][0] = label
            reportData.series.forEach((entry, c) => {
              grid[i + r][c + 1] = entry.data[i]
            })
          })
        }
        const worksheet = XLSX.utils.aoa_to_sheet(grid)

        let sheetName = ''
        if (sheet.name) {
          sheetName = sheet.name + ' | '
        }
        sheetName += 'Period ' + (instanceIndex + 1)

        XLSX.utils.book_append_sheet(new_workbook, worksheet, sheetName)
      })
    }
    XLSX.writeFile(new_workbook, this.props.reportName + intervalText + (new Date()).toISOString() + '.xlsx')
  }
  renderReportBody (width, height, selectedCampaign) {
    const reportData = this.getReportData()

    if (reportData && reportData.code) {
      return <p style={{ color: 'red' }}>{reportData.message}</p>
    }
    if (!reportData || typeof reportData.chartType === 'undefined') {
      return <p>Loading...</p>
    }
    if ((reportData.labels && reportData.labels.length === 0) ||
      (reportData.series && reportData.series.length === 0)) {
      return <p>No data. Try changing your filters.</p>
    }
    if (reportData.chartType === 'Map') {
      const { reportName, systemId, context, useDashboard } = this.props
      const report = getReport(context, systemId, reportName)
      const viewOption = getViewOption(context, systemId, reportName, useDashboard) || report.defaultViewOption
      return <Map width={width} height={height} data={reportData} viewOption={viewOption} />
    }
    if (reportData.chartType === 'Table') {
      return <SimpleTable data={reportData} />
    }
    if (reportData.chartType === 'Text') {
      return reportData.series.map((item, i) => (
        item.data.map((data, i) => (
          <p key={i}>
            {reportData.labels[i]} : {data}
          </p>
        ))
      ))
    }
    if (reportData.chartType === 'SSAIMainKPI') {
      return <SSAIMainKPI data={reportData} />
    }
    if (reportData.chartType === 'AreaAndLine' && reportData.labels && reportData.series && reportData.series[0] && reportData.series[0].data) {
      const reportData2 = this.getReportData(1)
      return <AreaAndLine width={width} height={height}
        data={reportData && reportData.labels.map((date, i) => ({ date: new Date(date), value: reportData.series[0].data[i] }))}
        data2={reportData2 && reportData2.labels.map((date, i) => ({ date: new Date(date), value: reportData2.series[0].data[i] }))} />
    }
    if (reportData.chartType === 'MultiLine' && reportData.labels && reportData.series) {
      const reportData2 = this.getReportData(1)
      return reportData && reportData.series && reportData.series[0] && reportData.series[0].data &&
        <MultiLine width={width} height={height}
          data={reportData.series[0].data}
          data2={reportData2 && reportData2.series[0].data}
        />
    }
    if (reportData.chartType === 'CampaignArea' && reportData.campaignsData && reportData.campaigns) {
      const campaignIndex = reportData.campaigns.indexOf(selectedCampaign)
      if (campaignIndex >= 0) {
        const data = reportData.campaignsData[campaignIndex].map(e => ({
          date: new Date(e.key_as_string || e.key),
          count: e.bcn_q4.value,
          value: e.ratio.value
        }))
        return <CampaignArea width={width} height={height}
          legend={'NONE'}
          data={data} />
      } else if (reportData.campaigns.length > 0) {
        return <p style={{ color: 'red' }}>Selected campaign ("{selectedCampaign}") is not present in the output. Please select another one.</p>
      } else {
        return <p>No active campaigns yet.</p>
      }
    }
    if (reportData.chartType === 'HorizontalBars') {
      return <HorizontalBars width={width} height={height}
        data={reportData && reportData.labels.map((name, i) => ({ name, value: reportData.series[0].data[i] }))} />
    }
    return <p style={{ color: 'red' }}>Data cannot be displayed. Unsupported chartType</p>
  }
  handleClick = event => {
    const { currentTarget } = event
    this.setState(state => ({
      anchorEl: currentTarget,
      helpOpen: !state.helpOpen,
    }))
  }
  render () {
    const { reportName, classes, disableTimingSelect, systemId, context, useDashboard } = this.props
    const { helpOpen, anchorEl } = this.state

    const dashboardItem = context.state.users.current.app_metadata.dashboardItems.find(item =>
      (item.system === systemId || item.systemId === systemId) && (item.chart === reportName || item.reportName === reportName))

    const onDashboard = !!dashboardItem

    let [paperWidth, reportNameBlockWidth] = this.state.widths

    if (!paperWidth) {
      paperWidth = this.state.widths[0]
    }

    if (!reportNameBlockWidth) {
      reportNameBlockWidth = this.state.widths[1]
    }

    let sidePaddings

    if (paperWidth < 500) {
      sidePaddings = 10
    }
    if (paperWidth >= 500 && paperWidth < 723) {
      sidePaddings = 10 + 30 * (paperWidth - 500) / (723 - 500)
    }
    if (paperWidth >= 723) {
      sidePaddings = 40
    }

    const space = paperWidth - reportNameBlockWidth - 2 * sidePaddings - 3 * iconWidth - 20 - 20
    const reportPeriodSwitchButtonWidth = Math.max(20, Math.min(space / 3, 60))
    const reportPeriodSwitchTexts = space > 130 ? reportPeriodSwitchTextsFull : reportPeriodSwitchTextsShort
    const reportPeriodSwitchSideMargins = 17 // Math.min(17, (space - reportPeriodSwitchButtonWidth * 3) / 2)

    let reportCampaignSwitchWidth = space - reportPeriodSwitchButtonWidth * 3 - 35
    reportCampaignSwitchWidth = isNaN(reportCampaignSwitchWidth) ? undefined : reportCampaignSwitchWidth

    let helpTexts = []

    const reportData = this.getReportData()
    if (reportData) {
      helpTexts = reportData.helpTexts || []
    }

    const interval = getInterval(context, systemId, reportName, useDashboard)
    const setInterval = async interval => {
      await setAppMetadata(context, systemId, reportName, { interval }, useDashboard)
      refreshReport(context, systemId, reportName, useDashboard)
    }

    const reportHeight = 360
    let reportWidth = paperWidth - 2 * sidePaddings
    if (isNaN(reportWidth)) reportWidth = 400

    const report = getReport(context, systemId, reportName)

    const setViewOption = async viewOption => {
      await setAppMetadata(context, systemId, reportName, { viewOption }, useDashboard)
    }
    const viewOption = getViewOption(context, systemId, reportName, useDashboard) || report.defaultViewOption


    const setCampaignName = async campaignName => {
      await setAppMetadata(context, systemId, reportName, { campaignName }, useDashboard)
    }

    const selectedCampaign = getCampaignName(context, systemId, reportName, useDashboard) || (reportData && reportData.campaigns && reportData.campaigns[0]) || ''

    const campaignSelectStyle = {
      width: reportCampaignSwitchWidth,
      "&::before": {
        borderBottom: '0px !important'
      }
    }

    if (reportData && reportData.campaigns && !reportData.campaigns.find(c => c === selectedCampaign)) {
      campaignSelectStyle.border = '1px solid #ff0000'
    }

    return (
      <GridItem xs={12} sm={12} md={12} lg={12} xl={6} className={classes.noMP}>
        <Paper square={true} className={classes.reportPaper} id={this.state.paperId} style={{ paddingLeft: sidePaddings, paddingRight: sidePaddings }}>
          <div className={classes.reportHeader} style={{ width: '100%', height: 50 }}>
            <div className={classes.reportNameBlock} style={{ float: 'left', marginTop: textMarginTop }} id={this.state.reportNameBlockId}>
              <div>{reportName}</div>
            </div>
            <IconButton
              style={{ float: 'left', padding: 0, marginLeft: 10.5, marginTop: iconMarginTop }}
              onClick={helpTexts.length > 0 ? this.handleClick : () => { }}>
              <HelpIcon style={{ width: iconWidth }} />
            </IconButton>
            <Popper open={helpOpen} anchorEl={anchorEl} placement="bottom-start" transition
              popperOptions={{
                modifiers: {
                  offset: { offset: '25px, 0px' }
                }
              }}>
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow">
                  <Paper className={classes.helpPaper} square={true}>
                    <ClickAwayListener onClickAway={this.handleClick}>
                      <div>
                        <IconButton style={{ float: 'right', width: 25, padding: 8 }}
                          onClick={this.handleClick}>
                          <CloseIcon style={{ width: 25 }} />
                        </IconButton>
                        <div className={classes.helpTexts}>
                          {helpTexts.map((text, key) => (
                            <div key={key} dangerouslySetInnerHTML={{ __html: text }} />
                          ))}
                        </div>
                      </div>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}


            </Popper>
            <IconButton onClick={this.downloadXlsx} style={{ float: 'right', padding: 0, marginTop: iconMarginTop }}>
              <ExportIcon style={{ width: iconWidth }} />
            </IconButton>

            {
              onDashboard
                ? (
                  <IconButton
                    onClick={() => toggleDashboardItem(context, systemId, reportName, interval)}
                    style={{ float: 'right', padding: 0, marginRight: 10, marginLeft: 10, marginTop: iconMarginTop }}>
                    <FavRedIcon style={{ width: iconWidth }} />
                  </IconButton>
                )
                : (
                  <IconButton
                    onClick={() => toggleDashboardItem(context, systemId, reportName, interval)}
                    style={{ float: 'right', padding: 0, marginRight: 10, marginLeft: 10, marginTop: iconMarginTop }}>
                    <FavGreyIcon style={{ width: iconWidth }} />
                  </IconButton>
                )
            }
            {
              !disableTimingSelect && (
                <div className={classes.reportPeriodSwitch} style={{
                  width: reportPeriodSwitchButtonWidth * 3 || undefined,
                  marginRight: reportPeriodSwitchSideMargins || undefined,
                  marginLeft: reportPeriodSwitchSideMargins - 7 || undefined,
                  marginTop: buttonsMarginTop
                }}>
                  <div
                    onClick={() => setInterval(possibleIntervals[2])}
                    style={{ width: reportPeriodSwitchButtonWidth || undefined }}
                    className={cx(
                      classes.reportPeriodSwitchButton,
                      classes.cYbnd,
                      {
                        [classes.activeInterval]: interval === possibleIntervals[2]
                      })}>
                    <div className={cx(classes.reportPeriodSwitchButtonInner, classes.cY)}>{reportPeriodSwitchTexts[2]}</div>
                  </div>
                  <div
                    onClick={() => setInterval(possibleIntervals[1])}
                    style={{ width: reportPeriodSwitchButtonWidth || undefined }}
                    className={cx(
                      classes.reportPeriodSwitchButton,
                      classes.cYbnd,
                      {
                        [classes.activeInterval]: interval === possibleIntervals[1]
                      })}>
                    <div className={cx(classes.reportPeriodSwitchButtonInner, classes.cY)}>{reportPeriodSwitchTexts[1]}</div>
                  </div>
                  <div
                    onClick={() => setInterval(possibleIntervals[0])}
                    style={{ width: reportPeriodSwitchButtonWidth || undefined }}
                    className={cx(
                      classes.reportPeriodSwitchButton,
                      classes.cYbnd,
                      {
                        [classes.activeInterval]: interval === possibleIntervals[0]
                      })}>
                    <div className={cx(classes.reportPeriodSwitchButtonInner, classes.cY)}>{reportPeriodSwitchTexts[0]}</div>
                  </div>
                </div>
              )
            }
            {!!report.showCampaignSelector && reportData && reportData.campaigns && reportData.campaigns.length > 0 &&
              <div style={{ width: reportCampaignSwitchWidth, float: 'right', marginLeft: 10 }}>
                <FormControl className={classes.formControl}>
                  <Select style={campaignSelectStyle} value={selectedCampaign} onChange={event => {
                    setCampaignName(event.target.value)
                  }}
                    input={<BootstrapInput name="campaigninput" id="campaigninput-customized-select" />}>
                    {reportData && reportData.campaigns && campaignSelectStyle.border &&
                      <MenuItem style={{ color: '#ffffff' }} key={reportData.campaigns.length} value={selectedCampaign}>{selectedCampaign}</MenuItem>}
                    {reportData && reportData.campaigns && reportData.campaigns.map((c, i) =>
                      <MenuItem key={i} value={c}>{c}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            }
            {
              !!report.viewOptions && report.viewOptions.length > 1 && (
                <div className={classes.reportPeriodSwitch} style={{
                  width: 60 * report.viewOptions.length || undefined,
                  marginRight: reportPeriodSwitchSideMargins || undefined,
                  marginLeft: reportPeriodSwitchSideMargins || undefined,
                  marginTop: buttonsMarginTop
                }}>{report.viewOptions.map((opt, i) => (
                  <div
                    key={i}
                    onClick={() => setViewOption(opt)}
                    style={{ width: reportPeriodSwitchButtonWidth || undefined }}
                    className={cx(
                      classes.reportPeriodSwitchButton,
                      classes.cYbnd,
                      {
                        [classes.activeInterval]: viewOption === opt
                      })}>
                    <div className={cx(classes.reportPeriodSwitchButtonInner, classes.cY)}>{opt}</div>
                  </div>
                ))}</div>
              )
            }
          </div>
          <div style={{ height: reportHeight }}>{this.renderReportBody(reportWidth, reportHeight, selectedCampaign)}</div>
        </Paper>
      </GridItem >
    )
  }
}

export default componentWithContext(withStyles(styles)(Report))
