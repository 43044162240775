/**
 * Initial state of UI
 */
export const initialState = {
  getDebounce: () => function debounce (fn, ms) {
    let timer
    return () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    }
  },

  resizers: [],

  /**
   * window (for sizes):
   */
  window: {},
  /**
   * Sidebar:
   */
  sidebarMiniActive: false,
  sidebarMiniActiveCatchHover: false,

  /**
   * Period selector:
   */
  periodSelectorDialogOpen: false,
  periodSelectorPeriod: 0,
  periodSelectorDateX: 'dateA',
  periodSelectorOpen: [false, false],
  periodSelectorSelectActive: [true, true],
  periodSelectorMonthShift: 0,

  /**
   * Channels:
   */
  currentChannel: null
}

/**
 * Reducer for UI
 * @param {*} entityName 
 */
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `TOGGLE_SIDEBAR_MINI_ACTIVE`:
      return {
        ...state,
        sidebarMiniActive: !state.sidebarMiniActive,
        sidebarMiniActiveCatchHover: false
      }
    case `TOGGLE_SIDEBAR_MINI_ACTIVE_CATCH_HOVER`:
      return {
        ...state,
        sidebarMiniActiveCatchHover: true
      }
    case `PERIOD_SELECTOR_STATE_CHANGED`: {
      const periodSelectorOpen = [...state.periodSelectorOpen]
      const periodSelectorSelectActive = [...state.periodSelectorSelectActive]
      if (action.payload.periodSelectorOpen) {
        periodSelectorOpen[action.payload.periodSelectorOpen.index] = action.payload.periodSelectorOpen.open
        if (action.payload.periodSelectorOpen.downtime && !action.payload.periodSelectorOpen.open) {
          periodSelectorSelectActive[action.payload.periodSelectorOpen.index] = false
        }
      }
      return {
        ...state,
        periodSelectorPeriod: action.payload.hasOwnProperty('periodSelectorPeriod') ? action.payload.periodSelectorPeriod : state.periodSelectorPeriod,
        periodSelectorDateX: action.payload.hasOwnProperty('periodSelectorDateX') ? action.payload.periodSelectorDateX : state.periodSelectorDateX,
        periodSelectorOpen,
        periodSelectorSelectActive
      }
    }
    case `PERIOD_SELECTOR_ACTIVATE`: {
      const periodSelectorSelectActive = [...state.periodSelectorSelectActive]
      periodSelectorSelectActive[action.payload.periodSelectorSelectActive.index] = true
      return {
        ...state,
        periodSelectorSelectActive
      }
    }
    case `PERIOD_SELECTOR_DIALOG_OPEN`: {
      return {
        ...state,
        periodSelectorDialogOpen: action.payload
      }
    }
    case `PERIOD_SELECTOR_MONTH_SHIFT`: {
      return {
        ...state,
        periodSelectorMonthShift: state.periodSelectorMonthShift + action.payload
      }
    }
    case `SET_WINDOW_SIZE`: {
      return {
        ...state,
        window: action.payload
      }
    }
    case `ADD_RESIZER`: {
      return {
        ...state,
        resizers: [...state.resizers].concat([action.payload])
      }
    }
    case `REMOVE_RESIZER`: {
      return {
        ...state,
        resizers: [...state.resizers].filter(r => r.name !== action.payload)
      }
    }
    /**
     * side effects there - but this is exactly what we need
     */
    case `CALL_RESIZERS`: {
      state.resizers.forEach(resizer => typeof resizer.handler === 'function' && resizer.handler())
      return state
    }
    case `SET_CURRENT_CHANNEL`: {
      return {
        ...state,
        currentChannel: action.payload
      }
    }
    default:
      return state
  }
}

const sleep = async (ms) => {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

/**
 * Actions for UI
 * @param {*} entityName
 * @param {*} dispatch
 */
export const actions = dispatch => {
  return {
    setCurrentChannel: cid => dispatch({
      type: `SET_CURRENT_CHANNEL`,
      payload: cid
    }),
    callResizers: () => dispatch({
      type: `CALL_RESIZERS`
    }),
    addResizer: (name, handler) => dispatch({
      type: `ADD_RESIZER`,
      payload: {
        name,
        handler
      }
    }),
    removeResizer: name => dispatch({
      type: `REMOVE_RESIZER`,
      payload: name
    }),
    toggleSidebarMiniActive: sleepTime => () => {
      dispatch({
        type: `TOGGLE_SIDEBAR_MINI_ACTIVE`,
        effects: async () => {
          await sleep(sleepTime)
          return { type: `TOGGLE_SIDEBAR_MINI_ACTIVE_CATCH_HOVER` }
        }
      })
    },
    setPeriodSelectorState: (periodSelectorPeriod, periodSelectorDateX) => {
      const payload = { periodSelectorDateX }
      if (typeof periodSelectorPeriod !== 'undefined') {
        payload.periodSelectorPeriod = periodSelectorPeriod
      }
      dispatch({
        type: `PERIOD_SELECTOR_STATE_CHANGED`, payload
      })
    },
    setOpen: index => (open, downtime) =>
      dispatch({
        type: `PERIOD_SELECTOR_STATE_CHANGED`,
        payload: { periodSelectorOpen: { index, open, downtime } },
        effects: async () => {
          if (!open && downtime) {
            await sleep(downtime)
            return { type: `PERIOD_SELECTOR_ACTIVATE`, payload: { periodSelectorSelectActive: { index } } }
          }
        }
      })
    ,
    setPeriodSelectorDialogOpen: open => dispatch({
      type: `PERIOD_SELECTOR_DIALOG_OPEN`,
      payload: open
    }),
    setPeriodSelectorMonthShift: periodSelectorMonthShift => dispatch({
      type: `PERIOD_SELECTOR_MONTH_SHIFT`, payload: periodSelectorMonthShift
    }),
    setWindowSize: (innerWidth, innerHeight) => dispatch({
      type: `SET_WINDOW_SIZE`, payload: { innerWidth, innerHeight }
    })
  }
}