import {
  useRef,
  useEffect,
  useCallback
} from 'react'
import { useReducer } from 'reinspect'

/**
 * Custom hook for async operations
 * @param {*} reducer 
 * @param {*} state0 
 * @param {*} effectContext reserved for metadata (tokens, api urls, etc.)
 */
const useReducerWithEffects = (reducer, state0, effectContext, id) => {
  const [state, reactDispatch] = useReducer(reducer, state0, x => x, id)
  const actions = useRef([])

  const dispatch = useCallback(action => {
    if (action.effects) {
      actions.current.push(action)
    }

    reactDispatch(action)
  }, [reactDispatch])

  useEffect(() => {
    actions.current.forEach(async action => {
      if (action.effects) {
        const nextAction = await action.effects(state, action, effectContext)

        if(nextAction) {
          dispatch(nextAction)
        }
      }
    })
    actions.current = []
  }, [state, dispatch, effectContext])

  return [state, dispatch]
}

export default useReducerWithEffects