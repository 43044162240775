const adServerParams = {
  spotx: {
    generic: {
      VPI: 'MP4',
      media_transcoding: 'high',
      'ssai[enabled]': 1,
      'ssai[vendor]': '',
      "context": "web",
      "ad_channel_id": "&265585",
      "player_width": 1280,
      "player_height": 720,
      "deliverytypes": ["dash"]
    },
    context: {
      web: {
        content_page_url: ''
      },
      app: {
        'app[bundle]': ''
      }
    }
  },
  freewheel: {
    cid: ''
  },
  gam: {},
  proxy: {
    "mappings": [
      {
        "value": "{{ip}}",
        "mapsTo": "x-forwarded-for",
        "position": "header"
      },
      {
        "value": "{{{user-agent}}}",
        "mapsTo": "user-agent",
        "position": "header"
      },
      {
        "value": "425856",
        "mapsTo": "siteid",
        "position": "query"
      },
      {
        "value": "1528596",
        "mapsTo": "pgid",
        "position": "query"
      },
      {
        "value": "101815",
        "mapsTo": "fmtid",
        "position": "query"
      },
      {
        "value": "1",
        "mapsTo": "oc",
        "position": "query"
      },
      {
        "value": "s",
        "mapsTo": "vcn",
        "position": "query"
      },
      {
        "value": "S",
        "mapsTo": "visit",
        "position": "query"
      },
      {
        "value": "{{tmstp}}",
        "mapsTo": "tmstp",
        "position": "query"
      },
      {
        "value": "vast4.2",
        "mapsTo": "out",
        "position": "query"
      },
      {
        "value": "0",
        "mapsTo": "vpaidt",
        "position": "query"
      },
      {
        "value": "0",
        "mapsTo": "vpaidv",
        "position": "query"
      },
      {
        "value": "1",
        "mapsTo": "ab",
        "position": "query"
      },
      {
        "value": "0",
        "mapsTo": "ssar",
        "position": "query"
      },
      {
        "value": "{{maxCount}}",
        "mapsTo": "ps",
        "position": "query"
      },
      {
        "value": "{{maxDuration}}",
        "mapsTo": "mabd",
        "position": "query"
      },
      {
        "value": "0",
        "mapsTo": "pb",
        "position": "query"
      },
      {
        "value": "{{gdpr}}",
        "mapsTo": "gdpr",
        "position": "query"
      },
      {
        "value": "{{gdpr_consent}}",
        "mapsTo": "gdpr_consent",
        "position": "query"
      },
      {
        "value": "{{us_privacy}}",
        "mapsTo": "us_privacy",
        "position": "query"
      },
      {
        "value": "{{publisher domain}}",
        "mapsTo": "pgdomain",
        "position": "query"
      },
      {
        "value": "1080",
        "mapsTo": "vph",
        "position": "query"
      },
      {
        "value": "1920",
        "mapsTo": "vpw",
        "position": "query"
      },
      {
        "value": "1",
        "mapsTo": "vpt",
        "position": "query"
      },
      {
        "value": "3",
        "mapsTo": "vpmt",
        "position": "query"
      },
      {
        "value": "{{schain}}",
        "mapsTo": "schain",
        "position": "query"
      },
      {
        "value": "0",
        "mapsTo": "skip",
        "position": "query"
      },
      {
        "value": "{{appname}}",
        "mapsTo": "appname",
        "position": "query"
      },
      {
        "value": "{{bundleId}}",
        "mapsTo": "buid",
        "position": "query"
      },
      {
        "value": "{{storeUrl}}",
        "mapsTo": "srl",
        "position": "query"
      },
      {
        "value": "{{user_id}}",
        "mapsTo": "uid",
        "position": "query"
      },
      {
        "value": "{{iabframeworks}}",
        "mapsTo": "iabframeworks",
        "position": "query"
      },
      {
        "value": "{{omidpn}}",
        "mapsTo": "omidpn",
        "position": "query"
      },
      {
        "value": "{{omidpv}}",
        "mapsTo": "omidpv",
        "position": "query"
      },
      {
        "value": "{{vdmin}}",
        "mapsTo": "vdmin",
        "position": "query"
      },
      {
        "value": "{{vdmax}}",
        "mapsTo": "vdmax",
        "position": "query"
      },
      {
        "value": "{{vdmax}}",
        "mapsTo": "vdmax",
        "position": "query"
      },
      {
        "value": "{{tgt}}",
        "mapsTo": "tgt",
        "position": "query"
      },
      {
        "value": "{{ctid}}",
        "mapsTo": "ctid",
        "position": "query"
      },
      {
        "value": "{{ctn}}",
        "mapsTo": "ctn",
        "position": "query"
      },
      {
        "value": "{{content_type}}",
        "mapsTo": "ctt",
        "position": "query"
      },
      {
        "value": "{{content_category}}",
        "mapsTo": "ctc",
        "position": "query"
      },
      {
        "value": "{{content_duration}}",
        "mapsTo": "ctd",
        "position": "query"
      },
      {
        "value": "{{cts}}",
        "mapsTo": "cts",
        "position": "query"
      },
      {
        "value": "{{cte}}",
        "mapsTo": "cte",
        "position": "query"
      },
      {
        "value": "{{ctr}}",
        "mapsTo": "ctr",
        "position": "query"
      },
      {
        "value": "{{ctpid}}",
        "mapsTo": "ctpid",
        "position": "query"
      }
    ],
    "functions": {},
    "whitelist": [
      "ip",
      "user-agent",
      "maxDuration",
      "maxCount",
      "tmstp",
      "gdpr",
      "gdpr_consent",
      "us_privacy",
      "publisher domain",
      "appName",
      "bundleId",
      "storeUrl",
      "user_id",
      "iabframeworks",
      "omidpn",
      "omidpv",
      "vdmin",
      "vdmax",
      "tgt",
      "ctid",
      "ctn",
      "ctt",
      "ctc",
      "ctd",
      "cts",
      "cte",
      "ctr",
      "ctpid"
    ],
    "url": "https://videoapi.smartadserver.com/ac"
  },
  gva: {
    "mappings": [
      {
        "value": "{{ip}}",
        "mapsTo": "x-forwarded-for",
        "position": "header"
      },
      {
        "value": "{{{user-agent}}}",
        "mapsTo": "user-agent",
        "position": "header"
      },
      {
        "value": "425856",
        "mapsTo": "siteid",
        "position": "query"
      },
      {
        "value": "1528596",
        "mapsTo": "pgid",
        "position": "query"
      },
      {
        "value": "101815",
        "mapsTo": "fmtid",
        "position": "query"
      },
      {
        "value": "1",
        "mapsTo": "oc",
        "position": "query"
      },
      {
        "value": "s",
        "mapsTo": "vcn",
        "position": "query"
      },
      {
        "value": "S",
        "mapsTo": "visit",
        "position": "query"
      },
      {
        "value": "{{tmstp}}",
        "mapsTo": "tmstp",
        "position": "query"
      },
      {
        "value": "vast4.2",
        "mapsTo": "out",
        "position": "query"
      },
      {
        "value": "0",
        "mapsTo": "vpaidt",
        "position": "query"
      },
      {
        "value": "0",
        "mapsTo": "vpaidv",
        "position": "query"
      },
      {
        "value": "1",
        "mapsTo": "ab",
        "position": "query"
      },
      {
        "value": "0",
        "mapsTo": "ssar",
        "position": "query"
      },
      {
        "value": "{{maxCount}}",
        "mapsTo": "ps",
        "position": "query"
      },
      {
        "value": "{{maxDuration}}",
        "mapsTo": "mabd",
        "position": "query"
      },
      {
        "value": "0",
        "mapsTo": "pb",
        "position": "query"
      },
      {
        "value": "{{gdpr}}",
        "mapsTo": "gdpr",
        "position": "query"
      },
      {
        "value": "{{gdpr_consent}}",
        "mapsTo": "gdpr_consent",
        "position": "query"
      },
      {
        "value": "{{us_privacy}}",
        "mapsTo": "us_privacy",
        "position": "query"
      },
      {
        "value": "{{publisher domain}}",
        "mapsTo": "pgdomain",
        "position": "query"
      },
      {
        "value": "1080",
        "mapsTo": "vph",
        "position": "query"
      },
      {
        "value": "1920",
        "mapsTo": "vpw",
        "position": "query"
      },
      {
        "value": "1",
        "mapsTo": "vpt",
        "position": "query"
      },
      {
        "value": "3",
        "mapsTo": "vpmt",
        "position": "query"
      },
      {
        "value": "{{schain}}",
        "mapsTo": "schain",
        "position": "query"
      },
      {
        "value": "0",
        "mapsTo": "skip",
        "position": "query"
      },
      {
        "value": "{{appname}}",
        "mapsTo": "appname",
        "position": "query"
      },
      {
        "value": "{{bundleId}}",
        "mapsTo": "buid",
        "position": "query"
      },
      {
        "value": "{{storeUrl}}",
        "mapsTo": "srl",
        "position": "query"
      },
      {
        "value": "{{user_id}}",
        "mapsTo": "uid",
        "position": "query"
      },
      {
        "value": "{{iabframeworks}}",
        "mapsTo": "iabframeworks",
        "position": "query"
      },
      {
        "value": "{{omidpn}}",
        "mapsTo": "omidpn",
        "position": "query"
      },
      {
        "value": "{{omidpv}}",
        "mapsTo": "omidpv",
        "position": "query"
      },
      {
        "value": "{{vdmin}}",
        "mapsTo": "vdmin",
        "position": "query"
      },
      {
        "value": "{{vdmax}}",
        "mapsTo": "vdmax",
        "position": "query"
      },
      {
        "value": "{{vdmax}}",
        "mapsTo": "vdmax",
        "position": "query"
      },
      {
        "value": "{{tgt}}",
        "mapsTo": "tgt",
        "position": "query"
      },
      {
        "value": "{{ctid}}",
        "mapsTo": "ctid",
        "position": "query"
      },
      {
        "value": "{{ctn}}",
        "mapsTo": "ctn",
        "position": "query"
      },
      {
        "value": "{{content_type}}",
        "mapsTo": "ctt",
        "position": "query"
      },
      {
        "value": "{{content_category}}",
        "mapsTo": "ctc",
        "position": "query"
      },
      {
        "value": "{{content_duration}}",
        "mapsTo": "ctd",
        "position": "query"
      },
      {
        "value": "{{cts}}",
        "mapsTo": "cts",
        "position": "query"
      },
      {
        "value": "{{cte}}",
        "mapsTo": "cte",
        "position": "query"
      },
      {
        "value": "{{ctr}}",
        "mapsTo": "ctr",
        "position": "query"
      },
      {
        "value": "{{ctpid}}",
        "mapsTo": "ctpid",
        "position": "query"
      }
    ],
    "functions": {},
    "whitelist": [
      "ip",
      "user-agent",
      "maxDuration",
      "maxCount",
      "tmstp",
      "gdpr",
      "gdpr_consent",
      "us_privacy",
      "publisher domain",
      "appName",
      "bundleId",
      "storeUrl",
      "user_id",
      "iabframeworks",
      "omidpn",
      "omidpv",
      "vdmin",
      "vdmax",
      "tgt",
      "ctid",
      "ctn",
      "ctt",
      "ctc",
      "ctd",
      "cts",
      "cte",
      "ctr",
      "ctpid"
    ],
    "url": "https://videoapi.smartadserver.com/ac"
  }
}
export default adServerParams