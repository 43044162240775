import React from 'react'
import Report from './Report'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import ChannelsSelector from './ChannelsSelector'
import PeriodsSelector from './PeriodsSelector'
import { componentWithContext } from '../../state/DispatchContext'
import { refreshAllReports } from '../../state/helpers'

class CenteredTabs extends React.Component {
  componentDidMount = async () => {
    try {
      await refreshAllReports(this.props.context)
    } catch (ex) {
      console.error(ex)
    }
  }
  render () {
    const systems = this.props.context.state.systems.list || []
    return (
      <div>
        <GridContainer justify="flex-end">
          <GridItem>
            <PeriodsSelector />
          </GridItem>
        </GridContainer>
        <GridContainer><GridItem><h4>Channel reports</h4></GridItem></GridContainer>
        <GridContainer justify="flex-end">
          <GridItem>
            <ChannelsSelector />
          </GridItem>
        </GridContainer>
        <GridContainer>
          {
            systems.map((system) =>
              system.reports && system.reports.filter(report => report.channelDependent).map(report => {
                return (
                  <Report
                    systemId={system.id}
                    reportName={report.reportName}
                    key={system.id + report.reportName}
                    disableTimingSelect={report.disableTimingSelect}
                    showCampaignSelector={report.showCampaignSelector}
                    useDashboard={false}>
                  </Report>
                )
              }))
          }
        </GridContainer>
        <GridContainer><GridItem><h4>Asset reports</h4></GridItem></GridContainer>
        <GridContainer>
          {
            systems.map((system) =>
              system.reports && system.reports.filter(report => !report.channelDependent).map(report => {
                return (
                  <Report
                    systemId={system.id}
                    reportName={report.reportName}
                    key={system.id + report.reportName}
                    disableTimingSelect={report.disableTimingSelect}
                    showCampaignSelector={report.showCampaignSelector}
                    useDashboard={false}>
                  </Report>
                )
              }))
          }
        </GridContainer>
      </div>
    )
  }
}

export default componentWithContext(CenteredTabs)

