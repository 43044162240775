import backend from '../utils/backend'
import token from '../utils/token'

import {
  initialState as entityInitialState,
  reducer as entityReducer,
  actions as entityActions,
  alwaysResolvingPromise,
  fetchingEffect,
  debugActionData
} from './entity'

const app_metadata = {
  selectedChannels: [],
  timePeriods: [
    {
      type: 'Last week'
    },
    {
      type: 'Previous period',
      compareWith: true
    }],
  dashboardItems: [],
  subscriptions: [],
  reportSettings: []
}

export const initialState = {
  ...entityInitialState,
  current: {
    app_metadata
  }
}
const selectedChannels = state => {
  if (state.current && state.current.app_metadata && state.current.app_metadata.selectedChannels) {
    return state.current.app_metadata.selectedChannels
  }
  return []
}

const initAppMetadata = state => {
  const newState = { ...state }
  if (!newState.current.app_metadata.timePeriods) {
    newState.current.app_metadata.timePeriods = initialState.current.app_metadata.timePeriods
  } else {
    newState.current.app_metadata.timePeriods = [...newState.current.app_metadata.timePeriods]
  }

  if (!newState.current.app_metadata.timePeriods[0]) {
    newState.current.app_metadata.timePeriods[0] = initialState.current.app_metadata.timePeriods[0]
  }

  if (!newState.current.app_metadata.timePeriods[1]) {
    newState.current.app_metadata.timePeriods[1] = initialState.current.app_metadata.timePeriods[1]
  }
  return newState
}

export const reducer = (state = initialState, action) => {
  state = entityReducer('users')(state, action)
  switch (action.type) {
    case 'SELECT_CHANNELS': {
      if (!Array.isArray(action.payload) || action.payload.length === 0) {
        return state
      }
      if (action.payload.find(x => typeof x !== 'string')) {
        return state
      }
      const newState = { ...state }
      newState.current.app_metadata.selectedChannels = selectedChannels(state)
        .filter(id => action.payload.indexOf(id) < 0)
        .concat(action.payload)
      return newState
    }
    case 'UNSELECT_CHANNELS': {
      if (!Array.isArray(action.payload) || action.payload.length === 0) {
        return state
      }
      if (action.payload.find(x => typeof x !== 'string')) {
        return state
      }
      const newState = { ...state }
      newState.current.app_metadata.selectedChannels = selectedChannels(state)
        .filter(id => action.payload.indexOf(id) < 0)
      return newState
    }
    case 'CLEAR_SELECTION': {
      const newState = { ...state }
      newState.current.app_metadata.selectedChannels = []
      return newState
    }
    case 'TOGGLE_COMPARE_WITH': {
      const newState = initAppMetadata(state)
      newState.current.app_metadata.timePeriods[1].compareWith = !newState.current.app_metadata.timePeriods[1].compareWith
      return newState
    }
    case `SET_DATE`: {
      const newState = initAppMetadata(state)
      newState.current.app_metadata.timePeriods[action.payload.index][action.payload.varName] = action.payload.value
      return newState
    }
    case `SET_PERIOD_TYPE`: {
      const newState = initAppMetadata(state)
      newState.current.app_metadata.timePeriods[action.payload.index].type = action.payload.type
      return newState
    }
    case `APP_METADATA_FETCH_SUCCESS`: {
      const newState = { ...state }
      newState.isLoading = false

      newState.current.app_metadata.selectedChannels = action.payload.selectedChannels || newState.current.app_metadata.selectedChannels
      newState.current.app_metadata.timePeriods = action.payload.timePeriods || newState.current.app_metadata.timePeriods
      newState.current.app_metadata.dashboardItems = action.payload.dashboardItems || newState.current.app_metadata.dashboardItems
      newState.current.app_metadata.subscriptions = action.payload.subscriptions || newState.current.app_metadata.subscriptions
      newState.current.app_metadata.reportSettings = action.payload.reportSettings || newState.current.app_metadata.reportSettings

      return newState
    }
    case `APP_METADATA_FETCH_ERROR`: {
      return {
        ...state,
        error: action.payload,
        isLoading: false
      }
    }
    default:
      return state
  }
}

export const actions = dispatch => ({
  ...entityActions('users', dispatch),
  getAppMetadata: () => alwaysResolvingPromise(cb => dispatch({
    type: `USERS_FETCH_START`,
    ...debugActionData('getAppMetadata', 'backend.getAppMetadata'),
    effects: fetchingEffect(`APP_METADATA`, backend.getAppMetadata, cb)
  })),
  updateAppMetadata: appMetadata => alwaysResolvingPromise(cb => dispatch({
    type: `USERS_FETCH_START`,
    ...debugActionData('updateAppMetadata', 'backend.updateAppMetadata', [appMetadata]),
    effects: fetchingEffect(`APP_METADATA`, backend.updateAppMetadata, cb, appMetadata)
  })),
  updateAppMetadataImmediate: appMetadata => alwaysResolvingPromise(cb => dispatch({
    type: `USERS_FETCH_START`,
    ...debugActionData('updateAppMetadataImmediate', 'backend.updateAppMetadata', [appMetadata]),
    effects: () => ({
      type: 'APP_METADATA_FETCH_SUCCESS',
      payload: appMetadata,
      effects: fetchingEffect(`APP_METADATA`, backend.updateAppMetadata, cb, appMetadata)
    })
  })),
  selectChannels: channelsIds => dispatch({ type: 'SELECT_CHANNELS', payload: channelsIds }),
  unselectChannels: channelsIds => dispatch({ type: 'UNSELECT_CHANNELS', payload: channelsIds }),
  clearSelection: () => dispatch({ type: 'CLEAR_SELECTION' }),
  toggleCompareWith: () => dispatch({ type: 'TOGGLE_COMPARE_WITH' }),
  setDateInPeriods: (index, varName, value) => dispatch({ type: `SET_DATE`, payload: { index, varName, value } }),
  setPeriodType: (index, type) => dispatch({ type: `SET_PERIOD_TYPE`, payload: { index, type } }),
  getMe: () => alwaysResolvingPromise(cb => {
    const user = token.loadUserFromToken()
    if (user) {
      dispatch({
        type: `USERS_FETCH_START`,
        ...debugActionData('getMe', 'backend.get (with modifications)', ['users', user.id]),
        effects: fetchingEffect(`USERS`, async (...args) => {
          const me = await backend.get(...args)
          if (!me.app_metadata) {
            me.app_metadata = app_metadata
          }
          if (!me.app_metadata.selectedChannels) {
            me.app_metadata.selectedChannels = app_metadata.selectedChannels
          }
          if (!me.app_metadata.timePeriods) {
            me.app_metadata.timePeriods = app_metadata.timePeriods
          }
          if (!me.app_metadata.dashboardItems) {
            me.app_metadata.dashboardItems = app_metadata.dashboardItems
          }
          if (!me.app_metadata.subscriptions) {
            me.app_metadata.subscriptions = app_metadata.subscriptions
          }
          if (!me.app_metadata.reportSettings) {
            me.app_metadata.reportSettings = app_metadata.reportSettings
          }
          return me
        }, cb, 'users', user.id)
      })
    } else {
      cb(false)
    }
  })
})