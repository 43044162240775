import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import Assignment from '@material-ui/icons/Assignment'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import extendedTablesStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import TableWrapper from '../../components/TableWrapper/TableWrapper.jsx'
import { withRouter } from 'react-router-dom'

import ProgressBar from './ProgressBar.jsx'
import FlowName from './FlowName.jsx'
import { MenuItem, Select } from '@material-ui/core'
import DurationField from './DurationField.jsx'
import JobsDetails from './JobsDetails.jsx'

class Jobs extends React.Component {
  _isMounted = false

  constructor (props) {
    super(props)

    const hash = props.history.location.hash
    const parsedHash = this.parseHash(hash)

    this.state = {
      status: parsedHash['status'] || 'all',
      time: parsedHash['time'] || null
    };
    this.child = React.createRef()
  }

  componentDidMount() {
    this._isMounted = true
    this.props.history.listen((h) => {
      const ph = this.parseHash(h.hash)
      this._isMounted && this.setState({
        status: ph['status'] || 'all',
        time: ph['time'] || null
      })
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  parseHash(hash) {
    const result = {}
    const hashTrim = hash.replace(/^#|^\?|&$/g, '').split('&')
    hashTrim.map(i => {
      const [key, value] = i.split('=')
      result[key] = value
      return null
    })
    return result
  }

  progressField(entry, index) {
    if(entry.status === 'running') {
      return (<ProgressBar id={entry.id} progress={entry.progress} />)
    } else {
      return (<div style={{
        textTransform: 'uppercase'
      }}>{entry.status}</div>)
    }
  }

  durationField(entry, index) {
    return (<DurationField
      id={entry.id}
      start={entry.start}
      end={entry.end}
      status={entry.status}
    />)
  }

  flowNameField(entry, index) {
    return (<FlowName id={entry.flowId} />)
  }

  dateField(entry, index) {
    const currentDate = new Date(entry.createdAt)
    return (<div>{currentDate.getMonth()+1}/{currentDate.getDate()}/{currentDate.getFullYear()}</div>)
  }

  filterChanged(data) {
    // this.setState({
    //   status: data.target.value
    // })
    this.props.history.push(`/app/jobs#status=${data.target.value}`)
  }

  render () {
    let toggleDisplay = (id, job) => {
        this.props.history.push(`/app/assets/${job.titleId}/jobs/${job.id}`) // please don't change
    }
    const jobId = this.props.match.params.jobId

    return (
      <div>
        <GridContainer>
          {
            jobId
              ? <JobsDetails
                {...this.props}
                id={jobId !== 'new' ? jobId : null}
                titleId={this.props.search}
                toggleDisplay={toggleDisplay} />
              : <TableWrapper
                onRef={(ref) => { this.child = ref }}
                {...this.props}
                resource={'jobs'}
                toggleDisplay={toggleDisplay}
                status={this.state.status === 'all' ? null : this.state.status}
                search={this.props.search}
                hideSearch={false}
                fields={['name', this.progressField, this.durationField, this.flowNameField, 'id', this.dateField]}
                labels={['Job Name', 'Progress', 'Duration', 'Flow', 'Job ID', 'Created']}
                rowsPerPage={10}
                resourceName={{ singular: 'Job', plural: 'Jobs' }}
                icon={Assignment}
                query={this.state.time ? {
                  createdAt: {
                    $gt: parseInt(this.state.time)
                  }
                } : {}}
                buttons={(
                  <div style={{
                    marginRight: 10,
                    marginTop: 20
                  }}>
                    <Select
                      labelid="resource-select-label"
                      id="resource-select"
                      value={this.state.status}
                      onChange={this.filterChanged.bind(this)}
                      label="Job Filter"
                    >
                      <MenuItem value='all'>
                        <em>All</em>
                      </MenuItem>
                      
                      <MenuItem value="done">
                        <em>Done</em>
                      </MenuItem>
                      
                      <MenuItem value="failed">
                        <em>Failed</em>
                      </MenuItem>
                      
                      <MenuItem value="running">
                        <em>Running</em>
                      </MenuItem>
                    </Select>
                  </div>
                )}
              />
          }
        </GridContainer >
      </div>

    )
  }
}
export default withRouter(withStyles(extendedTablesStyle)(Jobs))
