import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import Assignment from '@material-ui/icons/Assignment'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import JobDetails from '../../views/Assets/JobDetails.jsx'
import extendedTablesStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import TableWrapper from '../../components/TableWrapper/TableWrapper.jsx'
import { withRouter } from 'react-router-dom'

class Jobs extends React.Component { 
  constructor (props) {
    super(props);
    this.state = {
      showDetails: props.showDetails, 
      id: props.id
    }
  }

  render () {
    const assetId = this.props.match.params.assetId
    let toggleDisplay = (id) => {
      if (id) {
        this.props.history.push('/app/assets/' + assetId + '/jobs/' + id)
      } else {
        this.props.history.push('/app/assets/' + assetId)
      }
    }
    const jobId = this.props.match.params.jobId

    return (
      <div>
        <GridContainer>
          {
            jobId
              ? <JobDetails
                {...this.props}
                id={jobId !== 'new' ? jobId : null}
                titleId={this.props.search}
                toggleDisplay={toggleDisplay} />
              : <TableWrapper
                {...this.props}
                buttons={<p></p>}
                resource={'jobs'}
                toggleDisplay={toggleDisplay}
                search={this.props.search}
                hideSearch={true}
                fields={['status', 'id', 'name', 'createdAt']}
                labels={['Status', 'ID', 'Name', 'Created at']}
                rowsPerPage={10}
                resourceName={{ singular: 'Job', plural: 'Jobs' }}
                icon={Assignment}
              />
          }
        </GridContainer >
      </div>

    )
  }
}
export default withRouter(withStyles(extendedTablesStyle)(Jobs))
