import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Card from '@material-ui/core/Card'
import muxjs from 'mux.js'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import backend from '../../utils/backend'

import shakaModule from './shaka'
import hlsJsModule from './hls'

import './player.style.css'

const mrssUrls = origin => ({
  hls: origin + '/api/unsecured-v2/playlists/generate?format=mrss&packaging=hls&markers=0,3,30;10,3,30;60,3,30&content=',
  dash: origin + '/api/unsecured-v2/playlists/generate?format=mrss&packaging=dash&markers=0,3,30;10,3,30;60,3,30&content='
})

const loadMRSS = async (url, apiKey, type, manifest, origin) => {
  if (['hls', 'dash'].indexOf(type) < 0) {
    throw Error('loadMRSS() - type not supported')
  }

  const body = JSON.stringify({
    "url": encodeURI(mrssUrls(origin)[type] + manifest),
    "context": "web",
    "ifa": "ifa",
    "deliverytypes": [type]
  })
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'api-key': apiKey,
      'cache-control': 'no-cache'
    },
    body
  }
  const response = await fetch(url, options)
  return response.json()
}

export default class ChannelPlayer extends Component {
  constructor(props) {
    super(props)
    window.muxjs = muxjs
    this.state = {}

    this.close = this.close.bind(this)
    this.onErrorEvent = this.onErrorEvent.bind(this)
    this.isLoadedLeft = false
    this.isLoadedRight = false
  }
  async componentDidMount () {
    this._isMounted = true
    let channel
    try {
      channel = await backend.get('channels', this.props.playerChannelId)

      if (channel.type === 'dash-live' || channel.type === 'hls-live') {
        const response = await fetch(channel.outputUri)
        if (response.status === 404) {
          this._isMounted && this.setState({ playerRightError: { data: [null, 404] } })
        } else {
          const body = await response.json()
          channel.finalOutputUri = body.mediaURL
        }
      } else if (channel.type.indexOf('vod') >= 0) {
        const type = channel.type.replace('-vod', '')
        const mrss = await loadMRSS(channel.outputUri, channel.apiKey, type, channel.sourceManifestUrl, channel.NEMO_FQDN)
        channel.finalOutputUri = mrss[type].url
      } else {
        channel.finalOutputUri = channel.outputUri
      }
    } catch (ex) {
      console.error(ex.message)
    }
    if (channel && !channel.finalOutputUri) {
      this._isMounted && this.setState({ playerRightError: 'url cannot be retrieved' })
    }
    if (channel && channel.sourceManifestUrl) {

      if (channel.sourceManifestUrl.startsWith('http:')) {
        this._isMounted && this.setState({ validation: { SSL: false, CORS: undefined } })
      } else {
        try {
          await fetch(channel.sourceManifestUrl)
          this._isMounted && this.setState({ validation: { SSL: true, CORS: true } }) // if loaded - then all ok
        } catch (ex) {
          console.log(ex)
          backend.validateSourceManifestUrl(channel.sourceManifestUrl)
            .then(validation => {
              if (!validation.CORS)
                this._isMounted && this.setState({ validation })
            })
            .catch(e => this._isMounted && this.setState({ validationError: e }))
        }
      }

      if (channel.type === 'hls-live' || channel.type === 'hls-vod') {
        this.playerModule = hlsJsModule
      } else {
        this.playerModule = shakaModule
      }

      try {
        this.playerModule.initPlayer(this, channel)
      } catch (ex) {
        console.error(ex)
      }
    }
  }

  onErrorEvent (player) {
    return (event, data) => {
      const newStatePart = {}
      if((!this.isLoadedLeft && player === 'left') || (!this.isLoadedRight && player === 'right')) {
        newStatePart['player' + player + 'Error'] = event
        if (data) {
          newStatePart['player' + player + 'ErrorData'] = data
        }
      }
      if(Object.keys(newStatePart).length !== 0) {
        this._isMounted && this.setState(newStatePart)        
      }
    }
  }

  validationMessage = () => {
    const validation = this.state.validation
    let msg = {
      default: [],
      left: [],
      right: []
    }
    if (validation && validation.SSL === false) {
      msg.default.push('The original source stream is NOT secured with SSL (https). The player can only play https streams.')
    }
    if (validation && validation.CORS === false) {
      msg.default.push('The original source stream is NOT CORS-enabled. The player can only play CORS-enabled streams.')
    }

    const { playerLeftError } = this.state
    if (!this.isLoadedLeft && playerLeftError) {
      //TODO: push different messages depending on error (see the same section about right player)
      msg.left.push('Original source stream - error loading stream.')
    }

    const { playerRightError } = this.state
    if (!this.isLoadedRightv && playerRightError) {
      if (playerRightError.data && playerRightError.data[1] === 404)
        msg.right.push('Ad-enabled stream - error 404, cannot load stream. Try to restart your channel.')
      else
        msg.right.push('Ad-enabled stream - unexpected error.')
    }

    if (msg !== []) {
      return msg
    }
  }

  close () {
    try {
      this.playerModule && this.playerModule.destroyPlayer(this)
    } catch (ex) {
      console.error(ex)
    }

    if (typeof this.props.hide === 'function') {
      this.props.hide()
    }
  }

  render () {
    const validationErrors = this.validationMessage()
    return (
      <Dialog
        open={true}
        keepMounted
        onClose={() => {
          this.close()
        }}
        fullWidth={true}
        maxWidth="md"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <GridContainer>
            <GridItem xs={6}>
              <p style={{ fontWeight: 'bold' }}>Source Stream</p>
              <div data-vjs-player>
                <Card style={{ padding: 5, textAlign: 'center', marginBottom: 10 }}>
                  <video
                    ref={node => this.playerLeftRef = node}
                    width="320px"
                    height="256px"
                    poster="//shaka-player-demo.appspot.com/assets/poster.jpg"
                    controls
                    autoPlay
                    className="video-js">
                  </video>
                </Card>
              </div>
            </GridItem>
            <GridItem xs={6}>
              <p style={{ fontWeight: 'bold' }}>Stream with Ad-Insertion enabled</p>
              <div data-vjs-player>
                <Card  style={{ padding: 5, textAlign: 'center', marginBottom: 10 }}>
                  <video
                    ref={node => this.playerRightRef = node}
                    width="320px"
                    height="256px"
                    poster="//shaka-player-demo.appspot.com/assets/poster.jpg"
                    controls
                    autoPlay
                    className="video-js">
                  </video>
                </Card>
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6}>
              {validationErrors && validationErrors.left.length !== 0 && <div class="player-error">{validationErrors.left.map((msg, i) => msg)}</div>}
            </GridItem>
            <GridItem xs={6}>
              {validationErrors && validationErrors.right.length !== 0 && <div class="player-error">{validationErrors.right.map((msg, i) => msg)}</div>}
            </GridItem>
          </GridContainer>
          {
            validationErrors && validationErrors.default.length !== 0 && <GridContainer>
              <GridItem xs={12}>
                <div class="player-error">{validationErrors.default.map((msg, i) => msg)}</div>
              </GridItem>
            </GridContainer>
          }
        </DialogContent>
        <DialogActions>

          <Button
            onClick={() => {
              this.close()
            }}
            color="primary">
            Close
            </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
