import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import Assignment from "@material-ui/icons/Assignment";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import GridItem from "../../../components/Grid/GridItem.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardIcon from "../../../components/Card/CardIcon.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import Card from "../../../components/Card/Card.jsx";
import backend from '../../../utils/backend';
import token from '../../../utils/token'
import { showSuccessMessage, showErrorMessage } from '../../../components/Alerts/Alerts'

class InviteUsersPage extends Component {

  constructor (props) {
    super(props)
    this.state = {
      email: "",
      buttonCaption: 'Cancel'
    }
  }

  onClickInvite = async () => {
    try {
      const loggedInUser = token.loadUserFromToken()
      if (!loggedInUser) { throw new Error('Cannot get logged in user') }
      const inviteResult = await backend.inviteUser({ email: this.state.email, invitedBy: loggedInUser.email })

      if (inviteResult.message === "The user already exists.") {
        throw Error(inviteResult.message)
      }
      await showSuccessMessage(`${this.state.email} has been invited!`)
      this.setState({ buttonCaption: 'Back' })
    } catch (e) {
      console.log(e.message)
      showErrorMessage(e.message)
    }
  }

  render () {
    const { toggleDisplay, classes } = this.props

    return (
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <Grid item xs={4}>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Invite a User</h4>
            </Grid>
          </CardHeader>
          <CardBody>
            <Grid item xs={12}>
              <Grid item xs={6}>
                <TextField
                  id="email"
                  label="email"
                  className={classes.textField}
                  value={this.state.email}
                  onChange={(event) => this.setState({ email: event.target.value })}
                  margin="normal"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid>
              <Button onClick={toggleDisplay} >
                {this.state.buttonCaption}
              </Button>
              <Button
                disabled={this.state.buttonCaption === "Back"}
                onClick={this.onClickInvite}
              >Invite</Button>
            </Grid>
          </CardBody>
        </Card>
      </GridItem >
    )
  }
}

export default InviteUsersPage
