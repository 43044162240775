const JSONStateHandler = {

  getJSONFieldNames: (schema, types) => {
    let result = []

    if (typeof schema.properties !== 'object') {
      return result
    }

    types = types || ['object', 'array']
    // eslint-disable-next-line no-unused-vars
    for (let [key, value] of Object.entries(schema.properties)) {
      if (types.includes(value.type)) {
        result.push(key)
      }
    }

    return result
  },

  transformJSONProperties: (source, fieldNames) => {
    // convert the object properties to text
    let converted = {}
    fieldNames.forEach(fieldName => {
      converted[fieldName] = JSON.stringify(source[fieldName], null, 2) // pretty
    })

    return converted
  },
  transformJSONPropertiesFromSchema: (source, schema) => {
    return JSONStateHandler.transformJSONProperties(source, JSONStateHandler.getJSONFieldNames(schema))
  },
  validateJSONFieldsFromSchema: (source, schema) => {
    return JSONStateHandler.validateJSONFields(source, JSONStateHandler.getJSONFieldNames(schema))
  },
  validateJSONFields: (source, fieldNames) => {
    let currFieldName
    try {
      // convert JSON text to object
      fieldNames.forEach(fieldName => {
        currFieldName = fieldName
        source[fieldName] = JSON.parse(source[fieldName])
      })

      return true
    } catch (err) {
      // ecsue we can add value here, with the field name
      throw new Error(`Invalid JSON in ${currFieldName}`)
    }
  }
}
export default JSONStateHandler
