import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Assignment from '@material-ui/icons/Assignment'
import GridItem from '../../components/Grid/GridItem.jsx'
import Button from '../../components/CustomButtons/Button.jsx'
import Card from '../../components/Card/Card.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import { TextField, Grid } from '@material-ui/core'
import CardIcon from '../../components/Card/CardIcon.jsx'
import CardHeader from '../../components/Card/CardHeader.jsx'
import extendedTablesStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'

import backend from '../../utils/backend'

class Details extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.state = {
      flow: {}
    }
  }
  async componentDidMount () {
    this._isMounted = true

    if (this.props.id) {
      try {
        const flow = await backend.get('flows', this.props.id)
        flow.taskTemplates = JSON.stringify(flow.taskTemplates, null, 2)
        this.setState({
          flow
        })
      } catch (e) {
        if (e.response && e.response.status && this._isMounted) {
          this.setState({ status: e.response.status })
        }
      }
    } else {
      if (this._isMounted) {
        this.setState({
          flow: { name: 'test' }
        })
      }
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  saveFlow = async () => {
    console.log(this.state.flow)
    this.state.flow.taskTemplates = this.state.flow.taskTemplates || []
    
    try {
      // convert text to object
      this.state.flow.taskTemplates = JSON.parse(this.state.flow.taskTemplates)
      let result
      if (!this.state.flow.id) {
        result = await backend.create('flows', this.state.flow)
      } else {
        result = await backend.update('flows', this.state.flow)
      }
      if (result.code > 399) console.error(result)
      else this.props.toggleDisplay()
    } catch (e) {
      console.error(e)
    }
  }

  updateFlow = event => {
    const flow = this.state.flow
    flow[event.target.id] = event.target.value
    this.setState({ flow })
  }

  render () {
    const { classes } = this.props
    const { error } = this.state

    const backButton = <Button onClick={() => this.props.toggleDisplay()}>Cancel</Button>

    let header
    let content

    if (error) {
      content = <CardBody>Error in loading</CardBody>
    }
    if (this.state.status === 404) {
      content = <CardBody><h4>Workflow not found</h4>{backButton}</CardBody>
    } else if (this.state.flow) {
      header = (
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <Assignment />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Flow Details</h4>
        </CardHeader>)
      content = (<CardBody>
        <Grid container spacing={8}>
          <Grid item md={12}>
            <h4>Flow Settings</h4>
            <Grid item md={12}>
              <TextField
                id="name"
                label="Name"
                className={classes.textField}
                fullWidth
                helperText="Name your flow"
                value={this.state.flow.name || ''}
                onChange={this.updateFlow}
                margin="normal"
              />
            </Grid>
            <Grid container spacing={8}>
              <Grid item md={6}>
                <TextField
                  id="attempts"
                  label="Attempts"
                  type="number"
                  helperText="Retries until a job finally fails"
                  className={classes.textField}
                  value={this.state.flow.attempts || ''}
                  onChange={this.updateFlow}
                  margin="normal"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  id="timeout"
                  label="Timeout"
                  type="number"
                  helperText="Time in minutes until jobs fails"
                  className={classes.textField}
                  value={this.state.flow.timeout || ''}
                  onChange={this.updateFlow}
                  margin="normal"
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  multiline
                  id="taskTemplates"
                  label="Task Templates"
                  type="search"
                  className={classes.textField}
                  margin="dense"
                  fullWidth
                  onChange={this.updateFlow}
                  value={this.state.flow.taskTemplates}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            {backButton}
            <Button onClick={this.saveFlow} color="success">
              Save
                      </Button>
          </Grid>
        </Grid>
      </CardBody>
      )
    }

    return (
      <div>
        <GridItem>
          <Card>
            {header}
            {content}
          </Card>
        </GridItem>
      </div>
    )
  }
}

export default withStyles(extendedTablesStyle)(Details)


