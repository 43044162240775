import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Assignment from '@material-ui/icons/Assignment'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import Card from '../../components/Card/Card.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import CardIcon from '../../components/Card/CardIcon.jsx'
import CardHeader from '../../components/Card/CardHeader.jsx'
import Button from '../../components/CustomButtons/Button.jsx'
import TextField from '@material-ui/core/TextField'
import extendedTablesStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import backend from '../../utils/backend'
import { showErrorMessage, showSuccessMessage, showConfirmationMessage } from '../../components/Alerts/Alerts'
import { BackendError } from './../../utils/errors'

class Details extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      system: {}
    }
  }
  componentDidMount = async () => {
    const systemSchema = await backend.schema('systems')
    this.setState({
      availSystems: systemSchema.properties.type.enum,
      availCategories: systemSchema.properties.category.enum,
    })
    if (this.props.id) {
      try {
        const system = await backend.get('systems', this.props.id)
        system.settings = system.settings || {}
        const schema = await backend.schema('systems', system.type)
        schema.properties.settings = schema.properties.settings
          ? schema.properties.settings
          : { properties: {} }
        const enableBmCheck = system.type === 'bitmovin'
        this.setState({
          schema,
          system,
          currentSystem: system.type,
          enableBmCheck,
          origSystem: JSON.parse(JSON.stringify(system))
        })

      } catch (e) {
        if (e.response && e.response.status) {
          this.setState({ status: e.response.status })
        } else {
          return showErrorMessage('Error while Loading. ' + e.message)
        }
      }
    }
  }

  changeSystem = async event => {
    const schema = await backend.schema('systems', event.target.value)
    schema.properties.settings = schema.properties.settings
      ? schema.properties.settings
      : { properties: {} }
    const system = {
      id: this.state.system.id,
      name: this.state.system.name,
      type: event.target.value,
      settings: {}
    }
    this.setState({
      system,
      schema,
      currentSystem: event.target.value,
      enableBmCheck: false
    })
  }

  changeProperty = event => {
    const system = this.state.system
    system[event.target.name] = event.target.value
    this.setState({
      system,
      enableBmCheck: false
    })
  }

  changeSettings = event => {
    let system = this.state.system
    system.settings[event.target.name] = event.target.value
    this.setState({ system, enableBmCheck: false })
  }

  onSave = async () => {
    if (this.state.system.id && JSON.stringify(this.state.system) !== JSON.stringify(this.state.origSystem)) {
      const medias = await backend.list({ resource: 'medias', createdBySystemId: this.state.system.id })
      if (medias.length > 0 && ! await showConfirmationMessage(() => { }, {
        text: `You have ${medias.length} medias created with this system, it could be that by changing it's properties you can no longer be able to delete them from the cloud storage.`,
        confirmButtonText: 'Save changes'
      })) {
        return
      }
    }
    const upsert = this.state.system.id ? backend.update : backend.create
    let result
    try {
      result = await upsert('systems', this.state.system)
      if (result.code && result.code > 399) throw new BackendError(result)
      this.setState({ enableBmCheck: true })
      //this.props.toggleDisplay()
      showSuccessMessage('System Saved')
    } catch (e) {
      console.error(e)
      showErrorMessage(e)
    }
    this.setState({ origSystem: JSON.parse(JSON.stringify(this.state.system)) })
  }

  checkBitmovin = async () => {
    let result
    try {
      result = await backend.checkBitmovin(this.state.system.id, this.state.system.settings.apiKey, this.state.system.settings.organizationId)

      const show = result.status === "SUCCESS" ? showSuccessMessage : showErrorMessage
      this.state.enableBmCheck = false
      show(result.message)
    } catch (e) {
      console.error(e)
      this.state.enableBmCheck = false
      showErrorMessage(e)
    }
  }

  render () {
    window.sysState = this.state
    const { classes } = this.props

    const backButton = <Button onClick={() => this.props.toggleDisplay()}>Back</Button>

    let header
    let content

    if (this.state.status === 404) {
      content = <CardBody><h4>System not found</h4>{backButton}</CardBody>
    } else {
      header = <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <Assignment />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>System Details</h4>
      </CardHeader>
      content = (<CardBody>
        <GridContainer justify="space-between">
          <GridItem xs={4}>
            <TextField
              id="name"
              name="name"
              label="Name"
              // className={classes.textField}
              value={this.state.system.name || ''}
              onChange={this.changeProperty}
              margin="normal"
              fullWidth
            />
          </GridItem>
          <GridItem xs={4}>
            <FormControl width="100%">
              <InputLabel htmlFor="system">System</InputLabel>
              <Select
                name="system"
                value={this.state.currentSystem || ''}
                onChange={this.changeSystem}
                fullWidth
                inputProps={{
                  name: 'system',
                  id: 'system'
                }}
              >
                {this.state.availSystems ? (
                  this.state.availSystems.map(system => (
                    <MenuItem key={system} value={system}>
                      {system}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key={'placeholder'} value={''}>
                    loading...
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </GridItem>

          <GridItem xs={4}>
            <FormControl width="100%">
              <InputLabel htmlFor="system">Category</InputLabel>
              <Select
                name="category"
                value={this.state.system.category || ''}
                onChange={this.changeProperty}
                fullWidth
                inputProps={{
                  name: 'category',
                  id: 'category'
                }}
              >
                {this.state.availCategories ? (
                  this.state.availCategories.map(category => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key={'placeholder'} value={''}>
                    loading...
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </GridItem>

        </GridContainer>
        {this.state.schema ? (
          <div>
            <h3>Settings</h3>
            <GridContainer justify="space-between">
              {Object.keys(
                this.state.schema.properties.settings.properties
              ).map(key => (
                <GridItem key={key} xs={4}>
                  <TextField
                    id={key}
                    name={key}
                    label={key}
                    type={ ['activeDirectoryClientSecret', 'storageKey'].includes(key) ? 'password' : 'text' }
                    fullWidth
                    // className={classes.textField}
                    value={this.state.system.settings[key] || ''}
                    onChange={this.changeSettings}
                    margin="normal"
                  />
                </GridItem>
              ))}

            </GridContainer>
          </div>
        ) : (
          <p>select a system to show settings</p>
        )}
        {backButton}

        <Button color="success" onClick={this.onSave}>Save</Button>
        <span style={{ marginLeft: '15px' }}>
          {this.state.currentSystem === 'bitmovin' && this.state.enableBmCheck &&
            <Button color="warning" onClick={this.checkBitmovin}>Check Bitmovin</Button>
          }</span>

      </CardBody >)
    }

    return (
      <GridItem xs={12}>
        <Card>
          {header}
          {content}
        </Card>
      </GridItem>
    )
  }
}

export default withStyles(extendedTablesStyle)(Details)