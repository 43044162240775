import backend from '../utils/backend'

/**
 * Initial empty state for all entities
 */
export const initialState = {
  current: null,
  list: [],
  error: null,
  isLoading: false
}

/**
 * Basic reducer for entities
 * @param {*} entityName 
 */
export const reducer = entityName => (state = initialState, action) => {
  const ENTITY = entityName.toUpperCase()
  let newState
  switch (action.type) {
    case `${ENTITY}_FETCH_START`: {
      return {
        ...state,
        isLoading: true,
        error: null
      }
    }
    case `${ENTITY}_FETCH_SUCCESS`: {
      newState = { ...state }
      if (Array.isArray(action.payload)) {
        if (action.payload.dontOverwrite) {
          const newElements = action.payload.filter(elNew => !newState.list.find(el => el.id === elNew.id))
          newState.list = newState.list.filter(el => action.payload.find(elNew => el.id === elNew.id))
          if (newElements) {
            newState.list.push(...newElements)
          }
        } else {
          newState.list = action.payload
        }
      } else if (typeof action.payload === 'object') {
        newState.current = {
          ...newState.current,
          ...action.payload
        }
      }
      newState.isLoading = false
      return newState
    }
    case `${ENTITY}_FETCH_ERROR`: {
      return { ...state, error: action.payload, isLoading: false }
    }
    case `${ENTITY}_CURRENT_CHANGED`: {
      return { ...state, current: { ...state.current, ...action.payload } }
    }
    case `${ENTITY}_ITEM_CHANGED`: {
      newState = { ...state }
      newState.list[action.payload.index] =
        { ...newState.list[action.payload.index], ...action.payload.element }
      return newState
    }
    case `AFTER_EFFECT`: {
      if (typeof action.payload === 'function') {
        action.payload(state)        
      }
      return state
    }
    default:
      return state
  }
}
/**
 * abstract effect
 * @param {*} ENTITY 
 * @param {*} backendMethod 
 * @param  {...any} args 
 */
export const fetchingEffect = (ENTITY, backendMethod, cb, ...args) =>
  async () => {
    let err, res, type, payload
    try {
      res = payload = await backendMethod(...args)
      type = `${ENTITY}_FETCH_SUCCESS`
    } catch (e) {
      type = `${ENTITY}_FETCH_ERROR`
      err = payload = e
    }
    
    return {
      type,
      payload,
      effects: () => ({
        type: `AFTER_EFFECT`,
        payload: state => {
          if (typeof cb === 'function') {
            cb(err, res, state)
          }
        }
      })
    }
  }
  
/**
 * Let's make action promises never rejecting - to be able to put them safely in any place without await:
 * @param {*} f 
 */
export const alwaysResolvingPromise = async f => {
  return new Promise(resolve => f((error, result, state) => {
    if (error) {
      resolve({ error, state })
      return
    }
    resolve({ result, state })
  }))
}

/**
 * Constructs an object from 3 values. In the future, this method should be removed
 * (or can be set to return empty object).
 * @param {*} action 
 * @param {*} backendMethod 
 * @param {*} backendMethodArgs 
 */
export const debugActionData = (action, backendMethod, backendMethodArgs) => ({
  action, backendMethod, backendMethodArgs
})

/**
 * Basic actions for entities.
 * NB: action, backendMethod, backendMethodArgs - used only to debug with Redux Dev Tools
 * @param {*} entityName
 * @param {*} dispatch
 */
export const actions = (entityName, dispatch) => {
  const ENTITY = entityName.toUpperCase()
  return {
    /**
     * Sometimes we do not want to overwrite existing items in state (that's why dontOverwrite is there), and sometimes 
     * we have different url for GET request (differentEntityName).
     * (See case with sytems)
     */
    getAllItems: (dontOverwrite = false, differentEntityName = entityName) => alwaysResolvingPromise(cb => dispatch({
      type: `${ENTITY}_FETCH_START`,
      ...debugActionData('getAllItems', 'backend.list', [{ resource: differentEntityName }]),
      effects: async (...args) => {
        const action = await fetchingEffect(ENTITY, backend.list, cb, { resource: differentEntityName, limit: -1 })(...args)
        if (action.payload && dontOverwrite) {
          action.payload.dontOverwrite = true
        }
        return action
      }
    })),
    getOneItem: id => alwaysResolvingPromise(cb => dispatch({
      type: `${ENTITY}_FETCH_START`,
      ...debugActionData('getOneItem', 'backend.get', [entityName, id]),
      effects: fetchingEffect(ENTITY, backend.get, cb, entityName, id)
    })),
    updateItem: body => alwaysResolvingPromise(cb => dispatch({
      type: `${ENTITY}_FETCH_START`,
      ...debugActionData('updateItem', 'backend.update', [entityName, body]),
      effects: fetchingEffect(ENTITY, backend.update, cb, entityName, body)
    })),
    deleteItem: id => alwaysResolvingPromise(cb => dispatch({
      type: `${ENTITY}_FETCH_START`,
      ...debugActionData('getAllItems', 'backend.delete', [entityName, id]),
      effects: fetchingEffect(ENTITY, backend.delete, cb, entityName, id)
    })),
  }
}
