
/**
 * Use to extract full error information from e.g. Moleculer errors.
 *
 * @class BackendError
 * @extends {Error}
 */
class BackendError extends Error {
  /**
   *Creates an instance of BackendError.
   * @param {*} result - either a Moleculer error JSON result or similar or just a string.
   * (in the latter case acts exactly like Error)
   * @memberof BackendError
   */
  constructor(result) {
    let errorData
    if (result.data) {
      if (typeof result.data === 'object') {
        errorData = JSON.stringify(result.data)
      } else {
        errorData = `${result.data}`
      }
    }

    errorData = errorData ? ' - ' + errorData : ''
    const errorMessage = result.message ? `${result.message}${errorData}` : result
    super(errorMessage)
  }
}

/**
 * This one can hold any data and preserve stacktrace
 * @param {*} message 
 * @param {*} data 
 */
function ExtendedError (message, data) {
  this.name = 'ExtendedError'
  this.message = message || ''
  this.data = data
  var error = new Error(this.message)
  error.name = this.name
  this.stack = error.stack
}
ExtendedError.prototype = Object.create(Error.prototype)

export { BackendError, ExtendedError } 