import React, { Component } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import IconButton from '@material-ui/core/IconButton'
import GridItem from '../../components/Grid/GridItem.jsx'
import GridContainer from '../../components/Grid/GridContainer.jsx'

import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'

import CircularProgress from '@material-ui/core/CircularProgress'

import { green, deepOrange, yellow } from '@material-ui/core/colors'

class ResourceSuppressHLSTagsDropdown extends Component {

  constructor(props) {
    super(props)
    this.state = {
      currentValue: props.value,
      data: this.serializeData(props.data)
    }
  }

  valueGenerator() {
    const { style, classes, noEmpty, handleChange } = this.props
    const { currentValue, data } = this.state
    const result = []

    currentValue.forEach((value) => {
      result.push(
        <div key={value} style={{ display: 'inline-block', ...style }}>
          <GridContainer>
            <GridItem xs={10} sm={10} md={10} lg={10}>
              <FormControl className={classes.formControl} style={{ width: '100%' }}>
                {/* <InputLabel id="resource-select-label">{inputLabelText}</InputLabel> */}
                <Select
                  labelid="resource-select-label"
                  id="resource-select"
                  value={value || ''}
                  renderValue={value => {
                    if (data) {
                      const entry = data.find(entry => entry.id === value)
                      if (entry) return entry.name
                    }
                  }}
                  disabled={true}
                >
                  {!noEmpty && <MenuItem value="">
                    <em>None</em>
                  </MenuItem>}
                  {data && typeof data.map === 'function' && data.map(entry =>
                    <MenuItem disabled={typeof entry.status !== 'undefined' && !(entry.status === 'done')} key={entry.id} value={entry.id}>
                      <ListItemText primary={entry.name} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} />
                    </MenuItem>)}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={2} sm={2} md={2} lg={2}>
              <IconButton
                style={{ padding: 5 }}
                  className={classes.actionButton}
                  color='primary'
                  onClick={() => {
                    const nValue = currentValue.filter(i => i !== value)
                    this.setState({
                      currentValue: nValue
                    })
                    if(handleChange) handleChange(nValue)
                  }}
                >
                <img alt="" src={require('../../assets/img/buttons/remove.png')} height="24" />
              </IconButton>
            </GridItem>
          </GridContainer>
        </div>
      )
    })
    return result;
  }

  serializeData(data) {
    return data.reduce((ret, item) => {
      ret.push({
        id: item,
        name: item
      })
      return ret
    }, [])
  }

  trimedData() {
    const { data, currentValue } = this.state
    return data.filter(i => !currentValue.includes(i.id))
  }

  render () {
    const { classes, handleChange, inputLabelText, style } = this.props
    const data = this.trimedData()

    const statusIcon = status => {
      if (status === 'done') return <DoneIcon style={{ color: green[500] }} />
      if (status === 'failed') return <CloseIcon style={{ color: deepOrange[700] }} />
      return <CircularProgress size={25} style={{ color: yellow[600] }} />
    }

    return (
      <div style={style}>
        {this.valueGenerator()}
        { data.length !== 0 && <GridContainer>
            <GridItem xs={10} sm={10} md={10} lg={10}>
              <FormControl key={new Date() / 1000} className={classes.formControl} style={{ width: '100%' }}>
                <InputLabel id="resource-select-label">{inputLabelText}</InputLabel>
                <Select
                  labelid="resource-select-label"
                  id="resource-select"
                  onChange={(e) => {
                    const nValue = [
                      ...this.state.currentValue,
                      e.target.value
                    ]
                    this.setState({
                      currentValue: nValue
                    })
                    if(handleChange) handleChange(nValue)
                  }}
                  value=""
                >
                  {data && typeof data.map === 'function' && data.map(entry => {
                    return <MenuItem disabled={typeof entry.status !== 'undefined' && !(entry.status === 'done')} key={entry.id} value={entry.id}>
                      <ListItemText primary={entry.name} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} />
                      {entry.status && <div>{statusIcon(entry.status)}</div>}
                    </MenuItem>
                  })}
                </Select>
              </FormControl>
          </GridItem>
        </GridContainer> }
      </div>
    )
  }

}

export default ResourceSuppressHLSTagsDropdown
