// ##############################
// // // RegisterPage view styles
// #############################

import {
  container,
  cardTitle
} from "../../../../assets/jss/material-dashboard-pro-react.jsx";

import customCheckboxRadioSwitch from "../../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const registerPageStyle = {
  ...customCheckboxRadioSwitch,
  cardTitle: {
    ...cardTitle,
    textAlign: "center"
  },
  container: {
    ...container,
    position: "relative",
    zIndex: "3"
    // paddingTop: "23vh"
  },
  cardSignup: {
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    marginBottom: "100px",
    padding: "40px 0px"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  form: {
    padding: "0 20px",
    position: "relative"
  },
  socialTitle: {
    fontSize: "18px"
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  customFormControlClasses: {
    margin: "0 12px"
  },
  checkboxLabelControl: {
    margin: "0"
  },
  checkboxLabel: {
    marginLeft: "6px",
    color: "rgba(0, 0, 0, 0.26)"
  },
  termsHeader: {
    textAlign: "center"
  },
  termsHeaderImage: {
    height: "50px",
    display: "inline-block"
  },
  termsMainHeader: {
    color: "#FF0A78",
    fontSize: "1em",
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "30px"
  },
  termsChapterHeader: {
    color: "#FF0A78",
    fontSize: "1em",
    textAlign: "left"
  },
  termsEnd: {
    textAlign: "center"
  },
  termsParagraph: {
    fontSize: "1.0em"
  },
  termsHighlighted: {
    color: "#FF0A78"
  },
  termsSmall: {
    fontSize: "0.9em"
  },
  termsTable: {
    minWidth: 650,
    marginTop: "5px",
    marginBottom: "20px"
  },
  termsTableCell: {
    verticalAlign: "top",
    paddingRight: "10px",
    borderRight: "1px solid #e0e0e0",
    borderLeft: "1px solid #e0e0e0",
    borderTop: "1px solid #e0e0e0",
    color: "#3C4858",
    fontWeight: 300
  },
  termsTableHeaderText: {
    color: "#FF0A78",
    fontSize: "1.2em"
  },
  termsTableOrderedList: {
    paddingInlineStart: "0px",
    marginBlockStart: "0px",
    marginBlockEnd: "0px"
  }
};

export default registerPageStyle;
