import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Assignment from '@material-ui/icons/Assignment'
import GridContainer from '../../../components/Grid/GridContainer.jsx'
import InviteUsers from './InviteUsers'
import extendedTablesStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import UserDetails from './UserDetails.jsx'
import TableWrapper from '../../../components/TableWrapper/TableWrapper.jsx'
import Button from '../../../components/CustomButtons/Button.jsx'

class UsersPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showInviteUsers: false
    }
  }

  showInviteUsers = () => {
    this.setState({
      showInviteUsers: !this.state.showInviteUsers
    })
  }
  toggleDisplay = userId => {
    if (userId) {
      this.props.history.push('/app/settings/users/' + userId)
    } else {
      this.props.history.push('/app/settings/users')
    }
  }
  render () {
    const userId = this.props.match.params.userId
    return (
      <GridContainer>
        {userId ? (
          <UserDetails
            {...this.props}
            id={userId !== 'new' ? userId : null}
            toggleDisplay={this.toggleDisplay}
            goBack={()=>this.toggleDisplay()}
          />
        ) : this.state.showInviteUsers ? (
          <InviteUsers {...this.props} goBack={this.showInviteUsers} toggleDisplay={this.showInviteUsers} />
        ) : (
              <TableWrapper
                {...this.props}
                toggleDisplay={this.toggleDisplay}
                buttons={(
                  <Button
                    style={{ margin: '20px 10px' }}
                    variant="contained"
                    color="success"
                    onClick={() => {
                      this.setState({
                        showInviteUsers: true
                      })
                    }}
                    className={this.props.classes.button}
                  >
                    Invite User
                </Button>
                )}
                resource={'users'}
                fields={['id', 'name', 'email_verified', 'created_at']}
                labels={['ID', 'Name', 'Is email verified?', 'Created at']}
                goBack={this.goBack}
                rowsPerPage={10}
                resourceName={{ singular: 'User', plural: 'Users' }}
                icon={Assignment}
              />
            )}
      </GridContainer>
    )
  }
}
export default withStyles(extendedTablesStyle)(UsersPage)