import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import headerLinksStyle from '../../assets/jss/material-dashboard-pro-react/components/headerLinksStyle'
import backend from '../../utils/backend'
import { showErrorMessage } from '../Alerts/Alerts'
import token from './../../utils/token'

class HeaderJobsAlert extends React.Component {
    _jobsFailedCount = null
    state = {
        display: false,
        timer: null
    }

    async componentDidMount () {
        // TODO: Waiting for the job pages on the sidebar
        // TODO: Use the last date update and save the current created date into the memory

        try {
            const user = token.loadUserFromToken()
            if(['Admin', 'SuperAdmin', 'Editor'].includes(user.role)) {
                await this.updateDisplayState()
                this.state.timer = setInterval(async () => {
                    await this.updateDisplayState()
                }, 10000)
            }
        } catch (err) {
            console.log(err.message)
        }
    }

    componentWillUnmount() {
      clearInterval(this.state.timer)
    }

    closeMessage() {
        this.setState({
            display: false
        })
        return false
    }

    viewFailedJobs() {
        this.props.history.push('/app/jobs#status=failed')
        return false
    }

    async updateDisplayState() {
        try {
            const status = 'failed'
            const jobs = await this.loadJobs({status})
            if(this._jobsFailedCount !== jobs.totalCount) {
                this.setState({
                    display: jobs.totalCount !== 0
                })
                this._jobsFailedCount = jobs.totalCount
            }
        } catch(err) {
            console.log(err)
        }
    }

    async loadJobs({status}) {
        try {
            return backend.list({
                resource: 'jobs',
                status,
                limit: 10
            })
        } catch (e) {
            console.error(e)
            showErrorMessage(e.message)
        }
    }

    render() {
        return <div style={{
            display: this.state.display ? 'block' : 'none',
            marginBottom: 20
        }}>
            <div style={{
                backgroundColor: '#ec3748',
                color: '#ffffff',
                padding: 5,
                paddingLeft: 20,
                paddingRight: 20,
                position: 'absolute',
                left: 0,
                right: 0,
                top: 75
            }}>
                One or more jobs failed. <div onClick={this.viewFailedJobs.bind(this)} style={{
                    display: 'inline-block',
                    color: '#ffffff',
                    textDecoration: 'underline',
                    cursor: 'pointer'
                }}>Investigate the errors!</div>

                <div style={{
                    float: 'right',
                    display: 'inline-block',
                    cursor: 'pointer'
                }} onClick={this.closeMessage.bind(this)}>
                    <img alt="" height={22} src={require('../../assets/img/close-icon.svg')} />
                </div>
            </div>
        </div>
    }

}

export default withRouter(withStyles(headerLinksStyle)(HeaderJobsAlert))