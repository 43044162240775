import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import Email from '@material-ui/icons/Email'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import CustomInput from '../../components/CustomInput/CustomInput.jsx'
import Button from '../../components/CustomButtons/Button.jsx'
import Card from '../../components/Card/Card.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import CardHeader from '../../components/Card/CardHeader.jsx'
import CardFooter from '../../components/Card/CardFooter.jsx'
import loginPageStyle from '../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx'
import backend from '../../utils/backend'
import { withRouter } from 'react-router-dom'
import { showSuccessMessage, showErrorMessage } from '../../components/Alerts/Alerts'
import Logo from '../../assets/icons/serverside-ai-logo.svg'
import Swal from 'sweetalert2'
import { processAutn0Response } from '../../utils/auth0'
import { componentWithContext } from '../../state/DispatchContext'

class LoginPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cardAnimaton: 'cardHidden',
      firstname: '',
      email: '',
      password: ''
    }
  }

  async login () {
    try {
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')

      const resBody = await backend.login(this.state.email, this.state.password)
      if (resBody.error) throw new Error('Error logging in: ' + resBody.error)
      const token = resBody.access_token
      if (!token) throw new Error('No Token Transferred')
      localStorage.setItem('token', token)
      localStorage.setItem('refresh_token', resBody.refresh_token)
      this.props.history.push('app/dashboard')
      await this.props.context.actions.users.getMe()
    } catch (e) {
      const auth0entry = processAutn0Response(e.data)
      if (auth0entry && auth0entry.offerVerification) { //TODO: we need correct error processing, with codes or so
        const result = await Swal.fire({
          type: 'warning',
          title: auth0entry.messageForUser,
          input: 'checkbox',
          inputValue: 0,
          inputPlaceholder: 'Please send me verification message again',
          confirmButtonText: 'OK'
        })
        if (result.value) {
          try {
            await backend.sendVerificationEmail(this.state.email)
            Swal.fire('You will receive a verification email, if your address exists in our database.')
          } catch (ex) {
            showErrorMessage(ex.message)
          }
        }
      } else {
        let message = (auth0entry && auth0entry.messageForUser) || e.message
        if (auth0entry && auth0entry.showDetails && e.data && e.data.data) {
          message += ` code: ${e.data.code}, error: ${e.data.data.error}, error_description: ${e.data.data.error_description}`
        }
        showErrorMessage(message)
      }
    }
  }

  async sendPasswordReset () {
    try {
      const resBody = await backend.sendPasswordReset(this.state.email)
      if (resBody.error) {
        throw new Error('Error sending password reset: ' + resBody.error)
      }
      await showSuccessMessage('Password reset mail sent. Please check your email!')
    } catch (e) {
      console.error(e.message)
      showErrorMessage(e.message)
    }
  }

  componentDidMount () {
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: '' })
      }.bind(this),
      100
    )
  }

  componentWillUnmount () {
    clearTimeout(this.timeOutFunction)
    this.timeOutFunction = null
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={
              async (e) => {
                e.preventDefault()
                this.login.bind(this)
                this.login()
              }
            }>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="rose"
                >
                  <h4 className={classes.cardTitle}>Log in</h4>
                  <div className={classes.socialLine}>
                    <img src={Logo} className={classes.customButtonClass} alt="SERVERSIDE.AI"></img>
                  </div>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => {
                        this.setState({ email: event.target.value })
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomInput
                    labelText="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'password',
                      onChange: event => {
                        this.setState({ password: event.target.value })
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      )
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button
                    color="rose"
                    simple
                    size="lg"
                    block
                    type="submit"
                    onClick={async (e) => {
                      e.preventDefault()
                      this.login.bind(this)
                      this.login()
                    }}
                  >
                    Let's Go
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
        <Button
          simple
          size="sm"
          block
          onClick={this.sendPasswordReset.bind(this)}
        >
          Forgot Password?
        </Button>
      </div>
    )
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default componentWithContext(withRouter(withStyles(loginPageStyle)(LoginPage)))
