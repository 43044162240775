import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import extendedTablesStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import { withRouter } from 'react-router-dom'
import backend from '../../utils/backend'
import { showErrorMessage } from '../../components/Alerts/Alerts'

class DurationField extends React.Component { 
    timer = null

    constructor(props) {
        super(props)
        this.state = {
            value: '---'
        }
    }

    componentDidMount() {
        if(this.props.status === 'running') {
            this.timer = setInterval(async () => {
                const job = await this.loadJob()
                if(job.status !== 'running') {
                    this.setState({
                        value: this.getDuration(job.start, job.end)
                    })
                    clearInterval(this.timer)
                }
            }, 5000)
        }
    }

    componentWillUnmount() {
        if(this.timer)
            clearInterval(this.timer)
    }

    async loadJob() {
        try {
          return backend.get('jobs', this.props.id)
        } catch (e) {
          console.error(e)
          showErrorMessage(e.message)
        }
    }

    getDuration(start, end) {
        const startTime = (start ? new Date(start) : new Date()) / 1000
        const endTime = (end ? new Date(end) : new Date()) / 1000
        return new Date((endTime - startTime) * 1000).toISOString().substr(11, 8)
    }

    render() {
        if(this.props.status !== 'running') {
            return (<div>{this.getDuration(this.props.start, this.props.end)}</div>)
        } else {
            return (<div>{this.state.value}</div>)
        }
    }

}

export default withRouter(withStyles(extendedTablesStyle)(DurationField))