import React, { useState, useEffect, useContext, useRef } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Popper from '@material-ui/core/Popper'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Grow from '@material-ui/core/Grow'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import CircularProgress from '@material-ui/core/CircularProgress'
import backend from '../../utils/backend.js'
import moment from 'moment'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import { noMP } from '../../assets/jss/material-dashboard-pro-react'
import DispatchContext from '../../state/DispatchContext'
import cx from 'classnames'
import { ReactComponent as CloseIcon } from '../../assets/icons/serverside-icon-close-black.svg'
import { ReactComponent as HelpIcon } from '../../assets/icons/serverside-icon-help.svg'

const sourceAdColor = '#1482c8'
const contentColor = '#b7bdc7'
const restartedColor = '#a8a8a8'
const replacedAdColor = '#28b4a0'
const failedAdColor = '#ff2724'
const strokeColor = '#b7bdc7'
const selectedBorderColor = 'magenta'

const styles = theme => ({
  noMP,
  legendItem: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important'
  },
  manifestText: {
    fontSize: 10,
    padding: 5
  },
  legendText: {
    fontStyle: 'italic'
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  helpPaper: {
    backgroundColor: '#f2f4f7',
    fontSize: 12,
  },
  helpTexts: {
    paddingTop: 25,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 5
  },
  manifestItem: {
    fontFamily: 'Menlo,Monaco,Consolas,"Courier New",monospace',
    fontSize: 10,
    marginBottom: 0
  },
  manifestKeyWord: {
    backgroundColor: 'yellow'
  }
})

const getHeight = (element, type) => {
  switch (element) {
    case 'header':
      return type === 'hls-live' ? 64 : 60
    case 'timeline':
      return 100
    default:
      return 0
  }
}

/**
 * processing of HLS data, from storage format to svg-ready format
 * @param {*} monitoringData 
 * @param {*} timeFrame 
 * @param {*} actualWidth 
 * @param {*} actualHeight 
 */
const processHls = (monitoringData, timeFrame, actualWidth, actualHeight, middleGap = 0) => {
  if (!Array.isArray(monitoringData.keys) ||
    !Array.isArray(monitoringData.values) ||
    monitoringData.values.length !== monitoringData.keys.length) {
    return []
  }
  const maxTime = monitoringData.maxTime || (new moment()).valueOf() / 1000

  const sortedElements = monitoringData.values.map((el, i) => {
    const res = el || {}
    if (monitoringData.keys[i].indexOf('source') >= 0) {
      res.stream = 'source'
    } else {
      res.stream = 'output'
    }
    return res
  })
  const visibleElements = sortedElements.filter(el => el.loadTime + el.duration > maxTime - timeFrame).map(el => {
    const rect = { segmentData: el, style: { fill: contentColor, fillOpacity: 0.8 } }

    rect.x = (el.loadTime - (maxTime - timeFrame)) * actualWidth / timeFrame
    rect.width = el.duration * actualWidth / timeFrame

    rect.y = el.stream === 'source' ? 0 : (actualHeight / 2 + middleGap / 2)
    rect.height = actualHeight / 2 - middleGap / 2

    if (el.stream === 'source' && (el.cueOut || el.cueOutCont || el.ad)) {
      rect.style.fill = sourceAdColor
    }

    if (el.stream === 'output') {
      const correspondingSourceSegment = sortedElements.find(el0 => el0.stream === 'source' && el0.uri === el.uri)
      if (correspondingSourceSegment && (correspondingSourceSegment.cueOut || correspondingSourceSegment.cueOutCont || correspondingSourceSegment.ad)) {
        rect.style.fill = failedAdColor
      }
      if (!correspondingSourceSegment) {
        rect.style.fill = replacedAdColor
      }
    }
    return rect
  })
  return visibleElements
}
/**
 * processing of DASH data, from storage format to svg-ready format
 * @param {*} monitoringData 
 * @param {*} timeFrame 
 * @param {*} actualWidth 
 * @param {*} actualHeight 
 */
const processDash = (monitoringData, timeFrame, actualWidth, actualHeight, middleGap = 0) => {
  const marginRight = 20
  if (!Array.isArray(monitoringData.values)) {
    return []
  }
  const maxTime = monitoringData.maxTime || (new moment()).valueOf() / 1000

  const sortedElements = monitoringData.values.map((el, i) => {
    const res = el || {}
    if (monitoringData.keys[i].indexOf('source') >= 0) {
      res.stream = 'source'
    } else {
      res.stream = 'output'
    }
    return res
  })
  sortedElements.sort((x, y) => {
    return (x.stream === 'source' ? x.presentationTime - Number.MAX_SAFE_INTEGER : x.presentationTime) -
      (y.stream === 'source' ? y.presentationTime - Number.MAX_SAFE_INTEGER : y.presentationTime)
  })
  for (let i = 0; i < sortedElements.length - 1; ++i) {
    if (sortedElements[i].stream === 'output') {
      sortedElements[i].duration = sortedElements[i + 1].presentationTime - sortedElements[i].presentationTime
    }
  }
  sortedElements[sortedElements.length - 1].duration = marginRight

  const visibleElements = sortedElements.filter(el => el.presentationTime + marginRight > maxTime - timeFrame).map(el => {
    const rect = { segmentData: el, style: { fill: contentColor, fillOpacity: 0.8 } }

    rect.x = (el.presentationTime - (maxTime - timeFrame)) * actualWidth / timeFrame
    rect.width = el.duration * actualWidth / timeFrame

    rect.y = el.stream === 'source' ? 0 : (actualHeight / 2 + middleGap / 2)
    rect.height = actualHeight / 2 - middleGap / 2

    if (el.stream === 'source') {
      rect.style.fill = sourceAdColor
    }

    if (el.stream === 'output' && el.ad) {
      rect.style.fill = replacedAdColor
    }

    // we don't paint gray with DASH
    if (rect.style.fill === contentColor) {
      rect.style.fillOpacity = 0
      rect.style.strokeWidth = 1
      rect.style.stroke = strokeColor
    }

    return rect
  })
  return visibleElements
}

const processMessages = (messagesData, timeFrame, actualWidth, actualHeight, middleGap = 0) => {
  if (!messagesData || !messagesData.values || !messagesData.values.length > 0) {
    return []
  }
  const maxTime = messagesData.maxTime || (new moment()).valueOf() / 1000
  return messagesData.values.map(el => {
    if (!el) {
      return el
    }
    const res = { messageData: el }
    res.height = 1.25 * middleGap / 4
    res.width = 10
    res.x = (el.loadTime - (maxTime - timeFrame)) * actualWidth / timeFrame
    res.y = el.hasOwnProperty('sourceHealthy') ? actualHeight / 2 - middleGap / 2 : actualHeight / 2 + middleGap / 2 - res.height
    res.style = {
      fill: el.sourceHealthy || el.outputHealthy ? replacedAdColor : failedAdColor
    }
    if (el.sourceHealthy === null || el.outputHealthy === null) {
      res.style.fill = restartedColor
    }
    res.stream = el.hasOwnProperty('sourceHealthy') ? 'sourceMessage' : 'outputMessage'
    return res
  })
}
/**
 * loader in separate function - otherwise you get dependency warning on useEffect()
 * @param {*} setMonitoringData 
 * @param {*} channelId 
 */
const loadTimeline = async (setMonitoringData, channelId) => {
  try {
    setMonitoringData({ isLoading: true })
    const data = await backend.get('monitoring/timeline', channelId)
    data && setMonitoringData(data)
  } catch (ex) {
    setMonitoringData({ error: true })
    console.error(ex)
  }
}
const loadManifest = async (setManifestData, channelId, manifestKey) => {
  try {
    setManifestData({ isLoading: true })
    const data = await backend.request({ path: `monitoring/manifest/${channelId}/${manifestKey}` })

    data && setManifestData(data)
    return data
  } catch (ex) {
    console.error(channelId + '/' + manifestKey, ex)
  }
}
const loadMessages = async (setMessagesData, channelId) => {
  try {
    setMessagesData({ isLoading: true })
    const data = await backend.get('monitoring/messages', channelId)
    data && setMessagesData(data)
  } catch (ex) {
    console.error(ex)
  }
}

const bulletSize = 20

const HlsTimelineLegend = props => {
  const classes = props && props.classes ? props.classes : {}
  return (
    <GridContainer direction="column" className={classes.noMP}>
      <GridItem className={classes.noMP}>
        <GridContainer style={{ marginLeft: 0, marginBottom: 5, marginTop: 25 }} className={classes.noMP} >
          <GridItem className={classes.legendItem} style={{ height: bulletSize, width: bulletSize, backgroundColor: contentColor + '80' }}></GridItem>
          <GridItem className={props.classes.legendText}>Content segments - your standard program without ads</GridItem>
        </GridContainer>
      </GridItem>
      <GridItem className={classes.noMP}>
        <GridContainer style={{ margin: 0, marginBottom: 5 }} className={classes.noMP} >
          <GridItem className={classes.legendItem} style={{ height: bulletSize, width: bulletSize, backgroundColor: sourceAdColor + '80' }}></GridItem>
          <GridItem className={props.classes.legendText}>Ad segments (source) - marked as advertisement using STCE-35 markers in your source stream</GridItem>
        </GridContainer>
      </GridItem>
      <GridItem className={classes.noMP}>
        <GridContainer style={{ margin: 0, marginBottom: 5 }} className={classes.noMP} >
          <GridItem className={classes.legendItem} style={{ height: bulletSize, width: bulletSize, backgroundColor: replacedAdColor + '80' }}></GridItem>
          <GridItem className={props.classes.legendText}>Ad segments (output) - successfully replaced by serverside.ai system</GridItem>
        </GridContainer>
      </GridItem>
      <GridItem className={classes.noMP}>
        <GridContainer style={{ margin: 0, marginBottom: 5 }} className={classes.noMP} >
          <GridItem className={classes.legendItem} style={{ height: bulletSize, width: bulletSize, backgroundColor: failedAdColor + '80' }}></GridItem>
          <GridItem className={props.classes.legendText}>Ad segments (output) - not replaced by serverside.ai for some reason. Please check your channel and ad server settings if you see this.</GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}

const DashTimelineLegend = props => {
  const classes = props && props.classes ? props.classes : {}
  return (
    <GridContainer direction="column" className={classes.noMP}>
      <GridItem className={classes.noMP} >
        <GridContainer style={{ margin: 0, marginBottom: 5 }} className={classes.noMP} >
          <GridItem className={classes.legendItem} style={{ height: bulletSize, width: bulletSize, backgroundColor: sourceAdColor + '80' }}></GridItem>
          <GridItem className={props.classes.legendText}>Ad event (source) - marked as advertisement using STCE-35 markers in your source stream</GridItem>
        </GridContainer>
      </GridItem>
      <GridItem className={classes.noMP} >
        <GridContainer style={{ margin: 0, marginBottom: 5 }} className={classes.noMP} >
          <GridItem className={classes.legendItem} style={{ height: bulletSize, width: bulletSize, backgroundColor: replacedAdColor + '80' }}></GridItem>
          <GridItem className={props.classes.legendText}>Ad period (output) - successfully replaced by serverside.ai system. If you do not see replaced ad below your source ad in timeline - check your channel and ad server settings</GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>)
}

const getRowHorShift = (text) => {
  let count = 0
  let index = 0
  while (text.charAt(index) === '\t' || text.charAt(index) === ' ') {
    ++count
    if (text.charAt(index) === ' ') {
      ++count
    }
    ++index
  }
  return count
}
const ManifestView = ({ manifest, classes = {}, keyword, keyId }) => {
  if (!manifest) {
    return null
  }
  const lines = manifest.split('\n').map(text => ({ marginLeft: getRowHorShift(text), text }))

  return <div style={{ position: 'relative' }}>{lines.map((line, i) => {
    if (line.text.indexOf(keyword) >= 0) {
      return <p id={keyId + '_keyword'} className={cx(classes.manifestItem, classes.manifestKeyWord)} key={i} style={{ marginLeft: line.marginLeft }}>{line.text}</p>
    }
    return <p className={classes.manifestItem} key={i} style={{ marginLeft: line.marginLeft }}>{line.text}</p>
  })}</div>
}

const DetailsView = ({
  classes,
  height,
  width,
  sourceManifest,
  outputManifest,
  rectData,
  selection,
  channelType,
  message
}) => {
  if (!sourceManifest) {
    sourceManifest = {}
  }
  if (!outputManifest) {
    outputManifest = {}
  }
  const headerHeight = 100

  let selectedSourceEntryDescription, selectedOutputEntryDescription

  const selectedSourceSegment = rectData.find(el => el.segmentData && el.segmentData.id === selection.source)
  const selectedOutputSegment = rectData.find(el => el.segmentData && el.segmentData.id === selection.output)
  // console.log(selectedSourceSegment && selectedSourceSegment.segmentData, selectedOutputSegment && selectedOutputSegment.segmentData)
  if (selectedSourceSegment && selectedSourceSegment.segmentData) {
    if (channelType === 'hls-live') {
      selectedSourceEntryDescription = selectedSourceSegment.segmentData.uri
    } else {
      selectedSourceEntryDescription = `id="${selectedSourceSegment.segmentData.eventId}"`
    }
  }
  if (selectedOutputSegment && selectedOutputSegment.segmentData) {
    if (channelType === 'hls-live') {
      selectedOutputEntryDescription = selectedOutputSegment.segmentData.uri
    } else {
      selectedOutputEntryDescription = `id="${selectedOutputSegment.segmentData.periodId}" start="${selectedOutputSegment.segmentData.start}"`
    }
  }

  let sourceMessage, outputMessage
  if (message && message.stream === 'sourceMessage') {
    sourceMessage = message
    sourceManifest.manifest = message.messageData && message.messageData.manifest
  }
  if (message && message.stream === 'outputMessage') {
    outputMessage = message
    outputManifest.manifest = message.messageData && message.messageData.manifest
  }
  window.sourceManifest = sourceManifest
  window.outputManifest = outputManifest
  return (<div style={{ height, width }}>
    <GridContainer id="id1" direction="column" className={classes.noMP} style={{ width }}>
      <GridItem className={classes.noMP} style={{ width }}>
        <GridContainer id="id2" direction="row" className={classes.noMP} style={{ width }}>
          <GridItem className={classes.noMP} style={{ width: width / 2, overflow: 'auto', marginTop: '10px !important' }}>
            {!sourceMessage && selectedSourceEntryDescription && sourceManifest && sourceManifest.loadTime && <>
              <p><b>{channelType === 'hls-live' ? 'segment' : 'event'}</b> ({(new moment(sourceManifest.loadTime * 1000)).format('DD/MM HH:mm:ss')}):</p>
              <p>{selectedSourceEntryDescription} <button className={classes.manifestKeyWord} style={{ cursor: 'pointer' }} onClick={() => {
                const el = document.getElementById('source_manifest_keyword')
                if (el) {
                  const topPos = el.offsetTop
                  document.getElementById('source_manifest').scrollTop = topPos - 10
                }
              }}>show</button></p>
            </>}
            {sourceMessage && <>
              <p><b>message</b> ({(new moment(sourceMessage.messageData.loadTime * 1000)).format('DD/MM HH:mm:ss')}):</p>
              <p>{sourceMessage.messageData.sourceHealthy ? 'OK' : sourceMessage.messageData.sourceErrMessage}</p>
            </>}
          </GridItem>
          <GridItem className={classes.noMP} style={{ width: width / 2, overflow: 'auto', marginTop: '10px !important' }}>
            {!outputMessage && selectedOutputEntryDescription && outputManifest && outputManifest.loadTime && <>
              <p><b>{channelType === 'hls-live' ? 'segment' : 'period'}</b> ({(new moment(outputManifest.loadTime * 1000)).format('DD/MM HH:mm:ss')}):</p>
              <p>{selectedOutputEntryDescription} <button className={classes.manifestKeyWord} style={{ cursor: 'pointer' }} onClick={() => {
                const el = document.getElementById('output_manifest_keyword')
                if (el) {
                  const topPos = el.offsetTop
                  document.getElementById('output_manifest').scrollTop = topPos - 10
                }
              }}>show</button></p>
            </>}
            {outputMessage && <>
              <p><b>message</b> ({(new moment(outputMessage.messageData.loadTime * 1000)).format('DD/MM HH:mm:ss')}):</p>
              <p>{outputMessage.messageData.outputHealthy ? 'OK' : outputMessage.messageData.outputErrMessage}</p>
            </>}
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem className={classes.noMP} style={{ height: height - headerHeight, width }}>
        <GridContainer direction="row" className={classes.noMP} style={{ height: height - headerHeight, width }}>
          <GridItem id="source_manifest" className={classes.noMP} style={{ height: height - headerHeight, width: width / 2, overflow: 'auto', border: '1px solid #f2f4f7' }}>
            {sourceManifest && sourceManifest.isLoading ? <CircularProgress /> :
              <ManifestView classes={classes} manifest={sourceManifest.manifest} keyId="source_manifest" keyword={sourceManifest.keyword || selectedSourceEntryDescription} />}
          </GridItem>
          <GridItem id="output_manifest" className={classes.noMP} style={{ height: height - headerHeight, width: width / 2, overflow: 'auto', border: '1px solid #f2f4f7' }}>
            {outputManifest && outputManifest.isLoading ? <CircularProgress /> :
              <ManifestView classes={classes} manifest={outputManifest.manifest} keyId="output_manifest" keyword={outputManifest.keyword || selectedOutputEntryDescription} />}
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  </div>)
}

const getLastRect = rectData => {
  if (!rectData || !rectData.length > 0) {
    return null
  }
  return rectData.reduce((acc, cur) => {
    if (cur.x > acc.x) {
      return cur
    }
    return acc
  }, rectData[0])
}

const ChannelMonitorTimeline = (props = {}) => {
  const { classes, channelId, channelType } = props
  const [actualWidth, setActualWidth] = useState(0)
  const [timelineHeight/*, setTimelineHeight*/] = useState(100)
  const [middleGap, /*setMiddleGap*/] = useState(30)
  const [monitoringData, __setMonitoringData] = useState()
  const setMonitoringData = md => {
    if (md && md.maxTime) {
      md.maxTime += 10 // cheat to make the last block visible
    }
    __setMonitoringData(md)
  }
  const [messagesData, __setMessagesData] = useState()
  const setMessagesData = md => {
    if (md && md.maxTime) {
      md.maxTime += 10 // cheat to make the last block visible
    }
    __setMessagesData(md)
  }
  const [timeFrame, /*setTimeFrame*/] = useState(1200)
  const [sourceManifest, setSourceManifest] = useState()
  const [outputManifest, setOutputManifest] = useState()
  const [selection, setSelection] = useState({})
  const [playlistIndex, setPlaylistIndex] = useState(0)
  const [helpOpen, setHelpOpen] = useState(false)
  const anchorRef = React.useRef(null)
  const context = useContext(DispatchContext)

  const label1 = useRef()
  const label2 = useRef()
  const label3 = useRef()

  let timeLabelHeight = label1.current && label1.current.getBoundingClientRect().height
  if (isNaN(timeLabelHeight)) {
    timeLabelHeight = 0
  }
  let middleTimeLabelWidth = label2.current && label2.current.getBoundingClientRect().width
  if (isNaN(middleTimeLabelWidth)) {
    middleTimeLabelWidth = 0
  }
  let rightTimeLabelWidth = label3.current && label3.current.getBoundingClientRect().width
  if (isNaN(rightTimeLabelWidth)) {
    rightTimeLabelWidth = 0
  }

  if (typeof props.getRefreshHandler === 'function') {
    props.getRefreshHandler(() => {
      setMonitoringData(null)
      setSourceManifest(null)
      setOutputManifest(null)
      setMessagesData(null)
      loadTimeline(setMonitoringData, channelId)
      loadMessages(setMessagesData, channelId)
    })
  }

  useEffect(() => {

    context.actions.ui.addResizer(`timeline_${channelId}`, () => {
      const el = document.getElementById("timeline_inner")
      if (el) {
        setActualWidth(el.getBoundingClientRect().width)
      }
    })

    return () => context.actions.ui.removeResizer(`timeline_${channelId}`)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    loadTimeline(setMonitoringData, channelId)
    loadMessages(setMessagesData, channelId)
  }, [channelId])

  let rectData = [], TimelineLegend = () => null

  if (monitoringData && monitoringData.keys && monitoringData.keys.length) {
    const filteredData = {
      keys: [],
      values: []
    }

    for (let i = 0; i < monitoringData.keys.length; ++i) {
      const key = monitoringData.keys[i]
      if (key && (key.indexOf(`source::${playlistIndex}::`) >= 0 || key.indexOf(`output::${playlistIndex}::`) >= 0)) {
        filteredData.keys.push(key)
        filteredData.values.push(monitoringData.values[i])
        filteredData.maxTime = monitoringData.maxTime
      }
    }
    if (filteredData.keys.length && channelType === 'hls-live') {
      rectData = processHls(filteredData, timeFrame, actualWidth, timelineHeight, middleGap)
      TimelineLegend = HlsTimelineLegend
    }
    if (filteredData.keys.length && channelType === 'dash-live') {
      rectData = processDash(filteredData, timeFrame, actualWidth, timelineHeight, middleGap)
      TimelineLegend = DashTimelineLegend
    }
  }

  const rectData2 = processMessages(messagesData, timeFrame, actualWidth, timelineHeight, middleGap)
  window.messagesData = messagesData
  window.rectData2 = rectData2

  let sourceManifestKey, outputManifestKey
  let sourceElId, outputElId
  if (rectData && rectData.length) {
    if (!selection.source) {
      const lastSourceRect = getLastRect(rectData.filter(el => el.segmentData.stream === 'source'))
      if (lastSourceRect && lastSourceRect.segmentData) {
        sourceManifestKey = lastSourceRect.segmentData.manifestKey
        sourceElId = lastSourceRect.segmentData.id
        sourceManifest && (sourceManifest.keyword = lastSourceRect.segmentData.origUri)
      }
    } else {
      const el = rectData.find(el => el && el.segmentData && el.segmentData.id === selection.source)
      sourceManifestKey = el && el.segmentData.manifestKey
      el && sourceManifest && (sourceManifest.keyword = el.segmentData.origUri)
    }

    if (!selection.output) {
      const lastOutputRect = getLastRect(rectData.filter(el => el.segmentData.stream === 'output'))
      if (lastOutputRect && lastOutputRect.segmentData) {
        outputManifestKey = lastOutputRect.segmentData.manifestKey
        outputElId = lastOutputRect.segmentData.id
        outputManifest && (outputManifest.keyword = lastOutputRect.segmentData.uri)
      }
    } else {
      const el = rectData.find(el => el.segmentData.id === selection.output)
      outputManifestKey = el && el.segmentData.manifestKey
      el && outputManifest && (outputManifest.keyword = el.segmentData.uri)
    }
  }
  useEffect(() => {
    if (sourceManifestKey) {
      loadManifest(setSourceManifest, channelId, sourceManifestKey)
    }
  }, [channelId, sourceManifestKey])
  useEffect(() => {
    if (outputManifestKey) {
      loadManifest(setOutputManifest, channelId, outputManifestKey)
    }
  }, [channelId, outputManifestKey])
  useEffect(() => {
    if (sourceElId) {
      setSelection(selection => ({ ...selection, source: sourceElId }))
    }
  }, [sourceElId])
  useEffect(() => {
    if (outputElId) {
      setSelection(selection => ({ ...selection, output: outputElId }))
    }
  }, [outputElId])

  const maxTime = (monitoringData && monitoringData.maxTime) ||
    (messagesData && messagesData.maxTime) ||
    (new moment()).valueOf() / 1000
  const m3 = new moment((maxTime - 10) * 1000) // -10 because of the cheat
  const m1 = m3.clone()
  m1.add(-timeFrame, 'seconds')
  const m2 = m3.clone()
  m2.add(-timeFrame / 2, 'seconds')

  const timelineClick = rectData => e => {
    let { x, y } = document.getElementById('ChannelMonitorTimelineSvg').getBoundingClientRect()
    x = e.clientX - x
    y = e.clientY - y

    let stream
    if (y > timelineHeight / 2 + middleGap / 2) {
      stream = 'output'
    }
    if (y < timelineHeight / 2 - middleGap / 2) {
      stream = 'source'
    }

    if (y > timelineHeight / 2 - middleGap / 2 && y < timelineHeight / 2) {
      stream = 'sourceMessage'
    }
    if (y < timelineHeight / 2 + middleGap / 2 && y > timelineHeight / 2) {
      stream = 'outputMessage'
    }
    if (stream === 'output' || stream === 'source') { // segment / block
      const filtered = rectData.filter(el => el.x < x && el.segmentData.stream === stream)

      if (!filtered || !filtered.length > 0) {
        return
      }

      const el = filtered.reduce((agg, cur) => {
        if (cur.x > agg.x) return cur
        return agg
      })

      setSelection({ ...selection, [stream]: el.segmentData.id, message: false, el })
    }
    if (stream === 'outputMessage' || stream === 'sourceMessage') {
      const filtered = rectData2.filter(el => el.stream === stream)
      if (!filtered || !filtered.length > 0) {
        return
      }
      const el = filtered.reduce((agg, cur) => {
        if (Math.abs(cur.x - x) < Math.abs(agg.x - x)) return cur
        return agg
      })

      setSelection({ ...selection, [stream]: el.messageData.messageId, message: true, el })
    }
  }
  let manifestBlockHeight
  if (channelType === 'hls-live') {
    manifestBlockHeight = context.state.ui.window.innerHeight - 678 + 150
  }
  if (channelType === 'dash-live') {
    manifestBlockHeight = context.state.ui.window.innerHeight - 637 + 150
  }
  rectData && rectData.sort((a, b) => {
    let aScore, bScore
    if ((selection.source && a.segmentData.id === selection.source) ||
      (selection.output && a.segmentData.id === selection.output)) {
      aScore = a.x
    } else {
      aScore = 1000000 + a.x
    }
    if ((selection.source && b.segmentData.id === selection.source) ||
      (selection.output && b.segmentData.id === selection.output)) {
      bScore = b.x
    } else {
      bScore = 1000000 + b.x
    }
    return bScore - aScore
  })
  rectData2 && rectData2.sort((a, b) => {
    let aScore, bScore
    if ((selection.sourceMessage && a.messageData.messageId === selection.sourceMessage) ||
      (selection.outputMessage && a.messageData.messageId === selection.outputMessage)) {
      aScore = a.x
    } else {
      aScore = 1000000 + a.x
    }
    if ((selection.sourceMessage && b.messageData.messageId === selection.sourceMessage) ||
      (selection.outputMessage && b.messageData.messageId === selection.outputMessage)) {
      bScore = b.x
    } else {
      bScore = 1000000 + b.x
    }
    return bScore - aScore
  })
  const playlistSelectorWidth = 200

  const iconMarginTop = 3
  const iconWidth = 25
  const iconBlockWidth = 50
  const handleHelpClick = event => {
    const { currentTarget } = event
    anchorRef.current = currentTarget
    setHelpOpen(open => !open)
  }

  return (
    <div width="100%" ref={el => {
      if (!el) {
        return
      }
      setActualWidth(el.getBoundingClientRect().width)
    }}
      id="timeline_inner">
      <GridContainer direction="row" className={classes.noMP} style={{ width: actualWidth, height: getHeight('header', channelType) }}>
        <GridItem className={classes.noMP} style={{ width: actualWidth - playlistSelectorWidth - iconBlockWidth }}>
          <GridContainer justify="flex-start" direction="row" className={classes.noMP}>
            <GridItem className={classes.noMP} style={{ width: actualWidth - iconBlockWidth - playlistSelectorWidth - 50 }}>
              <h6 style={{ marginBottom: 25, textOverflow: 'elipsis' }}><b>{props.channelName}</b> ({props.channelId}), <small>(type {props.channelType})</small></h6>
            </GridItem>
            <GridItem className={classes.noMP} style={{ width: iconBlockWidth }}>
              <IconButton
                className="ssai-icon-button"
                buttonRef={anchorRef}
                style={{ float: 'left', padding: 0, marginLeft: 10.5, marginTop: iconMarginTop }}
                onClick={handleHelpClick}>
                <HelpIcon style={{ width: iconWidth }} />
              </IconButton>
              <Popper open={helpOpen} anchorEl={anchorRef.current} placement="bottom-start" transition
                popperOptions={{
                  modifiers: {
                    offset: { offset: '25px, 0px' }
                  }
                }}
                style={{ zIndex: 10000 }}>
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    id="menu-list-grow">
                    <Paper className={classes.helpPaper} square={true}>
                      <ClickAwayListener onClickAway={handleHelpClick}>
                        <div>
                          <IconButton className="ssai-icon-button" style={{ float: 'right', width: 25, padding: 8 }}
                            onClick={handleHelpClick}>
                            <CloseIcon style={{ width: 25 }} />
                          </IconButton>
                          <div className={classes.helpTexts}>
                            {TimelineLegend({
                              classes: props && props.classes
                            })}
                          </div>
                        </div>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </GridItem>
          </GridContainer>
        </GridItem>
        {channelType === 'hls-live' && monitoringData && monitoringData.playlists && <GridItem className={classes.noMP} style={{ width: playlistSelectorWidth }}>
          <FormControl className={classes.formControl}>
            <Select value={playlistIndex} onChange={event => {
              setPlaylistIndex(event.target.value)
              setSelection({ source: null, output: null })
            }}>
              {monitoringData.playlists.map((p, i) => <MenuItem key={i} value={p.index}>{p.uri}</MenuItem>)}
            </Select>
          </FormControl>
        </GridItem>}
      </GridContainer>
      <div style={{ height: getHeight('timeline', channelType), position: 'relative' }}>
        {(monitoringData && (monitoringData.isLoading || monitoringData.error || (monitoringData.values && monitoringData.values.length === 0))) ?
          (<div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
          }}>{monitoringData.error || (monitoringData.values && monitoringData.values.length === 0) ? 'Cannot load monitoring data' : <CircularProgress />}</div>)
          : (<>
            <div id="ChannelMonitorTimelineSvg" onClick={timelineClick(rectData)} style={{ height: timelineHeight }}>
              {rectData && rectData.length > 0 && <svg height={timelineHeight} width={actualWidth}>

                {rectData.map((rect, id) => {
                  const style = { ...rect.style }
                  if (selection.source && rect.segmentData.id === selection.source) {
                    style.stroke = selectedBorderColor
                    style.strokeWidth = 3
                  }
                  if (selection.output && rect.segmentData.id === selection.output) {
                    style.stroke = selectedBorderColor
                    style.strokeWidth = 3
                  }
                  if (!isNaN(rect.x) && !isNaN(rect.y) && !isNaN(rect.height) && !isNaN(rect.width)) {
                    return <rect key={id} x={rect.x} y={rect.y} height={rect.height} width={rect.width} style={style}></rect>
                  }
                  return null
                })}
                {rectData2 && rectData2.map((rect, id) => {
                  const style = { ...rect.style }
                  if (selection.sourceMessage && rect.messageData.messageId === selection.sourceMessage) {
                    style.stroke = selectedBorderColor
                    style.strokeWidth = 3
                  }
                  if (selection.outputMessage && rect.messageData.messageId === selection.outputMessage) {
                    style.stroke = selectedBorderColor
                    style.strokeWidth = 3
                  }
                  if (!isNaN(rect.x) && !isNaN(rect.y) && !isNaN(rect.height) && !isNaN(rect.width)) {
                    return <React.Fragment key={id}>
                      <rect x={rect.x} y={rect.y} height={rect.height} width={rect.width} style={style}></rect>
                    </React.Fragment>
                  }
                  return null
                })}
                <text fontFamily="monospace" fontWeight="bold" x={0} y={timelineHeight / 4 - middleGap / 4 + 5} style={{ fill: "black", fillOpacity: 0.35 }} transform="scale(2,1)">
                  source stream &gt;&gt; source stream &gt;&gt; source stream &gt;&gt; source stream &gt;&gt; source stream &gt;&gt; source stream &gt;&gt;
                  </text>
                <text fontFamily="monospace" fontWeight="bold" x={0} y={timelineHeight / 2 + middleGap + timelineHeight / 4 - middleGap / 4 - 10} style={{ fill: "black", fillOpacity: 0.35 }} transform="scale(2,1)">
                  &gt; output stream &gt;&gt; output stream &gt;&gt; output stream &gt;&gt; output stream &gt;&gt; output stream &gt;&gt; output stream &gt;&gt;
                  </text>
                <rect
                  x="0"
                  y="0"
                  height={timelineHeight / 2 - middleGap / 2}
                  width={actualWidth}
                  style={{ fillOpacity: 0, strokeWidth: 1, stroke: strokeColor }}></rect>


                <rect
                  x="0"
                  y={timelineHeight / 2 + middleGap / 2}
                  height={timelineHeight / 2 - middleGap / 2}
                  width={actualWidth}
                  style={{ fillOpacity: 0, strokeWidth: 1, stroke: strokeColor }}></rect>

                <text
                  fill={strokeColor}
                  ref={label1}
                  x="0"
                  y={timelineHeight / 2 + timeLabelHeight / 4}>
                  {m1.format('HH:mm:ss')}
                </text>
                <text
                  fill={strokeColor}
                  ref={label2}
                  x={actualWidth / 2 - middleTimeLabelWidth / 2}
                  y={timelineHeight / 2 + timeLabelHeight / 4}>
                  {m2.format('HH:mm:ss')}
                </text>
                <text
                  fill={strokeColor}
                  ref={label3}
                  x={actualWidth - rightTimeLabelWidth}
                  y={timelineHeight / 2 + timeLabelHeight / 4}>
                  {m3.format('HH:mm:ss')}
                </text>
              </svg>}
            </div>
          </>)}
      </div>
      {DetailsView({
        height: manifestBlockHeight,
        width: actualWidth,
        sourceManifest,
        outputManifest,
        classes: props && props.classes,
        rectData,
        selection,
        channelType,
        message: selection.message ? selection.el : null
      })}
    </div>
  )
}
export default withStyles(styles)(ChannelMonitorTimeline)