import shaka from 'shaka-player'

const initPlayer = (component, channel) => {
  shaka.polyfill.installAll()
  if (shaka.Player.isBrowserSupported()) {
    component.playerLeft = new shaka.Player(component.playerLeftRef)
    component.playerRight = new shaka.Player(component.playerRightRef)
    component.playerLeft.addEventListener('error', component.onErrorEvent('Left'))
    component.playerRight.addEventListener('error', component.onErrorEvent('Right'))

    component.playerLeft.load(channel.sourceManifestUrl)
      .then(() => {
        component.isLoadedLeft = true
        console.log('Left video has now been loaded!', channel.sourceManifestUrl)
        component._isMounted && component.setState({
          playerLeftError: null
        })
      })
      .catch(e => component._isMounted && component.setState({ playerLeftError: e }))

    component.playerRight.load(channel.finalOutputUri)
      .then(() => {
        component.isLoadedRight = true
        console.log('Right video has now been loaded!', channel.finalOutputUri)
        component._isMounted && component.setState({
          playerRightError: null
        })
      })
      .catch(e => { component._isMounted && component.setState({ playerRightError: e }) })
  } else {
    console.error('Browser not supported!')
  }
}

const destroyPlayer = component => {
  if (component.playerLeft) {
    component.playerLeft.unload()
    component.playerLeft.destroy()
  }
  if (component.playerRight) {
    component.playerRight.unload()
    component.playerRight.destroy()
  }
}

export default {
  initPlayer,
  destroyPlayer
}