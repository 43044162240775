/**
 * There exist "official" list of errors, but for some reason they are a bit different IRL.
 * https://auth0.com/docs/api/authentication?http#standard-error-responses
 */
const Auth0ErrorResponses = [
  {
    code: 401,
    error: 'invalid_client',
    messageForUser: 'Email and Password combination does not exist. Please also check that you have verified your email.',
    offerVerification: true
  },
  {
    code: 401,
    error: 'unauthorized',
    error_description: 'Please verify your email before logging in.',
    messageForUser: 'Email and Password combination does not exist. Please also check that you have verified your email.',
    offerVerification: true
  },
  {
    code: 403,
    error: 'invalid_grant',
    error_description: 'user is blocked',
    messageForUser: 'Your account was blocked. Please contact your system administrator.'
  },
  {
    code: 403,
    error: 'invalid_grant',
    error_description: 'Wrong email or password.',
    messageForUser: 'Email and Password combination does not exist. Please also check that you have verified your email.',
    offerVerification: true,
  },
  {
    code: 403,
    error: 'unauthorized_client',
    messageForUser: 'You are not authorized to access this resource. Please contact your system administrator.'
  },
  {
    code: 403,
    error: 'access_denied',
    messageForUser: 'Access denied. Please contact your system administrator.'
  },
  {
    code: 403,
    messageForUser: 'Access denied. Please contact your system administrator.'
  },
  {
    code: 429,
    error: 'too_many_requests',
    messageForUser: 'Your account was blocked because of too many requests. Please contact your system administrator.'
  },
  {
    code: 429,
    error: 'too_many_attempts',
    messageForUser: 'Your account was blocked because of too many requests. Please contact your system administrator.'
  },
  /**
   * fallback entry:
   */
  {
    messageForUser: 'Cannot login.',
    showDetails: true
  }
]

const processAutn0Response = responseJSON => {
  if (!responseJSON || !responseJSON.data) {
    return
  }
  const entry = Auth0ErrorResponses.find(res => {    
    if (res.code && res.code !== responseJSON.code) {
      return false
    }
    if (res.error && res.error !== responseJSON.data.error) {
      return false
    }
    if (res.error_description && res.error_description !== responseJSON.data.error_description) {
      return false
    }
    return true
  })
  if (entry) {
    entry.error_description = responseJSON.data.error_description
    return entry
  }
}
module.exports = {
  processAutn0Response
}