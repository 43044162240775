import Hls from 'hls.js'

const initPlayer = (component, channel) => {
  if (Hls.isSupported()) {
    component.playerLeft = new Hls({ maxBufferHole: 1 })
    component.playerLeft.loadSource(channel.sourceManifestUrl)
    component.playerLeft.attachMedia(component.playerLeftRef)
    component.playerLeft.on(Hls.Events.MANIFEST_PARSED, () => {
      // component.isLoadedLeft = false
      component.playerLeftRef.play()
    })
    component.playerLeft.on(Hls.Events.ERROR, component.onErrorEvent('Left'))

    component.playerRight = new Hls({ maxBufferHole: 1 })
    component.playerRight.loadSource(channel.finalOutputUri)
    component.playerRight.attachMedia(component.playerRightRef)
    component.playerRight.on(Hls.Events.MANIFEST_PARSED, () => {
      // component.isLoadedRight = false
      component.playerRightRef.play()
    })
    component.playerRight.on(Hls.Events.ERROR, component.onErrorEvent('Right'))

  } else if (component.playerLeftRef.canPlayType('application/vnd.apple.mpegurl')) {
    component.playerLeftRef.src = channel.sourceManifestUrl
    component.playerLeftRef.addEventListener('loadedmetadata', () => { 
      component.isLoadedLeft = true
      component.playerLeftRef.play()
      component._isMounted && component.setState({
        playerLeftError: null
      })
    })
    component.playerRightRef.src = channel.finalOutputUri
    component.playerRightRef.addEventListener('loadedmetadata', () => {
      component.isLoadedRight = true
      component.playerLeftRef.play()
      component._isMounted && component.setState({
        playerRightError: null
      })
    })
  }
}

const destroyPlayer = component => {
  if (component.playerLeft) {
    component.playerLeft.destroy()
  }
  if (component.playerRight) {
    component.playerRight.destroy()
  }
}

export default {
  initPlayer,
  destroyPlayer
}