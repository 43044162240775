import React, { useContext, useEffect, useState, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import DispatchContext from '../../state/DispatchContext'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Popper from '@material-ui/core/Popper'
import IconButton from '@material-ui/core/IconButton'
import cx from 'classnames'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import { ReactComponent as ChevronIcon } from '../../assets/icons/serverside-icon-chevron-red.svg'
import { ReactComponent as CheckBlackIcon } from '../../assets/icons/serverside-icon-check-black.svg'
import { ReactComponent as CheckboxBlackIcon } from '../../assets/icons/serverside-icon-checkbox-black.svg'
import { ReactComponent as CloseBlackIcon } from '../../assets/icons/serverside-icon-close-black.svg'

import { styles } from './styles/channelsSelectorStyles'

import { refreshAllReports } from '../../state/helpers'
import { TextField } from '@material-ui/core'

const sideMargin = 15

const selectedChannels = context => {
  if (context.state.users.current.app_metadata && context.state.users.current.app_metadata.selectedChannels) {
    return context.state.users.current.app_metadata.selectedChannels
  }
  return []
}
const isSelected = (channel, context) => {
  return selectedChannels(context).indexOf(channel.id) >= 0
}
const selectChannel = (channel, context) => {
  if (!isSelected(channel, context) && selectedChannels(context).length < 5) {
    context.actions.users.selectChannels([channel.id])
  } else {
    context.actions.users.unselectChannels([channel.id])
  }
}
const clearSelection = context => {
  context.actions.users.clearSelection()
}
const channelSelectorWelcomeMessage = context => {
  if (selectedChannels(context).length === 0) {
    return 'Select channels here...'
  }
  return selectedChannels(context).length + (selectedChannels(context).length > 1 ? ' channels selected': ' channel selected')
}
const applyChanges = async (context, useDashboard) => {
  try {
    await context.actions.users.updateAppMetadata(context.state.users.current.app_metadata).then(() => refreshAllReports(context, useDashboard))
  } catch (ex) {
    return false
  }
  return true
}
const errorMessage = context => {
  if (context.state.users.error) {
    return context.state.users.error.message
  }
}

const escapeRegExp = string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const ChannelsSelector = ({ classes, useDashboard }) => {
  const [popupWidth, setPopupWidth] = useState(634)
  const [open, setOpen] = useState(false)
  const [searchValue, setSearch] = useState('')

  const context = useContext(DispatchContext)
  const contextRef = useRef(context)

  useEffect(() => {
    let mounted = true
    contextRef.current.actions.channels.getAllItems()

    const debouncedHandleResize = contextRef.current.state.ui.getDebounce()(() => {
      mounted &&
        document.getElementById('channelSelectorWidget') &&
        setPopupWidth(Math.min(634, Math.max(346 + 18, window.innerWidth - document.getElementById('channelSelectorWidget').getBoundingClientRect().x - 62)))
    }, 1000)

    window.addEventListener('resize', debouncedHandleResize)
    debouncedHandleResize()
    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
      mounted = false
    }

  }, [])

  const anchorRef = React.useRef(null)
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const chevronClasses =
    cx(classes.chevronIcon, {
      [classes.chevronIconOpened]: open
    })

  const buttonsWidth = 255

  const searchTerms = searchValue.split(' ').filter(key => key.length !== 0).map(key => key.toLowerCase())

  return <div>
    <Button
      id="channelSelectorWidget"
      buttonRef={anchorRef}
      aria-owns={open ? 'menu-list-grow' : undefined}
      aria-haspopup="true"
      onClick={handleToggle}
      className={classes.tenantsControls}
      style={{ height: 45, fontSize: 18 }}
    >
      <Paper square={true} elevation={0} className={classes.tenantsControls}>
        <GridContainer spacing={0} justify="flex-end">
          <GridItem>{channelSelectorWelcomeMessage(context)}</GridItem>
          <GridItem><ChevronIcon className={chevronClasses} /></GridItem>
        </GridContainer>
      </Paper>
    </Button>
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      transition
      placement="bottom-start"
      className={classes.tenantsControlPopover}>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          id="menu-list-grow"
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper square={true} className={classes.reportControlPopoverPaper} style={{ width: popupWidth }}>
            <ClickAwayListener onClickAway={handleClose}>
              <div>
                <GridContainer spacing={0} justify="flex-start" className={classes.popoverGridTopLine} style={{ width: popupWidth - 30 }}>                  
                  <GridItem className={classes.popoverGridTopLineItemWithIcon}>
                    <IconButton className={classes.iconButton} onClick={() => clearSelection(context)}>
                      <CloseBlackIcon className={chevronClasses} />
                    </IconButton>
                  </GridItem>
                  <GridItem className={classes.popoverGridTopLineItemWithText}>Cancel selection</GridItem>
                </GridContainer>
                <div className={classes.reportControlPopoverSearch}>
                  <TextField
                    label="Search Name or ID"
                    type="search"
                    margin="dense"
                    fullWidth
                    onChange={(e) => {
                      setSearch(e.target.value)
                    }}
                  />
                </div>
                <Paper className={classes.channelsList} square={true} elevation={0}>
                  {context.state.channels.list.length > 0 &&
                    <GridContainer
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start"
                      className={classes.channelsGridContainer}
                    >
                      {context.state.channels.list.filter((channel) => {
                        if(searchTerms.length === 0)
                          return true

                        if(searchValue === channel.id)
                          return true

                        for(let key = 0; key < searchTerms.length; key++) {
                          const regexString = escapeRegExp(searchTerms[key])
                          const hasMatch = channel.name.toLowerCase().match(new RegExp(regexString, 'g'))
                          if(!hasMatch) return false
                        }

                        return true
                      }).map((channel, key) => <GridItem key={key} className={classes.gridItemVertical}>
                        <GridContainer spacing={0} justify="flex-start" className={classes.popoverGridChannel} onClick={() => selectChannel(channel, context)}>
                          <GridItem className={classes.popoverGridTopLineItemWithIcon}>
                            <IconButton className={classes.iconButton}>
                              <CheckboxBlackIcon className={cx(chevronClasses, classes.listIcons)} />
                              {isSelected(channel, context) &&
                                <CheckBlackIcon className={cx(classes.chevronIcon, classes.listIcons, classes.checkIcon)} />}
                            </IconButton>
                          </GridItem>
                          <GridItem className={classes.popoverGridTopLineItemWithText}>{channel.name}</GridItem>
                        </GridContainer>
                      </GridItem>
                      )}
                    </GridContainer>}
                </Paper>
                <Paper className={classes.footer} square={true} elevation={0} style={{ width: popupWidth - sideMargin * 2 }}>
                  <GridContainer className={classes.noMP} spacing={0} justify="space-between" style={{ width: popupWidth - sideMargin * 2 }}>
                    <GridItem className={cx(classes.noMP, classes.cYbnd)} style={{ width: popupWidth - buttonsWidth - sideMargin * 2 }}>
                      <p className={cx(classes.error, classes.cY)}>{errorMessage(context)}</p>
                    </GridItem>
                    <GridItem className={classes.noMP} style={{ width: buttonsWidth }}>
                      <GridContainer className={classes.noMP} spacing={0} justify="flex-end"
                        style={{ width: buttonsWidth }}>
                        <GridItem className={classes.noMP}>
                          <Button className={classes.btnCancel} onClick={(event) => {
                            context.actions.users.getAppMetadata()
                            handleClose(event)
                          }}>Cancel</Button>
                        </GridItem>
                        <GridItem className={classes.btnGridItem}>
                          <Button className={classes.btnApply} onClick={async event => {
                            event.persist()
                            const result = await applyChanges(context, useDashboard)
                            if (result) {
                              handleClose(event)
                            }
                          }}>Apply</Button>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </Paper>
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </div>
}

export default withStyles(styles)(ChannelsSelector)