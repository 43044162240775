import React, { Component } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'

import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'

import CircularProgress from '@material-ui/core/CircularProgress'

import { green, deepOrange, yellow } from '@material-ui/core/colors'

class ResourceEnumDropdownWithStatus extends Component {
  render () {
    const { classes, handleChange, value, data, inputLabelText, style, noEmpty } = this.props

    const statusIcon = status => {
      if (status === 'done') return <DoneIcon style={{ color: green[500] }} />
      if (status === 'failed') return <CloseIcon style={{ color: deepOrange[700] }} />
      return <CircularProgress size={25} style={{ color: yellow[600] }} />
    }

    return (
      <FormControl className={classes.formControl} style={style}>
        <InputLabel id="resource-select-label">{inputLabelText}</InputLabel>
        <Select
          labelid="resource-select-label"
          id="resource-select"
          value={value || ''}
          onChange={handleChange}
          renderValue={value => {
            if (data) {
              const entry = data.find(entry => entry.id === value)
              if (entry) return entry.name
            }
          }}
        >
          {!noEmpty && <MenuItem value="">
            <em>None</em>
          </MenuItem>}
          {data && typeof data.map === 'function' && data.map(entry =>
            <MenuItem disabled={typeof entry.status !== 'undefined' && !(entry.status === 'done')} key={entry.id} value={entry.id}>
              <ListItemText primary={entry.name} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} />
              {entry.status && <div>{statusIcon(entry.status)}</div>}
            </MenuItem>)}
        </Select>
      </FormControl>
    )
  }

}

export default ResourceEnumDropdownWithStatus
