import { noMP, cYbnd, cY, transition } from '../../../assets/jss/material-dashboard-pro-react'
export const styles = theme => ({
  noMP,
  tenantsControls: {
    paddingRight: 10,
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
    textTransform: 'none',
    borderRadius: 0,
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#ffffff'
    },
    fontWeight: 400,
    width: 364
  },
  chevronIcon: {
    width: 13,
    height: 13,
    ...transition
  },
  chevronIconOpened: {
    transform: 'rotate(180deg)',
    ...transition
  },
  tenantsControlPopover: {
    zIndex: 10,
    borderRadius: 0,

    marginTop: 8
  },
  tenantsControlPopoverPaper: {
    height: 334,
    borderRadius: 0
  },
  reportControlPopoverPaper: {
    borderRadius: 0
  },
  reportControlPopoverSearch: {
    marginLeft: 15,
    marginRight: 15
  },
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing.unit * 2,
  },
  popoverGridTopLine: {
    marginRight: '15px !important',
    marginLeft: '15px !important',
    paddingTop: 24,
    height: 70,
    borderBottom: '1px solid #dadfe6'
  },
  popoverGridTopLineItemWithIcon: {
    paddingRight: '0px !important',
    paddingLeft: '0px !important'
  },
  popoverGridTopLineItemWithText: {
    paddingLeft: '10px !important',
    paddingRight: '40px !important',
    paddingTop: '2px !important'
  },
  iconButton: {
    position: 'relative',
    width: 20,
    height: 20,
    padding: 0,
    '&:hover': {
      backgroundColor: '#ffffff'
    }
  },
  menuList: {
    paddingTop: 0,
    paddingBotton: 0,
  },
  listIcons: {
    position: 'absolute'
  },
  checkIcon: {
    width: 9,
    height: 9
  },
  channelsGridContainer: {
    margin: 0,
    width: 629
  },
  popoverGridChannel: {
    marginRight: '0px !important',
    marginLeft: '0px !important',
    paddingRight: '0px !important',
    paddingLeft: '15px !important',
    paddingBottom: 5,
    paddingTop: 5,
    width: 629,
    '&:hover': {
      backgroundColor: '#fafbfc'
    },
    cursor: 'pointer'
  },
  channelsList: {
    maxHeight: 193,
    minHeight: 193,
    overflow: 'auto'
  },
  footer: {
    height: 70,
    marginRight: '15px !important',
    marginLeft: '15px !important',
    marginBottom: '2px !important',
    paddingTop: 3,
    paddingRight: 15,
    borderTop: '1px solid #dadfe6',
  },
  gridItemVertical: {
    width: 629,
    paddingLeft: '0px !important',
    paddingRight: '0px !important'
  },
  btnCancel: {
    backgroundColor: '#f2f4f7',
    color: '#818994',
    width: 120,
    height: 40,
    marginTop: 11
  },
  btnApply: {
    backgroundColor: '#ff0066',
    color: '#ffffff',
    width: 120,
    height: 40,
    marginTop: 11,
    '&:hover': {
      backgroundColor: '#ee0044'
    },
  },
  btnGridItem: {
    paddingRight: '0px !important'
  },
  error: {
    color: '#ff0066'
  },
  cYbnd,
  cY
})
