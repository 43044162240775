import React, { Component } from 'react'
import GridItem from '../../components/Grid/GridItem.jsx'
import Button from '../../components/CustomButtons/Button.jsx'
import TextField from '@material-ui/core/TextField'
import Card from '../../components/Card/Card.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import withStyles from '@material-ui/core/styles/withStyles'
import extendedTablesStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import DataConnector from '../../utils/DataConnector'
import ResourceDetails from '../../components/ResourceDetails/ResourceDetails.jsx'
import JSONStateHandler from '../../utils/JSONStateHandler.js'
import { showSuccessMessage, showErrorMessage } from '../../components/Alerts/Alerts'

class JobDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false, isLoaded: false, data: {}
    }
    this.dataConnector = {}
    this.dataParams = {
      model: 'jobs',
      caption: { singular: 'job', plural: 'jobs' },
      handleObjectsAsJSON: true // treat all arrays and objects as JSON
    }
  }

  async componentDidMount () {
    this.dataConnector = new DataConnector(this.dataParams, this, showErrorMessage, showSuccessMessage)
    const schema = await this.dataConnector.getSchema()
    this.setState({ objectFields: JSONStateHandler.getJSONFieldNames(schema), schema }) // fields with custom rendering 
    await this.dataConnector.loadItem(this.props.id)

  }

  handleSave = (event) => {
    // call base save
    this.dataConnector.handleSave({ event })
  }

  render () {
    const { classes } = this.props
    const { error, data, schema } = this.state
    const objectFields = this.state.objectFields
    const { handleChange } = this.dataConnector // we are using a custom save due to object properties
    const handleSave = this.handleSave

    // not yet sure if this is worth/suitable to pulling out as a general compoment
    // but we do use it 3 times here
    const ObjectField = ({ name, disabled }) => {
      return (
        <TextField
          id={name}
          name={name}
          label={name}
          margin="normal"
          multiline
          fullWidth
          value={data[name] || ''}
          onChange={handleChange}
          disabled={disabled}
        />)
    }

    let content

    if (error) {
      content = <CardBody>Error in loading</CardBody>
    }
    if (this.state.status === 404) {
      content = <CardBody>Job not found</CardBody>
    } else if (data && schema) {
      content = (<CardBody>
        <ResourceDetails
          schema={schema}
          data={data}
          handleChange={handleChange}
          exclude={objectFields} // because we want them at the end layout-wise. If we use the  handleObjectsAsJSON, then DataConnector + component can handle them
          multiline={objectFields}
          classes={classes}
        />
        {ObjectField({ name: 'inputMediaIds', disabled: true })}
        {ObjectField({ name: 'settings', disabled: true })}
        {ObjectField({ name: 'tasks', disabled: true })}
        <Button
          style={{ margin: '20px 10px' }}
          variant="contained"
          color="success"
          onClick={handleSave}
        >
          Save
        </Button>{' '}
      </CardBody>)
    }

    return (
      <GridItem xs={12}>
        <Card>
          {content}
        </Card>
      </GridItem>
    )
  }
}

export default withStyles(extendedTablesStyle)(JobDetails)
