import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import GridItem from '../Grid/GridItem.jsx'
import EnhancedTable from '../EnhancedTable/EnhancedTable.jsx'
import Button from '../CustomButtons/Button.jsx'
import Card from '../Card/Card.jsx'
import CardBody from '../Card/CardBody.jsx'
import CardHeader from '../Card/CardHeader.jsx'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import extendedTablesStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import { isFunction } from 'lodash'

export class TableWrapperController {
  _listCallback = {}

  constructor() {
    this._isMounted = false
  }

  register(name, cb) {
    this._listCallback[name] = cb
  }

  async reload() {
    if(isFunction(this._listCallback['reload'])) {
      await this._listCallback['reload'].call()
    }
  }
}

class TableWrapper extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      search: props.search || ''
    }
    this.child = React.createRef()
  }
  componentDidMount () {
    this.props.onRef && this.props.onRef(this)
  }
  componentWillUnmount () {
    this.props.onRef && this.props.onRef(undefined)
  }
  fetchItems = async (showAlert) => {
    return this.child.fetchItems(showAlert)
  }
  reload = async () => {
    await this.child.reload()
  }
  updateSearch = event => {
    this.setState({ search: event.target.value })
  }
  render () {

    const {
      classes,
      resource,
      resourceName,
      fields,
      showStateButton,
      stateChangePreprocessor,
      goBack,
      toggleDisplay,
      rowsPerPage,
      setPlayerChannelId,
      hideSearch,
      maxColumnWidth,
      xtra,
      labels
    } = this.props

    const showSearch = !hideSearch
    //console.log (JSON.stringify({  resource, resourceName, fields, showStateButton, goBack, toggleDisplay, rowsPerPage }))

    const buttons = this.props.buttons ||
      (<Button
        style={{ margin: '20px 10px' }}
        variant="contained"
        color="success"
        onClick={() => toggleDisplay('new')}
        className={classes.button}
      >
        Add {resourceName.singular}
      </Button>)

    return (
      <GridItem xs={12}>
        <Card style={{ margin: 0 }}>
          <CardHeader color="rose" icon>
            <Grid container>
              <Grid item xs={4}>
                { this.props.prependButtons ? this.props.prependButtons() : null }
              </Grid>
              {showSearch && <Grid item xs={4}>
                <TextField
                  id="standard-search"
                  label="Search"
                  type="search"
                  className={classes.textField}
                  margin="dense"
                  fullWidth
                  onChange={this.updateSearch}
                />
              </Grid>}
              <Grid
                item
                xs={4}
                container
                alignItems="flex-start"
                justify="flex-end"
                direction="row"
              >
                {buttons}
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody style={{ padding: 0 }}>
            <EnhancedTable
              controller={this.props.controller}
              onRef={ref => (this.child = ref)}
              status={this.props.status}
              filterFields={this.props.filterFields}
              filterValues={this.props.filterValues}
              resource={resource}
              fields={fields}
              labels={labels}
              search={this.state.search}
              toggleDisplay={toggleDisplay}
              goBack={goBack}
              showStateButton={showStateButton}
              stateChangePreprocessor={stateChangePreprocessor}
              rowsPerPage={rowsPerPage}
              setPlayerChannelId={setPlayerChannelId}
              maxColumnWidth={maxColumnWidth}
              isCurrent={this.props.isCurrent}
              onRowClick={this.props.onRowClick}
              beforeDelete={this.props.beforeDelete}
              resetMethod={this.props.resetMethod}
              actionButtons={this.props.actionButtons}
              query={this.props.query}
            />
          </CardBody>
        </Card>
        {xtra}
      </GridItem>
    )
  }
}

export default withStyles(extendedTablesStyle)(TableWrapper)
