import React from 'react'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import Report from '../Reports/Report'
import { componentWithContext } from '../../state/DispatchContext'
import { refreshAllReports, getReport } from '../../state/helpers'
import ChannelsSelector from '../Reports/ChannelsSelector'
import PeriodsSelector from '../Reports/PeriodsSelector'
class Dashboard extends React.Component {
  async componentDidMount () {
    try {
      await refreshAllReports(this.props.context, true)
    } catch (ex) {
      console.error(ex)
    }
  }
  render () {
    const dashboardItems = this.props.context.state.users.current.app_metadata.dashboardItems
    const allDashboardReports = dashboardItems.map(di => ({ systemId: di.systemId, ...getReport(this.props.context, di.systemId, di.reportName)})).filter(report => report.reportName)
    const channelDashboardReports = allDashboardReports.filter(report => report.channelDependent)
    const assetDashboardReports = allDashboardReports.filter(report => !report.channelDependent)
    return (
      <div>
        <GridContainer justify="flex-end">
          <GridItem>
            <PeriodsSelector />
          </GridItem>
        </GridContainer>
        {allDashboardReports.length === 0
          ? <div style={{ textAlign: 'center', width: '100%', marginTop: '50px' }}>
            <h3>No reports added to the dashboard yet</h3>
            <h5>Check the "Reports" section to build your personal dashboard</h5>
          </div>
          : <>
            {channelDashboardReports.length !== 0 && <>
              <GridContainer><GridItem><h4>Channel reports</h4></GridItem></GridContainer>
              <GridContainer justify="flex-end">
                <GridItem>
                  <ChannelsSelector />
                </GridItem>
              </GridContainer>
              <GridContainer>
                {channelDashboardReports.map(report =>
                  <Report
                    key={report.systemId + report.reportName}
                    systemId={report.systemId}
                    reportName={report.reportName}
                    disableTimingSelect={report.disableTimingSelect}
                    showCampaignSelector={report.showCampaignSelector}
                    useDashboard={true}
                  />
                )
                }
              </GridContainer></>}
            {assetDashboardReports.length !== 0 && <>
              <GridContainer><GridItem><h4>Asset reports</h4></GridItem></GridContainer>
              <GridContainer>
                {assetDashboardReports.map(report =>
                  <Report
                    key={report.systemId + report.reportName}
                    systemId={report.systemId}
                    reportName={report.reportName}
                    disableTimingSelect={report.disableTimingSelect}
                    showCampaignSelector={report.showCampaignSelector}
                    useDashboard={true}
                  />
                )
                }
              </GridContainer></>}
          </>
        }
      </div>
    )
  }
}

export default componentWithContext(Dashboard)
