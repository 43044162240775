import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Hidden from "@material-ui/core/Hidden"

// material-ui icons
import Menu from "@material-ui/icons/Menu"

// core components
import HeaderLinks from "./HeaderLinks"
import HeaderJobs from "./HeaderJobs"
import Button from "../../components/CustomButtons/Button.jsx"

import headerStyle from "../../assets/jss/material-dashboard-pro-react/components/headerStyle.jsx"

import { withRouter } from 'react-router-dom'

function Header (props) {
  const { classes, color } = props
  const appBarClasses = cx({
    [" " + classes[color]]: color
  })

  // Force open and close drawer for mobile and tablet as original function has been modified and is not working
  const openCloseDrawer = () => {
    const layoverHolder = document.body.children
    let layoverArray = []
    for (let item of layoverHolder) {
      if (item.tagName === 'DIV') {
        layoverArray.push(item);
      }
    }
    const layover = layoverArray[1]
    layover.style.visibility = 'visible'

    const sidebar = document.querySelectorAll('[role="document"]')
    sidebar[0].style.transform = 'none' 

    const blackCover = layover.children
    blackCover[0].style.transition = 'opacity 1s'
    blackCover[0].style.opacity = "1"

    let screenOut = (window.screen.width < 500) ? '-50px' : '-220px'
    
    blackCover[0].onclick = function() {
      blackCover[0].style.transition = 'opacity 1s'
      blackCover[0].style.opacity = "0"
      layover.style.visibility = 'hidden'
      sidebar[0].style.transform = `translateX(100vw) translateX(${screenOut})`
    }
      
  }
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          <HeaderJobs />
        </div>
        <Hidden smDown implementation="css">
          <HeaderLinks history={props.history} />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={openCloseDrawer}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool
}

export default withRouter(withStyles(headerStyle)(Header))
