import React, { Component } from 'react'
import ReactDom from 'react-dom'
import Card from '../../components/Card/Card.jsx'
import TV from '@material-ui/icons/Tv'
import CardBody from '../../components/Card/CardBody.jsx'
import CardIcon from '../../components/Card/CardIcon.jsx'
import CardHeader from '../../components/Card/CardHeader.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import Button from '../../components/CustomButtons/Button.jsx'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import backend from '../../utils/backend'
import Spinner from 'react-spinner-material'
import * as constants from './Constants'
import { BackendError } from '../../utils/errors'
import { showSuccessMessage, showErrorMessage, showWarningMessage, showConfirmationMessage } from '../../components/Alerts/Alerts'
import ResourceEnumDropdown from '../../components/ResourceDetails/ResourceEnumDropdown'
import ResourceEnumDropdownWithStatus from '../../components/ResourceDetails/ResourceEnumDropdownWithStatus'
import FileUploadProgress from 'react-fileupload-progress'
import LinearProgress from '@material-ui/core/LinearProgress'
import nameGenerate from 'project-name-generator'
import Swal from 'sweetalert2'
import uuid from 'uuid/v4'
import defaultsAdServerParams from './defaultsAdServerParams'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'

import regularFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle'
import withStyles from '@material-ui/core/styles/withStyles'

import Switch from '@material-ui/core/Switch'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import path from 'path'

import HMACSecretOption from './HMACSecretOption'
import ResourceSuppressHLSTagsDropdown from '../../components/ResourceDetails/ResourceSuppressHLSTagsDropdown.jsx'

const defaultHmac = {
  secret: '',
  isSecretEncrypted: false,
  method: '',
  window: 0,
  cookieDomain: ''
}

let audioNormalizationDefaults = {
  integratedLoudness: -23,
  loudnessRange: 11,
  maximumTruePeakLevel: -1
} //NEM-1089: defaults

// This validation is only used at the very end, at point of save, not on field
// change - for obvious reasons ..
const jsonError = value => {
  try {
    JSON.parse(value)
    return '' // to make the rest of the code easier, clearer
  } catch (err) {
    return constants.ERROR_INVALID_JSON
  }
}

const checks = {
  valueInRange: (value, min, max) => {
    if (value < min || value > max) {
      return 'value must be between ' + min + ' and ' + max
    }
    return ''
  },
  hasValue: value => (value ? '' : constants.ERROR_EMPTY),
  isURL: value => {
    try {
      new URL(value)
      return ''
    } catch (_) {
      return constants.ERROR_URL_NOT_VALID
    }
  }
}

const textAbout3Jobs = 'No media found - 3 jobs to create it already failed. Please check if there is an issue with the source and archive the existing jobs to be able to retry.'

// on Save field input validations
const validationList = {
  name: [checks.hasValue],
  sourceManifestUrl: [checks.hasValue, checks.isURL],
  timeShiftWindow: [(value) => { return checks.valueInRange(value, 0, 3600) }] // max 60 mins
}

const channelUrlText = {
  vod: `Please provide an example video from your playlist (HLS or DASH). 
  We will analyse it to figure out the correct content conditioning profile for you. 
  (If you do not have an example yet you can specify the profile below or choose one of our presets)`,
  live: `Please provide the URL to your live stream. We will analyse it to 
  figure out the correct content conditioning profile and take it as an input 
  source to insert personalised ads. (You will have to opportunity to fine tune your profile below)`
}

const streamTypes = [
  { id: 'hls', name: 'hls' },
  { id: 'dash', name: 'dash' }
]

const roundButtonStyle = {
  borderRadius: '25px',
  width: '25px',
  paddingLeft: '16px',
  paddingTop: '8px',
  paddingRight: '16px',
  paddingBottom: '8px',
  marginLeft: '10px'
}

const defaultStitcherSettings = {
  shouldUseRelativeSegmentUrl: false,
  numberOfSegmentsToReplayAfterMidroll: 3,
  includeDashEvents: false,
  shouldFilterHlsByBandwidth: false,
  bandwidthThreshold: 650000,
  useScte35: false,
  replaceInsteadOfInsert: false,
  provideVastURL: false
}

const defaultPreRollSettings = {
  adSource: {
    system: 'spotx',
    url: '',
    params: ''
  },
  maxDuration: 30,
  maxCount: 1
}

class ChannelDetailsPage extends Component {
  _isMounted = false


  state = {
    // **NOTE:  currentChannel object MUST conform to the schema spec (found in the backend channels.service.schema)
    // this means structure, all field names etc.
    currentChannel: {
      id: uuid(),
      type: 'dash-vod',
      sourceManifestUrl: '',
      name: '',
      state: 'disabled',
      outputUri: '',
      apiKey: '',
      adSource: {
        url: '',
        system: 'spotx',
        params: '' // will be JSONed to an object
      },
      adSlateManifestUrl: '',
      stitcher: defaultStitcherSettings,
      preRoll: defaultPreRollSettings,
      enablePreRoll: false,
      timeShiftWindow: 0,
      cmaf: undefined,
      http: false,
      hasSeparateAudio: undefined,
      enableServerSideBeacons: false,
      isMultiPeriod: false,
      dynamicConfiguration: false,
      fallbackSourceTags: false,
      passThroughCaidSegmentation: false,
      cohortsEnabled: false,
      xApiKey: '',
      trueSourceUrl: '',
      cacheList: '',
      prefetchDuration: 0,
      cacheTime: 0,
      contentLocationReplacement: undefined,
      adLocationReplacement: undefined,
      segmentationConfiguration: undefined,
      suggestedPresentationDelay: 0,
      hlsSuppressTags: [],
      preservePlaylistFilename: false,
      dvrWindow: 0,
      startAtDvrWindow: true
    },
    newProfileInfo: {
      analyzed: false,
      data: {}
    },
    //ui
    saveButtonLabel: 'Save',
    disableSaveButton: false,
    disableBackButton: false,
    fetching: false,
    analysing: false,
    profileOpen: false,
    encodingProgress: false,

    //validation errors
    errors: [],

    fileName: '',

    popovers: {
      integratedLoudness: { anchorEl: null, text: `Set the targeted integrated loudness value. Range is from '-70.0' to '-5.0'. Default value is '${audioNormalizationDefaults.integratedLoudness}'. Value is measured in LUFS (Loudness Units, referenced to Full Scale)` },
      loudnessRange: { anchorEl: null, text: `Set the targeted loudness range target. Range is from '1.0' to '20.0'. Default value is '${audioNormalizationDefaults.loudnessRange}'. Loudness range measures the variation of loudness on a macroscopic time-scale in units of LU (Loudness Units). For programmes shorter than 1 minute, the use of the measure Loudness Range is not recommended due to too few data points (Loudness Range is based on the Short-term-Loudness values (3-seconds-window)).` },
      maximumTruePeakLevel: { anchorEl: null, text: `Set maximum true peak. Range is from '-9.0' to '0.0'. Default value is '${audioNormalizationDefaults.maximumTruePeakLevel}'. Values are measured in dBTP (db True Peak)` },

      beaconImpression: { anchorEl: null, text: `Ad inserted` },
      beaconStart: { anchorEl: null, text: `Ad started` },
      beaconFirstQuartile: { anchorEl: null, text: `25% viewed` },
      beaconMidpoint: { anchorEl: null, text: `50% viewed` },
      beaconThirdQuartile: { anchorEl: null, text: `75% viewed` },
      beaconComplete: { anchorEl: null, text: `100% viewed` },
      beaconClick: { anchorEl: null, text: `Include serverside clickTrackingURL` },

      // lockProfile: { anchorEl: null, text: `Lock profile to prevent changes` },
      httpProxyUrl: { anchorEl: null, text: `Custom http proxy for stitcher` },
      shouldUseRelativeSegmentUrl: { anchorEl: null, text: `Use relative segment URL means not to include base address in segments url` },
      numberOfSegmentsToReplayAfterMidroll: { anchorEl: null, text: 'Number of segments to replay after midroll' },
      includeDashEvents: { anchorEl: null, text: 'Include DASH events' },
      shouldFilterHlsByBandwidth: { anchorEl: null, text: 'Filter HLS by bandwidth' },
      bandwidthThreshold: { anchorEl: null, text: 'Maximum HLS bandwidth' },
      preRollMaxDuration: { anchorEl: null, text: 'Maximum preroll duration in seconds' },
      preRollMaxCount: { anchorEl: null, text: 'Maximum number of ads inserted into the preroll ad-pod' },
      enablePreRoll: { anchorEl: null, text: 'If activated one or more ads are played before the stream starts' },
      timeShiftWindow: { anchorEl: null, text: 'Set the live channel time-shift window in seconds. (max 3600 = 1 hour)' },
      dvrWindow: { anchorEl: null, text: 'Configuration of the maximum playlist size in seconds. If 0 is selected, then the source playlist size will be used.' },
      startAtDvrWindow: {anchorEl: null, text: 'If disabled [DEFAULT], then the playlist size will be using the source playlist size. If enabled, then the playlist size will grow until the source size of configured size (see Playlist size).'},
      'segmentationConfiguration.segmentationTypeIdsStart': { anchorEl: null, text: '[time-signal] Set of segmentation types signaling start of replacement opportunity' },
      'segmentationConfiguration.segmentationTypeIdsEnd': { anchorEl: null, text: '[time-signal] Set of segmentation types signaling end of replacement opportunity' },
      'segmentationConfiguration.scte35IsHexNotBase64': { anchorEl: null, text: 'SCTE35 commands are hexadecimal [true] or base64 [false] encoded' },
      'segmentationConfiguration.defaultDuration': { anchorEl: null, text: 'Fallback when no cue-out duration provided' },
      'segmentationConfiguration.durationFromScte35OverManifest': { anchorEl: null, text: 'SCTE35 duration overrules HLS attribute DURATION' },
      'segmentationConfiguration.fallbackSourceTags': { anchorEl: null, text: 'Fallback when ad not inserted' },
      'useScte35': { anchorEl: null, text: 'If active, the Ad-Markers will be taken from SCTE-35 markers in the manifest file' },
      'replaceInsteadOfInsert': { anchorEl: null, text: 'If the personalized ads should replace an existing ad-break or be inserted in a file that has no original ads' },
      'provideVastURL': { anchorEl: null, text: 'Providing the corresponding VAST.xml URL within the stitcher response' },
      hmac: { anchorEl: null, text: 'HMAC: An authentication method that verifies requests via an HMAC token and secures TS segments with a newly created token. This implementation is based on the Akamai HMAC implementation. Details can be found in our documentation: https://nowtilus.gitbook.io/serverside-ai/.' },
      hmacSecret: { anchorEl: null, text: 'Shared secret.' },
      hmacMethod: { anchorEl: null, text: 'The method used to validate and generate HMAC token.' },
      hmacWindow: { anchorEl: null, text: 'Lifetime of the token until it expires. (In seconds)' },
      hmacCookieDomain: { abchorEl: null, text: 'Define the domain for the HMAC cookie' },
      hlsIndependentMediaSequences: { anchorEl: null, text: 'Independent Media Sequences: Support independent media sequences. (This will improve compatibility but can lead to increased load on the system)' },
      hlsLegacyMediaSequenceHandling: { anchorEl: null, text: 'Legacy Media Sequence Handling: Non-Legacy mode will handle not inline between playlists more gracefully. (This will improve compatibility but can lead to increased load on the system)' },
      generateTitleIdsUsingURL: { anchorEl: null, text: 'Ad conditioning will ignore incoming ad ID and use source URL to generate it' },
      suppressHLSTags: { anchorEl: null, text: 'Not every HLS player supports all HLS tags. In this section you can select HLS tags that should not be included in the manifest to increase the compatibility.' },
      preservePlaylistFilename: { abchorEl: null, text: 'By enabling the preserve playlist name, the serverside.ai output playlist will following the source.' },
    },

  }

  toggleProfile = () => {
    this._isMounted && this.setState({
      profileOpen: !this.state.profileOpen
    })
  }

  toggleContentLocation = () => {
    this._isMounted && this.setState({
      contentLocationOpen: !this.state.contentLocationOpen
    })
  }

  toggleAdLocation = () => {
    this._isMounted && this.setState({
      adLocationOpen: !this.state.adLocationOpen
    })
  }

  toggleSegmentationConfiguration = () => {
    this._isMounted && this.setState({
      segmentationConfigurationOpen: !this.state.segmentationConfigurationOpen
    })
  }

  modifyCurrentChannel = (newChannelObject, setAdSlate = true) => {
    if (newChannelObject.channelType && this.state.currentChannel.type) {
      newChannelObject.type = `${this.state.currentChannel.type.split('-')[0]}-${newChannelObject.channelType}`
    }
    if (newChannelObject.internalBeacons && newChannelObject.internalBeacons.baseUrl) {
      newChannelObject.internalBeacons.baseUrl = newChannelObject.http ? newChannelObject.internalBeacons.baseUrl.replace(/^https:\/\//i, 'http://') : newChannelObject.internalBeacons.baseUrl.replace(/^http:\/\//i, 'https://')
    }
    let outputUri
    if (this.state.currentChannel.outputUri) {
      outputUri = newChannelObject.http ? this.state.currentChannel.outputUri.replace(/^https:\/\//i, 'http://') : this.state.currentChannel.outputUri.replace(/^http:\/\//i, 'https://')
    }
    let currentChannel = {
      ...this.state.currentChannel,
      ...newChannelObject,
      contentLocationReplacement: this.state.currentChannel.contentLocationReplacement || {},
      adLocationReplacement: this.state.currentChannel.adLocationReplacement || {},
      outputUri
    }
    this._isMounted && this.setState({ currentChannel })
    setAdSlate && this.setAdSlate()
  }

  handlePopoverClick = key => {
    return event => {
      this.closeAllPopovers()
      this._isMounted && this.setState({ popovers: { ...this.state.popovers, [key]: { ...this.state.popovers[key], anchorEl: event.currentTarget } } })
    }
  }

  handlePopoverClose = key => {
    return () => {
      this._isMounted && this.setState({ popovers: { ...this.state.popovers, [key]: { ...this.state.popovers[key], anchorEl: null } } })
    }
  }

  closeAllPopovers = () => {
    if (!this._isMounted) return

    const popovers = this.state.popovers

    for (var prop in popovers) {
      if (Object.prototype.hasOwnProperty.call(popovers, prop)) {
        popovers[prop] = { ...popovers[prop], anchorEl: null }
      }
    }

    this._isMounted && this.setState({ popovers })
  }

  async componentDidMount () {
    window.makeHbbTVChannel = () => {
      this.modifyCurrentChannel({
        sourceManifestUrl: 'http://some.url',
        adSlateManifestUrl: 'http://some.url',
        profile: JSON.stringify({}),
        type: 'dash-live',
        http: true,
        adSource: {
          system: 'proxy',
          url: 'http://localhost:3102',
          params: JSON.stringify({})
        }
      })
      this.setState({ profileOpen: true })
    }
    this._isMounted = true
    const { id } = this.props
    let currentChannel = {}
    let schema, systems, systemSchema, ssai, baseUrl, encoderSystem
    try {
      [schema, systems, systemSchema] = await Promise.all([
        backend.modelSchema('channels'), // modelSchema ensures we get the full schema depth, including e.g. enum for adServer
        backend.list({ resource: 'systems' }),
        backend.schema('systems')])
      ssai = systems.find(s => s.category === 'ssai')
      encoderSystem = systems.find(s => s.category === 'encoder')
      baseUrl = (ssai && ssai.settings.beaconsBaseUrl) || systemSchema.beaconsBaseUrl
    } catch (ex) {
      console.error('Cannot load data')
    }
    if (!id) {
      currentChannel = {
        ...this.state.currentChannel,
        name: this.generateName(),
        internalBeacons: {
          baseUrl,
          events: [
            'impression',
            'start',
            'firstQuartile',
            'midpoint',
            'thirdQuartile',
            'complete'
          ]
        }
      }
      currentChannel.adSource.params = this.loadDefaultAdServerParams(currentChannel.adSource)
      currentChannel.preRoll.adSource.params = this.loadDefaultAdServerParams(currentChannel.preRoll.adSource)
    } else {
      try {
        currentChannel = await backend.get(constants.CHANNELS, id)
        currentChannel.id = id
        currentChannel.outputUri = currentChannel.outputUri || ''
        if (currentChannel.adSource.params && currentChannel.adSource.params.metadata) {
          delete currentChannel.adSource.params.metadata
        }
        currentChannel.adSource.params = currentChannel.adSource.params
          ? JSON.stringify(currentChannel.adSource.params, null, 2)
          : '{}'

        // handle preRoll
        currentChannel.preRoll = currentChannel.preRoll || defaultPreRollSettings
        currentChannel.preRoll.adSource = currentChannel.preRoll.adSource || {}
        currentChannel.preRoll.adSource.params = currentChannel.preRoll.adSource.params
          ? JSON.stringify(currentChannel.preRoll.adSource.params, null, 2)
          : '{}'

        currentChannel.profile = currentChannel.profile ? JSON.stringify(currentChannel.profile, null, 2) : ''
        currentChannel.stitcher = currentChannel.stitcher || defaultStitcherSettings
        // Default? If not present, is added in channels-service anyway ...
        // currentChannel.timeShiftWindow =

        this.dealWithNullableObjects(currentChannel)

        this._isMounted && this.setState({ currentChannel: currentChannel })
        this.setAdSlate()
      } catch (e) {
        if (e.response && e.response.status && this._isMounted) {
          this._isMounted && this.setState({ status: e.response.status })
        } else {
          return showErrorMessage('Error while Loading. ' + e.message)
        }
      }
    }

    if (this._isMounted) {
      const newCurrentChannel = { ...this.state.currentChannel, ...currentChannel }
      this.dealWithNullableObjects(newCurrentChannel)
      this.setState({
        currentChannel: newCurrentChannel,
        schema,
        baseUrl,
        unchangedProfile: newCurrentChannel.profile,
        encoderSystem
      })
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  assetUrlIdGetter (url = '') {
    const regex = /[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/g
    const result = url.toLowerCase().match(regex)
    return result && result[0]
  }

  setAdSlate = async () => {
    if (!this._isMounted) return
    try {
      const targetAdSlateAssetId = this.assetUrlIdGetter(this.state.currentChannel.adSlateManifestUrl)
      const adSlates = await backend.list({ resource: 'titles', type: 'ad-slate', limit: -1 })
      const currentAdSlateId = (await backend.list({ resource: 'medias', search: targetAdSlateAssetId })).reduce((r, item) => {
        if (!r) return item.titleId
        return r
      }, null)


      //for drop-down:
      const adSlatesById = {}
      for (let i = 0; i < adSlates.length; ++i) {
        adSlatesById[adSlates[i].id] = adSlates[i].name
      }

      this._isMounted && this.setState({ adSlatesById, adSlates, currentAdSlateId })
    } catch (ex) {
      console.log(ex)
      return showErrorMessage('Cannot load ad-slates from server. Please try again later or contact support.')
    }
  }

  generateName = () => nameGenerate({ words: 2, alliterative: true }).spaced + ' channel'

  setErrors = errors => {
    this._isMounted && this.setState({ errors })
  }

  resetErrors = () => {
    this._isMounted && this.setState({ errors: [] })
  }

  getErrors = field => {
    return this.state.errors
      .filter(error => error.key === field)
      .map(error => error.error)
      .join(' ')
  }

  validateField = (key, value, validations) => {
    const errors = []

    validations.forEach(validation => {
      const error = validation(value)
      if (error) {
        errors.push({ key, error })
      }
    })
    return errors
  }

  // segmentationConfiguration
  checkStartEndWithSanitise = ({ channel, segmentationTypeIdsFieldName, errors }) => {
    let segmentationTypeIdsErr
    let segmentationTypeIdsField = channel.segmentationConfiguration[segmentationTypeIdsFieldName]

    if (!Array.isArray(segmentationTypeIdsField)) {
      segmentationTypeIdsField = segmentationTypeIdsField || '[]'
      if (!segmentationTypeIdsField.startsWith('[')) {
        segmentationTypeIdsField = '[' + segmentationTypeIdsField
      }
      if (!segmentationTypeIdsField.endsWith(']')) {
        segmentationTypeIdsField = segmentationTypeIdsField + ']'
      }

      segmentationTypeIdsErr = segmentationTypeIdsField &&
        segmentationTypeIdsField !== ''
        && jsonError(segmentationTypeIdsField)

      if (segmentationTypeIdsErr) {
        errors.push({
          key: `segmentationConfiguration.${segmentationTypeIdsFieldName}`,
          error: segmentationTypeIdsErr
        })
      } else {
        segmentationTypeIdsField = JSON.parse(segmentationTypeIdsField)
      }
    }

    if (segmentationTypeIdsField.length === 0) {
      errors.push({
        key: `segmentationConfiguration.${segmentationTypeIdsFieldName}`,
        error: `${segmentationTypeIdsFieldName} cannot be empty`
      })
    }


  }


  validate = channel => {
    let errors = []

    // validations for individual fields
    Object.keys(channel).forEach(key => {
      const validations = validationList[key]
      if (validations) {
        const value = channel[key]
        errors = errors.concat(this.validateField(key, value, validations))
      }
    })

    // whole object validations or special cases
    let adSourceParamsErr = channel.adSource.params && channel.adSource.params !== '' && jsonError(channel.adSource.params)
    if (adSourceParamsErr) {
      errors.push({ key: 'adSource.params', error: adSourceParamsErr })
    }

    try {
      JSON.parse(this.state.currentChannel.profile)
    } catch (e) {
      errors.push({ key: 'profile', error: 'Cannot parse JSON: ' + e.message })
    }
    return errors
  }



  clearSegmentationConfiguration = () => {
    this._isMounted && this.setState({
      currentChannel: { ...this.state.currentChannel, segmentationConfiguration: {} }
    })
  }

  handleSave = async () => {
    if (!this.state.unchangedProfile || this.state.currentChannel.profile === this.state.unchangedProfile) {
      return this.saveChannel()
    } else {
      showConfirmationMessage(() => this.saveChannel(), {
        title: 'Channel profile has changed',
        text: `Are you sure you want to save the channel with changed profile? It may cause reencodings of some medias.`,
        confirmButtonText: 'Yes'
      })
    }
  }

  saveChannel = async () => {
    this.resetErrors()
    const errors = this.validate(this.state.currentChannel)
    this.setErrors(errors)

    try {
      if (errors.length > 0) {
        throw new Error('Invalid fields: ' + errors.map(e => e.key).join(', '))
      }
      let result = {}
      this._isMounted && this.setState({
        disableSaveButton: true,
        disableBackButton: true,
        fetching: true,
        saveButtonLabel: 'Saving ... '
      })

      // convert AdSource parameters text to object
      let adSourceParams = this.state.currentChannel.adSource.params
      adSourceParams = ('' || adSourceParams) === '' ? {} : JSON.parse(this.state.currentChannel.adSource.params)

      let channel = { ...this.state.currentChannel, adSource: { ...this.state.currentChannel.adSource, params: adSourceParams } }

      if (channel.internalBeacons && JSON.stringify(channel.internalBeacons) !== '{}') {
        channel.internalBeacons = {
          ...channel.internalBeacons,
          baseUrl: this.state.baseUrl
        }
      }

      let channelCopy = JSON.parse(JSON.stringify(channel))
      window.channelCopy = channelCopy
      if (this.state.currentChannel.preRoll &&
        this.state.currentChannel.preRoll.adSource &&
        this.state.currentChannel.preRoll.adSource.params) {
        channelCopy.preRoll = channel.preRoll || {}
        channelCopy.preRoll.adSource = channel.preRoll.adSource || {}
        channelCopy.preRoll.adSource.params =
          this.state.currentChannel.preRoll.adSource.params === ''
            ? {}
            : JSON.parse(this.state.currentChannel.preRoll.adSource.params)

      }

      channelCopy.profile = JSON.parse(channel.profile)

      // make sure we don't try to send a stitcher for a live channel
      if (this.getChannelTypeShort(channelCopy) === 'live') {
        delete channelCopy.stitcher
      }

      if (!channelCopy.enablePreRoll) delete channelCopy.preRoll
      if (!channelCopy.timeShiftWindow || channelCopy.timeShiftWindow === '') channelCopy.timeShiftWindow = 0

      if (!channelCopy.authentication) channelCopy.authentication = {}

      if (!Number.isInteger(channelCopy.dvrWindow)) {
        channelCopy.dvrWindow = 0
      }

      if (this.props.id) {
        result = await backend.update(
          constants.CHANNELS,
          channelCopy
        )
      } else {
        result = await backend.create(
          constants.CHANNELS,
          channelCopy
        )
      }

      this._isMounted && this.setState({
        disableSaveButton: true,
        disableBackButton: false,
        fetching: false
      })

      this._isMounted && this.setState({
        unchangedProfile: JSON.stringify(channelCopy.profile, null, 2)
      })

      // make sure we give the full error information
      // **TODO: make this a generic thing
      if (result.code && result.code > 399) throw new BackendError(result)

      this.resetErrors()
      await showSuccessMessage('Your item has been saved')
      this.props.toggleDisplay(this.props.id ? this.props.id : '')
      this._isMounted && this.setState({
        disableSaveButton: false,
        disableBackButton: false,
        fetching: false,
        saveButtonLabel: 'Save',
        outputUri: result.outputUri // this can change, for instance if http toggle is set or unset
      })
    } catch (err) {
      showErrorMessage('Error while Saving: ' + err.message)
      this._isMounted && this.setState({
        saveButtonLabel: 'Save',
        disableSaveButton: false,
        disableBackButton: false,
        fetching: false
      })
    }
  }

  onUploadSuccessful (data) {
    const response = JSON.parse(data.target.response)

    const jobId = (response[0] || response).job.id
    const sourceMedia = (response[0] || response).sourceMedia
    const adSlate = (response[0] || response).title

    this.watchEncoding({
      jobId,
      currentChannel: this.state.currentChannel,
      adSlate,
      sourceMedia
    })

    Swal.fire({
      type: 'success',
      title: 'Upload Successful',
      text: 'The file was uploaded successfully. A process is running in the backround to create the packages matching your channel. This may take a few minutes.',
      confirmButtonText: 'OK'
    })
  }

  onUploadFailed (why) {
    let msg = 'Please try again or contact support.'
    if (why instanceof ProgressEvent && why.currentTarget instanceof XMLHttpRequest) {
      try {
        const response = JSON.parse(why.currentTarget.response)
        msg = response.message
      } catch (ex) {
        console.error('Cannot parse error', why, ex)
      }
    }
    Swal.fire({
      type: 'warning',
      title: 'Upload Error',
      text: 'There was an error on uploading your file. ' + msg,
      confirmButtonText: 'OK'
    })
  }

  dealWithNullableObjects = channel => {
    if (!channel.internalBeacons) {
      channel.internalBeacons = {}
    }
    if (!channel.contentLocationReplacement) {
      channel.contentLocationReplacement = {}
    }
    if (!channel.adLocationReplacement) {
      channel.adLocationReplacement = {}
    }
    if (!channel.segmentationConfiguration) {
      channel.segmentationConfiguration = {}
    }
  }

  handleChange = event => {
    let currentChannel = this.state.currentChannel
    // deal with one level of object properties
    // e.g. adSource.url
    // I have temporaily forgotten any neater way to do this !
    const id = event.target.id || event.target.name // (some base controls only return name, value in target)
    let params = id.split(".")

    params.reduce((aggregate, current, i) => {
      if (i === params.length - 1) aggregate[current] = event.target.value
      return aggregate[current]
    }, currentChannel)

    // recalculate the ad-server-params
    if (event.target.name === 'adSource.system') {
      currentChannel.adSource.params = this.loadDefaultAdServerParams(currentChannel.adSource)
    }
    // recalculate the ad-server-params
    if (event.target.name === 'preRoll.adSource.system') {
      currentChannel.preRoll.adSource.params = this.loadDefaultAdServerParams(currentChannel.preRoll.adSource)
    }

    this.dealWithNullableObjects(currentChannel)
    this._isMounted && this.setState({ currentChannel })
  }

  loadDefaultAdServerParams (adSource) {
    if (defaultsAdServerParams[adSource.system]) {
      const defaultParams = defaultsAdServerParams[adSource.system]
      return JSON.stringify(defaultParams, null, 2)
    } else return '{}'
  }

  handleAdSlateSelect = async event => {
    const currentChannel = this.state.currentChannel
    const adSlate = this.state.adSlates && this.state.adSlates.find(ads => ads.id === event.target.value)
    if (!adSlate) return

    if (!currentChannel.profile) {
      await Swal.fire({
        type: 'warning',
        title: 'No Profile',
        text: 'A profile has to be added to the channel before selecting an ad slate',
        confirmButtonText: 'OK'
      })
      return
    }
    let sourceMedias
    try {
      sourceMedias = await backend.list({ resource: 'medias', titleId: adSlate.id, type: 'source', limit: 100 })
    } catch (ex) {
      console.error('backend.list method failed')
      return
    }
    const sourceMedia = sourceMedias[0]
    try {
      if (!currentChannel.type) {
        Swal.fire({
          type: 'error',
          title: 'Cannot create ad slate',
          text: 'Please select channel type first (in profile section)',
          confirmButtonText: 'OK'
        })
        return
      }
      const resultingMedia = await backend.putChannelAsset({
        titleId: adSlate.id,
        channelId: currentChannel.id,
        channelType: currentChannel.type,
        profile: JSON.parse(currentChannel.profile),
        mediaUrl: sourceMedia.url,
        name: adSlate.name,
        cmaf: currentChannel.cmaf || false,
        http: currentChannel.http || false,
        hasSeparateAudio: currentChannel.hasSeparateAudio || false,
        workflowParams: {
          externalResourceId: sourceMedia.externalResourceId,
          externalResourceType: sourceMedia.externalResourceType,
          createdBySystemId: sourceMedia.createdBySystemId
        }
      })

      currentChannel.adSlateManifestUrl = resultingMedia.url
    } catch (e) {
      if ((e.message.includes('Not Found') || e.message.includes('ERR_NOT_FOUND'))
        && !e.message.includes('3 jobs to create it already failed')) {
        try {
          currentChannel.adSlateManifestUrl = ''
          // Extremely ugly - not sure if there is a better way to parse the response
          // (here we remove all non-json texts from message)
          let parsableMessage = e.message.substr(e.message.indexOf('{'), e.message.length - e.message.indexOf('{'))
          parsableMessage = parsableMessage.substr(0, parsableMessage.lastIndexOf('}') + 1)

          const response = JSON.parse(parsableMessage)

          let jobId
          if (response.data && response.data.job && response.data.job.id) {
            jobId = response.data.job.id
          }
          if (response.data && response.data.jobs && response.data.jobs[0] && response.data.jobs[0].id) {
            jobId = response.data.jobs[0].id
          }
          if (!jobId) {
            throw new Error(e.message)
          }

          Swal.fire({
            type: 'success',
            title: 'Job Started',
            text: 'A process is running in the backround to create the packages matching your channel. This may take a few minutes.',
            confirmButtonText: 'OK'
          })

          this.watchEncoding({ jobId, currentChannel, adSlate, sourceMedia })
        } catch (e) {
          let text = e.message
          if (e.message && e.message.indexOf(textAbout3Jobs)) {
            text = textAbout3Jobs
          }
          Swal.fire({
            type: 'error',
            title: 'Cannot create ad slate',
            text,
            confirmButtonText: 'OK'
          })
        }
      } else {
        Swal.fire({
          type: 'error',
          title: 'Cannot create ad slate',
          text: e.message,
          confirmButtonText: 'OK'
        })
      }
    }
    this.dealWithNullableObjects(currentChannel)
    this._isMounted && this.setState({ currentAdSlateId: event.target.value, currentChannel })
  }

  watchEncoding ({ jobId, currentChannel, adSlate, sourceMedia }) {
    let progress = 1
    this._isMounted && this.setState({ encodingProgress: progress })

    let encodingWatcher = async () => {
      let job = {}
      try {
        job = await backend.get('jobs', jobId)
      } catch (ex) {
        console.error(ex)
      }
      if (progress > 99) progress = 70
      if (job.status === 'running') {
        progress = progress + 2
        setTimeout(() => encodingWatcher(), 5000)
      } else if (job.status === 'done') {
        this._isMounted && this.setState({ encodingProgress: 100 })
        try {
          const resultingMedia = await backend.putChannelAsset({
            titleId: adSlate.id,
            channelId: currentChannel.id,
            channelType: currentChannel.type,
            profile: JSON.parse(currentChannel.profile),
            mediaUrl: sourceMedia.url,
            name: adSlate.name,
            doNotRunJob: true,
            cmaf: currentChannel.cmaf || false,
            http: currentChannel.http || false,
            hasSeparateAudio: currentChannel.hasSeparateAudio || false
          })
          currentChannel.adSlateManifestUrl = resultingMedia.url
          this.dealWithNullableObjects(currentChannel)

          this._isMounted && this.setState({ currentChannel })
          await Swal.fire({
            type: 'success',
            title: 'Sucessfully encoded ad-slate',
            confirmButtonText: 'OK'
          })
          progress = false
        } catch (e) {
          await Swal.fire({
            type: 'error',
            title: 'Error while trying to encode ad-slate',
            text: 'Please try again later or contact support. Error details: ' + e.message,
            confirmButtonText: 'OK'
          })
          progress = false
        }
      }
      else if (job.status === 'failed') {
        const errorDetails = job.tasks.filter(task => task.error).map(task => task.error).join(', ')
        console.error(job)
        Swal.fire({
          type: 'error',
          title: 'Error while trying to encode ad-slate',
          text: 'Please try again later or contact support. Error details: ' + errorDetails,
          confirmButtonText: 'OK'
        })
        progress = false
      }
      this._isMounted && this.setState({ encodingProgress: progress })
    }
    encodingWatcher()
  }

  handleConfirmReplaceProfile = async (event, profileWarning) => {
    let message = '<br />'

    if (profileWarning && profileWarning.length > 0) {
      message = '<div style="font-size: 12px; text-align: left;"><ul>'
      message += profileWarning.map(w => {
        let msg = w.message
        if (w.data) {
          msg += `: ${w.data.width}x${w.data.height}`
        }
        return `<li>${msg}</li>`
      }).join('')
      message += '</ul></div>'
    }

    showConfirmationMessage(() => {
      const currentChannel = this.state.currentChannel

      currentChannel.profile = this.state.newProfileInfo.data.profile
      // **TODO - this duplicates functionality from the service, but I can't think of a neat way to expose it right now.
      currentChannel.type = this.state.newProfileInfo.data.type

      currentChannel.cmaf = this.state.newProfileInfo.data.cmaf
      currentChannel.hasSeparateAudio = this.state.newProfileInfo.data.hasSeparateAudio
      currentChannel.isMultiPeriod = this.state.newProfileInfo.data.isMultiPeriod
      currentChannel.dynamicConfiguration = this.state.newProfileInfo.data.dynamicConfiguration
      currentChannel.fallbackSourceTags = this.state.newProfileInfo.data.fallbackSourceTags
      currentChannel.passThroughCaidSegmentation = this.state.newProfileInfo.data.passThroughCaidSegmentation
      currentChannel.cohortsEnabled = this.state.newProfileInfo.data.cohortsEnabled
      currentChannel.xApiKey = this.state.newProfileInfo.data.xApiKey
      currentChannel.cacheList = this.state.newProfileInfo.data.cacheList
      currentChannel.trueSourceUrl = this.state.newProfileInfo.trueSourceUrl
      currentChannel.prefetchDuration = this.state.newProfileInfo.data.prefetchDuration
      currentChannel.cacheTime = this.state.newProfileInfo.data.cacheTime
      this.dealWithNullableObjects(currentChannel)

      this._isMounted && this.setState({
        currentChannel,
        profileOpen: true,
        newProfileInfo: {
          ...this.state.newProfileInfo,
          analyzed: false
        }
      })
      if (this.state.newProfileInfo.data.isLive) {
        this.setAdSlate()
      }
    }, {
      title: 'Update Profile',
      html: `<p>Are you sure you want to replace the current profile?</p>${message}<textarea style="width: 100%; height: 200px;">${this.state.newProfileInfo.data.profile}</textarea>`,
      confirmButtonText: 'Replace',
      onCancel: () => {
        this._isMounted && this.setState({
          newProfileInfo: {
            analyzed: false
          }
        })
      }
    })
  }

  handleAnalyse = async event => {
    event.persist()
    try {
      this._isMounted && this.setState({
        analysing: true
      })

      const currentChannel = this.state.currentChannel
      let sourceURL = (currentChannel.sourceManifestUrl).split(':')
      currentChannel.http = sourceURL[0] === "http" ? true : false
      const proxyManifestUrl = await backend.getProxyManifestUrl(currentChannel.sourceManifestUrl)
      const manifestURL = proxyManifestUrl || currentChannel.sourceManifestUrl
      currentChannel.sourceManifestUrl = manifestURL
      const urlObject = new URL(manifestURL)
      const extension = path.extname(urlObject.pathname)
      if (extension === '.mp4' && (!currentChannel.profile || currentChannel.profile === '')) {
        this._isMounted && this.setState({
          analysing: false,
          profileOpen: true
        })

        // currentChannel.type = 'dash-vod'
        currentChannel.profile = '{}' // so the profile section will open

        showErrorMessage('We cannot automatically create encoding profiles for mp4s. If you know what the encoding profile should look like for your mp4s, then you can enter it manually in the "Profile" section.')

      } else if (extension === '.mrss') {
        this._isMounted && this.setState({
          analysing: false,
          profileOpen: false
        })
        showErrorMessage('You cannot directly use an MRSS feed as a channel source. Please use instead a url link to one typical playlist file  (.m3u8, .mpd) from the MRSS feed.')
      } else {
        const profileInfo = await backend.getProfile({
          sourceManifestUrl: manifestURL,
          apiKey: currentChannel.apiKey,
          manifestAnalysisOnly: this.state.manifestAnalysisOnly
        })
        if (profileInfo.error) {
          this._isMounted && this.setState({ analysing: false })
          let message
          switch (profileInfo.error.type) {
            case 'NO_MASTER_MANIFEST':
              message = `<p>Please provide a master manifest. Further details can be found under: 
              <a href="https://nowtilus.gitbook.io/serverside-ai/source-stream/packaging/hls-live-stream" target="_blank" rel="noopener noreferrer">
              https://nowtilus.gitbook.io/serverside-ai/source-stream/packaging/hls-live-stream</a></p>`
              break
            default:
              message = profileInfo.error.message
              break
          }
          showErrorMessage({ html: message })
        } else {

          if (this.props.id !== null) {
            this._isMounted && this.setState({
              analysing: false,
              newProfileInfo: {
                analyzed: true,
                data: {
                  profile: JSON.stringify(profileInfo.profile, null, 2),
                  type: `${profileInfo.manifestType}-${profileInfo.isLive ? 'live' : 'vod'}`,
                  cmaf: !!profileInfo.cmaf,
                  hasSeparateAudio: !!profileInfo.hasSeparateAudio,
                  isMultiPeriod: !!profileInfo.isMultiPeriod,
                  isLive: profileInfo.isLive
                }
              }
            })

            this.handleConfirmReplaceProfile(event, profileInfo.warnings)

            // if (profileInfo.warnings && profileInfo.warnings.length > 0) {
            //   let message = profileInfo.warnings.map(w => {
            //     let msg = w.message
            //     if (w.data) {
            //       msg += `: ${w.data.width}x${w.data.height}`
            //     }
            //     return `<p>${msg}</p>`
            //   }).join('')
            //   await showWarningMessage({ title: 'Warning', html: message })
            // }
            return
          }

          currentChannel.profile = JSON.stringify(profileInfo.profile, null, 2)
          // **TODO - this duplicates functionality from the service, but I can't think of a neat way to expose it right now.
          currentChannel.type = `${profileInfo.manifestType}-${profileInfo.isLive ? 'live' : 'vod'}`

          currentChannel.cmaf = !!profileInfo.cmaf
          currentChannel.hasSeparateAudio = !!profileInfo.hasSeparateAudio
          currentChannel.isMultiPeriod = !!profileInfo.isMultiPeriod
          currentChannel.dynamicConfiguration = !!profileInfo.dynamicConfiguration
          currentChannel.fallbackSourceTags = !!profileInfo.fallbackSourceTags
          currentChannel.passThroughCaidSegmentation = !!profileInfo.passThroughCaidSegmentation
          currentChannel.cohortsEnabled = !!profileInfo.cohortsEnabled
          currentChannel.xApiKey = profileInfo.xApiKey || ''
          currentChannel.cacheList = profileInfo.cacheList || ''
          currentChannel.trueSourceUrl = profileInfo.trueSourceUrl || ''
          currentChannel.prefetchDuration = profileInfo.prefetchDuration || 0
          currentChannel.cacheTime = profileInfo.cacheTime || 0
          this.dealWithNullableObjects(currentChannel)


          this._isMounted && this.setState({
            currentChannel,
            analysing: false,
            profileOpen: true
          })
          if (profileInfo.isLive) {
            this.setAdSlate()
          }

          if (profileInfo.warnings && profileInfo.warnings.length > 0) {
            let message = profileInfo.warnings.map(w => {
              let msg = w.message
              if (w.data) {
                msg += `: ${w.data.width}x${w.data.height}`
              }
              return `<p>${msg}</p>`
            }).join('')
            await showWarningMessage({ title: 'Warning', html: message })
          }
        }
      }
    } catch (err) {
      this._isMounted && this.setState({
        analysing: false
      })
      await showErrorMessage('Error while Analysing: ' + err.message)
    }
  }

  renderRoundQuestionMark = key => {
    if (!key) { return }
    if (!this.state.popovers[key]) { return }
    const open = !!this.state.popovers[key].anchorEl
    const id = 'id_' + key
    return <React.Fragment>
      <Button
        style={roundButtonStyle}
        aria-owns={open ? id : undefined}
        aria-haspopup="true"
        color="white"
        onMouseEnter={this.handlePopoverClick(key)}
        onMouseLeave={this.handlePopoverClose(key)}
        onClick={this.handlePopoverClick(key)} >?</Button>
      <Popover
        style={{ pointerEvents: 'none' }}
        id={id}
        open={open}
        anchorEl={this.state.popovers[key].anchorEl}
        onClose={this.handlePopoverClose(key)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        <Typography>{this.state.popovers[key].text}</Typography>
      </Popover>
    </React.Fragment>
  }

  handleTypeChange = e => {
    this.modifyCurrentChannel({ type: e.target.value + '-' + this.state.currentChannel.type.split('-')[1] })
  }

  handlePresetChange = e => this.modifyCurrentChannel({ presetId: e.target.value })

  handleProfileChange = e => this.modifyCurrentChannel({ profile: e.target.value })

  getChannelTypeShort = (channel) => {
    if (channel.type.includes('vod')) {
      return 'vod'
    }
    if (channel.type.includes('live')) {
      return 'live'
    }

    return ''
  }

  safeValueAsNumber = (target) => {
    //else "0" stays "0" and does not become 0
    return target.value === "0" ? 0 : (target.valueAsNumber || target.value)
  }

  toggleBeacon = (beaconName, enabled) => {
    console.log(beaconName, enabled)
    const fixedOrder = ["impression", "start", "firstQuartile", "midpoint", "thirdQuartile", "complete"]
    const events = (this.state.currentChannel.internalBeacons && this.state.currentChannel.internalBeacons.events)
    if (events) {
      const index = events.indexOf(beaconName)
      if (enabled && index < 0) {
        events.push(beaconName)
      }
      if (!enabled && index >= 0) {
        events.splice(index, 1)
      }
      events.sort((a, b) => fixedOrder.indexOf(a) - fixedOrder.indexOf(b))
      this.modifyCurrentChannel({ internalBeacons: { ...this.state.currentChannel.internalBeacons, baseUrl: this.state.baseUrl, events } })
    } else if (enabled) {
      this.modifyCurrentChannel({ internalBeacons: { baseUrl: this.state.baseUrl, events: [beaconName] } })
    }
  }

  isHMACEnabled () {
    let hmac = (this.state.currentChannel.authentication && this.state.currentChannel.authentication.hmac) || {}
    return hmac && Object.keys(hmac).length !== 0
  }

  changeHMACValue ({ secret, method, window, cookieDomain, isSecretEncrypted }) {
    const currentChannel = this.state.currentChannel
    currentChannel.authentication = {
      ...currentChannel.authentication,
      hmac: {
        secret,
        isSecretEncrypted,
        method,
        window,
        cookieDomain
      }
    }
    this.setState({ currentChannel })
  }

  render () {
    const xs = [4, 2, 8], sm = [4, 2, 8], md = [3, 2, 7], lg = [3, 2, 7]
    window.state = this.state
    const beaconsEvents = (this.state.currentChannel.internalBeacons && this.state.currentChannel.internalBeacons.events) || []
    const beaconsLiveList = ['hls-live', 'dash-live']

    const { goBack } = this.props

    const classes = this.props.classes

    const {
      id,
      type,
      sourceManifestUrl,
      name,
      outputUri,
      apiKey,
      adSource,
      adSlateManifestUrl,
      stitcher,
      profile,
      preRoll,
      enablePreRoll,
      dvrWindow,
      startAtDvrWindow,
      overwriteGroupId,
      suggestedPresentationDelay
    } = this.state.currentChannel

    const useAudioNormalization = typeof this.state.currentChannel.useAudioNormalization === 'undefined' ? false : this.state.currentChannel.useAudioNormalization
    const audioNormalization = this.state.currentChannel.audioNormalization || audioNormalizationDefaults
    const hmac = (this.state.currentChannel.authentication && this.state.currentChannel.authentication.hmac) || defaultHmac

    const hlsIndependentMediaSequences = typeof this.state.currentChannel.hlsIndependentMediaSequences !== 'undefined' ? this.state.currentChannel.hlsIndependentMediaSequences : false
    const hlsLegacyMediaSequenceHandling = typeof this.state.currentChannel.hlsLegacyMediaSequenceHandling !== 'undefined' ? this.state.currentChannel.hlsLegacyMediaSequenceHandling : false

    const timeShiftWindow = this.state.currentChannel.timeShiftWindow
    const cmaf = this.state.currentChannel.cmaf || false
    const http = this.state.currentChannel.http || false
    const isMultiPeriod = this.state.currentChannel.isMultiPeriod || false
    const dynamicConfiguration = this.state.currentChannel.dynamicConfiguration || false
    const fallbackSourceTags = this.state.currentChannel.fallbackSourceTags || false
    const passThroughCaidSegmentation = this.state.currentChannel.passThroughCaidSegmentation || false
    const cohortsEnabled = this.state.currentChannel.cohortsEnabled || false
    const xApiKey = this.state.currentChannel.xApiKey || ''
    const cacheList = this.state.currentChannel.cacheList || ''
    const trueSourceUrl = this.state.currentChannel.trueSourceUrl || ''
    const prefetchDuration = this.state.currentChannel.prefetchDuration || 0
    const cacheTime = this.state.currentChannel.cacheTime || 0
    const hasSeparateAudio = this.state.currentChannel.hasSeparateAudio || false
    const enableServerSideBeacons = beaconsLiveList.includes(type) ? (this.state.currentChannel.enableServerSideBeacons || false) : false
    const contentLocationReplacement = this.state.currentChannel.contentLocationReplacement || { regex: '', options: '' }
    const adLocationReplacement = this.state.currentChannel.adLocationReplacement || { regex: '', options: '' }
    const segmentationConfiguration = this.state.currentChannel.segmentationConfiguration || {}
    const generateTitleIdsUsingURL = this.state.currentChannel.generateTitleIdsUsingURL
    const hlsSuppressTags = this.state.currentChannel.hlsSuppressTags || []
    const preservePlaylistFilename = this.state.currentChannel.preservePlaylistFilename || false

    const {
      fetching,
      saveButtonLabel,
      disableBackButton,
      disableSaveButton,
      analysing,
      profileOpen,
      contentLocationOpen,
      adLocationOpen,
      segmentationConfigurationOpen,
      manifestAnalysisOnly,
      encoderSystem
    } = this.state

    const isBitmovin = encoderSystem && (encoderSystem.type === 'bitmovin')

    let channelType
    if (this.state.currentChannel.channelType) channelType = this.state.currentChannel.channelType || this.state.currentChannel.type
    else channelType = this.getChannelTypeShort(this.state.currentChannel)

    let validAdSourceSystems = []

    if (this._isMounted &&
      this.state.schema &&
      this.state.schema.adSource &&
      this.state.schema.adSource.properties &&
      this.state.schema.adSource.properties.system) {
      validAdSourceSystems = this.state.schema.adSource.properties.system.enum
    }

    let validSpliceModes = []
    if (this._isMounted &&
      this.state.schema &&
      this.state.schema.segmentationConfiguration &&
      this.state.schema.segmentationConfiguration.properties &&
      this.state.schema.segmentationConfiguration.properties.spliceMode) {
      validSpliceModes = this.state.schema.segmentationConfiguration.properties.spliceMode.enum
    }

    if (preRoll && preRoll.adSource && preRoll.adSource.params && typeof preRoll.adSource.params === 'object') {
      preRoll.adSource.params = JSON.stringify(preRoll.adSource.params, null, 2)
    }

    const backButton = <Button
      style={{ margin: '20px 10px' }}
      variant="contained"
      onClick={() => {
        goBack()
      }}
      disabled={disableBackButton}>Back</Button>

    let header
    let content

    if (this.state.status === 404) {
      content = (<CardBody><h4>Channel not found</h4>{backButton}</CardBody>)
    } else if (!adSource) {
      content = (<CardBody><h4>Error on page</h4>{backButton}</CardBody>)
    } else {
      header = <CardHeader color="rose" icon>
        <Grid container>
          <Grid item xs={12}>
            <CardIcon color="rose">
              <TV />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Channels Details</h4>
          </Grid>
        </Grid>
      </CardHeader>
      content = (<React.Fragment>
        <Card>
          {header}
          <CardBody>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Radio
                    checked={channelType === 'vod'}
                    onChange={() => this.modifyCurrentChannel({ channelType: 'vod' })}
                    value="vod"
                    name="radioChannelType_VOD"
                    aria-label="radioChannelType_VOD"
                    icon={
                      <FiberManualRecord
                        className={classes.radioUnchecked}
                      />
                    }
                    checkedIcon={
                      <FiberManualRecord
                        className={classes.radioChecked}
                      />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot
                }}
                label="VoD (The player will provide a MRSS playlist)"
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={channelType === 'live'}
                    onChange={() => this.modifyCurrentChannel({ channelType: 'live' })}
                    value="live"
                    name="radioChannelType_Live"
                    aria-label="radioChannelType_Live"
                    icon={
                      <FiberManualRecord
                        className={classes.radioUnchecked}
                      />
                    }
                    checkedIcon={
                      <FiberManualRecord
                        className={classes.radioChecked}
                      />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot
                }}
                label="Live (Providing a live-stream)"
              />
              <TextField
                id="name"
                label="Channel Name *"
                placeholder="Enter channel Name"
                type="search"
                className={classes.textField}
                margin="dense"
                fullWidth
                onChange={this.handleChange}
                value={name}
              />
              <TextField
                id="apiKey"
                label="API key (optional)"
                placeholder="Enter API key, if available"
                type="search"
                className={classes.textField}
                margin="dense"
                fullWidth
                onChange={this.handleChange}
                value={apiKey}
              />
              <div style={{ color: 'red' }}>
                {this.getErrors('apiKey')}
              </div>

              <div style={{ color: 'red' }}>{this.getErrors('name')}</div>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4} lg={3}>
                      <div style={{ float: 'left', marginTop: '15px' }}>HMAC</div>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('hmac')}</GridItem>
                    <GridItem xs={4} sm={4} md={2} lg={1}>
                      <Switch
                        disabled={false}
                        onChange={e => {
                          const authentication = this.state.currentChannel.authentication || {}
                          if (e.target.checked) {
                            authentication.hmac = authentication.hmac || defaultHmac
                          } else {
                            delete authentication.hmac
                          }
                          this.modifyCurrentChannel({ authentication })
                        }}
                        value="status"
                        color="primary"
                        size="small"
                        inputProps={{ 'aria-label': 'status checkbox' }}
                        checked={this.isHMACEnabled()}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                {this.isHMACEnabled() && <GridItem xs={12} sm={12} md={12} lg={12}>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4} lg={3}>
                      <div style={{ float: 'left', marginTop: '15px' }}>Secret</div>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('hmacSecret')}</GridItem>
                    <GridItem xs={4} sm={4} md={2} lg={1}>
                      <HMACSecretOption onSave={(result) => {
                        this.changeHMACValue({
                          ...hmac,
                          ...result
                        })
                      }} value={hmac.secret} />
                    </GridItem>
                  </GridContainer>
                </GridItem>}
                {this.isHMACEnabled() && <GridItem xs={12} sm={12} md={12} lg={12}>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4} lg={3}>
                      <div style={{ float: 'left', marginTop: '15px' }}>Method</div>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('hmacMethod')}</GridItem>
                    <GridItem xs={4} sm={4} md={2} lg={1}>
                      <TextField
                        id="hmacMethod"
                        // label="API key (optional)"
                        placeholder="Method"
                        type="text"
                        className={classes.textField}
                        margin="dense"
                        fullWidth
                        onChange={e => this.changeHMACValue({
                          ...hmac,
                          method: e.target.value
                        })}
                        value={hmac.method}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>}
                {this.isHMACEnabled() && <GridItem xs={12} sm={12} md={12} lg={12}>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4} lg={3}>
                      <div style={{ float: 'left', marginTop: '15px' }}>Window</div>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('hmacWindow')}</GridItem>
                    <GridItem xs={4} sm={4} md={2} lg={1}>
                      <TextField
                        id="hmacWindow"
                        // label="API key (optional)"
                        placeholder="Window"
                        type="text"
                        className={classes.textField}
                        margin="dense"
                        fullWidth
                        onChange={e => this.changeHMACValue({
                          ...hmac,
                          window: parseInt(e.target.value)
                        })}
                        value={hmac.window}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>}
                {this.isHMACEnabled() && <GridItem xs={12} sm={12} md={12} lg={12}>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4} lg={3}>
                      <div style={{ float: 'left', marginTop: '15px' }}>Cookie Domain</div>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('hmacCookieDomain')}</GridItem>
                    <GridItem xs={4} sm={4} md={2} lg={1}>
                      <TextField
                        id="hmacCookieDomain"
                        // label="API key (optional)"
                        placeholder="Cookie Domain"
                        type="text"
                        className={classes.textField}
                        margin="dense"
                        fullWidth
                        onChange={e => this.changeHMACValue({
                          ...hmac,
                          cookieDomain: e.target.value
                        })}
                        value={hmac.cookieDomain}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>}
              </GridContainer>

              {this.state.currentChannel.outputUri && <TextField
                id="outputUri"
                label="Output URI"
                type="search"
                className={classes.textField}
                margin="dense"
                fullWidth
                onChange={this.handleChange}
                value={outputUri}
                disabled={true}
              />
              }
              <div style={{ color: 'red' }}>{this.getErrors('outputUri')}</div>
              {channelType === 'live' &&  <GridContainer>
                <GridItem xs={12} sm={9} md={10} lg={10}>
                  {contentLocationOpen && <label style={{ marginTop: '20px' }}>Content Location Replacement</label>}
                </GridItem>
                <GridItem xs={12} sm={3} md={2} lg={2}>
                  <Button
                    style={{ float: 'right' }}
                    onClick={this.toggleContentLocation}
                    color={contentLocationOpen ? null : 'success'}>
                    {contentLocationOpen ? 'Hide' : 'Content Location Replacement'}
                  </Button>
                </GridItem>
              </GridContainer> }
              {contentLocationOpen && <Grid item xs={12}>
                <GridContainer>
                  <GridItem xs={12} sm={9} md={10} lg={10}>
                    <label>Regex</label>
                    <TextField
                      id="contentLocationReplacement.regex"
                      placeholder="Enter regex e.g. ^.*(\\$h.*token=)\\w*(&?.*)$ "
                      type="search"
                      className={classes.textField}
                      margin="dense"
                      fullWidth
                      onChange={this.handleChange}
                      value={contentLocationReplacement.regex || ''}
                    />
                    <div style={{ color: 'red' }}>
                      {this.getErrors('contentLocationReplacement.regex')}
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={9} md={10} lg={10}>
                    <label>Options</label>
                    <TextField
                      id="contentLocationReplacement.options"
                      type="search"
                      className={classes.textField}
                      placeholder="Enter regex options e.g. gi"
                      margin="dense"
                      fullWidth
                      onChange={this.handleChange}
                      value={contentLocationReplacement.options || ''}
                    />
                    <div style={{ color: 'red' }}>
                      {this.getErrors('contentLocationReplacement.options')}
                    </div>
                  </GridItem>
                </GridContainer>
              </Grid>
              }
              {channelType === 'live' && <GridContainer>
                <GridItem xs={12} sm={9} md={10} lg={10}>
                  {adLocationOpen && <label style={{ marginTop: '20px' }}>Ad Location Replacement</label>}
                </GridItem>
                <GridItem xs={12} sm={3} md={2} lg={2}>
                  <Button
                    style={{ float: 'right' }}
                    onClick={this.toggleAdLocation}
                    color={adLocationOpen ? null : 'success'}>
                    {adLocationOpen ? 'Hide' : 'Ad Location Replacement'}
                  </Button>
                </GridItem>
              </GridContainer>
              }
              {adLocationOpen && <Grid item xs={12}>
                <GridContainer>
                  <GridItem xs={12} sm={9} md={10} lg={10}>
                    <label>Regex</label>
                    <TextField
                      id="adLocationReplacement.regex"
                      placeholder="Enter regex e.g. ^.*(\\$h.*token=)\\w*(&?.*)$ "
                      type="search"
                      className={classes.textField}
                      margin="dense"
                      fullWidth
                      onChange={this.handleChange}
                      value={adLocationReplacement.regex || ''}
                    />
                    <div style={{ color: 'red' }}>
                      {this.getErrors('adLocationReplacement.regex')}
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={9} md={10} lg={10}>
                    <label>Options</label>
                    <TextField
                      id="adLocationReplacement.options"
                      type="search"
                      className={classes.textField}
                      placeholder="Enter regex options e.g. gi"
                      margin="dense"
                      fullWidth
                      onChange={this.handleChange}
                      value={adLocationReplacement.options || ''}
                    />
                    <div style={{ color: 'red' }}>
                      {this.getErrors('adLocationReplacement.options')}
                    </div>
                  </GridItem>
                </GridContainer>
              </Grid>
              }
            </Grid>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h5>Specify Source</h5>
            <Grid item xs={12}>
              <div>{channelUrlText[channelType]}</div>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={9} md={10} lg={10}>
                      <TextField
                        id="sourceManifestUrl"
                        name="sourceManifestUrl"
                        label="Channel URL *"
                        placeholder="Enter or paste a valid channel creation url"
                        type="search"
                        className={classes.textField}
                        margin="dense"
                        fullWidth
                        onChange={this.handleChange}
                        value={sourceManifestUrl.replace(/\s/g, '')}
                      />
                      <div style={{ color: 'red' }}>
                        {this.getErrors('sourceManifestUrl')}
                      </div>
                    </GridItem>

                    <GridItem xs={12} sm={3} md={2} lg={2}>
                      <Button
                        style={{ float: 'right' }}
                        disabled={!sourceManifestUrl || analysing}
                        type={'submit'}
                        color={'info'}
                        onClick={async (e) => { this.state.newProfileInfo.analyzed ? this.handleConfirmReplaceProfile(e) : this.handleAnalyse(e) }}
                      >
                        {analysing ? 'Analysing...' : (this.state.newProfileInfo.analyzed ? 'Update Profile' : 'Analyse')}
                        <Spinner
                          size={12}
                          color={'#FFF'}
                          radius={2}
                          visible={analysing}
                        />
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>

              </GridContainer>
              <div>
                example:
                https://live.unified-streaming.com/scte35/scte35.isml/.mpd
              </div>
              <GridContainer>
                <GridItem><div><Switch
                  disabled={false}
                  checked={manifestAnalysisOnly}
                  onChange={e => this.setState({ manifestAnalysisOnly: e.target.checked })}
                  value="status"
                  color="primary"
                  size="small"
                  inputProps={{ 'aria-label': 'status checkbox' }}
                /> Manifest analysis only</div></GridItem>
                <GridItem><div><Switch
                  disabled={false}
                  checked={cmaf}
                  onChange={e => this.modifyCurrentChannel({ cmaf: e.target.checked }, false)}
                  value="status"
                  color="primary"
                  size="small"
                  inputProps={{ 'aria-label': 'status checkbox' }}
                /> CMAF stream</div></GridItem>
                { !type.includes('dash') && <GridItem><div><Switch
                  disabled={false}
                  checked={hasSeparateAudio}
                  onChange={e => this.modifyCurrentChannel({ hasSeparateAudio: e.target.checked }, false)}
                  value="status"
                  color="primary"
                  size="small"
                  inputProps={{ 'aria-label': 'status checkbox' }}
                /> Stream has separate audio playlist</div></GridItem> }
                <GridItem><div><Switch
                  disabled={false}
                  checked={http}
                  onChange={e => this.modifyCurrentChannel({ http: e.target.checked }, false)}
                  value="status"
                  color="primary"
                  size="small"
                  inputProps={{ 'aria-label': 'status checkbox' }}
                /> http stream</div></GridItem>
                {type.includes('dash') &&
                  <GridItem><div><Switch
                    disabled={false}
                    checked={isMultiPeriod}
                    onChange={e => this.modifyCurrentChannel({ isMultiPeriod: e.target.checked }, false)}
                    value="status"
                    color="primary"
                    size="small"
                    inputProps={{ 'aria-label': 'status checkbox' }}
                  />multi period</div></GridItem>
                }
                {channelType === 'live' &&
                  <GridItem><div><Switch
                    disabled={false}
                    checked={dynamicConfiguration}
                    onChange={e => this.modifyCurrentChannel({ dynamicConfiguration: e.target.checked }, false)}
                    value="status"
                    color="primary"
                    size="small"
                    inputProps={{ 'aria-label': 'status checkbox' }}
                  />Dynamic configuration</div></GridItem>
                }
                {channelType === 'live' && dynamicConfiguration &&
                  <GridItem><div><Switch
                    disabled={false}
                    checked={fallbackSourceTags}
                    onChange={e => this.modifyCurrentChannel({ fallbackSourceTags: e.target.checked }, false)}
                    value="status"
                    color="primary"
                    size="small"
                    inputProps={{ 'aria-label': 'status checkbox' }}
                  />Fallback source tags</div></GridItem>
                }
                {channelType === 'live' && dynamicConfiguration &&
                  <GridItem><div><Switch
                    disabled={false}
                    checked={passThroughCaidSegmentation}
                    onChange={e => this.modifyCurrentChannel({ passThroughCaidSegmentation: e.target.checked }, false)}
                    value="status"
                    color="primary"
                    size="small"
                    inputProps={{ 'aria-label': 'status checkbox' }}
                  />Pass through caid segmentation</div></GridItem>
                }
                {channelType === 'live' && dynamicConfiguration &&
                  <GridItem><div><Switch
                    disabled={false}
                    checked={cohortsEnabled}
                    onChange={e => this.modifyCurrentChannel({ cohortsEnabled: e.target.checked }, false)}
                    value="status"
                    color="primary"
                    size="small"
                    inputProps={{ 'aria-label': 'status checkbox' }}
                  />Cohorts enabled</div></GridItem>
                }
              </GridContainer>
              {channelType === 'live' && dynamicConfiguration &&
                <Grid item xs={12}>
                  <TextField
                    id="trueSourceUrl"
                    label="True Source URL"
                    placeholder="Enter true source url here"
                    type="search"
                    className={classes.textField}
                    margin="dense"
                    fullWidth
                    onChange={this.handleChange}
                    value={trueSourceUrl || ''}
                  />
                  <TextField
                    id="xApiKey"
                    label="X-API-KEY"
                    placeholder="Enter X-API-KEY here"
                    type="search"
                    className={classes.textField}
                    margin="dense"
                    fullWidth
                    onChange={this.handleChange}
                    value={xApiKey || ''}
                  />
                  <TextField
                    id="prefetchDuration"
                    label="Prefetch Duration"
                    placeholder="Enter Prefetch Duration here"
                    type="number"
                    step="0.1"
                    className={classes.textField}
                    margin="dense"
                    fullWidth
                    onChange={this.handleChange}
                    value={prefetchDuration || 0}
                  />
                  <TextField
                    id="cacheTime"
                    label="Cache time/segment length"
                    placeholder="Enter Cache time here"
                    type="number"
                    step="0.1"
                    className={classes.textField}
                    margin="dense"
                    fullWidth
                    onChange={this.handleChange}
                    value={cacheTime || 0}
                  />
                  { cohortsEnabled && dynamicConfiguration &&
                    <TextField
                    id="cacheList"
                    label="Cache key list"
                    placeholder="<key>,<key>"
                    type="search"
                    className={classes.textField}
                    margin="dense"
                    fullWidth
                    onChange={this.handleChange}
                    value={cacheList || ''}
                  />
                  }
                </Grid>}
            </Grid>
          </CardBody>
        </Card>
        {hasSeparateAudio && isBitmovin && type === 'hls-vod' && <Card>
          <CardBody>
            <Grid item xs={12}>
              <h5>Overwrite Audio GroupID</h5>
              <div>This option is sometimes needed in case of HLS VoD with Bitmovin encoder</div>
              <TextField
                id="overwriteGroupId"
                label="New audio group id"
                placeholder="Enter new audio group id here"
                type="search"
                className={classes.textField}
                margin="dense"
                fullWidth
                onChange={this.handleChange}
                value={overwriteGroupId || ''}
              />
            </Grid>
          </CardBody>
        </Card>}
        {
          profile && <Card>
            <CardBody>
              <Grid item xs={12}>
                <GridContainer>
                  <GridItem xs={6} sm={9} md={10} lg={10}>
                    <h5>Profile</h5>
                  </GridItem>

                </GridContainer>
              </Grid>
              <Grid item xs={12}>
                <GridContainer>
                  <GridItem xs={6} sm={9} md={10} lg={10}>
                    <div>If you have provided an example above the profile to
                      condition your ads have already been configured and you can move on with the next step.
                      If you had no example, or want to fine tune your profile, you can use the editor below.</div>
                  </GridItem>
                  <GridItem xs={6} sm={3} md={2} lg={2}>
                    <Button
                      style={{ float: 'right' }}
                      onClick={this.toggleProfile}
                      color={profileOpen ? null : 'success'}>
                      {profileOpen ? 'Hide' : 'View'}
                    </Button>

                  </GridItem>
                </GridContainer>
                {profileOpen && <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={8} lg={8}>
                        <ResourceEnumDropdownWithStatus
                          style={{ width: '100%' }}
                          classes={classes}
                          handleChange={this.handleTypeChange}
                          value={this.state.currentChannel.type.split('-')[0]}
                          data={streamTypes}
                          inputLabelText={'Stream Type:'}
                          noEmpty={true}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>}
              </Grid>
              {profileOpen && <Grid item xs={12}>
                <GridContainer style={{ marginTop: '10px', marginBottom: '20px' }}>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      multiline
                      id="profile"
                      label="Encoding Profile"
                      type="search"
                      className={classes.textField}
                      margin="dense"
                      fullWidth
                      onChange={this.handleProfileChange}
                      value={profile}
                    />
                    <div style={{ color: 'red' }}>
                      {this.getErrors('profile')}
                    </div>
                  </GridItem>
                </GridContainer>
              </Grid>}
            </CardBody>
          </Card>
        }

        <Card>
          <CardBody>
            <Grid item xs={12}>
              <h5>Ad Server</h5>
              <div>Please choose one of the provided ad servers and
                fill out the necessary parameters. If you are in doubt, or have not set up your ad-server account yet,
                feel free to use Serverside.ai to get a demo-campaign with example advertisements.</div>

              <TextField
                id="adSource.url"
                label="Ad Server URL (optional)"
                placeholder="Enter Ad Server URL, if available"
                type="search"
                className={classes.textField}
                margin="dense"
                fullWidth
                onChange={this.handleChange}
                value={adSource.url}
              />
              <div style={{ color: 'red' }}>
                {this.getErrors('adServerUrl')}
              </div>

              <ResourceEnumDropdown
                name="adSource.system"
                placeholder="Choose Ad Server Type"
                enumArray={validAdSourceSystems}
                theKey={'system'}
                data={this.state.currentChannel.adSource}
                handleChange={this.handleChange}
                required={true}
                classes={classes}
              />
              <div style={{ color: 'red' }}>
                {this.getErrors('adServerType')}
              </div>

              <label>Ad Server Parameter</label>
              <TextField
                multiline
                id="adSource.params"
                label="ad source parameters (optional)"
                placeholder="Enter ad source params, if available"
                type="search"
                className={classes.textField}
                margin="dense"
                fullWidth
                onChange={this.handleChange}
                value={adSource.params}
              />
              <div style={{ color: 'red' }}>
                {this.getErrors('adSource.params')}
              </div>
            </Grid>
          </CardBody>
        </Card>
        {
          type === 'hls-live' && <Card>
            <CardBody>
              <Grid item xs={12}>
                <h5>Preroll</h5>
                <div>In this section a preroll ad-pod can be configured that is displayed before your live-stream starts.</div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4} lg={3}>
                        <div style={{ float: 'left', marginTop: '15px' }}>Enable Preroll</div>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('enablePreRoll')}</GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>
                        <Switch
                          disabled={false}
                          checked={enablePreRoll}
                          onChange={e => this.modifyCurrentChannel({ enablePreRoll: e.target.checked })}
                          value="status"
                          color="primary"
                          size="small"
                          inputProps={{ 'aria-label': 'status checkbox' }}
                        />
                      </GridItem>
                    </GridContainer>
                    {
                      enablePreRoll === true &&
                      <div>
                        <GridContainer>
                          <GridItem xs={4} sm={4} md={4} lg={3}>
                            <div style={{ float: 'left', marginTop: '15px' }}>Maximum Duration</div>
                          </GridItem>
                          <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('preRollMaxDuration')}</GridItem>
                          <GridItem xs={4} sm={4} md={2} lg={1}>
                            <TextField
                              id="preRoll.maxDuration"
                              type='number'
                              className={classes.textField}
                              margin="dense"
                              onChange={e => this.modifyCurrentChannel({ preRoll: { ...preRoll, maxDuration: this.safeValueAsNumber(e.target) } })}
                              value={preRoll.maxDuration}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={4} sm={4} md={4} lg={3}>
                            <div style={{ float: 'left', marginTop: '15px' }}>Maximum Count</div>
                          </GridItem>
                          <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('preRollMaxCount')}</GridItem>
                          <GridItem xs={4} sm={4} md={2} lg={1}>
                            <TextField
                              id="preRoll.maxCount"
                              type='number'
                              className={classes.textField}
                              margin="dense"
                              onChange={e => this.modifyCurrentChannel({ preRoll: { ...preRoll, maxCount: this.safeValueAsNumber(e.target) } })}
                              value={preRoll.maxCount}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={4} sm={4} md={4} lg={3}>
                            <div style={{ float: 'left', marginTop: '15px' }}>Use dedicated ad-server for pre roll</div>
                          </GridItem>
                          <GridItem xs={4} sm={4} md={2} lg={1}>
                            <Switch
                              disabled={false}
                              checked={preRoll.useDifferentAdServer}
                              onChange={e => this.modifyCurrentChannel({ preRoll: { ...preRoll, useDifferentAdServer: e.target.checked } })}
                              value="status"
                              color="primary"
                              size="small"
                              inputProps={{ 'aria-label': 'status checkbox' }}
                            />
                          </GridItem>
                          {preRoll.useDifferentAdServer === true &&
                            <GridItem item xs={12}>
                              <h5>Preroll Ad Server</h5>
                              <div></div>
                              <div style={{ color: 'red' }}>
                                {this.getErrors('apiKey')}
                              </div>

                              <TextField
                                id="preRoll.adSource.url"
                                label="Ad Server URL"
                                placeholder="Enter Ad Server URL"
                                type="search"
                                className={classes.textField}
                                margin="dense"
                                fullWidth
                                onChange={this.handleChange}
                                value={preRoll.adSource.url}
                              />
                              <div style={{ color: 'red' }}>
                                {this.getErrors('adServerUrl')}
                              </div>

                              <ResourceEnumDropdown
                                name="preRoll.adSource.system"
                                placeholder="Choose Ad Server Type"
                                enumArray={validAdSourceSystems}
                                theKey={'system'}
                                data={this.state.currentChannel.preRoll.adSource}
                                handleChange={this.handleChange}
                                required={true}
                                classes={classes}
                              />
                              <div style={{ color: 'red' }}>
                                {this.getErrors('adServerType')}
                              </div>

                              <label>Ad Server Parameter</label>
                              <TextField
                                multiline
                                id="preRoll.adSource.params"
                                label="ad source parameters (optional)"
                                placeholder="Enter ad source params, if available"
                                type="search"
                                className={classes.textField}
                                margin="dense"
                                fullWidth
                                onChange={this.handleChange}
                                value={preRoll.adSource.params}
                              />
                              <div style={{ color: 'red' }}>
                                {this.getErrors('adSource.params')}
                              </div>
                            </GridItem>
                          }
                        </GridContainer>
                      </div>
                    }
                  </GridItem>
                </GridContainer>
              </Grid>
            </CardBody>
          </Card>
        }{
          type === 'dash-live' &&
          <Card>
            <CardBody>
              <Grid>
                <h5>Suggested Presentation Delay</h5>
                <GridContainer>
                  <GridItem xs={12} sm={9} md={10} lg={10}>
                    <TextField
                      id="suggestedPresentationDelay"
                      name="suggestedPresentationDelay"
                      type="number"
                      placeholder="Enter value here"
                      className={classes.textField}
                      margin="dense"
                      onChange={e => this.modifyCurrentChannel({ suggestedPresentationDelay: e.target.value ? +e.target.value : 0 })}
                      value={suggestedPresentationDelay}
                    />
                  </GridItem>

                </GridContainer>
              </Grid>
            </CardBody>
          </Card>
        }
        <Card>
          <CardBody>
            <Grid>
              <GridContainer>
                <GridItem xs={12} sm={9} md={10} lg={10}>
                  {segmentationConfigurationOpen && <label style={{ marginTop: '20px' }}>Segmentation Configuration</label>}
                </GridItem>
                <GridItem xs={12} sm={3} md={2} lg={2}>
                  <Button
                    style={{ float: 'right' }}
                    onClick={this.toggleSegmentationConfiguration}
                    color={segmentationConfigurationOpen ? null : 'info'}>
                    {segmentationConfigurationOpen ? 'Hide' : 'Segmentation Configuration'}
                  </Button>
                </GridItem>
              </GridContainer>
              {segmentationConfigurationOpen && <Grid>
                <GridContainer>
                  <GridItem xs={12} sm={9} md={10} lg={10}>
                    <ResourceEnumDropdown
                      name="segmentationConfiguration.spliceMode"
                      id="segmentationConfiguration.spliceMode"
                      placeholder="Choose splice mode"
                      enumArray={validSpliceModes}
                      theKey={'spliceMode'}
                      data={segmentationConfiguration}
                      handleChange={e => this.modifyCurrentChannel({ segmentationConfiguration: { ...segmentationConfiguration, spliceMode: e.target.value } }, false)}
                      required={true}
                      classes={classes}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={4} sm={4} md={4} lg={3}>
                    <div style={{ float: 'left', marginTop: '15px' }}>Segmentation Type Ids Start</div>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('segmentationConfiguration.segmentationTypeIdsStart')}</GridItem>
                  <GridItem xs={12} sm={4} md={6} lg={8}>
                    <TextField
                      id="segmentationConfiguration.segmentationTypeIdsStart"
                      name="segmentationConfiguration.segmentationTypeIdsStart"
                      placeholder="<timeSignal>,<timeSignal> ..."
                      className={classes.textField}
                      margin="dense"
                      onChange={e => this.modifyCurrentChannel(
                        {
                          segmentationConfiguration: {
                            ...segmentationConfiguration,
                            segmentationTypeIdsStart: e.target.value
                          }
                        })}
                      value={segmentationConfiguration.segmentationTypeIdsStart || ''}
                    /></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={4} sm={4} md={4} lg={3}>
                    <div style={{ float: 'left', marginTop: '15px' }}>Segmentation Type Ids End</div>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('segmentationConfiguration.segmentationTypeIdsEnd')}</GridItem>
                  <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                    <TextField
                      id="segmentationConfiguration.segmentationTypeIdsEnd"
                      name="segmentationConfiguration.segmentationTypeIdsEnd"
                      placeholder="<timeSignal>,<timeSignal> ..."
                      className={classes.textField}
                      margin="dense"
                      onChange={e => this.modifyCurrentChannel(
                        {
                          segmentationConfiguration: {
                            ...segmentationConfiguration,
                            segmentationTypeIdsEnd: e.target.value
                          }
                        })}
                      value={segmentationConfiguration.segmentationTypeIdsEnd || ''}
                    /></GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={4} sm={4} md={4} lg={3}>
                    <div style={{ float: 'left', marginTop: '15px' }}>Scte35 is Hex not Base64 </div>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('segmentationConfiguration.scte35IsHexNotBase64')}</GridItem>
                  <GridItem xs={4} sm={4} md={2} lg={1}>
                    <Switch
                      disabled={false}
                      checked={segmentationConfiguration.scte35IsHexNotBase64}
                      onChange={e => this.modifyCurrentChannel({
                        segmentationConfiguration: {
                          ...segmentationConfiguration,
                          scte35IsHexNotBase64: e.target.checked
                        }
                      })}
                      value="status"
                      color="primary"
                      size="small"
                      inputProps={{ 'aria-label': 'status checkbox' }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={4} sm={4} md={4} lg={3}>
                    <div style={{ float: 'left', marginTop: '15px' }}>Duration from Scte35 over manifest </div>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('segmentationConfiguration.durationFromScte35OverManifest')}</GridItem>
                  <GridItem xs={4} sm={4} md={2} lg={1}>
                    <Switch
                      disabled={false}
                      checked={segmentationConfiguration.durationFromScte35OverManifest}
                      onChange={e => this.modifyCurrentChannel({
                        segmentationConfiguration: {
                          ...segmentationConfiguration,
                          durationFromScte35OverManifest: e.target.checked
                        }
                      })}
                      value="status"
                      color="primary"
                      size="small"
                      inputProps={{ 'aria-label': 'status checkbox' }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={4} sm={4} md={4} lg={3}>
                    <div style={{ float: 'left', marginTop: '15px' }}>Default Duration</div>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('segmentationConfiguration.defaultDuration')}</GridItem>
                  <GridItem xs={4} sm={4} md={2} lg={1}>
                    <TextField
                      id="segmentationConfiguration.defaultDuration"
                      type='number'
                      className={classes.textField}
                      margin="dense"
                      onChange={e => this.modifyCurrentChannel({ segmentationConfiguration: { ...segmentationConfiguration, defaultDuration: this.safeValueAsNumber(e.target) } }, false)}
                      value={segmentationConfiguration.defaultDuration || ''}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={4} sm={4} md={4} lg={3}>
                    <div style={{ float: 'left', marginTop: '15px' }}>Fallback source tags</div>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('segmentationConfiguration.fallbackSourceTags')}</GridItem>
                  <GridItem xs={4} sm={4} md={2} lg={1}>
                    <Switch
                      disabled={false}
                      checked={segmentationConfiguration.fallbackSourceTags || false}
                      onChange={e => this.modifyCurrentChannel({
                        segmentationConfiguration: {
                          ...segmentationConfiguration,
                          fallbackSourceTags: e.target.checked
                        }
                      })}
                      value="status"
                      color="primary"
                      size="small"
                      inputProps={{ 'aria-label': 'status checkbox' }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={3} md={2} lg={2}>
                    <Button
                      style={{ float: 'left' }}
                      onClick={() => {
                        this.clearSegmentationConfiguration()
                      }}
                      color={'warning'}>
                      Clear
                    </Button>
                  </GridItem>
                </GridContainer>
              </Grid>}
            </Grid>
          </CardBody>
        </Card>

        {
          beaconsLiveList.includes(type) && <Card>
            <CardBody>
              <Grid item xs={12}>
                <h5>Ad beacons</h5>
                <div>In this section you can choose to use server-side ad-beacons, instead of the default client-side beacons</div>
                <GridContainer>

                  <GridItem><div><Switch
                    disabled={false}
                    checked={enableServerSideBeacons}
                    onChange={e => this.modifyCurrentChannel({ enableServerSideBeacons: e.target.checked }, false)}
                    value="status"
                    color="primary"
                    size="small"
                    inputProps={{ 'aria-label': 'status checkbox' }}
                  /> Use server-side ad-beacons</div></GridItem>
                </GridContainer>
              </Grid>
            </CardBody>
          </Card>
        }
        {!enableServerSideBeacons &&
          <Card>
            <CardBody>
              <Grid item xs={12}>
                <h5>Beacons Settings</h5>
                <div>Here you can switch beacons on and off</div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4} lg={3}><div style={{ float: 'left', marginTop: '15px' }}>Impression</div></GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('beaconImpression')}</GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>
                        <Switch
                          disabled={false}
                          checked={beaconsEvents.indexOf('impression') >= 0}
                          onChange={e => this.toggleBeacon('impression', e.target.checked)}
                          value="status"
                          color="primary"
                          size="small"
                          inputProps={{ 'aria-label': 'status checkbox' }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4} lg={3}><div style={{ float: 'left', marginTop: '15px' }}>Start</div></GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('beaconStart')}</GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>
                        <Switch
                          disabled={false}
                          checked={beaconsEvents.indexOf('start') >= 0}
                          onChange={e => this.toggleBeacon('start', e.target.checked)}
                          value="status"
                          color="primary"
                          size="small"
                          inputProps={{ 'aria-label': 'status checkbox' }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4} lg={3}><div style={{ float: 'left', marginTop: '15px' }}>First Quartile</div></GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('beaconFirstQuartile')}</GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>
                        <Switch
                          disabled={false}
                          checked={beaconsEvents.indexOf('firstQuartile') >= 0}
                          onChange={e => this.toggleBeacon('firstQuartile', e.target.checked)}
                          value="status"
                          color="primary"
                          size="small"
                          inputProps={{ 'aria-label': 'status checkbox' }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4} lg={3}><div style={{ float: 'left', marginTop: '15px' }}>Midpoint</div></GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('beaconMidpoint')}</GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>
                        <Switch
                          disabled={false}
                          checked={beaconsEvents.indexOf('midpoint') >= 0}
                          onChange={e => this.toggleBeacon('midpoint', e.target.checked)}
                          value="status"
                          color="primary"
                          size="small"
                          inputProps={{ 'aria-label': 'status checkbox' }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4} lg={3}><div style={{ float: 'left', marginTop: '15px' }}>Third Quartile</div></GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('beaconThirdQuartile')}</GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>
                        <Switch
                          disabled={false}
                          checked={beaconsEvents.indexOf('thirdQuartile') >= 0}
                          onChange={e => this.toggleBeacon('thirdQuartile', e.target.checked)}
                          value="status"
                          color="primary"
                          size="small"
                          inputProps={{ 'aria-label': 'status checkbox' }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4} lg={3}><div style={{ float: 'left', marginTop: '15px' }}>Complete</div></GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('beaconComplete')}</GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>
                        <Switch
                          disabled={false}
                          checked={beaconsEvents.indexOf('complete') >= 0}
                          onChange={e => this.toggleBeacon('complete', e.target.checked)}
                          value="status"
                          color="primary"
                          size="small"
                          inputProps={{ 'aria-label': 'status checkbox' }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4} lg={3}><div style={{ float: 'left', marginTop: '15px' }}>Click</div></GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('beaconClick')}</GridItem>
                      <GridItem xs={4} sm={4} md={2} lg={1}>
                        <Switch
                          disabled={false}
                          checked={beaconsEvents.indexOf('click') >= 0}
                          onChange={e => this.toggleBeacon('click', e.target.checked)}
                          value="status"
                          color="primary"
                          size="small"
                          inputProps={{ 'aria-label': 'status checkbox' }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </Grid>
            </CardBody>
          </Card>
        }
        {
          channelType === 'live' && <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4} lg={3}>
                  <div style={{ float: 'left', marginTop: '15px' }}>Timeshift Window</div>
                </GridItem>
                <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('timeShiftWindow')}</GridItem>
                <GridItem xs={4} sm={4} md={2} lg={1}>
                  <TextField
                    id="timeShiftWindow"
                    type='number'
                    InputProps={{
                      inputProps: {
                        max: 3600, min: 0
                      }
                    }}
                    className={classes.textField}
                    margin="dense"
                    onChange={e => this.modifyCurrentChannel({ timeShiftWindow: this.safeValueAsNumber(e.target) }, false)}
                    value={timeShiftWindow}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4} lg={3}>
                  <div style={{ float: 'left', marginTop: '15px' }}>Playlist size</div>
                </GridItem>
                <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('dvrWindow')}</GridItem>
                <GridItem xs={4} sm={4} md={2} lg={1}>
                  <TextField
                    id="dvrWindow"
                    type='number'
                    InputProps={{
                      inputProps: {
                        max: 3600, min: 0
                      }
                    }}
                    className={classes.textField}
                    margin="dense"
                    onChange={e => this.modifyCurrentChannel({ dvrWindow: this.safeValueAsNumber(e.target) }, false)}
                    value={dvrWindow}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={2} lg={2}><div style={{ float: 'left', marginTop: '15px' }}>Growing Playlist</div></GridItem>
                <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('startAtDvrWindow')}</GridItem>
                <Switch
                    disabled={false}
                    checked={!startAtDvrWindow}
                    onChange={e => {
                      const currentChannel = this.state.currentChannel
                      currentChannel.startAtDvrWindow = !e.target.checked
                      this.setState({ currentChannel })
                    }}
                    value="status"
                    color="primary"
                    size="small"
                    inputProps={{ 'aria-label': 'status checkbox' }}
                  />

              </GridContainer>
              {type === 'hls-live' && <GridContainer>
                <GridItem xs={4} sm={4} md={4} lg={3}><div style={{ float: 'left', marginTop: '15px' }}>Independent Media Sequences</div></GridItem>
                <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('hlsIndependentMediaSequences')}</GridItem>
                <GridItem xs={4} sm={4} md={2} lg={1}>
                  <Switch
                    disabled={false}
                    checked={hlsIndependentMediaSequences}
                    onChange={e => {
                      const currentChannel = this.state.currentChannel
                      currentChannel.hlsIndependentMediaSequences = e.target.checked
                      if (e.target.checked && currentChannel.hlsLegacyMediaSequenceHandling) {
                        currentChannel.hlsLegacyMediaSequenceHandling = false
                      }
                      this.setState({ currentChannel })
                    }}
                    value="status"
                    color="primary"
                    size="small"
                    inputProps={{ 'aria-label': 'status checkbox' }}
                  />
                </GridItem>
              </GridContainer>}
              {type === 'hls-live' && <GridContainer>
                <GridItem xs={4} sm={4} md={4} lg={3}><div style={{ float: 'left', marginTop: '15px' }}>Legacy Media Sequence Handling</div></GridItem>
                <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('hlsLegacyMediaSequenceHandling')}</GridItem>
                <GridItem xs={4} sm={4} md={2} lg={1}>
                  <Switch
                    disabled={false}
                    checked={hlsLegacyMediaSequenceHandling}
                    onChange={e => {
                      const currentChannel = this.state.currentChannel
                      currentChannel.hlsLegacyMediaSequenceHandling = e.target.checked
                      if (e.target.checked && currentChannel.hlsIndependentMediaSequences) {
                        currentChannel.hlsIndependentMediaSequences = false
                      }
                      this.setState({ currentChannel })
                    }}
                    value="status"
                    color="primary"
                    size="small"
                    inputProps={{ 'aria-label': 'status checkbox' }}
                  />
                </GridItem>
              </GridContainer>}
              {type === 'hls-live' && <GridContainer>
                <GridItem xs={4} sm={4} md={4} lg={3}><div style={{ float: 'left', marginTop: '15px' }}>Suppress HLS Tags</div></GridItem>
                <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('suppressHLSTags')}</GridItem>
                <GridItem xs={4} sm={4} md={2} lg={1}>
                  <ResourceSuppressHLSTagsDropdown
                    style={{ width: '350px' }}
                    classes={classes}
                    handleChange={(value) => {
                      this.setState({
                        currentChannel: {
                          ...this.state.currentChannel,
                          hlsSuppressTags: value
                        }
                      })
                    }}
                    value={hlsSuppressTags}
                    data={[
                      'PROGRAM-DATE-TIME',
                      'ALLOW-CACHE',
                      'DATERANGE',
                      'I-FRAMES-ONLY',
                      'I-FRAME-STREAM-INF',
                      'SESSION-DATA',
                      'INDEPENDENT-SEGMENTS',
                      'DISCONTINUITY-SEQUENCE',
                      'START',
                      'GAP'
                    ]}
                    inputLabelText={'Suppress HLS Tags'}
                  />
                </GridItem>
              </GridContainer>}
              {type === 'hls-live' && <GridContainer>
                <GridItem xs={4} sm={4} md={4} lg={3}><div style={{ float: 'left', marginTop: '15px' }}>Legacy Media Sequence Handling</div></GridItem>
                <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('hlsLegacyMediaSequenceHandling')}</GridItem>
                <GridItem xs={4} sm={4} md={2} lg={1}>
                  <Switch
                    disabled={false}
                    checked={hlsLegacyMediaSequenceHandling}
                    onChange={e => {
                      const currentChannel = this.state.currentChannel
                      currentChannel.hlsLegacyMediaSequenceHandling = e.target.checked
                      if (e.target.checked && currentChannel.hlsIndependentMediaSequences) {
                        currentChannel.hlsIndependentMediaSequences = false
                      }
                      this.setState({ currentChannel })
                    }}
                    value="status"
                    color="primary"
                    size="small"
                    inputProps={{ 'aria-label': 'status checkbox' }}
                  />
                </GridItem>
              </GridContainer>}
              {type === 'hls-live' && <GridContainer>
                <GridItem xs={4} sm={4} md={4} lg={3}><div style={{ float: 'left', marginTop: '15px' }}>Preserve playlist name</div></GridItem>
                <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('preservePlaylistFilename')}</GridItem>
                <GridItem xs={4} sm={4} md={2} lg={1}><Switch
                  disabled={false}
                  checked={preservePlaylistFilename || false}
                  onChange={e => this.modifyCurrentChannel({ preservePlaylistFilename: e.target.checked }, false)}
                  value={preservePlaylistFilename || false}
                  color="primary"
                  size="small"
                  inputProps={{ 'aria-label': 'status checkbox' }}
                /></GridItem>
              </GridContainer>}
            </CardBody>
          </Card>
        }
        {
          channelType === 'vod' && <Card>
            <CardBody>
              <Grid item xs={12}>
                <h5>Advanced Settings</h5>
                <div>This section provides advanced settings to fine tune your channel configuration.</div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                      <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}><div style={{ float: 'left', marginTop: '15px' }}>Custom http proxy url</div></GridItem>
                      <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>{this.renderRoundQuestionMark('httpProxyUrl')}</GridItem>
                      <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                        <TextField
                          id="stitcher.httpProxyUrl"
                          name="stitcher.httpProxyUrl"
                          label="custom http proxy url"
                          placeholder="Enter or paste a valid http proxy url"
                          type="search"
                          className={classes.textField}
                          margin="dense"
                          fullWidth
                          onChange={e => this.modifyCurrentChannel({ stitcher: { ...stitcher, httpProxyUrl: e.target.value } })}
                          value={stitcher.httpProxyUrl || ''}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}><div style={{ float: 'left', marginTop: '15px' }}>Use relative segment url</div></GridItem>
                      <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>{this.renderRoundQuestionMark('shouldUseRelativeSegmentUrl')}</GridItem>
                      <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                        <Switch
                          disabled={false}
                          checked={stitcher.shouldUseRelativeSegmentUrl}
                          onChange={e => this.modifyCurrentChannel({ stitcher: { ...stitcher, shouldUseRelativeSegmentUrl: e.target.checked } })}
                          value="status"
                          color="primary"
                          size="small"
                          inputProps={{ 'aria-label': 'status checkbox' }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}><div style={{ float: 'left', marginTop: '15px' }}>Segments to replay after midroll</div></GridItem>
                      <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>{this.renderRoundQuestionMark('numberOfSegmentsToReplayAfterMidroll')}</GridItem>
                      <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}><TextField
                        id="textField_numberOfSegmentsToReplayAfterMidroll"
                        type='number'
                        className={classes.textField}
                        margin="dense"
                        onChange={e => this.modifyCurrentChannel({ stitcher: { ...stitcher, numberOfSegmentsToReplayAfterMidroll: this.safeValueAsNumber(e.target) } })}
                        value={stitcher.numberOfSegmentsToReplayAfterMidroll}
                      /></GridItem>
                    </GridContainer>
                    {type === 'dash-vod' && <GridContainer>
                      <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}><div style={{ float: 'left', marginTop: '15px' }}>Include DASH events</div></GridItem>
                      <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>{this.renderRoundQuestionMark('includeDashEvents')}</GridItem>
                      <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                        <Switch
                          disabled={false}
                          checked={stitcher.includeDashEvents}
                          onChange={e => this.modifyCurrentChannel({ stitcher: { ...stitcher, includeDashEvents: e.target.checked } })}
                          value="status"
                          color="primary"
                          size="small"
                          inputProps={{ 'aria-label': 'status checkbox' }}
                        />
                      </GridItem>
                    </GridContainer>}
                    {type === 'hls-vod' && <GridContainer>
                      <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}><div style={{ float: 'left', marginTop: '15px' }}>Filter HLS by Bandwidth</div></GridItem>
                      <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>{this.renderRoundQuestionMark('shouldFilterHlsByBandwidth')}</GridItem>
                      <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                        <Switch
                          disabled={false}
                          checked={stitcher.shouldFilterHlsByBandwidth || false}
                          onChange={e => this.modifyCurrentChannel({ stitcher: { ...stitcher, shouldFilterHlsByBandwidth: e.target.checked } })}
                          value="status"
                          color="primary"
                          size="small"
                          inputProps={{ 'aria-label': 'status checkbox' }}
                        />
                      </GridItem>
                    </GridContainer>}
                    {type === 'hls-vod' && <GridContainer>
                      <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}><div style={{ float: 'left', marginTop: '15px' }}>Maximum HLS Bandwidth</div></GridItem>
                      <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>{this.renderRoundQuestionMark('bandwidthThreshold')}</GridItem>
                      <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}><TextField
                        id="textField_bandwidthThreshold"
                        className={classes.textField}
                        margin="dense"
                        onChange={e => this.modifyCurrentChannel({ stitcher: { ...stitcher, bandwidthThreshold: e.target.checked } })}
                        value={stitcher.bandwidthThreshold}
                      /></GridItem>
                    </GridContainer>}
                    <GridContainer>
                      <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}><div style={{ float: 'left', marginTop: '15px' }}>Use SCTE-35 Ad-Markers</div></GridItem>
                      <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>{this.renderRoundQuestionMark('useScte35')}</GridItem>
                      <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}><Switch
                        disabled={false}
                        checked={stitcher.useScte35 || false}
                        onChange={e => this.modifyCurrentChannel({ stitcher: { ...stitcher, useScte35: e.target.checked } })}
                        value="status"
                        color="primary"
                        size="small"
                        inputProps={{ 'aria-label': 'status checkbox' }}
                      /></GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}><div style={{ float: 'left', marginTop: '15px' }}>Replace Ad-Breaks</div></GridItem>
                      <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>{this.renderRoundQuestionMark('replaceInsteadOfInsert')}</GridItem>
                      <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}><Switch
                        disabled={false}
                        checked={stitcher.replaceInsteadOfInsert || false}
                        onChange={e => this.modifyCurrentChannel({ stitcher: { ...stitcher, replaceInsteadOfInsert: e.target.checked } })}
                        value="status"
                        color="primary"
                        size="small"
                        inputProps={{ 'aria-label': 'status checkbox' }}
                      /></GridItem>
                    </GridContainer>
                    {(type === 'hls-vod' || type === 'dash-vod') && <GridContainer>
                      <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}><div style={{ float: 'left', marginTop: '15px' }}>Provide VAST URL</div></GridItem>
                      <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>{this.renderRoundQuestionMark('provideVastURL')}</GridItem>
                      <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}><Switch
                        disabled={false}
                        checked={stitcher.provideVastURL || false}
                        onChange={e => this.modifyCurrentChannel({ stitcher: { ...stitcher, provideVastURL: e.target.checked } })}
                        value="status"
                        color="primary"
                        size="small"
                        inputProps={{ 'aria-label': 'status checkbox' }}
                      /></GridItem>
                    </GridContainer>}
                    {type === 'hls-vod' && <GridContainer>
                      <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}><div style={{ float: 'left', marginTop: '15px' }}>Preserve playlist name</div></GridItem>
                      <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>{this.renderRoundQuestionMark('preservePlaylistFilename')}</GridItem>
                      <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}><Switch
                        disabled={false}
                        checked={preservePlaylistFilename || false}
                        onChange={e => this.modifyCurrentChannel({ preservePlaylistFilename: e.target.checked }, false)}
                        value={preservePlaylistFilename || false}
                        color="primary"
                        size="small"
                        inputProps={{ 'aria-label': 'status checkbox' }}
                      /></GridItem>
                    </GridContainer>}
                  </GridItem>
                </GridContainer>
              </Grid>
            </CardBody>
          </Card>
        }
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4} lg={3}><div style={{ float: 'left', marginTop: '15px' }}>Always generate titleIds from the source URL</div></GridItem>
              <GridItem xs={4} sm={4} md={2} lg={1}>{this.renderRoundQuestionMark('generateTitleIdsUsingURL')}</GridItem>
              <GridItem xs={4} sm={4} md={2} lg={1}>
                <Switch
                  disabled={false}
                  checked={generateTitleIdsUsingURL || false}
                  onChange={e => this.modifyCurrentChannel({ generateTitleIdsUsingURL: e.target.checked })}
                  value="status"
                  color="primary"
                  size="small"
                  inputProps={{ 'aria-label': 'status checkbox' }}
                />
              </GridItem>
            </GridContainer>
          </CardBody></Card>
        {
          channelType === 'live' && <Card>
            <CardBody>
              <Grid item xs={12}>
                <h5>Ad Slate</h5>
                <React.Fragment>
                  <div>
                    It is possible that the returned ads from the ad-server do not
                    completely fill out the requested ad-pod. (e.g. if you have a 40
                    second pod for ads but there are only ads of the size of 30 seconds
                    on the ad-server) In this case we will fill the remaining time with a
                    ad-slate that can be defined here. If you do not have an ad-slate
                    in place yet you can choose the Serverside.ai default and change
                    it later.
                  </div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <ResourceEnumDropdownWithStatus
                            style={{ width: '100%' }}
                            classes={classes}
                            handleChange={this.handleAdSlateSelect}
                            value={this.state.currentAdSlateId}
                            data={this.state.adSlates}
                            inputLabelText={'Select Ad-Slate from Assets:'}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <label>or upload your own *.mp4 file:</label>
                          <FileUploadProgress
                            method='post'
                            key='ex1'
                            url={backend.getMediaUploadUrl(this.props.id || id)}
                            beforeSend={res => backend.beforeSend({
                              req: res,
                              profile,
                              name: this.state.fileName || 'ad slate',
                              type,
                              cmaf,
                              hasSeparateAudio,
                              http,
                              audioNormalization: useAudioNormalization ? audioNormalization : undefined
                            })}
                            onLoad={this.onUploadSuccessful.bind(this)}
                            formRenderer={this.formRenderer.bind(this)}
                            formGetter={this.formGetter.bind(this)}
                            onError={this.onUploadFailed}
                            progressRenderer={this.progressRenderer.bind(this)}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  {this.state.encodingProgress &&
                    <Grid>
                      <h5>Ad slate conditioning</h5>
                      <LinearProgress
                        variant="determinate"
                        value={this.state.encodingProgress}
                      />
                    </Grid>
                  }
                  <TextField
                    id="adSlateManifestUrl"
                    label="Ad Slate URL"
                    placeholder="Enter Ad Slate URL, if available"
                    type="search"
                    className={classes.textField}
                    margin="dense"
                    fullWidth
                    onChange={this.handleChange}
                    value={adSlateManifestUrl}
                    disabled
                  />
                  <div style={{ color: 'red' }}>
                    {this.getErrors('adSlateManifestUrl')}
                  </div>
                </React.Fragment>
              </Grid>
            </CardBody>
          </Card>
        }
        {isBitmovin && <Card>
          <CardBody>
            <Grid item xs={12}>
              <h5>Audio normalization</h5>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <GridContainer>
                    <GridItem><div><Switch
                      disabled={false}
                      checked={useAudioNormalization}
                      onChange={e => this.modifyCurrentChannel({ useAudioNormalization: e.target.checked, audioNormalization }, false)}
                      value="status"
                      color="primary"
                      size="small"
                      inputProps={{ 'aria-label': 'status checkbox' }}
                    /> Use audio normalization</div></GridItem>
                  </GridContainer>
                  {useAudioNormalization && <>
                    <GridContainer>
                      <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}><div style={{ float: 'left', marginTop: '15px' }}>Integrated Loudness</div></GridItem>
                      <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>{this.renderRoundQuestionMark('integratedLoudness')}</GridItem>
                      <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                        <TextField
                          id="audioNormalization.integratedLoudness"
                          name="audioNormalization.integratedLoudness"
                          placeholder="Enter integratedLoudness value"
                          type="number"
                          className={classes.textField}
                          margin="dense"
                          fullWidth
                          onChange={e => this.modifyCurrentChannel({ useAudioNormalization, audioNormalization: { ...audioNormalization, integratedLoudness: e.target.value } }, false)}
                          value={audioNormalization.integratedLoudness || ''}
                          style={{ maxWidth: 167 }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}><div style={{ float: 'left', marginTop: '15px' }}>Loudness Range</div></GridItem>
                      <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>{this.renderRoundQuestionMark('loudnessRange')}</GridItem>
                      <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                        <TextField
                          id="audioNormalization.loudnessRange"
                          name="audioNormalization.loudnessRange"
                          placeholder="Enter loudnessRange value"
                          type="number"
                          className={classes.textField}
                          margin="dense"
                          fullWidth
                          onChange={e => this.modifyCurrentChannel({ useAudioNormalization, audioNormalization: { ...audioNormalization, loudnessRange: e.target.value } }, false)}
                          value={audioNormalization.loudnessRange || ''}
                          style={{ maxWidth: 167 }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]}><div style={{ float: 'left', marginTop: '15px' }}>Maximum True Peak Level</div></GridItem>
                      <GridItem xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]}>{this.renderRoundQuestionMark('maximumTruePeakLevel')}</GridItem>
                      <GridItem xs={xs[2]} sm={sm[2]} md={md[2]} lg={lg[2]}>
                        <TextField
                          id="audioNormalization.maximumTruePeakLevel"
                          name="audioNormalization.maximumTruePeakLevel"
                          placeholder="Enter maximumTruePeakLevel value"
                          type="number"
                          className={classes.textField}
                          margin="dense"
                          fullWidth
                          onChange={e => this.modifyCurrentChannel({ useAudioNormalization, audioNormalization: { ...audioNormalization, maximumTruePeakLevel: e.target.value } }, false)}
                          value={audioNormalization.maximumTruePeakLevel || ''}
                          style={{ maxWidth: 167 }}
                        />
                      </GridItem>
                    </GridContainer>
                  </>}
                </GridItem>
              </GridContainer>
            </Grid>
          </CardBody>
        </Card>}
        <Card>
          <CardBody>
            <Grid item xs={12}>
              {backButton}
              <Button
                style={{ margin: '20px 10px' }}
                variant="contained"
                color="success"
                onClick={this.handleSave}
                disabled={disableSaveButton}
              >
                {saveButtonLabel}
                <Spinner
                  size={12}
                  color={'#FFF'}
                  radius={2}
                  visible={fetching}
                />
              </Button>
            </Grid>
          </CardBody>
        </Card>
      </React.Fragment >)
    }


    return (
      <GridItem xs={12} >
        {content}
      </GridItem>
    )
  }
  onChangeHandler = event => {
    this._isMounted && this.setState({ fileName: event.target.files[0] ? event.target.files[0].name : '' })
  }
  formGetter = () => new FormData(ReactDom.findDOMNode(this.uploadForm))
  formRenderer = (onSubmit) => {
    window.boundObject = onSubmit.boundObject
    return <div>
      <form onSubmit={async e => {
        e.persist()
        e.preventDefault()
        await backend.modelSchema('channels') // to make sure that token is fresh
        onSubmit(e)
      }} ref={x => this.uploadForm = x} method={'post'}>
        <Button
          variant={'contained'}
          component={'label'}
          color={'white'}>
          Select File
          <input
            name={'file'}
            type={'file'}
            style={{ display: 'none' }}
            accept={'.mp4,.mov'}
            onChange={this.onChangeHandler.bind(this)} />
        </Button>
        <Button
          style={{ float: 'right' }}
          disabled={!this.state.fileName}
          type={'submit'}
          color={this.state.fileName ? 'dribbble' : 'transparent'}>
          Upload
        </Button>
      </form>
    </div>
  }
  progressRenderer = (progress, hasError) => {
    let message = ''
    if (hasError) {
      message = 'failed to upload.'
    } else if (this.state.fileName) {
      if (progress === 100) {
        message = 'file uploaded.'
      } else if (progress > -1) {
        message = 'uploading...'
      }
    }
    return <React.Fragment>
      <div>
        <label>{this.state.fileName || 'no file selected'}</label>
        <label style={{ float: 'right', color: hasError ? '#a94442' : '' }}>{message}</label></div>
      <div>
        <LinearProgress variant={'determinate'} value={progress} color={hasError ? 'secondary' : 'primary'} />
      </div>
    </React.Fragment>
  }
}

export default withStyles(regularFormsStyle)(ChannelDetailsPage)
