import React, { Component } from 'react'
import GridItem from '../../components/Grid/GridItem.jsx'
import Button from '../../components/CustomButtons/Button.jsx'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Card from '../../components/Card/Card.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import withStyles from '@material-ui/core/styles/withStyles'
import extendedTablesStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import DataConnector from './../../utils/DataConnector'
import { createSuccessMessage, showErrorMessage } from '../../components/Alerts/Alerts'
import { withRouter } from 'react-router-dom'

class AssetMetadata extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: { state: 'delivered', type: '', name: '' }
    }
    this.dataParams = {
      model: 'titles',
      caption: { singular: 'asset metadata', plural: 'asset metadata' }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.status !== prevState.status) {
      this.props.setAssetStatus(this.state.status)
    }
  }

  async componentDidMount () {
    const dataConnector = new DataConnector(this.dataParams, this, showErrorMessage, this.showSuccessMessage)
    if (dataConnector) {
      this.setState({ dataConnector })
      if (this.props.id) {
        await dataConnector.loadItem(this.props.id)
      }
    }
  }

  showSuccessMessage = createSuccessMessage(() => {
    this.props.history.push('/app/assets/' + this.state.data.id)
  })

  render () {
    const { classes } = this.props
    const { error, data } = this.state
    const { handleChange, handleSave } = this.state.dataConnector || {}

    // ** TODO: get from schema
    const validTypes = ['movie', 'episode', 'season', 'series', 'ad', 'catchup', 'ad-slate']

    let content

    if (error) {
      content = <CardBody>Error in loading</CardBody>
    }

    if (this.state.status === 404) {
      content = <CardBody><h4>Asset not found</h4></CardBody>
    } else if (data) {
      content = (<CardBody>
        <TextField
          id="state"
          name="state"
          label="Status"
          type="search"
          className={classes.textField}
          margin="dense"
          fullWidth
          value={data.state}
          disabled
        />
        <div>
          <FormControl className={classes.formControl} required>
            <InputLabel shrink htmlFor="role-label-placeholder">
              Type
          </InputLabel>
            <Select
              value={data.type}
              onChange={handleChange}
              input={<Input id="type_p" />}
              name="type"
              className={classes.selectEmpty}
            >
              {validTypes.map((item, index) =>
                <MenuItem key={index} value={item}>{item}</MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
        <TextField
          id="studio"
          name="studio"
          label="Studio"
          type="search"
          className={classes.textField}
          margin="dense"
          fullWidth
          value={data.studio}
          onChange={handleChange}
        />
        <TextField
          id="name"
          name="name"
          label="Name"
          type="search"
          className={classes.textField}
          margin="dense"
          fullWidth
          value={data.name}
          onChange={handleChange}
          required
        />
        <TextField
          id="deliverer"
          name="deliverer"
          label="Deliverer"
          type="search"
          className={classes.textField}
          margin="dense"
          fullWidth
          value={data.deliverer}
          onChange={handleChange}
        />
        <TextField
          multiline
          id="synopsis"
          name="synopsis"
          label="Synopsis"
          type="search"
          className={classes.textField}
          margin="dense"
          fullWidth
          value={data.synopsis}
          onChange={handleChange}
        />
        <Button
          style={{ margin: '20px 10px' }}
          variant="contained"
          color="success"
          onClick={handleSave}
        >
          Save
      </Button>{' '}

      </CardBody>)
    }

    return (
      <GridItem xs={12}>
        <Card>
          {content}
        </Card>
      </GridItem>
    )
  }
}

export default withRouter(withStyles(extendedTablesStyle)(AssetMetadata))
