import React, { useRef, useEffect, useState } from 'react'
import * as d3 from 'd3'
import * as topojson from 'topojson-client'
import uuid from 'uuid/v4'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import world from './world-atlas-110m.json'
import countryIds from './country-ids.json'
import Tooltip from './Tooltip'

import './styles/map.css'

const tooltipRectParams = ({ x: 0, y: 0, width: 140, height: 42 })
const tooltipKeyParams = ({ x: 10, y: 17 })
const tooltipValueParams = ({ x: 10, y: 37 })
const noDataColor = '#dadfe6'
const noDataColorHover = '#b7bdc7'
const buttonSize = 25
const buttonPadding = 5
const buttonLineWidth = 1.5
const buttonSignSize = 16
const buttonX = 3.15
const buttonY = 4.3
const buttonColorLight = '#eceff2'
const buttonColorDark = '#757981'

const showTooltip = () => function (d) {
  this.node.setAttribute('transform', `translate(${d.event.offsetX},${d.event.offsetY})`)
  this._key.textContent = d.feature.name
  this._value.textContent = d.feature.value
  const width = Math.max(this._key.getBoundingClientRect().width, this._value.getBoundingClientRect().width) + 20
  this.rect.setAttribute('width', width)
}

const Map = ({ data = [], width, height, viewOption = 'MAP' }) => {
  const d3Container = useRef(null)
  const [currentReportId] = useState(uuid())
  const [mapTransform, setMapTransform] = useState()

  const features = topojson.feature(world, world.objects.countries).features

  features.forEach(f => {
    const c = countryIds.find(e => e.code === f.id)
    if (c) {
      f.name = c.name
      for (let i = 0; i < data.labels.length; ++i) {
        if (data.labels[i] === c.isocode) {
          f.value = data.series[0].data[i]
        }
      }
    }
  })

  useEffect(() => {
    if (viewOption === 'MAP' &&
      d3Container.current &&
      height &&
      width &&
      data &&
      data.series &&
      data.series[0] &&
      data.series[0].data &&
      data.series[0].data.length > 0) {
      const projection = d3
        .geoNaturalEarth1()
        .rotate([0, 0])
        .precision(0.1)
        .fitSize([width, height], { type: "Sphere" })

      const path = d3.geoPath().projection(projection)

      const tooltip = new Tooltip(
        {
          d3,
          tooltipRectParams,
          tooltipCircleParams: null,
          tooltipKeyParams,
          tooltipValueParams,
          _show: showTooltip()
        })

      const svg = d3.select(d3Container.current)
      svg.selectAll(`*`).remove()
      svg.attr(`viewBox`, [0, 0, width, height])
        .attr('width', width)
        .attr('height', height)
        .attr('class', 'map')

      const upperLevelContainer = svg.append('g')
      const featuresContainer = upperLevelContainer.append('g')

      const featureMouseMove = (feature, event) => {
        if (feature.path && feature.value) {
          feature.path.attr('fill', '#842470')
          tooltip.show({ feature, event })
        }
        if (feature.path && !feature.value) {
          feature.path.attr('fill', noDataColorHover)
        }
      }
      const featureMouseLeave = (feature) => {
        if (feature.path) {
          feature.path.attr('fill', feature.value ? colorScale(feature.value) : noDataColor)
        }
        tooltip.hide()
      }

      featuresContainer.append('rect')
        .attr('height', height)
        .attr('width', width)
        .attr('fill', 'white')

      const colorScale = d3.scaleLinear()
        .domain([d3.min(data.series[0].data), d3.max(data.series[0].data)])
        .range(['#ffb2d1', '#ff0066'])

      features.forEach(feature => {
        feature.path = featuresContainer.append('path')
          .attr('d', path(feature))
          .attr('class', 'country')
          .attr('fill', feature.value ? colorScale(feature.value) : noDataColor)
          .on('mousemove', () => featureMouseMove(feature, d3.event))
          .on('mouseleave', () => featureMouseLeave(feature, d3.event))
      })

      const zoomed = () => {
        setMapTransform(d3.event.transform)
        featuresContainer.node().setAttribute("transform", d3.event.transform)
      }

      const zoom = d3
        .zoom()
        .extent([[0, 0], [width, height]])
        .translateExtent([[-50, -50], [width + 50, height + 50]])
        .scaleExtent([1, 100])
        .on("zoom", zoomed)

      const zoomIn = () => {
        upperLevelContainer
          .transition()
          .call(zoom.scaleBy, 2)
      }

      const zoomOut = () => {
        upperLevelContainer
          .transition()
          .call(zoom.scaleBy, 0.5)
      }

      const buttonsContainer = upperLevelContainer.append('g')
      const buttonZoomOutContainer = buttonsContainer.append('g')
      buttonZoomOutContainer.highlightedChildren = []
      buttonZoomOutContainer.highlightedChildren.push(
        buttonZoomOutContainer.append('rect')
          .attr('fill', buttonColorLight)
          .attr('x', buttonX)
          .attr('y', buttonY)
          .attr('width', buttonSize)
          .attr('height', buttonSize)
          .attr('class', 'button buttonLight')
          .on('click', zoomOut))
      buttonZoomOutContainer.append('rect')
        .attr('fill', buttonColorDark)
        .attr('x', buttonX + (buttonSize - buttonSignSize) / 2)
        .attr('y', buttonY + (buttonSize - buttonSignSize) / 2)
        .attr('width', buttonSignSize)
        .attr('height', buttonSignSize)
        .attr('class', 'button')
        .on('click', zoomOut)
      buttonZoomOutContainer.highlightedChildren.push(
        buttonZoomOutContainer.append('rect')
          .attr('fill', buttonColorLight)
          .attr('x', buttonX)
          .attr('y', buttonY)
          .attr('width', buttonSize)
          .attr('height', (buttonSize - buttonLineWidth) / 2)
          .attr('class', 'button buttonLight')
          .on('click', zoomOut))
      buttonZoomOutContainer.highlightedChildren.push(
        buttonZoomOutContainer.append('rect')
          .attr('fill', buttonColorLight)
          .attr('x', buttonX)
          .attr('y', buttonY + (buttonSize - buttonLineWidth) / 2 + buttonLineWidth)
          .attr('width', buttonSize)
          .attr('height', (buttonSize - buttonLineWidth) / 2)
          .attr('class', 'button buttonLight')
          .on('click', zoomOut))
      buttonZoomOutContainer.attr('transform', `translate(${width - buttonSize - 2 * buttonPadding},${height - buttonSize - 2 * buttonPadding})`)
        .on('mousemove', () => buttonZoomOutContainer.highlightedChildren.forEach(el => el.attr('fill', noDataColorHover)))
        .on('mouseleave', () => buttonZoomOutContainer.highlightedChildren.forEach(el => el.attr('fill', buttonColorLight)))

      const buttonZoomInContainer = buttonsContainer.append('g')
      buttonZoomInContainer.highlightedChildren = []
      buttonZoomInContainer.highlightedChildren.push(
        buttonZoomInContainer.append('rect')
          .attr('fill', buttonColorLight)
          .attr('x', buttonX)
          .attr('y', buttonY)
          .attr('width', buttonSize)
          .attr('height', buttonSize)
          .attr('class', 'button')
          .on('click', zoomIn))
      buttonZoomInContainer.append('rect')
        .attr('fill', buttonColorDark)
        .attr('x', buttonX + (buttonSize - buttonSignSize) / 2)
        .attr('y', buttonY + (buttonSize - buttonSignSize) / 2)
        .attr('width', buttonSignSize)
        .attr('height', buttonSignSize)
        .attr('class', 'button')
        .on('click', zoomIn)
      buttonZoomInContainer.highlightedChildren.push(
        buttonZoomInContainer.append('rect')
          .attr('fill', buttonColorLight)
          .attr('x', buttonX)
          .attr('y', buttonY)
          .attr('width', (buttonSize - buttonLineWidth) / 2)
          .attr('height', (buttonSize - buttonLineWidth) / 2)
          .attr('class', 'button')
          .on('click', zoomIn))
      buttonZoomInContainer.highlightedChildren.push(

        buttonZoomInContainer.append('rect')
          .attr('fill', buttonColorLight)
          .attr('x', buttonX + (buttonSize - buttonLineWidth) / 2 + buttonLineWidth)
          .attr('y', buttonY)
          .attr('width', (buttonSize - buttonLineWidth) / 2)
          .attr('height', (buttonSize - buttonLineWidth) / 2)
          .attr('class', 'button')
          .on('click', zoomIn))

      buttonZoomInContainer.highlightedChildren.push(
        buttonZoomInContainer.append('rect')
          .attr('fill', buttonColorLight)
          .attr('x', buttonX)
          .attr('y', buttonY + (buttonSize - buttonLineWidth) / 2 + buttonLineWidth)
          .attr('width', (buttonSize - buttonLineWidth) / 2)
          .attr('height', (buttonSize - buttonLineWidth) / 2)
          .attr('class', 'button')
          .on('click', zoomIn))
      buttonZoomInContainer.highlightedChildren.push(
        buttonZoomInContainer.append('rect')
          .attr('fill', buttonColorLight)
          .attr('x', buttonX + (buttonSize - buttonLineWidth) / 2 + buttonLineWidth)
          .attr('y', buttonY + (buttonSize - buttonLineWidth) / 2 + buttonLineWidth)
          .attr('width', (buttonSize - buttonLineWidth) / 2)
          .attr('height', (buttonSize - buttonLineWidth) / 2)
          .attr('class', 'button')
          .on('click', zoomIn))

      buttonZoomInContainer.attr('transform', `translate(${width - buttonSize - 2 * buttonPadding},${height - 2 * buttonSize - 3 * buttonPadding})`)
        .on('mousemove', () => buttonZoomInContainer.highlightedChildren.forEach(el => el.attr('fill', noDataColorHover)))
        .on('mouseleave', () => buttonZoomInContainer.highlightedChildren.forEach(el => el.attr('fill', buttonColorLight)))      

      upperLevelContainer.call(zoom)

      svg.append(() => tooltip.node)

      if (mapTransform) {
        upperLevelContainer.call(zoom.transform, mapTransform)
      } else {
        const allBounds = features.filter(f => typeof f.value !== 'undefined').map(f => path.bounds(f))
        const bound0 = d3.min(allBounds, d => d[0][0])
        const bound1 = d3.min(allBounds, d => d[0][1])
        const bound2 = d3.max(allBounds, d => d[1][0])
        const bound3 = d3.max(allBounds, d => d[1][1])
        const dx = bound2 - bound0
        const dy = bound3 - bound1
        const x = (bound0 + bound2) / 2
        const y = (bound1 + bound3) / 2
        const scale = .9 / Math.max(dx / width, dy / height)
        const translate = [width / 2 - scale * x, height / 2 - scale * y]
        upperLevelContainer.call(zoom.transform, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale))
      }
    }
    // eslint-disable-next-line
  }, [data, width, height, currentReportId, viewOption])

  if (viewOption === 'MAP') {
    return <svg id={currentReportId} width={width} height={height} ref={d3Container} />
  }
  if (viewOption === 'TABLE') {
    return (
      <div style={{ height, overflow: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Country</TableCell>
              {data.series.map((s, i) => (
                <TableCell key={i}>{s.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.labels.map((l, r) => (
              <TableRow key={r}>
                <TableCell>{data.labels[r]}</TableCell>
                {data.series.map((s, c) => (
                  <TableCell key={c}>{s.data[r]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

    )
  }
}

export default Map
