import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Assignment from '@material-ui/icons/Assignment'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import extendedTablesStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import Details from '../../views/Playlists/PlaylistDetails.jsx'
import TableWrapper from '../../components/TableWrapper/TableWrapper.jsx'

class PlaylistsPage extends React.Component {
  state = {}
  toggleDisplay = playlistId => {
    if (playlistId) {
      this.props.history.push('/app/playlists/' + playlistId)
    } else {
      this.props.history.push('/app/playlists')
    }
  }
  render() {
    
    const playlistId = this.props.match.params.playlistId
    return (
      <GridContainer>
        {playlistId ? (
          <Details
            {...this.props}
            id={playlistId !== 'new' ? playlistId : null}
            toggleDisplay={this.toggleDisplay}
          />
        ) : (
          <TableWrapper 
            {...this.props}
            toggleDisplay={this.toggleDisplay}
            resource="playlists"
            fields={['id', 'name', 'createdAt']}
            labels={['ID', 'Name', 'Created at']}
            goBack={this.goBack}
            rowsPerPage={10}
            resourceName={ { singular: 'Playlist', plural: 'Playlists' } }
            icon={Assignment}
          />
        )}
      </GridContainer>
    )
  }
}

export default withStyles(extendedTablesStyle)(PlaylistsPage)
