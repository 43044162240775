import React from 'react'
import TV from '@material-ui/icons/Tv'
import withStyles from '@material-ui/core/styles/withStyles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import PlayIcon from '@material-ui/icons/PlayArrowOutlined'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import extendedTablesStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import ChannelDetails from './ChannelDetails'
import TableWrapper from '../../components/TableWrapper/TableWrapper.jsx'
import ChannelPlayer from '../../components/ChannelPlayer/ChannelPlayer.jsx'
import ChannelMonitorTimeline from './ChannelMonitorTimeline'
import backend from '../../utils/backend'
import setPreRoll from '../../utils/set-preroll'
import { componentWithContext } from '../../state/DispatchContext'
import { showSuccessMessage, showErrorMessage } from '../../components/Alerts/Alerts'

class ChannelsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      popover: {
        key: null,
        index: null
      }
    }
    this.child = React.createRef()
  }
  /*
  async componentDidMount () {
    async function refresher () {
      if (this.child && typeof this.child.fetchItems === 'function' && this.child.fetchItems(false)) {
        setTimeout(() => refresher.call(this), 60000)
      }
    }
    await refresher.call(this)
  }*/
  componentDidMount () {
    const refresher = setInterval(() => {
      if (this.child && typeof this.child.fetchItems === 'function') {
        this.child.fetchItems(false)
      }
    }, 60000)
    this.setState({ refresher })
  }
  componentWillUnmount () {
    clearInterval(this.state.refresher)
  }

  toggleDisplay = channelId => {
    if (channelId) {
      this.props.history.push('/app/ad-insertion/channels/' + channelId)
    } else {
      this.props.history.push('/app/ad-insertion/channels')
    }
  }

  setPlayerChannelId = id => {
    this.setState({ playerChannelId: id })
  }

  hideChannelPlayer = () => {
    this.setState({ playerChannelId: false })
  }

  resetChannel = async (id) => {
    try {
      await backend.reset(id)
      showSuccessMessage('channel successfully reset')
    }
    catch (err) {
      showErrorMessage(`Error resetting channel: ${err.message}`)
      try {
        console.error(await err.data)
      } catch {
      }
    }
  }

  popoverHandle = (key, index) => {
    if (key && index !== null) {
      return event => {
        this.setState({
          popover: {
            key,
            index,
            anchorEl: event.currentTarget
          }
        })
      }
    }
    return () => this.setState({
      popover: {
        key: null,
        index: null,
        anchorEl: null
      }
    })
  }
  renderControls = (channel, index, props, classes) => {
    return <React.Fragment>
      {props.setPlayerChannelId && (
        <IconButton
          className={[classes.actionButton, "ssai-icon-button"].join(' ')}
          key='play-button'
          onClick={() => {
            props.setPlayerChannelId(channel.id)
          }}
        >
          <PlayIcon fontSize="small" className={classes.icon} />
      </IconButton>)}
    </React.Fragment>
  }
  stateChangePreprocessor (channel) {
    setPreRoll(channel)
  }
  isCurrent (item) {
    return item.id === this.props.context.state.ui.currentChannel
  }
  onRowClick (item) {
    this.props.context.actions.ui.setCurrentChannel(item.id)
  }
  render () {
    const channelId = this.props.match.params.channelId
    const propsForChannelDetails = { ...this.props }
    delete propsForChannelDetails.classes
    return (
      <GridContainer>
        {channelId ? (
          <ChannelDetails
            {...propsForChannelDetails}
            id={channelId !== 'new' ? channelId : null}
            toggleDisplay={this.toggleDisplay}
            // goBack is right now only used directly by this page because the add channel dialog has a back button
            goBack={() => this.toggleDisplay()}
          />
        ) : (
          <TableWrapper
            isCurrent={this.isCurrent.bind(this)}
            onRowClick={this.onRowClick.bind(this)}
            onRef={ref => (this.child = ref)}
            resource={'channels'}
            toggleDisplay={this.toggleDisplay}
            fields={[
              this.renderControls.bind(this),
              'name',
              'type',
              'id',
              'createdAt'
            ]}
            labels={[
              '',
              'Name',
              'Type',
              'ID',
              'Created at'
            ]}
            goBack={() => this.toggleDisplay()}
            showStateButton={true}
            stateChangePreprocessor={this.stateChangePreprocessor}
            rowsPerPage={10}
            resourceName={{ singular: 'Channel', plural: 'Channels' }}
            icon={TV}
            setPlayerChannelId={this.setPlayerChannelId}
            resetMethod={this.resetChannel}
            xtra={this.state.playerChannelId ?
              <ChannelPlayer
                playerChannelId={this.state.playerChannelId}
                hide={this.hideChannelPlayer}>
              </ChannelPlayer> : ''}
          />
        )}
        {this.state.timelineChannelId &&
          <Dialog
            open={true}
            fullWidth={true}
            maxWidth="xl"
          >
            <DialogContent>
              <GridContainer direction="column">
                <GridItem>
                  <ChannelMonitorTimeline
                    channelId={this.state.timelineChannelId}
                    channelType={this.state.timelineChannelType}
                    channelName={this.state.timelineChannelName}
                    getRefreshHandler={handler => this.refreshMonitorTimeline = handler}
                  />
                </GridItem>
                <GridItem >
                </GridItem>
              </GridContainer>
              <DialogActions>
                <Button
                  onClick={() => this.refreshMonitorTimeline && this.refreshMonitorTimeline()}
                  color="primary">
                  Refresh
                </Button>
                <Button
                  onClick={() => {
                    this.setState({ timelineChannelId: undefined })
                  }}
                  color="primary">
                  Close
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>}
      </GridContainer>
    )
  }
}


export default componentWithContext(withStyles(extendedTablesStyle)(ChannelsPage))
