import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class SimpleTable extends React.Component {

  render() {
    //console.log('Table')
    return (
      <Table>
        <TableHead>
          <TableRow>
            {
              this.props.data.labels.map((label, i) => (
                <TableCell
                  padding='none'
                  key={label.name + '-' + i}
                >{label.display}</TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.data.series.map((row, i) => (
            <TableRow key={i}>
              {
                this.props.data.labels.map((label, i) => (
                  <TableCell 
                    component="th"
                    scope="row"
                    key={label.name + '-' + i}
                    padding='none'
                  >
                    {row[label.name]}
                  </TableCell>
                ))
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

export default SimpleTable
