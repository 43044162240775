import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import loginPageStyle from '../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx'


class Error404Page extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <div className={classes.container} style={{textAlign: 'center'}}>
        <h1 style={{fontSize: '7em'}}>Error 404</h1><h2>Page not found :(</h2><h4>Ooooups! Looks like you got lost.</h4>
      </div>
    )
  }
}

export default withStyles(loginPageStyle)(Error404Page)