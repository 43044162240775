import React, { useContext, useEffect, useState, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Popper from '@material-ui/core/Popper'
import IconButton from '@material-ui/core/IconButton'

import cx from 'classnames'
import moment from 'moment'

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer.jsx'

import { ReactComponent as ChevronIcon } from '../../assets/icons/serverside-icon-chevron-black.svg'
import { ReactComponent as ChevronIconRed } from '../../assets/icons/serverside-icon-chevron-red.svg'

import { ReactComponent as CheckBlackIcon } from '../../assets/icons/serverside-icon-check-black.svg'
import { ReactComponent as CheckboxBlackIcon } from '../../assets/icons/serverside-icon-checkbox-black.svg'

import DispatchContext from '../../state/DispatchContext'
import { periodTypes, getPeriod } from '../../state/helpers'

import { styles } from './styles/periodsSelectorStyles'

import { refreshAllReports } from '../../state/helpers'

const applyChanges = async (context, useDashboard) => {
  try {
    context.actions.users.updateAppMetadata(context.state.users.current.app_metadata).then(() => refreshAllReports(context, useDashboard))
  } catch (ex) {
    return false
  }
  return true
}
const errorMessage = context => {
  if (context.state.users.error) {
    return context.state.users.error.message
  }
}

/**
 * Is given date actually in period?
 * @param {*} context 
 * @param {*} pIndex 
 * @param {*} m 
 */
const inPeriod = (context, pIndex, m) => {
  if (!context.state.users.current.app_metadata.timePeriods ||
    !context.state.users.current.app_metadata.timePeriods[pIndex]) {
    return false
  }
  const [m1, m2] = getPeriod(context, pIndex)
  if (m1 > m || m2 < m) {
    return false
  }
  return true
}

const Month = withStyles(styles)(({
  classes,
  weekdays = ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
  monthshift = 0 }) => {
  const context = useContext(DispatchContext)
  /**
   * Creating days array:
   */
  const days = [[], [], [], [], [], []]
  const m = new moment()
  m.add(monthshift, 'month')
  const title = m.format('MMMM YYYY')
  m.date(1)
  const startDay = m.day()
  if (startDay === 0) {
    m.add(-6, 'day')
  } else {
    m.add(1 - startDay, 'day')
  }
  for (let w = 0; w < 6; ++w) {
    for (let d = 0; d < 7; ++d) {
      days[w][d] = m.date()
      m.add(1, 'day')
    }
  }
  const __days = days.map(dayrow => dayrow.map(day => ({ day })))
  let currentMonth = false
  m.startOf('day')
  m.add(-42, 'day')
  for (let i = 0; i < __days.length; ++i) {
    for (let j = 0; j < __days[i].length; ++j) {
      __days[i][j].m = m.clone()
      if (inPeriod(context, 0, m)) {
        __days[i][j].inPeriod0 = true
      }
      if (inPeriod(context, 1, m)) {
        __days[i][j].inPeriod1 = true
      }
      m.add(1, 'day')
      if (!currentMonth && __days[i][j].day === 1) {
        currentMonth = true
        __days[i][j].currentMonth = true
        continue
      }
      if (currentMonth && __days[i][j].day === 1) {
        currentMonth = false
        __days[i][j].currentMonth = false
        continue
      }
      __days[i][j].currentMonth = currentMonth
    }
  }

  /**
   * Processing clicks on calendar
   * @param {*} i 
   * @param {*} j 
   */
  const onDayClick = (i, j) => {
    if (context.state.ui.periodSelectorDateX) {
      context.actions.users.setPeriodType(context.state.ui.periodSelectorPeriod, 'User defined')
    }
    if (context.state.ui.periodSelectorPeriod === 0 && context.state.ui.periodSelectorDateX === 'dateA') {
      context.actions.users.setDateInPeriods(0, 'dateA', __days[i][j].m.toDate())
      if (__days[i][j].m > moment(context.state.users.current.app_metadata.timePeriods[0].dateB)) {
        context.actions.users.setDateInPeriods(0, 'dateB', __days[i][j].m.toDate())
      }
      context.actions.ui.setPeriodSelectorState(0, 'dateB')
    }
    if (context.state.ui.periodSelectorPeriod === 0 && context.state.ui.periodSelectorDateX === 'dateB') {
      context.actions.users.setDateInPeriods(0, 'dateB', __days[i][j].m.toDate())
      if (__days[i][j].m < moment(context.state.users.current.app_metadata.timePeriods[0].dateA)) {
        context.actions.users.setDateInPeriods(0, 'dateA', __days[i][j].m.toDate())
      }
      context.actions.ui.setPeriodSelectorState(1, 'dateA')
    }
    if (context.state.ui.periodSelectorPeriod === 1 && context.state.ui.periodSelectorDateX === 'dateA') {
      context.actions.users.setDateInPeriods(1, 'dateA', __days[i][j].m.toDate())
      if (__days[i][j].m > moment(context.state.users.current.app_metadata.timePeriods[1].dateB)) {
        context.actions.users.setDateInPeriods(1, 'dateB', __days[i][j].m.toDate())
      }
      context.actions.ui.setPeriodSelectorState(1, 'dateB')
    }
    if (context.state.ui.periodSelectorPeriod === 1 && context.state.ui.periodSelectorDateX === 'dateB') {
      context.actions.users.setDateInPeriods(1, 'dateB', __days[i][j].m.toDate())
      if (__days[i][j].m < moment(context.state.users.current.app_metadata.timePeriods[1].dateA)) {
        context.actions.users.setDateInPeriods(1, 'dateA', __days[i][j].m.toDate())
      }
      context.actions.ui.setPeriodSelectorState(0, 'dateA')
    }
  }

  return (
    <GridContainer
      spacing={0}
      justify="space-between"
      direction="column"
      className={classes.monthMain}>
      <GridItem className={classes.monthHeader}>
        <div className={classes.monthHeaderSubBlock}>{title}</div>
      </GridItem>
      <GridItem className={classes.monthDays}>
        <GridContainer
          spacing={0}
          justify="space-between"
          direction="column"
          className={classes.noMP}>
          <GridItem className={classes.monthDaysHeader}></GridItem>
          <GridItem className={classes.monthDaysDays}>
            <GridContainer
              spacing={0}
              justify="space-between"
              direction="column"
              className={classes.monthMain} style={{ height: 166 }}>
              <GridItem className={classes.monthMain}>
                <GridContainer
                  spacing={0}
                  justify="space-between"
                  className={classes.monthMain}>
                  <GridItem className={classes.monthDaysLeftRight}></GridItem>
                  <React.Fragment>{weekdays.map((Wd, i) => (
                    <GridItem key={i} className={cx(classes.monthDaysWeekDay, classes.currentMonth)}>{Wd}</GridItem>
                  ))}</React.Fragment>
                  <GridItem className={classes.monthDaysLeftRight}></GridItem>
                </GridContainer>
              </GridItem>
              {__days.map((daysRow, i) => (<GridItem key={i} className={classes.monthMain}>
                <GridContainer
                  spacing={0}
                  justify="space-between"
                  className={classes.monthMain}>
                  <GridItem className={classes.monthDaysLeftRight}></GridItem>
                  <React.Fragment>{daysRow.map((day, j) => (
                    <GridItem key={j} className={cx(
                      classes.monthDaysWeekDay,
                      {
                        [classes.currentMonth]: day.currentMonth,
                        [classes.inPeriod0]: day.currentMonth && day.inPeriod0,
                        [classes.inPeriod1]: day.currentMonth && day.inPeriod1
                      })}
                      onClick={() => onDayClick(i, j)}>{day.day}</GridItem>
                  ))}</React.Fragment>

                  <GridItem className={classes.monthDaysLeftRight}></GridItem>
                </GridContainer>
              </GridItem>))}
            </GridContainer>
          </GridItem>
          <GridItem className={classes.monthDaysFooter}></GridItem>

        </GridContainer>
      </GridItem>
      <GridItem className={classes.monthFooter}></GridItem>
    </GridContainer>
  )
})

/**
 * Some helpers
 * @param {*} context 
 */
const compareWith = context =>
  context.state.users.current.app_metadata.timePeriods &&
  context.state.users.current.app_metadata.timePeriods[1] &&
  context.state.users.current.app_metadata.timePeriods[1].compareWith
const togglecompareWith = context => context.actions.users.toggleCompareWith()
const periodDateBlockStyle = (context, index, varName) => {
  const color = ['#ff0066', '#6282b3'][index]
  let lineWeight = 1
  if (context.state.ui.periodSelectorPeriod === index &&
    context.state.ui.periodSelectorDateX === varName) {
    lineWeight = 2
  }
  return ({
    border: lineWeight + 'px solid ' + color
  })
}

const Period = withStyles(styles)(({
  classes,
  index,
  style
}) => {
  let checkbox, dateA = '00/0/00', dateB = '00/0/00'

  if (index === 0) {
    checkbox = false
  }
  if (index === 1) {
    checkbox = true
  }

  const context = useContext(DispatchContext)

  /**
   * Set whether we change start or end of the period
   * @param {*} varName 'dateA' or 'dateB' or ''
   */
  const setActiveVar = varName => {
    context.actions.ui.setPeriodSelectorState(index, varName)
  }

  const open = context.state.ui.periodSelectorOpen[index]
  const active = context.state.ui.periodSelectorSelectActive[0]
    && context.state.ui.periodSelectorSelectActive[1]

  /**
   * Open/close dropdown
   */
  const setOpen = (f, dt) => {
    if (typeof f === 'function') {
      context.actions.ui.setOpen(index)(f(context.state.ui.periodSelectorOpen[index]), dt)
      return
    }
    context.actions.ui.setOpen(index)(f, dt)
  }

  /**
   * Toggle dropdown
   */
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const [m1, m2] = getPeriod(context, index)
  dateA = m1.format('DD/MM/YY')
  dateB = m2.format('DD/MM/YY')

  const dimInputs = context.state.users.current.app_metadata.timePeriods[index].type === 'User defined' ? false : true

  const chevronClasses =
    cx(classes.chevronIcon, {
      [classes.chevronIconOpened]: open
    })
  const anchorRef = React.useRef(null)

  /**
   * What if we select period type (via dropdown)
   * @param {*} option 
   */
  const periodTypeSelected = option => () => {
    context.actions.users.setPeriodType(index, option)
    if (index === context.state.ui.periodSelectorPeriod) {
      if (option !== 'User defined') {
        context.actions.ui.setPeriodSelectorState(undefined, '')
      } else {
        context.actions.ui.setPeriodSelectorState(index, 'dateA')
      }
    }
    setOpen(false, 50)
  }

  /**
   * Renders option for dropdown
   * @param {*} option 
   */
  const periodTypeOption = option => (
    <GridItem
      key={option}
      className={cx(classes.periodType, classes.cYbnd)}
      onClick={periodTypeSelected(option)}>
      <div className={cx(classes.periodTypeText, classes.cY)}>{option}</div>
    </GridItem>
  )

  const option = context.state.users.current.app_metadata.timePeriods[index].type

  const height = style.height
  return (
    <GridContainer
      spacing={0}
      justify="space-between"
      className={classes.periodBlock}
      direction="column"
      style={{ width: 190, height }}>
      <GridItem className={classes.periodHeaderBlock}>
        <GridContainer
          spacing={0}
          justify="space-between"
          className={classes.periodHeaderBlock}
          direction="row">
          <GridItem className={classes.periodHeaderCheckboxBlock}>
            {checkbox &&
              <IconButton id={'periodTypeSelect' + index} className={classes.iconButton} onClick={() => togglecompareWith(context)}>
                <CheckboxBlackIcon className={cx(classes.chevronIcon, classes.listIcons)} />
                {compareWith(context) &&
                  <CheckBlackIcon className={cx(classes.chevronIcon, classes.listIcons, classes.checkIcon)} />}
              </IconButton>}
          </GridItem>
          <GridItem className={classes.periodHeaderTextBlock} onClick={event => { active && handleToggle() }}>
            <div className={classes.periodHeaderText}>{context.state.users.current.app_metadata.timePeriods[index].type}</div>
          </GridItem>
          <GridItem className={classes.periodHeaderChevronBlock}>
            <IconButton
              className={classes.iconButton}
              onClick={event => {
                active && handleToggle()
              }}
              buttonRef={anchorRef}
              id={'period-type-select-' + index}
              aria-owns={open ? 'period-type-grow-' + index : undefined}
              aria-haspopup="true">
              <ChevronIcon className={chevronClasses} />
            </IconButton>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              transition
              placement="bottom-end"
              className={classes.periodTypeDropdownPopper}
              popperOptions={{
                modifiers: {
                  offset: { offset: '6px, -40px' },
                  flip: { enabled: false }
                }
              }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id={'period-type-grow-' + index}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                  }}
                >
                  <Paper
                    square={true}
                    elevation={0}
                    className={classes.periodTypeDropdownPaper}
                    style={{ height: periodTypes[index].length * 40 + 2 }}>
                    <ClickAwayListener onClickAway={event => {
                      setOpen(false, 200)
                    }}>
                      <React.Fragment>
                        <GridContainer
                          spacing={0}
                          justify="space-between"
                          className={classes.periodTypeDropdownPaper}
                          style={{ height: periodTypes[index].length * 40 + 2 }}
                          direction="column">
                          {periodTypeOption(option)}
                          {periodTypes[index].filter(opt => opt !== option).map(opt => periodTypeOption(opt))}
                        </GridContainer>
                      </React.Fragment>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}

            </Popper>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem className={classes.periodFieldBlock} style={{ height: height - (215.5 - 175) }}>
        <GridContainer
          spacing={0}
          justify="space-between"
          className={classes.periodField}>
          <GridItem
            className={cx(classes.periodDateBlock, { [classes.opacity50]: dimInputs })}
            style={periodDateBlockStyle(context, index, 'dateA')}
            onClick={() => setActiveVar('dateA')}
          ><div className={classes.periodDate}>{dateA}</div></GridItem>
          <GridItem className={classes.periodSepBlock}><div className={classes.periodSep}>-</div></GridItem>
          <GridItem
            className={cx(classes.periodDateBlock, { [classes.opacity50]: dimInputs })}
            style={periodDateBlockStyle(context, index, 'dateB')}
            onClick={() => setActiveVar('dateB')}><div className={classes.periodDate}>{dateB}</div></GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>

  )
})

const componentLayouts = [
  {
    popupWidth: 228.5,
    periodsBlockWidth: 228.5,
    periodsJustify: 'center',
    periodSplitters: [
      {},
      {},
      {},
      {}
    ]
  },
  {
    popupWidth: 457,
    periodsBlockWidth: 457,
    periodsJustify: 'center',
    periodSplitters: [
      {},
      {},
      {},
      {}
    ]
  },
  {
    popupWidth: 673,
    periodsBlockWidth: 673,
    periodsJustify: 'center',
    periodSplitters: [
      {
        width: 15,
      },
      {
        width: 15
      },
      {
        width: 15,
        height: 100,
        borderLeft: '1px solid #dadfe6'
      },
      {
        width: 15
      }
    ],
    periodBlock: {
      height: 100
    }
  },
  {
    popupWidth: 1129,
    periodsBlockWidth: 1129 - 672,
    periodsJustify: 'space-between',
    periodSplitters: [
      {
        width: 15,
        height: 200,
        borderLeft: '1px solid #dadfe6'
      },
      {
        width: 15
      },
      {
        width: 15,
        height: 200,
        borderLeft: '1px solid #dadfe6'
      },
      {
        width: 28
      }
    ],
    periodBlock: {
      height: 215.5
    }
  }
]

const PeriodsSelector = ({ classes, useDashboard }) => {
  // const context = useContext(DispatchContext)
  const [layoutIndex, setLayoutIndex] = useState(3)
  const popupWidth = componentLayouts[layoutIndex].popupWidth
  // const [open, setOpen] = useState(true)
  const context = useContext(DispatchContext)
  const open = context.state.ui.periodSelectorDialogOpen
  const setOpen = context.actions.ui.setPeriodSelectorDialogOpen
  const handleToggle = () => {
    setOpen(!context.state.ui.periodSelectorDialogOpen)
  }

  const monthShift = context.state.ui.periodSelectorMonthShift

  const contextRef = useRef(context)
  useEffect(() => {
    let mounted = true
    const debouncedHandleResize = contextRef.current.state.ui.getDebounce()(() => {
      if (!mounted) {
        return
      }
      let newI = 0
      for (let i = 1; i < componentLayouts.length; ++i) {
        if (window.innerWidth >= componentLayouts[i].popupWidth + 10) {
          newI = i
        }
      }
      setLayoutIndex(newI)
    }, 1000)
    window.addEventListener('resize', debouncedHandleResize)
    debouncedHandleResize()
    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
      mounted = false
    }
  }, [])

  const anchorRef = React.useRef(null)
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const chevronClasses =
    cx(classes.chevronIcon, classes.chevronIconMain, {
      [classes.chevronIconOpened]: open
    })

  //let periodsBlockWidth
  let errorBlockWidth
  const monthsBlockWidth = 672 //TODO: depends on popupWidth

  let dpDirection = 'row', mDirection = 'row'

  if (layoutIndex === 3) {
    //periodsBlockWidth = popupWidth - monthsBlockWidth
    errorBlockWidth = 836
    dpDirection = 'row'
    mDirection = 'row'
  }
  if (layoutIndex === 2) {
    //periodsBlockWidth = 228.5 * 2 // 457
    errorBlockWidth = 836 - 228.5 * 2 //  379
    dpDirection = 'column'
    mDirection = 'row'
  }
  if (layoutIndex <= 1) {
    //periodsBlockWidth = 228.5 * 2 // 457
    errorBlockWidth = 200 // TODO: set correct value
    dpDirection = 'column'
    mDirection = 'column'
  }

  const footerHeight = 70.5

  const buttonsBlockWidth = popupWidth - errorBlockWidth

  const hLineLength = popupWidth - 28 * 2

  const periods = [getPeriod(context, 0), getPeriod(context, 1)]
  return (
    <div>
      <Button
        id="periodsSelectorWidget"
        buttonRef={anchorRef}
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.tenantsControls}
      >
        <Paper square={true} elevation={0} className={classes.tenantsControls}>
          <GridContainer spacing={0} justify="flex-end">
            <GridItem>
              <GridContainer direction="column" style={{ height: 45 }}>
                <GridItem className={classes.periodHeaderRow0} style={{ height: 25 }}>{periods[0][0].format('D/M/YY')} - {periods[0][1].format('D/M/YY')}</GridItem>
                <GridItem className={classes.periodHeaderRow1} style={{ height: 15 }}>{periods[1][0].format('D/M/YY')} - {periods[1][1].format('D/M/YY')}</GridItem>
              </GridContainer>
            </GridItem>
            <GridItem className={classes.chevronWeirdShift}><ChevronIconRed className={chevronClasses} /></GridItem>
          </GridContainer>


        </Paper>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement="bottom-end"
        className={classes.tenantsControlPopover}
        popperOptions={{
          modifiers: {
            offset: { offset: '0px, 0px' }
          }
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper square={true} className={classes.tenantsControlPopoverPaper} style={{
              width: popupWidth,
              top: -100,
              left: 0
            }}>
              <ClickAwayListener onClickAway={event => {
                if (context.state.ui.periodSelectorOpen.find(x => x)) {
                  return
                }
                handleClose(event)
              }}>
                <GridContainer
                  spacing={0}
                  justify="space-between"
                  direction="column"
                  className={classes.noMP}
                  style={{ width: popupWidth }}>
                  <GridItem className={classes.noMP} style={{ width: popupWidth, height: 30 }}>
                  </GridItem>
                  <GridItem className={classes.noMP}>
                    <GridContainer direction={dpDirection} spacing={0} justify="space-between" className={cx(classes.noMP, classes.popoverGridTopLevel)} style={{ width: popupWidth }}>
                      <GridItem id="monthsBlock" className={classes.noMP} style={{ width: monthsBlockWidth }}>
                        <GridContainer spacing={0} justify="space-between" className={classes.noMP} style={{ width: monthsBlockWidth }} direction={mDirection}>
                          <GridItem id="prevMonth" className={classes.arrowBlock}>
                            <div className={classes.arrowSubBlock}>
                              <IconButton className={classes.arrowButton} onClick={() => context.actions.ui.setPeriodSelectorMonthShift(-1)}>
                                <ChevronIcon className={classes.chevronIcon} style={{ transform: 'rotate(90deg)' }} />
                              </IconButton>
                            </div>
                          </GridItem>
                          <GridItem id="month1" className={classes.monthBlock}><Month monthshift={monthShift - 2} /></GridItem>
                          <GridItem id="monthspace1_2" className={classes.monthSpace}></GridItem>
                          <GridItem id="month2" className={classes.monthBlock}><Month monthshift={monthShift - 1} /></GridItem>
                          <GridItem id="monthspace2_3" className={classes.monthSpace}></GridItem>
                          <GridItem id="month3" className={classes.monthBlock}><Month monthshift={monthShift} /></GridItem>
                          <GridItem id="nextMonth" className={classes.arrowBlock}>
                            <div className={classes.arrowSubBlock}>
                              <IconButton className={classes.arrowButton} onClick={() => context.actions.ui.setPeriodSelectorMonthShift(1)}>
                                <ChevronIcon className={classes.chevronIcon} style={{ transform: 'rotate(-90deg)' }} />
                              </IconButton>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem id="periodsBlock" className={classes.noMP} style={{ width: componentLayouts[layoutIndex].periodsBlockWidth }}>
                        <GridContainer spacing={0} justify={componentLayouts[layoutIndex].periodsJustify} className={classes.noMP} style={{ width: componentLayouts[layoutIndex].periodsBlockWidth }}>
                          <GridItem className={classes.noMP} style={componentLayouts[layoutIndex].periodSplitters[0]}></GridItem>
                          <GridItem id="period0" style={componentLayouts[layoutIndex].periodBlock} className={classes.periodBlock}>
                            <Period index={0} style={componentLayouts[layoutIndex].periodBlock}></Period>
                          </GridItem>
                          <GridItem className={classes.noMP} style={componentLayouts[layoutIndex].periodSplitters[1]}></GridItem>
                          <GridItem className={classes.noMP} style={componentLayouts[layoutIndex].periodSplitters[2]}></GridItem>
                          <GridItem id="period1" style={componentLayouts[layoutIndex].periodBlock} className={classes.periodBlock}>
                            <Period index={1} style={componentLayouts[layoutIndex].periodBlock}></Period>
                          </GridItem>
                          <GridItem className={classes.noMP} style={componentLayouts[layoutIndex].periodSplitters[3]}></GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem className={classes.noMP}>
                    <GridContainer spacing={0} justify="space-between" className={classes.noMP} style={{ width: popupWidth, height: 1 }}>
                      <GridItem className={classes.noMP} style={{ width: 28, height: 1 }}></GridItem>
                      <GridItem className={classes.hLine} style={{ width: hLineLength, height: 1 }}></GridItem>
                      <GridItem className={classes.noMP} style={{ width: 28, height: 1 }}></GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem className={classes.noMP}>
                    <Paper className={classes.noMP} square={true} elevation={0} style={{ width: popupWidth, height: footerHeight }}>
                      <GridContainer spacing={0} justify="space-between" className={classes.noMP} style={{ width: popupWidth, height: footerHeight }}>
                        <GridItem className={classes.noMP} style={{ width: errorBlockWidth }}><p className={classes.error}>{errorMessage(context)}</p></GridItem>
                        <GridItem className={classes.noMP} style={{ width: buttonsBlockWidth }}>
                          <GridContainer className={classes.noMP} spacing={0} justify="flex-end" style={{ width: buttonsBlockWidth, height: footerHeight }}>
                            <GridItem className={classes.noMP}>
                              <Button className={classes.btnCancel} onClick={(event) => {
                                context.actions.users.getAppMetadata()
                                handleClose(event)
                              }}>Cancel</Button>
                            </GridItem>
                            <GridItem className={classes.noMP} style={{ width: 25 }}></GridItem>
                            <GridItem className={classes.noMP}>
                              <Button className={classes.btnApply} onClick={async event => {
                                event.persist()
                                const result = await applyChanges(context, useDashboard)
                                if (result) {
                                  handleClose(event)
                                }
                              }}>Apply</Button>
                            </GridItem>
                            <GridItem className={classes.noMP} style={{ width: 28 }}></GridItem>

                          </GridContainer>
                        </GridItem>
                      </GridContainer>

                    </Paper>
                  </GridItem>
                </GridContainer>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div >)
}

export default withStyles(styles)(PeriodsSelector)