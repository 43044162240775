import backend from '../utils/backend'
import {
  initialState as entityInitialState,
  reducer as entityReducer,
  actions as entityActions,
  alwaysResolvingPromise,
  fetchingEffect,
  debugActionData
} from './entity'

export const initialState = {
  ...entityInitialState
}

export const reducer = (state = initialState, action) => {
  state = entityReducer('systems')(state, action)
  switch (action.type) {
    case `SYSTEM_REPORTS_FETCH_SUCCESS`: {
      const newState = { ...state, list: [...state.list] }
      for (let i = 0; i < newState.list.length; ++i) {
        if (newState.list[i].id === action.payload.systemId) {
          newState.list[i] = {
            ...newState.list[i],
            reports: action.payload.map(report => ({ ...report, instances: [] }))
          }
        }
      }
      newState.isLoading = false
      return newState
    }
    case `SYSTEM_REPORTS_FETCH_ERROR`: {
      return {
        ...state,
        error: action.payload,
        isLoading: false
      }
    }
    case `REPORT_FETCH_SUCCESS`: {
      const newState = { ...state, list: [...state.list] }
      for (let i = 0; i < newState.list.length; ++i) {
        if (newState.list[i].id === action.payload.systemId) {
          newState.list[i] = {
            ...newState.list[i],
            reports: [...newState.list[i].reports || []]
          }
          for (let j = 0; j < newState.list[i].reports.length; ++j) {
            if (newState.list[i].reports[j].reportName === action.payload.reportName) {
              newState.list[i].reports[j] = {
                ...newState.list[i].reports[j],
                instances: [...newState.list[i].reports[j].instances]
              }
              newState.list[i].reports[j].instances[action.payload.reportInstanceIndex] = {
                reportData: action.payload,
                reportParams: action.payload.reportParams
              }
            }
          }
        }
      }
      newState.isLoading = false
      return newState
    }
    default: {
      return state
    }
  }
}

export const actions = dispatch => ({
  ...entityActions('systems', dispatch),
  getReports: systemId => alwaysResolvingPromise(cb => dispatch({
    type: `SYSTEMS_FETCH_START`,
    ...debugActionData('getReports', 'backend.list', [{ resource: 'systems/' + systemId + '/reports' }]),
    effects: async (...args) => {
      const action =
        await fetchingEffect(
          `SYSTEM_REPORTS`,
          backend.list,
          cb,
          { resource: 'systems/' + systemId + '/reports' })(...args)
      let payload = []
      if (action.payload && typeof action.payload[Symbol.iterator] === 'function') {
        payload = [...action.payload]
      }
      payload.systemId = systemId
      return {
        ...action,
        payload
      }
    }
  })),

  getReport: (reportParams, reportInstanceIndex = 0) => alwaysResolvingPromise(cb => dispatch({
    type: `SYSTEMS_FETCH_START`,
    ...debugActionData('getReport', 'backend.getReport', [{ reportParams }]),
    effects: async (...args) => {
      const action =
        await fetchingEffect(
          `REPORT`,
          backend.getReport,
          cb,
          reportParams)(...args)
      return {
        ...action,
        payload: {
          ...action.payload,
          systemId: reportParams.system,
          reportName: reportParams.chart,
          reportParams,
          reportInstanceIndex
        }
      }
    }
  }))

})