/**
 * helpers for working with state
 */

import moment from 'moment'

/**
 * Contents of drop-downs
 */
export const periodTypes = [
  [
    // 'Right now',
    'Today',
    'Last day',
    // 'Last 24h',
    'Last 7 days',
    'Last week',
    'Last month',
    'User defined'],
  ['Previous period',
    'Previous month',
    'Previous year',
    'User defined']
]

/**
 * Returns period (selected in Periods Selector component)
 * @param {*} context 
 * @param {*} pIndex 
 */
export const getPeriod = (context, pIndex) => {
  let m1, m2
  let option = context.state.users.current.app_metadata.timePeriods[pIndex].type
  if (pIndex === 0) {

    if (['Right now', 'Today'].indexOf(option) >= 0) {
      m1 = new moment()
      m1.startOf('day')
      m2 = m1.clone()
    }
    if (['Last day', 'Last 24h'].indexOf(option) >= 0) {
      m1 = new moment()
      m1.startOf('day')
      m1.add(-1, 'day')
      m2 = m1.clone()
    }
    if (option === 'Last 7 days') {
      m2 = new moment()
      m2.startOf('day')
      m2.add(-1, 'day')
      m1 = m2.clone()
      m1.add(-6, 'day')
    }
    if (option === 'Last week') {
      m2 = new moment()
      m2.startOf('week')
      m1 = m2.clone()
      m1.add(-6, 'day')
    }
    if (option === 'Last month') {
      m2 = new moment()
      m2.startOf('month')
      m2.add(-1, 'day')
      m1 = m2.clone()
      m1.startOf('month')
    }

    if (option === 'User defined') {
      m1 = moment(context.state.users.current.app_metadata.timePeriods[pIndex].dateA)
      m2 = moment(context.state.users.current.app_metadata.timePeriods[pIndex].dateB)
    }
  }
  if (pIndex === 1) {
    const [m01, m02] = getPeriod(context, 0)
    if (option === 'Previous period') {
      if (context.state.users.current.app_metadata.timePeriods[0].type === 'Last month') {
        m2 = m01.clone()
        m2.startOf('month')
        m2.add(-1, 'day')
        m1 = m2.clone()
        m1.startOf('month')
      } else {
        m2 = m01.clone()
        m2.add(-1, 'day')
        m1 = m2.clone()
        m1.add(m01 - m02, 'ms')
      }
    }
    if (option === 'Previous month') {
      m1 = m01.clone()
      m2 = m02.clone()
      m1.add(-1, 'month')
      m2.add(-1, 'month')
    }
    if (option === 'Previous year') {
      m1 = m01.clone()
      m2 = m02.clone()
      m1.add(-1, 'year')
      m2.add(-1, 'year')
    }
    if (option === 'User defined') {
      m1 = moment(context.state.users.current.app_metadata.timePeriods[pIndex].dateA)
      m2 = moment(context.state.users.current.app_metadata.timePeriods[pIndex].dateB)
    }
  }
  return [m1, m2]
}

/**
 * return Report object from state
 * @param {*} context 
 * @param {*} systemId 
 * @param {*} reportName 
 */
export const getReport = (context, systemId, reportName) => {
  const system = context.state.systems.list.find(s => s.id === systemId)
  if (!system || !system.reports) {
    return undefined
  }
  return system.reports.find(r => r.reportName === reportName)
}

/**
 * 'Instance' means actual report data for a given set of input parameters (channel list, period, interval, etc.)
 * @param {*} context 
 * @param {*} systemId 
 * @param {*} reportName 
 * @param {*} reportInstanceIndex 
 */
export const getReportInstance = (context, systemId, reportName, reportInstanceIndex = 0) => {
  const report = getReport(context, systemId, reportName)
  if (!report || !report.instances) {
    return undefined
  }
  return report.instances[reportInstanceIndex]
}

/**
 * Returns interval value (in miliseconds) for the given report on the Dashboard
 * using it's 'timing' value saved in app_metadata->dashboardItems, if it exists there.
 * Otherwise looks up in reportSettings or returns default value = 24 * 60 * 60 * 1000 (24 hours or 'DAYS' option)
 * @param {*} context 
 * @param {*} systemId 
 * @param {*} reportName 
 */
export const getInterval = (context, systemId, reportName, useDashboard = false) => {
  let item = useDashboard
    ? context.state.users.current.app_metadata.dashboardItems.find(item =>
      (item.system === systemId || item.systemId === systemId) && (item.chart === reportName || item.reportName === reportName))
    : undefined
  if (item) {
    if (item.interval) {
      return item.interval
    }
    if (item.timing === '24h') {
      return 60 * 60 * 1000
    }
    if (item.timing === '30d' || item.timing === 'last') {
      return 24 * 60 * 60 * 1000
    }
  }
  item = context.state.users.current.app_metadata.reportSettings.find(item =>
    (item.system === systemId || item.systemId === systemId) && (item.chart === reportName || item.reportName === reportName))
  if (item) {
    return item.interval
  }
  return 24 * 60 * 60 * 1000
}

/**
 * Same logic as with intervals - but about view options (MAP / TABLE):
 * @param {*} context 
 * @param {*} systemId 
 * @param {*} reportName 
 */
export const getViewOption = (context, systemId, reportName, useDashboard = false) => {
  let item = useDashboard
    ? context.state.users.current.app_metadata.dashboardItems.find(item =>
      (item.system === systemId || item.systemId === systemId) && (item.chart === reportName || item.reportName === reportName))
    : undefined
  if (item) {
    if (item.viewOption) {
      return item.viewOption
    }
  }
  item = context.state.users.current.app_metadata.reportSettings.find(item =>
    (item.system === systemId || item.systemId === systemId) && (item.chart === reportName || item.reportName === reportName))
  if (item) {
    return item.viewOption
  }
}

/**
 * Sets app_metadata's entry for report - in reportSettings or dashboardItems
 * @param {*} context 
 * @param {*} systemId 
 * @param {*} reportName 
 * @param {*} newProperties 
 * @param {*} useDashboard 
 */
export const setAppMetadata = async (context, systemId, reportName, newProperties, useDashboard = true) => {
  const collectionName = useDashboard ? 'dashboardItems' : 'reportSettings'
  const collection = context.state.users.current.app_metadata[collectionName].map(x => x)
  const index = collection.findIndex(item =>
    (item.system === systemId || item.systemId === systemId) &&
    (item.chart === reportName || item.reportName === reportName))
  if (index >= 0) {
    Object.assign(collection[index], newProperties)
  } else {
    collection.push({ systemId, reportName, ...newProperties })
  }
  const appMetadata = {}
  appMetadata[collectionName] = collection
  return context.actions.users.updateAppMetadataImmediate(appMetadata)
}

export const getChannelIds = context => {
  if (context.state.users.current
    && context.state.users.current.app_metadata
    && context.state.users.current.app_metadata.selectedChannels
    && context.state.users.current.app_metadata.selectedChannels.length) {
    return context.state.users.current.app_metadata.selectedChannels
  }
  return []
}

export const getCampaignName = (context, systemId, reportName, useDashboard = false) => {
  const metaData = context.state.users.current.app_metadata
  const settings = useDashboard ? metaData.dashboardItems : metaData.reportSettings
  let item = settings.find(item =>
    (item.system === systemId || item.systemId === systemId) && (item.chart === reportName || item.reportName === reportName))
  return item && item.campaignName
}



/**
 * Sequentially requests Systems (without overwrite in state), then Reports (without actual data), the data for each Report
 * @param {*} context 
 */
export const refreshAllReports = async (context, useDashboard = false) => {
  const appMetadataResult = await context.actions.users.getAppMetadata()
  const ctx = { state: { users: appMetadataResult.state } }
  const systems = await context.actions.systems.getAllItems(true, 'systems/listSummary')
  // (here we can use .filter() before map)
  systems.result && systems.result.map(async sys => {
    const reports = await context.actions.systems.getReports(sys.id)
    reports.result && reports.result.forEach(async report => {
      if (useDashboard &&
        (!context.state.users.current ||
          !context.state.users.current.app_metadata ||
          !context.state.users.current.app_metadata.dashboardItems ||
          !context.state.users.current.app_metadata.dashboardItems.find(di =>
            (di.system === sys.id || di.systemId === sys.id) && (di.chart === report.reportName || di.reportName === report.reportName)))) {
        return
      }
      let prevInstance
      for (let i = 0; i < report.numOfInstances; ++i) {
        const [m1, m2] = getPeriod(ctx, i)
        m2.add(1, 'day')
        let reportParams = {
          system: sys.id,
          chart: report.reportName,
          interval: getInterval(ctx, sys.id, report.reportName, useDashboard),
          start: m1.valueOf(),
          end: m2.valueOf(),
          channelIds: getChannelIds(ctx),
          campaignName: getCampaignName(ctx, sys.id, report.reportName, useDashboard)
        }
        if (prevInstance && prevInstance.result && typeof prevInstance.result.paramsForNextInstance === 'object') {
          reportParams = Object.assign(reportParams, prevInstance.result.paramsForNextInstance)
        }
        prevInstance = await context.actions.systems.getReport(reportParams, i)
      }
    })
  })
}

export const refreshReport = async (context, systemId, reportName, useDashboard = false) => {
  const system = context.state.systems.list.find(s => s.id === systemId)
  if (!system) {
    return
  }

  const report = system.reports.find(r => r.reportName === reportName)
  if (!report) {
    return
  }

  let prevInstance
  for (let i = 0; i < report.numOfInstances; ++i) {
    const [m1, m2] = getPeriod(context, i)
    m2.add(1, 'day')
    let reportParams = {
      system: systemId,
      chart: reportName,
      interval: getInterval(context, systemId, reportName, useDashboard),
      start: m1.valueOf(),
      end: m2.valueOf(),
      channelIds: getChannelIds(context),
      campaignName: getCampaignName(context, systemId, reportName, useDashboard)
    }
    if (prevInstance && prevInstance.result && typeof prevInstance.result.paramsForNextInstance === 'object') {
      reportParams = Object.assign(reportParams, prevInstance.result.paramsForNextInstance)
    }
    prevInstance = await context.actions.systems.getReport(reportParams, i)
  }
}

/**
 * This is what happens when we press "star" icon on the Report
 * @param {*} context 
 * @param {*} system 
 * @param {*} chart 
 * @param {*} timing 
 */
export const toggleDashboardItem = async (context, systemId, reportName, interval) => {
  const filtered = context.state.users.current.app_metadata.dashboardItems.filter(item => item.reportName !== reportName || item.systemId !== systemId)
  if (filtered.length === context.state.users.current.app_metadata.dashboardItems.length) {
    filtered.push({ reportName, systemId, interval })
  }
  context.actions.users.updateAppMetadata({ dashboardItems: filtered })
}

/**
 * Returns actual tenant name
 * @param {*} context 
 */
export const getTenant = context => {
  if (context.state.users.current.user_metadata) {
    return context.state.users.current.user_metadata.company
  }
}
