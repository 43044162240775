import Swal from 'sweetalert2'
import * as constants from './Constants'

export const createSuccessMessage = (callback = () => { }) =>
  (message = constants.Message.success) => {
    Swal.fire(constants.Title.success, message, constants.Icon.success).then(callback)
  }

export const showSuccessMessage = createSuccessMessage()

export const showMessage = (swalArgs = { title: constants.Title.info,  type: constants.Icon.info }, message) => {
  if (typeof message === 'string') {
    swalArgs.text = message
  }
  if (typeof message === 'object') {
    Object.assign(swalArgs, message)
  }
  return Swal.fire(swalArgs)
}


export const showErrorMessage = (message = constants.Message.error) => {
  const swalArgs = {
    title: constants.Title.error,
    type: constants.Icon.error
  }
  return showMessage(swalArgs, message)
}

export const showWarningMessage = (message = constants.Message.warning) => {
  const swalArgs = {
    title: constants.Title.warning,
    type: constants.Icon.warning
  }
  return showMessage(swalArgs, message)
}

export const showConfirmationMessage = (onConfirm, opts) => {
  return Swal.fire({
    title: (opts && opts.title) || constants.Title.warning,
    text: (opts && opts.html && opts.html.length !== 0 ? undefined : (opts && opts.text) || constants.Message.warning),
    html: (opts && opts.html) || undefined,
    type: constants.Icon.warning,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: (opts && opts.confirmButtonText) || constants.Message.confirmButtonText
  }).then((result) => {
    if(result.dismiss === 'cancel') {
      ((opts && opts.onCancel) || (() => null))()
      return false
    } else if (result.value) {
      onConfirm && onConfirm()
      return true
    }
    return false
  })
}
