import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { Switch, Route, Redirect } from "react-router-dom"
import PerfectScrollbar from "perfect-scrollbar"
import "perfect-scrollbar/css/perfect-scrollbar.css"
import withStyles from "@material-ui/core/styles/withStyles"
import Header from "../components/Header/Header.jsx"
import Footer from "../components/Footer/Footer.jsx"
import Sidebar from "../components/Sidebar/Sidebar.jsx"
import appRoutes from "../routes/app.jsx"
import appStyle from "../assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx"
import { componentWithContext } from '../state/DispatchContext'
import HeaderJobsAlert from '../components/HeaderJobsAlert/HeaderJobsAlert.jsx'
import HeaderTitle from "../components/Header/HeaderTitle.jsx"
import Toaster, { ToasterController } from "../components/Toaster/Toaster.jsx"

const appRoutesFlatList = () => appRoutes().reduce((total, current) => {
  let result = total
  if (current.views) result = [...total, ...current.views]
  result = [...result, current]
  return result
}, [])

const slideTimeout = 1000
const SwitchRoutes = () => (
  <Switch>
    {appRoutesFlatList().map((prop, key) => {
      if (!localStorage.getItem('token'))
        return <Redirect from={prop.path} to='/login' key={key} />

      if (prop.redirect) {
        if (prop.exact) {
          return <Redirect exact from={prop.path} to={prop.pathTo} key={key} />
        } else {
          return <Redirect from={prop.path} to={prop.pathTo} key={key} />
        }
      }

      return <Route path={prop.path} component={prop.component} key={key} />
    })}
  </Switch>
)

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest)
  return (
    React.createElement(component, finalProps)
  )
}

const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route {...rest} render={routeProps => {
      return renderMergedProps(component, routeProps, rest)
    }} />
  )
}

var ps

class App extends React.Component {
  constructor(props) {
    super(props)
    global.toasterController = new ToasterController()
  }
  componentDidMount () {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      })
      document.body.style.overflow = "hidden"
    }
  }
  componentWillUnmount () {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy()
    }
    window.removeEventListener("resize", this.resizeFunction)
  }
  componentDidUpdate (e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0
    }
  }
  getRoute () {
    return this.props.location.pathname !== "/maps/full-screen-maps"
  }
  sidebarMinimize () {
    this.props.context.actions.toggleSidebarMiniActive(slideTimeout)()
  }

  render () {
    window.context = this.props.context
    const routes = appRoutes()
    const { classes, ...rest } = this.props
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.props.context.state.ui.sidebarMiniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      })
    const switchRoutesForHeader = (
      <Switch>
        {appRoutesFlatList().map((prop, key) => {
          if (!localStorage.getItem('token') || prop.redirect)
            return null

          return <PropsRoute
            path={prop.path} component={Header}
            key={key}
            miniActive={this.props.context.state.ui.sidebarMiniActive}
            routes={routes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest} />
        })}
      </Switch>
    )
    let containerStyle
    let pageWidth
    if (this.props.context.state.ui.window.innerWidth < 960) {
      pageWidth = this.props.context.state.ui.window.innerWidth
    } else {
      if (this.props.context.state.ui.sidebarMiniActive) {
        pageWidth = this.props.context.state.ui.window.innerWidth - 105
      } else {
        pageWidth = this.props.context.state.ui.window.innerWidth - 320
      }
    }

    const minWidthForMargins = 600

    if (pageWidth < 960) {
      containerStyle = {
        paddingLeft: 10 + (62-10) * (pageWidth - minWidthForMargins) / (960 - minWidthForMargins),
        paddingRight: 10 + (62-10) * (pageWidth - minWidthForMargins) / (960 - minWidthForMargins)
      }
    }

    if (pageWidth >= 960 && pageWidth <= 1600) {
      containerStyle = {
        paddingLeft: 62,
        paddingRight: 62,
      }
    }
    if (pageWidth > 1600 && pageWidth < 1814) {
      containerStyle = {
        paddingLeft: (pageWidth - 1476) / 2,
        paddingRight: (pageWidth - 1476) / 2,
      }
    }
    if (pageWidth >= 1814) {
      containerStyle = {
        paddingLeft: 169,
        paddingRight: 169,
      }
    }
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          logoText={"Nowtilus"}          
          color="grey"
          bgColor="black"
          miniActive={this.props.context.state.ui.sidebarMiniActive}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel" id="mainPanel">
          <Toaster controller={global.toasterController} />
          {switchRoutesForHeader}
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content} id='contentDiv' style={{ paddingLeft: 0, paddingRight: 0 }}>
              <HeaderJobsAlert />
              <div className={classes.container} id='containerDiv' style={{ ...containerStyle, marginLeft: 0, marginRight: 0 }}>
                <HeaderTitle routes={routes} />
                <SwitchRoutes />
              </div>
            </div>
          ) : (
              <div className={classes.map} id="mapDiv"><SwitchRoutes /></div>
            )}
          {this.getRoute() ? <Footer fluid /> : null}
        </div>
      </div>
    )
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
}

export default componentWithContext(withStyles(appStyle)(App))
