/**
 * Sets the preRoll and enabe Preroll properties in a safe way
 * Used in both front and back end. Sadly we have to duplicate
 * the code because we cant import form outside src in the FE
 * without ejecting from create-react-app!
 *
 * @param {*} channel
 * @param {*} setFrom
 */
module.exports = (channel, setFrom) => {
  const defaultPreRollSettings = {
    adSource: {
      system: 'spotx',
      url: 'https://test.ad-server.com',
      params: {}
    },
    maxDuration: 30,
    maxCount: 1,
    useDifferentAdServer: true
  }

  setFrom = setFrom || channel
  // has to be explictly boolean or we get "unprocessable entity errors"
  if (channel.type === 'hls-live') {
    channel.enablePreRoll = setFrom.enablePreRoll || false
    channel.preRoll = channel.enablePreRoll ? (setFrom.preRoll || defaultPreRollSettings) : null
  }
}
