import React, { useEffect, useRef } from 'react'
import { StateInspector } from 'reinspect'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ReactGA from 'react-ga'

import indexRoutes from './routes/index.jsx'
import './assets/scss/material-dashboard-pro-react.css?v=1.4.0'
import {
  initialState,
  reducer,
  actions
} from './state/entity'
import {
  initialState as uiInitialState,
  reducer as uiReducer,
  actions as uiActions
} from './state/ui.js'
import {
  initialState as usersInitialState,
  reducer as usersReducer,
  actions as usersActions
} from './state/users'
import {
  initialState as systemsInitialState,
  reducer as systemsReducer,
  actions as systemsActions
} from './state/systems'
import DispatchContext from './state/DispatchContext'
import useReducerWithEffects from './state/useReducerWithEffects'

if (window.location.host === 'admin.serverside.ai') {
  ReactGA.initialize('UA-116929900-3');
  ReactGA.pageview('/app');
}

const ContextProvider = props => {
  const [channels, dispatchChannels] = useReducerWithEffects(reducer('channels'), initialState, {}, 'channels')
  const [users, dispatchUsers] = useReducerWithEffects(usersReducer, usersInitialState, {}, 'users')
  const [systems, dispatchSystems] = useReducerWithEffects(systemsReducer, systemsInitialState, {}, 'systems')
  const [ui, dispatchUI] = useReducerWithEffects(uiReducer, uiInitialState, {}, 'ui')

  const context = {
    state: {
      channels,
      users,
      systems,
      ui
    },
    actions: {
      channels: actions('channels', dispatchChannels),
      users: usersActions(dispatchUsers),
      systems: systemsActions(dispatchSystems),
      ui: uiActions(dispatchUI)
    }
  }

  const contextRef = useRef(context)

  /**
   * here we set up centralized resize listener:
   */
  useEffect(() => {
    const resizeFunction = () => {
      contextRef.current.state.ui.getDebounce()(() => {
        contextRef.current.actions.ui.setWindowSize(window.innerWidth, window.innerHeight)
        contextRef.current.actions.ui.callResizers()
      }, 500)()
    }
    window.addEventListener("resize", resizeFunction)
    contextRef.current.actions.ui.setWindowSize(window.innerWidth, window.innerHeight)
    contextRef.current.actions.users.getMe()
  }, [])
  return (
    <DispatchContext.Provider value={context}>
      {props.children}
    </DispatchContext.Provider>
  )
}

ReactDOM.render(
  <StateInspector name="nemo2app">
    <ContextProvider>
      <BrowserRouter>
        <Switch>
          {indexRoutes.map((prop, key) => {
            return <Route path={prop.path} component={prop.component} key={key} />
          })}
        </Switch>
      </BrowserRouter>
    </ContextProvider>
  </StateInspector>,
  document.getElementById("root")
)
