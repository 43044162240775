import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import tasksStyle from "../../assets/jss/material-dashboard-pro-react/components/tasksStyle.jsx";
import backend from '../../utils/backend'
import { showErrorMessage } from '../Alerts/Alerts'
import GridItem from '../../components/Grid/GridItem.jsx'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import Button from "@material-ui/core/Button"

class Tasks extends React.Component {
  state = {
    checked: this.props.checkedIndexes,
    notifications: [],
    hasMore: true,
    lastPage: -1
    // initialLoad: false
  };

  async loadNotifications({status, userOnly = true, limit, offset}) {
    try {
      return backend.list({
        resource: 'notifications',
        status,
        userOnly,
        limit,
        offset
      })
    } catch (e) {
      console.error(e)
      showErrorMessage(e.message)
    }
  }

  async loadPage() {
    const page = this.state.lastPage + 1
    const limit = 50
    const offset = page * limit
    const notifications = await this.loadNotifications({
      status: this.props.status,
      userOnly: this.props.userOnly,
      limit,
      offset
    })
    let updatedNotifications = this.state.notifications
    updatedNotifications.push(...notifications)
    this.setState({
      hasMore: notifications.length === 0 || notifications.length < limit ? false : true,
      notifications: updatedNotifications,
      lastPage: page
    })
  }

  handleToggle = value => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  };

  async updateNotification(command, notification) {
    if (command === 'archive') {
      notification.status = 'archived'
      try {
        await backend.update('notifications', notification)
      } catch (e) {
        await showErrorMessage('Cannot update the notification', e.message)
      }
    }
    const updatedNotifications = this.state.notifications.filter(oldNoti => oldNoti.id !== notification.id)
    this.setState({notifications: updatedNotifications})
  }

  componentDidMount() {
    this.loadPage()
  }

  render() {

    const { classes } = this.props;

    const items = this.state.notifications.map(notification => (
      <GridContainer key={notification.id}>
        <GridItem xs={1}>
          <Checkbox
            checked={notification.status === 'archived'}
            tabIndex={-1}
            onClick={() => {this.updateNotification('archive', notification)}}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot
            }}
          />
        </GridItem>
        <GridItem xs={11}>
          {notification.createdAt} - {notification.topic} - {notification.content}
        </GridItem>
      </GridContainer>
    ))

    return (
      <GridContainer>
        <GridItem xs={12}>
          {items}
        </GridItem>
        {this.state.hasMore
          ? <Button onClick={this.loadPage.bind(this)}>Load more</Button>
          : ''
        }
      </GridContainer>
    );
  }
}

Tasks.propTypes = {
  classes: PropTypes.object.isRequired,
  tasksIndexes: PropTypes.arrayOf(PropTypes.number),
  checkedIndexes: PropTypes.arrayOf(PropTypes.number),
  tasks: PropTypes.arrayOf(PropTypes.node)
};

export default withStyles(tasksStyle)(Tasks);
