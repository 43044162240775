import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import extendedTablesStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import { withRouter } from 'react-router-dom'
import backend from '../../utils/backend'
import { showErrorMessage } from '../../components/Alerts/Alerts'

class ProgressBar extends React.Component { 
    timer = null

    constructor(props) {
        super(props)
        this.state = {
            show: true,
            progress: props.progress,
            status: null
        }
    }

    componentDidMount() {
        this.timer = setInterval(async () => {
            const job = await this.loadJob();
            if(job.status !== 'running') {
                this.setState({
                    show: false,
                    status: job.status
                });
                clearInterval(this.timer)
            } else {
                if(this.state.progress < 100) {
                    this.setState({
                        progress: this.state.progress + 2
                    });
                }
            }
        }, 5000)
    }

    componentWillUnmount() {
        if(this.timer) 
            clearInterval(this.timer)
    }

    async loadJob() {
        try {
          return backend.get('jobs', this.props.id)
        } catch (e) {
          console.error(e)
          showErrorMessage(e.message)
        }
    }

    render() {
        return (
            this.state.show
            ? <div>
                <div style={{
                    display: 'inline-block',
                    border: '1px solid #949494',
                    width: '100px',
                    height: '15px',
                    marginRight: '10px',
                    verticalAlign: 'middle'
                }}>
                    <div style={{
                        backgroundColor: '#ff0f6a',
                        height: '100%',
                        width: `${this.state.progress}%`
                    }}></div>
                </div>
                {this.state.progress}%
            </div>
            : <div style={{
                textTransform: 'uppercase'
            }}>{this.state.status}</div>
        )
    }

}

export default withRouter(withStyles(extendedTablesStyle)(ProgressBar))