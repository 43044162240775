import { noMP, cYbnd, cY, transition } from '../../../assets/jss/material-dashboard-pro-react'

export const styles = theme => ({
  noMP,  
  tenantsControls: {
    paddingRight: 10,
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
    textTransform: 'none',
    borderRadius: 0,
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#ffffff'
    },
    fontWeight: 400,
    width: 240
  },
  chevronIcon: {
    width: 10,
    height: 10,
    ...transition
  },
  chevronIconMain: {
    width: 13,
    height: 13,
    ...transition
  },
  chevronIconOpened: {
    transform: 'rotate(180deg)',
    ...transition
  },
  chevronWeirdShift: {
    paddingTop: '12px !important'
  },
  tenantsControlPopover: {
    zIndex: 2000,
    borderRadius: 0,
    marginTop: 8
  },
  periodTypeDropdownPopper: {
    zIndex: 2100,
    borderRadius: 0,
    height: 242
  },
  paper: {
    marginRight: theme.spacing.unit * 2,
  },
  popoverGridTopLevel: {
  },
  arrowBlock: {
    ...noMP,
    position: 'relative',
    width: 30,
    height: 215.5
  },
  arrowSubBlock: {
    position: 'absolute',
    top: 14,
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  arrowButton: {
    width: 28,
    height: 28,
    padding: 0,
    borderRadius: 0,
    '&:active': {
      borderRadius: 0
    }
  },
  monthBlock: {
    ...noMP,
    width: 190,
    height: 215.5
  },
  monthSpace: {
    ...noMP,
    width: 15,
    height: 15
  },
  periodBlock: {
    ...noMP,
    width: 190,
  },
  btnCancel: {
    backgroundColor: '#f2f4f7',
    color: '#818994',
    width: 120,
    height: 40,
    marginTop: 15
  },
  btnApply: {
    backgroundColor: '#ff0066',
    color: '#ffffff',
    width: 120,
    height: 40,
    marginTop: 15,
    '&:hover': {
      backgroundColor: '#ee0044'
    },
  },
  error: {
    color: '#ff0066',
    paddingLeft: 28,
    paddingTop: 5
  },
  emptyHeader: {
    height: 30.5
  },
  hLine: {
    ...noMP,
    height: 1,
    backgroundColor: '#dadfe6'
  },
  monthMain: {
    ...noMP,
    width: 190
  },
  monthHeader: {
    ...noMP,
    height: 30,
    width: 190,
    backgroundColor: '#dadfe6',
    position: 'relative'
  },
  monthHeaderSubBlock: {
    width: 190,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    textAlign: 'center',
    fontWeight: 400,
    textTransform: 'uppercase'
  },
  monthDays: {
    ...noMP,
    height: 176,
    width: 190,
    backgroundColor: '#fafbfc'
  },
  monthFooter: {
    ...noMP,
    height: 15.5,
    width: 190
  },

  monthDaysHeader: {
    ...noMP,
    height: 5,
    width: 190
  },
  monthDaysDays: {
    ...noMP,
    width: 190
  },
  monthDaysWeekDay: {
    ...noMP,
    height: 20,
    width: 20,
    fontSize: 13,
    textAlign: 'right',
    cursor: 'default',
    color: '#dadfe6',
    paddingRight: '2px !important'
  },
  inPeriod0: {
    color: '#ffffff !important',
    backgroundColor: '#ff0066',
    fontWeight: 400,
    borderRadius: 2
  },
  inPeriod1: {
    color: '#ffffff !important',
    backgroundColor: '#6282b3',
    fontWeight: 400,
    borderRadius: 2
  },
  monthDaysFooter: {
    ...noMP,
    height: 5,
    width: 190
  },
  monthDaysLeftRight: {
    ...noMP,
    width: 7
  },
  currentMonth: {
    color: '#818994',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: '#dadfe6'
    }
  },
  periodHeaderBlock: {
    ...noMP,
    width: 190,
    height: 40,

    backgroundColor: '#f2f4f7'
  },
  periodHeader: {
    ...noMP,
    width: 190,

  },
  periodHeaderTextBlock: {
    ...noMP,
    width: 110,
    position: 'relative',
  },
  periodHeaderText: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    textAlign: 'left',
    paddingLeft: '20px !important',
    fontSize: 13
  },
  periodHeaderSelectBlock: {
    ...noMP,
    width: 150,
  },
  periodHeaderCheckboxBlock: {
    ...noMP,
    width: 40,
    paddingLeft: '3px !important',
    paddingTop: '3px !important'
  },
  periodHeaderChevronBlock: {
    ...noMP,
    width: 40,
    paddingTop: '3px !important'
  },
  periodDateBlock: {
    ...noMP,
    width: 80.5,
    height: 30,
    position: 'relative'
  },
  periodDate: {
    ...noMP,
    width: 80.5,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    textAlign: 'center'
  },
  periodFieldBlock: {
    ...noMP,
    width: 190
  },
  periodField: {
    ...noMP,
    width: 190,
    paddingTop: '15px !important'
  },
  periodSepBlock: {
    ...noMP,
    width: 29,
    position: 'relative'
  },
  periodSep: {
    ...noMP,
    width: 29,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    textAlign: 'center'
  },
  listIcons: {
    position: 'absolute',
    width: 14,
    height: 13
  },
  checkIcon: {
    width: 9,
    height: 9
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  iconButton: {
    width: 34,
    height: 34,
    cursor: 'default'
  },
  periodType: {
    ...noMP,
    cursor: 'default',
    height: 40,
    '&:hover': {
      backgroundColor: '#f2f4f7'
    },
    textAlign: 'right',
    borderLeft: '1px solid #f2f4f7',
    borderRight: '1px solid #f2f4f7',
  },
  periodTypeDropdownPaper: {
    ...noMP,
    width: 190,
    borderBottom: '1px solid #f2f4f7',
    borderTop: '1px solid #f2f4f7'
  },
  cYbnd,
  cY,
  periodTypeText: {
    width: 190,
    textAlign: 'right',
    paddingRight: '15px !important'
  },
  opacity50: {
    opacity: 0.5
  },
  periodHeaderRow0: {
    fontSize: 18
  },
  periodHeaderRow1: {
    fontSize: 12
  }
})