import React, { Component } from 'react'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import TextField from '@material-ui/core/TextField'

import ResourceEnumDropdown from './ResourceEnumDropdown.jsx'

class ResourceDetails extends Component {
  render () {
    let { schema, data, handleChange, exclude, multiline, classes } = this.props
    exclude = exclude || []
    if (!schema || typeof schema.properties !== 'object') {
      return <div><GridContainer justify="space-between">No schema!</GridContainer></div>
    }
    
    return (
      <div> <GridContainer justify="space-between">
        {Object.keys(
          schema.properties
        ).map(key => (
          !exclude.includes(key) &&
          <GridItem key={key} xs={4}>
            {schema.properties[key].enum
              ? <ResourceEnumDropdown
                enumArray={schema.properties[key].enum}
                theKey={key}
                data={data}
                handleChange={handleChange}
                required={schema.required.includes(key)}
                classes={classes}
              />
              : <TextField
                id={key}
                name={key}
                label={key}
                fullWidth
                // className={classes.textField}
                value={data[key] || ''}
                onChange={handleChange}
                margin="normal"
                multiline={multiline && multiline.includes(key)}
                required={schema.required.includes(key)}
              />}
          </GridItem>
        ))}
      </GridContainer>
      </div>
    )
  }
}

export default ResourceDetails
