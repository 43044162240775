import React from "react"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import headerStyle from "../../assets/jss/material-dashboard-pro-react/components/headerStyle.jsx"
import { withRouter } from 'react-router-dom'
import { Grid } from "@material-ui/core"

class HeaderTitle extends React.Component {
  activeRoute (routeName) {
    if(!routeName) return false
    let routeMatcher = new RegExp(routeName.replace(/:[^\s/]+/g, '([\\w-]+)'))
    return this.props.location.pathname.match(routeMatcher)
  }

  getSubRoutes() {
    return this.props.routes.filter((prop) => !!prop.views).reduce((result, prop) => {
      return [
        ...result,
        ...prop.views
      ];
    }, [])
  }

  getTitleFromRoute() {
    const result = [
      ...this.props.routes,
      ...this.getSubRoutes()
    ].filter((prop) => this.activeRoute(prop.path))
      .filter((prop) => !!prop.title)
      .reduce((ret, prop) => {
        return [
          ...ret,
          {
            ...prop,
            routeMatch: this.activeRoute(prop.path)
          }
        ]
      }, [])
    return result.length !== 0 ? result[0] : null
  }

  render() {
    const routeInfo = this.getTitleFromRoute()
    if(!!routeInfo) {
      return (
        <div style={{ marginBottom: 28 }}>
          <Grid container>
            <Grid item xs={6}>
              <h3 style={{
                fontWeight: 'bold',
                marginTop: 0,
                color: '#6d8dbe'
              }}>{routeInfo.title}</h3>
            </Grid>
            { routeInfo.subTitle && routeInfo.routeMatch && routeInfo.routeMatch[1] &&
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <h3 style={{
                fontWeight: 'bold',
                marginTop: 0,
                marginBottom: 5,
                color: '#6d8dbe'
              }}>
                {routeInfo.subTitle}
              </h3>
              <p style={{ color: '#b1b1b1', marginBottom: 0 }}>
                {routeInfo.routeMatch[1]}
              </p>
            </Grid> }
          </Grid>
        </div>
      )
    } else {
      return (<div />)
    }
  }
}


export default withRouter(withStyles(headerStyle)(HeaderTitle))
