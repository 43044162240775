import Dashboard from "../views/Dashboard/Dashboard.jsx"
import ReportsPage from "../views/Reports/ReportsPage.jsx"
import PlaylistsPage from "../views/Playlists/Playlists.jsx"
import Channels from "../views/Channels/Channels.jsx"
import Systems from "../views/Systems/Systems.jsx"
import Assets from "../views/Assets/Assets.jsx"
import Jobs from "../views/Jobs/Jobs"
import Workflows from "../views/Workflows/Workflows.jsx"
import Users from "../views/Settings/Users/Users.jsx"
import Account from "../views/Settings/Account/Account.jsx"
import NotificationsPage from "../views/Settings/Notifications/Notifications.jsx"
import Profile from "../views/Settings/Profile/Profile.jsx"
import Error404 from "../views/Errors/Error404.jsx"

import DashboardIcon from "@material-ui/icons/Dashboard"

import Theaters from "@material-ui/icons/Theaters"
import LinearScale from "@material-ui/icons/LinearScale"
import ListIcon from '@material-ui/icons/List';

import { ReactComponent as AnalyticsIconGrey } from '../assets/icons/serverside-icon-analytics-grey.svg'
import { ReactComponent as AnalyticsIconRed } from '../assets/icons/serverside-icon-analytics-red.svg'

import { ReactComponent as SSAIChannelsIconGrey } from '../assets/icons/serverside-icon-ssai-channel-grey.svg'
import { ReactComponent as SSAIChannelsIconRed } from '../assets/icons/serverside-icon-ssai-channel-red.svg'

import { ReactComponent as AssetsIconGrey } from '../assets/icons/serverside-icon-assets-grey.svg'
import { ReactComponent as AssetsIconRed } from '../assets/icons/serverside-icon-assets-red.svg'

import { ReactComponent as SettingsIconGrey } from '../assets/icons/serverside-icon-system-settings-grey.svg'
import { ReactComponent as SettingsIconRed } from '../assets/icons/serverside-icon-system-settings-red.svg'

import { ReactComponent as SupportIconGrey } from '../assets/icons/serverside-icon-support.svg'

import token from '../utils/token'

var settingsViews = [
  {
    path: "/app/settings/users/:userId",
    name: "Users",
    title: 'User Details',
    mini: "US",
    component: Users,
    roles: ['Admin', 'SuperAdmin'],
    sidebar: false
  },
  {
    path: "/app/settings/users",
    name: "Users",
    title: 'Users',
    mini: "US",
    component: Users,
    roles: ['Admin', 'SuperAdmin']
  },
  {
    path: "/app/settings/account",
    name: "Account",
    mini: "AC",
    component: Account,
    roles: ['Admin', 'SuperAdmin']
  },
  {
    path: "/app/settings/profile",
    name: "Profile",
    mini: "PR",
    component: Profile,
    roles: ['Viewer', 'Admin', 'SuperAdmin', 'Editor']
  },
  {
    path: "/app/settings/notifications",
    name: "Notifications",
    mini: "NO",
    component: NotificationsPage,
    roles: ['Viewer', 'Admin', 'SuperAdmin']
  }
]

var adInsertionsViews = [
  {
    path: "/app/ad-insertion/channels/:channelId",
    name: "Channel",
    title: 'Channel Details',
    mini: "CH",
    component: Channels,
    roles: ['Admin', 'SuperAdmin', 'Editor'],
    sidebar: false
  },
  {
    path: "/app/ad-insertion/channels",
    name: "Channels",
    title: 'Channels',
    mini: "CH",
    component: Channels,
    roles: ['Admin', 'SuperAdmin', 'Editor']
  },
  {
    path: "/app/ad-insertion/systems/:systemId",
    name: "System",
    title: 'System Details',
    mini: "SY",
    component: Systems,
    roles: ['SuperAdmin'],
    sidebar: false
  },
  {
    path: "/app/ad-insertion/systems",
    name: "Systems",
    title: 'Systems',
    mini: "SY",
    component: Systems,
    roles: ['SuperAdmin']
  }
]

const baseRoutes = (role) => {
  return [
    {
      path: "/app/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      iconSelected: DashboardIcon,
      component: Dashboard,
      roles: ['Viewer', 'Admin', 'SuperAdmin', 'Editor']
    },
    {
      path: "/app/reports",
      name: "Reports",
      icon: AnalyticsIconGrey,
      iconSelected: AnalyticsIconRed,
      component: ReportsPage,
      roles: ['Viewer', 'Admin', 'SuperAdmin', 'Editor']
    },
    {
      collapse: true,
      path: "/app/ad-insertion",
      name: "Ad Insertion",
      state: "openAdInsertion",
      icon: SSAIChannelsIconGrey,
      iconSelected: SSAIChannelsIconRed,
      roles: ['Admin', 'SuperAdmin', 'Editor'],
      views: adInsertionsViews.filter(view => { return view.roles.includes(role) }),
      exact: true,
      component: Error404
    },
    {
      path: "/app/assets/:assetId/jobs/:jobId",
      name: "Asset - Job",
      title: 'Asset - Job Details',
      icon: Theaters,
      component: Assets,
      roles: ['Admin', 'SuperAdmin', 'Editor'],
      sidebar: false
    },
    {
      path: "/app/assets/:assetId/jobs",
      name: "Asset - Jobs",
      title: 'Asset Details',
      subTitle: 'Super Semi Asset',
      icon: Theaters,
      component: Assets,
      roles: ['Admin', 'SuperAdmin', 'Editor'],
      sidebar: false
    },
    {
      path: "/app/assets/:assetId/medias/:mediaId",
      name: "Assets - Media",
      title: 'Asset - Media Details',
      icon: Theaters,
      component: Assets,
      roles: ['Admin', 'SuperAdmin', 'Editor'],
      sidebar: false
    },
    {
      path: "/app/assets/:assetId/medias",
      name: "Asset - Medias",
      title: 'Asset Details',
      subTitle: 'Super Semi Asset',
      icon: Theaters,
      component: Assets,
      roles: ['Admin', 'SuperAdmin', 'Editor'],
      sidebar: false
    },
    {
      path: "/app/assets/:assetId",
      name: "Asset",
      title: 'Asset Details',
      subTitle: 'Super Semi Asset',
      icon: Theaters,
      component: Assets,
      roles: ['Admin', 'SuperAdmin', 'Editor'],
      sidebar: false
    },
    {
      path: "/app/assets",
      name: "Assets",
      title: 'Assets',
      icon: AssetsIconGrey,
      iconSelected: AssetsIconRed,
      component: Assets,
      roles: ['Admin', 'SuperAdmin', 'Editor']
    },
    {
      path: "/app/jobs/:jobId",
      name: "Job Details",
      title: 'Job Details',
      icon: Theaters,
      iconSelected: Theaters,
      component: Jobs,
      roles: ['Admin', 'SuperAdmin', 'Editor'],
      sidebar: false
    },
    {
      path: "/app/jobs",
      name: "Jobs",
      title: 'Jobs',
      icon: Theaters,
      iconSelected: Theaters,
      component: Jobs,
      roles: ['Admin', 'SuperAdmin', 'Editor']
    },
    {
      path: "/app/playlists/:playlistId",
      name: "Playlists (alpha)",
      icon: ListIcon,
      iconSelected: ListIcon,
      component: PlaylistsPage,
      roles: ['SuperAdmin'],
      sidebar: false
    },
    {
      path: "/app/playlists",
      name: "Playlists (alpha)",
      icon: ListIcon,
      iconSelected: ListIcon,
      component: PlaylistsPage,
      roles: ['SuperAdmin']
    },
    {
      path: "/app/workflows/:workflowId",
      name: "Workflow",
      title: 'Workflow Details',
      icon: LinearScale,
      iconSelected: LinearScale,
      component: Workflows,
      roles: ['SuperAdmin'],
      sidebar: false
    },
    {
      path: "/app/workflows",
      name: "Workflows",
      title: 'Workflows',
      icon: LinearScale,
      iconSelected: LinearScale,
      component: Workflows,
      roles: ['SuperAdmin']
    },
    {
      collapse: true,
      redirect: true,
      pathTo: '/app/settings/users',
      path: "/app/settings",
      name: "Settings",
      state: "openSettings",
      icon: SettingsIconGrey,
      iconSelected: SettingsIconRed,
      roles: ['Viewer', 'Admin', 'SuperAdmin', 'Editor'],
      views: settingsViews.filter(view => { return view.roles.includes(role) })
    },
    {
      bottom: true,
      path: "https://nowtilus.atlassian.net/servicedesk/customer/portal/2",
      name: "Support",
      icon: SupportIconGrey,
      iconSelected: SupportIconGrey,
      roles: ['Viewer', 'Admin', 'SuperAdmin', 'Editor']
    },
    {
      redirect: true,
      exact: true,
      path: "/app",
      pathTo: "/app/dashboard",
      name: "Dashboard",
      roles: ['Viewer', 'Admin', 'SuperAdmin', 'Editor'],
      sidebar: false
    },
    {
      sidebar: false,
      component: Error404
    }
  ]
}

const permissionedRoutes = () => {
  let rv = []
  let user = token.loadUserFromToken()
  if (user) {
    rv = baseRoutes(user.role).filter(route => {
      return typeof route.roles === 'undefined' || route.roles.includes(user.role)
    })
  }
  return rv
}

export default permissionedRoutes
