import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Assignment from '@material-ui/icons/Assignment'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import extendedTablesStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import Details from '../../views/Systems/SystemsDetails.jsx'
import TableWrapper from '../../components/TableWrapper/TableWrapper.jsx'
import backend from '../../utils/backend'
import { showConfirmationMessage } from '../../components/Alerts/Alerts'

class SystemsPage extends React.Component {
  state = {}
  toggleDisplay = systemId => {
    if (systemId) {
      this.props.history.push('/app/ad-insertion/systems/' + systemId)
    } else {
      this.props.history.push('/app/ad-insertion/systems')
    }
  }
  async beforeDelete (item, cb) {
    const medias = await backend.list({ resource: 'medias', createdBySystemId: item.id })
    if (medias.length > 0) {
      return showConfirmationMessage(
        () => cb(item.id),
        {
          text: `You have ${medias.length - 1} medias created with this System. If you delete the system you will not be able to delete them from the cloud storage.`
        }
      )
    }
    return showConfirmationMessage(() => cb(item.id))
  }
  render () {
    const systemId = this.props.match.params.systemId
    return (
      <GridContainer>
        {systemId ? (
          <Details
            {...this.props}
            id={systemId !== 'new' ? systemId : null}
            toggleDisplay={this.toggleDisplay}
          />
        ) : (
          <TableWrapper
            {...this.props}
            toggleDisplay={this.toggleDisplay}
            resource="systems"
            fields={['id', 'name', 'createdAt']}
            labels={['ID', 'Name', 'Created at']}
            goBack={this.goBack}
            rowsPerPage={10}
            resourceName={{ singular: 'System', plural: 'Systems' }}
            icon={Assignment}
            beforeDelete={this.beforeDelete.bind(this)}
          />
        )}
      </GridContainer>
    )
  }
}

export default withStyles(extendedTablesStyle)(SystemsPage)
