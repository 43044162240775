import React from "react"
import PropTypes from "prop-types"
import PerfectScrollbar from "perfect-scrollbar"
import { NavLink, Redirect } from "react-router-dom"
import cx from "classnames"
import withStyles from "@material-ui/core/styles/withStyles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Hidden from "@material-ui/core/Hidden"
import Collapse from "@material-ui/core/Collapse"
import Icon from "@material-ui/core/Icon"
import { componentWithContext } from '../../state/DispatchContext'

import HeaderLinks from "../../components/Header/HeaderLinks.jsx"
import sidebarStyle from "../../assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx"
import token from './../../utils/token'

import ssaiFullLogo from "../../assets/icons/serverside-ai-logo-full.svg"
import ssaiLogo from "../../assets/icons/serverside-ai-logo.svg"

import { ReactComponent as CollapseIcon } from "../../assets/icons/serverside-icon-chevron-black.svg"

const slideTimeout = 1000
var ps
class SidebarWrapper extends React.Component {
  componentDidMount () {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false
      })
    }
  }
  componentWillUnmount () {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy()
    }
  }
  render () {
    const { className, user, headerLinks, links } = this.props
    return (
      <div className={className} ref="sidebarWrapper">
        {user}
        {headerLinks}
        {links}
      </div>
    )
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openAvatar: false,
      openComponents: this.activeRoute("/components"),
      openForms: this.activeRoute("/forms"),
      openTables: this.activeRoute("/tables"),
      openMaps: this.activeRoute("/maps"),
      openPages: this.activeRoute("-page"),
      miniActive: true
    }
    this.activeRoute.bind(this)
  }
  componentDidMount () {
    let user
    try {
      user = token.loadUserFromToken()
      if (user) { this.setState({ user: user.name }) } // the old functionality set user to name
      else { this.setState({ noUser: true }) }
    } catch (err) {
      // TODO: error handling
      console.log(err.message)
    }
  }
  activeRoute (routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1
  }
  openCollapse (collapse) {
    var st = {}
    st[collapse] = !this.state[collapse]
    this.setState(st)
  }
  render () {
    if (this.state.noUser) {
      return <Redirect
        to="/login"
      />
    }

    const {
      classes,
      color,
      image,
      routes,
      bgColor,
      rtlActive,
      context
    } = this.props

    const sidebarMiniActive = context.state.ui.sidebarMiniActive
    const __sidebarMiniActive = context.state.ui.sidebarMiniActiveCatchHover ? this.state.miniActive : context.state.ui.sidebarMiniActive

    var links = (
      <List className={classes.list}>
        {routes.map((prop, key) => {
          if (prop.sidebar === false) {
            return null
          }
          if (prop.collapse) {
            const navLinkClasses =
              classes.itemLink +
              " " +
              cx({
                [classes.collapseActive]: this.activeRoute(prop.path),
                [classes.active]: this.activeRoute(prop.path)
              })
            const itemText =
              classes.itemText +
              " " +
              cx({
                [classes.itemTextMini]:
                  sidebarMiniActive && __sidebarMiniActive,
                [classes.itemTextMiniRTL]:
                  rtlActive && sidebarMiniActive && __sidebarMiniActive,
                [classes.itemTextRTL]: rtlActive
              })
            const collapseItemText =
              classes.collapseItemText +
              " " +
              cx({
                [classes.collapseItemTextMini]:
                  sidebarMiniActive && __sidebarMiniActive,
                [classes.collapseItemTextMiniRTL]:
                  rtlActive && sidebarMiniActive && __sidebarMiniActive,
                [classes.collapseItemTextRTL]: rtlActive
              })
            const itemIcon =
              classes.itemIcon +
              " " +
              cx({
                [classes.itemIconRTL]: rtlActive
              })
            const caret =
              classes.caret +
              " " +
              cx({
                [classes.caretRTL]: rtlActive
              })
            const PropIcon = this.activeRoute(prop.path) ? prop.iconSelected : prop.icon
            return (
              <ListItem key={key} className={classes.item}>
                <NavLink
                  to={"#"}
                  className={navLinkClasses}
                  onClick={() => this.openCollapse(prop.state)}
                >
                  <b
                    className={
                      caret +
                      " " +
                      (this.state[prop.state] ? classes.caretActive : "")
                    }
                  />
                  <ListItemIcon className={itemIcon}>
                    {typeof prop.icon === "string" ? (
                      <Icon>{prop.icon}</Icon>
                    ) : (
                        <PropIcon className={classes.propIcon} />
                      )}
                  </ListItemIcon>
                  <ListItemText
                    primary={prop.name}
                    disableTypography={true}
                    className={itemText}
                  />
                </NavLink>
                <Collapse in={this.state[prop.state]} unmountOnExit>
                  <List className={classes.list + " " + classes.collapseList}>
                    {prop.views.map((prop, key) => {
                      if (prop.sidebar === false || prop.redirect) {
                        return null
                      }
                      const navLinkClasses =
                        classes.collapseItemLink +
                        " " +
                        cx({
                          [classes[color]]: this.activeRoute(prop.path),
                          [classes.active]: this.activeRoute(prop.path)
                        })
                      const collapseItemMini =
                        classes.collapseItemMini +
                        " " +
                        cx({
                          [classes.collapseItemMiniRTL]: rtlActive
                        })
                      return (
                        <ListItem key={key} className={classes.collapseItem}>
                          <NavLink to={prop.path} className={navLinkClasses}>
                            {sidebarMiniActive && __sidebarMiniActive &&
                              <span className={cx(collapseItemMini, { [classes.collapseItemActive]: this.activeRoute(prop.path) })}>
                                {prop.mini}
                              </span>}
                            <ListItemText
                              primary={prop.name}
                              disableTypography={true}
                              className={cx(collapseItemText, { [classes.collapseItemActive]: this.activeRoute(prop.path) })}
                            />
                          </NavLink>
                        </ListItem>
                      )
                    })}
                  </List>
                </Collapse>
              </ListItem>
            )
          }
          const navLinkClasses =
            classes.itemLink +
            " " +
            cx({
              [classes[color]]: this.activeRoute(prop.path),
              [classes.active]: this.activeRoute(prop.path)
            })
          const itemText =
            classes.itemText +
            " " +
            cx({
              [classes.itemTextMini]:
                sidebarMiniActive && __sidebarMiniActive,
              [classes.itemTextMiniRTL]:
                rtlActive && sidebarMiniActive && __sidebarMiniActive,
              [classes.itemTextRTL]: rtlActive
            })
          const itemIcon =
            classes.itemIcon +
            " " +
            cx({
              [classes.itemIconRTL]: rtlActive
            })
          const PropIcon = this.activeRoute(prop.path) ? prop.iconSelected : prop.icon
          
          if(prop.bottom) {
            return (
              <ListItem key={key} className={classes.item} style={{
                position: "fixed",
                bottom: "0px",
                padding: "15px 10px 23px 19px",
                lineHeight: "1.5em",
                width: "318px"
              }}>
                <a href={prop.path} target="_blank" rel="noopener noreferrer" style={{color: "inherit", opacity:1}}>
                  <ListItemIcon className={itemIcon}>
                    <PropIcon className={classes.propIcon}/>
                  </ListItemIcon>
                  <ListItemText
                    primary={prop.name}
                    disableTypography={true}
                    className={itemText}
                  />
                </a>
              </ListItem>
            )
          } else {
            return (
              <ListItem key={key} className={classes.item}>
                <NavLink to={prop.path} className={navLinkClasses}>
                  <ListItemIcon className={itemIcon}>
                    {typeof PropIcon === "string" ? (
                      <Icon>{PropIcon}</Icon>
                    ) : (
                        <PropIcon className={classes.propIcon} />
                      )}
                  </ListItemIcon>
                  <ListItemText
                    primary={prop.name}
                    disableTypography={true}
                    className={itemText}
                  />
                </NavLink>
              </ListItem>
            )
          }
        })}
      </List>
    )

    const logoClasses =
      classes.logo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white",
        // [classes.grey]: !sidebarMiniActive
      })
    const sidebarCollapseIconMiniActive = cx(
      classes.sidebarSwitcher,
      classes.zeroBorder,
      {
        [classes.sidebarCollapseIconMiniActive]: sidebarMiniActive
      }
    )
    let brand
    const collapseIconOnClick = () => {
      context.actions.ui.toggleSidebarMiniActive(slideTimeout)()
    }
    const ssaiLogoBlock = ssaiLogoImg => sidebarMiniActive ?
      <div className={classes.collapseIconClickable} onClick={collapseIconOnClick}>{ssaiLogoImg}</div> :
      <a href="https://www.nowtilus.tv">{ssaiLogoImg}</a>
    //const 
    if (sidebarMiniActive && __sidebarMiniActive) {
      brand = (
        <div className={logoClasses}>
          {ssaiLogoBlock(<img src={ssaiLogo} alt="logo" style={{ width: "34.75px", height: "40px", marginLeft: "34px" }} className={classes.img} />)}
          <div className={cx(classes.sidebarCollapseIconDiv, classes.zeroBorder)}>
            <div className={sidebarCollapseIconMiniActive}>
              <CollapseIcon className={cx(classes.sidebarCollapseIcon, classes.zeroBorder)} />
            </div>
          </div>
        </div>
      )
    } else {
      brand = (
        <div className={logoClasses}>
          {ssaiLogoBlock(<img src={ssaiFullLogo} alt="logo" style={{ width: "176.28px", height: "40px", marginLeft: "34px" }} className={classes.img} />)}
          <div className={cx(classes.sidebarCollapseIconDiv, classes.zeroBorder)}>
            <div className={sidebarCollapseIconMiniActive}>
              <CollapseIcon onMouseDown={collapseIconOnClick} className={cx(classes.sidebarCollapseIcon, classes.zeroBorder)} />
            </div>
          </div>
        </div>
      )
    }


    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          sidebarMiniActive && __sidebarMiniActive,
        [classes.drawerPaperRTL]: rtlActive
      })
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          sidebarMiniActive && __sidebarMiniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      })
    return (
      <div ref="mainPanel" id="__sidebar">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"] + " " + classes.overflowYVisible
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              headerLinks={<HeaderLinks rtlActive={rtlActive} />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"] + " " + classes.overflowYVisible
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    )
  }
}

Sidebar.defaultProps = {
  bgColor: "blue"
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
    "grey"
  ]),
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object)
}

export default componentWithContext(withStyles(sidebarStyle)(Sidebar))