import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import Assignment from '@material-ui/icons/Assignment'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import AssetDetails from '../../views/Assets/AssetDetails.jsx'
import extendedTablesStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import TableWrapper from '../../components/TableWrapper/TableWrapper.jsx'

class AssetsPage extends React.Component {
  constructor() {
    super();
    this.state = {}
  }
  toggleDisplay = assetId => {
    if (assetId) {
      this.props.history.push('/app/assets/' + assetId)
    } else {
      this.props.history.push('/app/assets')
    }
  }
  render () {
    const assetId = this.props.match.params.assetId
    return (
      <GridContainer>
        {assetId
          ? <AssetDetails
            
            id={assetId !== 'new' ? assetId : ''}
            toggleDisplay={this.toggleDisplay}
            goBack={this.goBack} />
          : <TableWrapper
            
            resource={'titles'}
            toggleDisplay={this.toggleDisplay}
            fields={['name', 'type', 'state', 'id', 'createdAt']}
            labels={['Name', 'Type', 'State', 'ID', 'Created at']}
            goBack={this.goBack}
            rowsPerPage={10}
            resourceName={{ singular: 'Asset', plural: 'Assets' }}
            icon={Assignment}
            maxColumnWidth={'400px'}
          />
        }
      </GridContainer>
    )
  }
}
export default withStyles(extendedTablesStyle)(AssetsPage)
