import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Grow from '@material-ui/core/Grow'
import Hidden from '@material-ui/core/Hidden'
import Popper from '@material-ui/core/Popper'
import { ReactComponent as Notifications } from '../../assets/icons/serverside-icon-notification.svg'
import { ReactComponent as Person } from '../../assets/icons/serverside-icon-profile.svg'
import Button from '../../components/CustomButtons/Button.jsx'
import headerLinksStyle from '../../assets/jss/material-dashboard-pro-react/components/headerLinksStyle'
import cx from 'classnames'
import backend from '../../utils/backend'
import { withRouter } from 'react-router-dom'

class HeaderLinks extends React.Component {
  _isMounted = false
  state = {
    open: false,
    userOpen: false,
    notifications: [],
    notificationsCount: 0
  }
  handleClick = () => {
    this._isMounted && this.setState({ open: !this.state.open })
  }
  userMenuOpen = () => {
    this._isMounted && this.setState({ userOpen: !this.state.userOpen })
  }
  goto = page => {
    this.props.history.push('/app/' + page)
    this.handleClose()
  }

  handleClose = () => {
    this._isMounted && this.setState({ open: false, userOpen: false })
  }

  logout = () => {
    this.props.history.push('/login')
    localStorage.removeItem('token')
    localStorage.removeItem('refresh_token')
    this.handleClose()
  }

  async componentDidMount () {
    this._isMounted = true
    try {
      const notifications = await backend.list({
        resource: 'notifications',
        limit: 10,
        status: 'new'
      })
      this._isMounted && this.setState({
        notifications,
        notificationsCount: notifications ? notifications.totalCount : 0
      })
    } catch (ex) {
      console.error('Notifications not loaded', ex)
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { classes, rtlActive } = this.props
    const { open, userOpen } = this.state
    const dropdownItem = classNames(
      classes.dropdownItem,
      classes.primaryHover,
      { [classes.dropdownItemRTL]: rtlActive }
    )
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    })
    const managerClasses = classNames({
      [classes.managerClasses]: true
    })
    return (
      <div className={wrapper}>
        <div className={managerClasses}>
          <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={open ? 'menu-list' : null}
            aria-haspopup="true"
            onClick={this.handleClick}
            className={cx(classes.svgButton, rtlActive ? classes.buttonLinkRTL : classes.buttonLink)}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : ''
            }}
            buttonRef={node => {
              this.anchorEl = node
            }}
          >
            <Notifications
              className={
                classes.headerLinksSvg +
                ' ' +
                (rtlActive
                  ? classes.links + ' ' + classes.linksRTL
                  : classes.links)
              }
            />
            <span className={classes.notifications}>
              {this.state.notificationsCount > 9 ? '9+' : this.state.notificationsCount}
            </span>
            <Hidden mdUp implementation="css">
              <span onClick={this.handleClick} className={classes.linkText}>
                Notification
              </span>
            </Hidden>
          </Button>
          <Popper
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !open,
              [classes.pooperResponsive]: true,
              [classes.pooperNav]: true
            })}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list"
                style={{ transformOrigin: '0 0 0' }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList role="menu">
                      {this.state.notifications.map((notification, i) => {
                        return (
                          <MenuItem
                            key={i}
                            onClick={this.handleClose}
                            className={dropdownItem}
                          >
                            {' '}
                            {notification.topic}
                          </MenuItem>
                        )
                      })}
                      <MenuItem
                        onClick={this.goto.bind(this, 'settings/notifications')}
                        className={dropdownItem}
                      >
                        ... View All
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        <div className={managerClasses}>
          <Button
            color="transparent"
            justIcon
            aria-label="User"
            aria-owns={userOpen ? 'user-list' : null}
            aria-haspopup="true"
            onClick={this.userMenuOpen}
            className={cx(classes.svgButton, classes.buttonLink)}
            muiClasses={{
              label: ''
            }}
            buttonRef={node => {
              this.userAnchorEl = node
            }}
          >
            <Person className={classes.headerLinksSvg + ' ' + classes.links} />
          </Button>
          <Popper
            open={userOpen}
            anchorEl={this.userAnchorEl}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !userOpen,
              [classes.pooperResponsive]: true,
              [classes.pooperNav]: true
            })}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="user-list"
                style={{ transformOrigin: '0 0 0' }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={this.goto.bind(this, 'settings/profile')}
                        className={dropdownItem}
                      >
                        {' '}
                        Profile
                      </MenuItem>
                      <MenuItem onClick={this.logout} className={dropdownItem}>
                        {' '}
                        Logout
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    )
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
}

export default withRouter(withStyles(headerLinksStyle)(HeaderLinks))
