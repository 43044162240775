const attr = (element, params) => Object.keys(params).reduce((acc, curr) => acc.attr(curr, params[curr]), element)

class Tooltip {
  constructor ({ d3, tooltipRectParams, tooltipCircleParams, tooltipKeyParams, tooltipValueParams, tooltipKey2Params, tooltipValue2Params, _show
  }) {
    const _g = d3.create("svg:g")
      .attr('font-size', 10)
      .attr('pointer-events', 'none')
      .attr('text-anchor', 'start')
      .attr('font-size', 14)
      .attr('display', 'none')
    const _filter = _g.append('svg:filter')
    _filter
      .attr('id', 'f3')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '200%')
      .attr('height', '200%')
    _filter.append('svg:feOffset')
      .attr('result', 'offOut')
      .attr('in', 'SourceGraphic')
      .attr('dx', 2)
      .attr('dy', 2)
    _filter.append('svg:feColorMatrix')
      .attr('result', 'matrixOut')
      .attr('in', 'offOut')
      .attr('type', 'matrix')
      .attr('values', '0.7 0 0 0 0 0 0.7 0 0 0 0 0 0.7 0 0 0 0 0 0.8 0')
    _filter.append('svg:feGaussianBlur')
      .attr('result', 'blurOut')
      .attr('mode', 'normal')
    _filter.append('svg:feBlend')
      .attr('in', 'SourceGraphic')
      .attr('in2', 'blurOut')
      .attr('mode', 'normal')
    this.rect = attr(_g.append('svg:rect')
      .attr('filter', 'url(#f3)')
      .attr('fill', '#f2f4f7ee'), tooltipRectParams).node()

    tooltipCircleParams && (this._circle = attr(_g.append("circle")
      .attr("fill", "#ff0066")
      .attr("stroke", "none"), tooltipCircleParams).node())

    tooltipKeyParams && (this._key = attr(_g.append('svg:text')
      .attr('fill', '#979ba0'), tooltipKeyParams).node())

    tooltipValueParams && (this._value = attr(_g.append('svg:text')
      .attr('fill', '#ff0066')
      .attr('font-weight', 600), tooltipValueParams).node())

    tooltipKey2Params && (this._key2 = attr(_g.append('svg:text')
      .attr('fill', '#979ba0'), tooltipKey2Params).node())

    tooltipValue2Params && (this._value2 = attr(_g.append('svg:text')
      .attr('fill', '#ff0066')
      .attr('font-weight', 600), tooltipValue2Params).node())

    this.node = _g.node()
    this._show = _show
    this.tooltipRectParams = tooltipRectParams
    this.tooltipKeyParams = tooltipKeyParams
    this.tooltipValueParams = tooltipValueParams
  }

  show (d) {
    this.node.removeAttribute("display")
    if (typeof this._show === 'function') {
      this._show.call(this, d)
    }
  }

  hide () {
    this.node.setAttribute("display", "none")
  }
}

export default Tooltip