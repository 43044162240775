import React, { Component } from 'react'
import GridItem from '../../components/Grid/GridItem.jsx'
import Button from '../../components/CustomButtons/Button.jsx'
import Card from '../../components/Card/Card.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import withStyles from '@material-ui/core/styles/withStyles'
import extendedTablesStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import DataConnector from './../../utils/DataConnector'
import ResourceDetails from '../../components/ResourceDetails/ResourceDetails'
import { showSuccessMessage, showErrorMessage } from '../../components/Alerts/Alerts'

class MediaDetails extends Component {
  constructor(props) {
    super(props)

    this.dataConnector = {}
    this.dataParams = {
      model: 'medias',
      caption: { singular: 'media', plural: 'medias' }
    }
    this.state = { error: false, isLoaded: false, data: {}, schema: {} }
  }

  async componentDidMount () {
    this.dataConnector = new DataConnector(this.dataParams, this, showErrorMessage, showSuccessMessage)
    this.setState({ schema: await this.dataConnector.getSchema() })
    await this.dataConnector.loadItem(this.props.id)
  }

  render () {
    const { classes } = this.props
    const { error, data, schema } = this.state
    const { handleChange, handleSave } = this.dataConnector

    let content

    if (error) {
      content = <CardBody>Error in loading</CardBody>
    }

    if (this.state.status === 404) {
      content = <CardBody>Media not found</CardBody>
    } else if (data && schema) {
      content = (<CardBody>
        <ResourceDetails
          schema={schema}
          data={data}
          handleChange={handleChange}
          classes={classes}
        />
        <Button
          style={{ margin: '20px 10px' }}
          variant="contained"
          color="success"
          onClick={handleSave}
        >
          Save
        </Button>{' '}
      </CardBody>)
    }

    return (
      <GridItem xs={12}>
        <Card>
          {content}
        </Card>
      </GridItem>
    )

  }
}

export default withStyles(extendedTablesStyle)(MediaDetails)
