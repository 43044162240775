import LoginPage from "../views/Pages/LoginPage.jsx";
import RegisterPage from "../views/Pages/RegisterPage.jsx";
import Error404Page from "../views/Pages/Error404Page.jsx";

import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";
const pagesRoutes = [
  { 
    exact: true, 
    path: "/", 
    component: LoginPage,
    useInHeader: false
  },
  {
    path: "/register",
    name: "Register Page",
    short: "Register",
    mini: "RP",
    icon: PersonAdd,
    component: RegisterPage,
    useInHeader: false
  },
  {
    path: "/login",
    name: "Login Page",
    short: "Login",
    mini: "LP",
    icon: Fingerprint,
    component: LoginPage
  },
  {
    component: Error404Page,
    path: "/",
    useInHeader: false
  }
];

export default pagesRoutes;
