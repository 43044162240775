import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Assignment from '@material-ui/icons/Assignment'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import extendedTablesStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import Details from '../../views/Workflows/WorkflowDetails.jsx'
import TableWrapper from '../../components/TableWrapper/TableWrapper.jsx'

class WorkflowsPage extends React.Component {
  state={}
  toggleDisplay = workflowId => {
    if (workflowId) {
      this.props.history.push('/app/workflows/' + workflowId)
    } else {
      this.props.history.push('/app/workflows')
    }
  }
  render() {
    const workflowId = this.props.match.params.workflowId
    return (
      <GridContainer>
        {workflowId ? (
          <Details
            id={workflowId !== 'new' ? workflowId : null}
            toggleDisplay={this.toggleDisplay}
          />
        ) : (
          <TableWrapper 
            resource="flows"
            fields={['id', 'name', 'createdAt']}
            labels={['ID', 'Name', 'Created at']}
            toggleDisplay={this.toggleDisplay}
            goBack={()=>this.toggleDisplay()}
            rowsPerPage={10}
            resourceName={ { singular: 'Flow', plural: 'Flows' } }
            icon={Assignment}
          />
        )}
      </GridContainer>
    )
  }
}
export default withStyles(extendedTablesStyle)(WorkflowsPage)