import React, { createContext } from 'react'

const DispatchContext = createContext(null)

/**
 * Use this HOC to connect class components to context
 * @param {*} WrappedComponent 
 */
export const componentWithContext = function (WrappedComponent) {
  return class extends React.Component {
    render () {
      return <DispatchContext.Consumer>
        {context => {
          if (context === undefined) {
            throw new Error('componentWithContext must be used within a DispatchContext')
          }
          return <WrappedComponent {...this.props} context={context} />
        }}
      </DispatchContext.Consumer>
    }
  }
}

export default DispatchContext