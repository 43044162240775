import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import cx from 'classnames'
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import { noMP, cYbnd, cY, transition } from '../../assets/jss/material-dashboard-pro-react'


const styles = theme => ({
  noMP, cYbnd, cY, transition,
  reportText: {
    ...cY,
    fontSize: 12,
    letterSpacing: 0,
    color: '#979ba0',
    marginTop: 3,
    marginLeft: 5,
    top: '10%',
    transform: 'translateY(-50%)'
  },
  reportNumber: {
    ...cY,
    fontSize: 54,
    fontWeight: 600,
    letterSpacing: 0,
    color: '#6282b3',
    marginTop: 20,
    marginLeft: 5,
    top: '40%',
    transform: 'translateY(-50%)'
  }
})

const SSAIMainKPI = ({ classes, data }) => {
  let vals = ['...', '...', '...', '...']
  let labels = ['...', '...', '...', '...']

  if (data && data.series && data.series[0] && data.series[0].data && data.series[0].data.length === 4) {
    vals = data.series[0].data.map(x => {
      if(x && typeof x === 'string') {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      }
      return x
    })
  }

  if (data && data.labels && data.labels.length === 4) {
    labels = data.labels
  }

  return (
    <GridContainer className={classes.noMP} style={{ width: '100%', height: '100%' }} direction="column">
      <GridItem className={classes.noMP} style={{ height: '50%', borderBottom: '1px solid #dadfe6' }}>
        <GridContainer className={classes.noMP} direction="row" style={{ height: '100%' }}>
          <GridItem className={cx(classes.noMP, classes.cYbnd)} style={{ width: '50%', height: '100%', borderRight: '1px solid #dadfe6' }} >
            <div className={classes.reportText}>{labels[0]}</div>
            <div className={classes.reportNumber}>{vals[0]}</div>
          </GridItem>
          <GridItem className={cx(classes.noMP, classes.cYbnd)} style={{ width: '50%' }} >
            <div className={classes.reportText} style={{ marginLeft: 20 }}>{labels[1]}</div>
            <div className={classes.reportNumber} style={{ marginLeft: 20 }}>{vals[1]}</div></GridItem>
        </GridContainer>
      </GridItem>
      <GridItem className={classes.noMP} style={{ height: '50%' }}>
        <GridContainer className={classes.noMP} direction="row" style={{ height: '100%' }}>
          <GridItem className={cx(classes.noMP, classes.cYbnd)} style={{ width: '50%', height: '100%', borderRight: '1px solid #dadfe6' }} >
            <div className={classes.reportText}>{labels[2]}</div>
            <div className={classes.reportNumber}>{vals[2]}</div>
          </GridItem>
          <GridItem className={cx(classes.noMP, classes.cYbnd)} style={{ width: '50%' }} >
            <div className={classes.reportText} style={{ marginLeft: 20 }}>{labels[3]}</div>
            <div className={classes.reportNumber} style={{ marginLeft: 20 }}>{vals[3]}</div></GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}

export default withStyles(styles)(SSAIMainKPI)
